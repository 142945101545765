import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { User } from '../types/User';
import { isCurrentUserAdmin, isCurrentUserManager, isIdeaRoomUser } from '../utils/userUtils';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { I18nKeys } from './I18nKeys';

export enum MenuItems {
  ClientData = 'CLIENT_DATA',
  Dealers = 'DEALERS',
  Groups = 'GROUPS',
  Leads = 'LEADS',
  Orders = 'ORDERS',
  Usage = 'USAGE',
  Users = 'USERS',
  Payments = 'Payments',
  Pricing = 'PRICING',
  Reports = 'REPORTS',
  Settings = 'SETTINGS',
  Sites = 'SITES',
}

export const menuItems = {
  [MenuItems.Groups]: {
    visible: (_user: User, isIdearoomGroup: boolean) => isIdearoomGroup,
    key: 'Groups',
    i18nKey: () => I18nKeys.MenuGroupsOption,
    ideaRoomOnly: () => false,
  },
  [MenuItems.Leads]: {
    visible: (_user: User, isIdearoomGroup: boolean) => !isIdearoomGroup,
    key: 'Leads',
    i18nKey: () => I18nKeys.MenuLeadsOption,
    ideaRoomOnly: () => false,
  },
  [MenuItems.Orders]: {
    visible: (user: User, isIdearoomGroup: boolean) => !isIdearoomGroup && isIdeaRoomUser(user),
    key: 'Orders',
    i18nKey: () => I18nKeys.MenuOrdersOption,
    ideaRoomOnly: () => true,
  },
  [MenuItems.Payments]: {
    visible: (user: User, isIdearoomGroup: boolean) => !isIdearoomGroup && isIdeaRoomUser(user),
    key: 'Payments',
    i18nKey: () => I18nKeys.MenuPaymentsOption,
    ideaRoomOnly: () => true,
  },
  [MenuItems.Usage]: {
    visible: (user: User, isIdearoomGroup: boolean) =>
      !isIdearoomGroup && (isIdeaRoomUser(user) || isCurrentUserAdmin(user)),
    key: 'Usage',
    i18nKey: () => I18nKeys.MenuUsageOption,
    ideaRoomOnly: () => false,
  },
  [MenuItems.Dealers]: {
    visible: (user: User, _: boolean, configurators: any[]) =>
      configurators.some((c) =>
        getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.DealerNetworkEnabled, true),
      ) &&
      (isIdeaRoomUser(user) || isCurrentUserAdmin(user)),
    key: 'Dealers',
    i18nKey: () => I18nKeys.MenuDealersOption,
    ideaRoomOnly: () => false,
  },
  [MenuItems.Users]: {
    visible: (user: User, isIdearoomGroup: boolean) =>
      !isIdearoomGroup && (isIdeaRoomUser(user) || isCurrentUserAdmin(user) || isCurrentUserManager(user)),
    key: 'Users',
    i18nKey: () => I18nKeys.MenuUsersOption,
    ideaRoomOnly: () => false,
  },
  [MenuItems.Pricing]: {
    visible: (user: User, isIdearoomGroup: boolean, configurators: any[]) =>
      !isIdearoomGroup &&
      (isCurrentUserAdmin(user) || isIdeaRoomUser(user)) &&
      configurators.some((c) => getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.PricingEnabled, true)),
    key: 'Pricing',
    i18nKey: () => I18nKeys.MenuPricingOption,
    ideaRoomOnly: () => false,
  },
  [MenuItems.Sites]: {
    visible: (user: User, isIdearoomGroup: boolean) =>
      !isIdearoomGroup && (isIdeaRoomUser(user) || isCurrentUserAdmin(user)),
    key: 'Sites',
    i18nKey: () => I18nKeys.MenuSitesOption,
    ideaRoomOnly: () => false,
  },
  [MenuItems.ClientData]: {
    visible: (user: User) => isIdeaRoomUser(user),
    key: 'Client-Data',
    i18nKey: () => I18nKeys.MenuClientDataOption,
    ideaRoomOnly: () => true,
  },
  [MenuItems.Reports]: {
    visible: (_user: User, isIdearoomGroup: boolean) => isIdearoomGroup,
    key: 'Reports',
    i18nKey: () => I18nKeys.MenuReportsOption,
    ideaRoomOnly: () => false,
  },
  [MenuItems.Settings]: {
    visible: (user: User, _: boolean, _configurators: any[], selectedGroupId: string) =>
      /* TODO: Add back in commented line after soft release */
      /* {(isIdeaRoomUser(user) || isCurrentUserAdmin(user)) && ( */
      isIdeaRoomUser(user) ||
      (['EagleCarports', 'Hillhout', 'IdeaRoom'].includes(selectedGroupId) && isCurrentUserAdmin(user)),
    key: 'Settings',
    i18nKey: () => I18nKeys.MenuSettingsOption,
    ideaRoomOnly: (user: User, selectedGroupId: string) =>
      isIdeaRoomUser(user) && !['EagleCarports', 'Hillhout', 'IdeaRoom'].includes(selectedGroupId),
  },
};
