import { Box, Card, CardActions, CardContent, CardHeader, CircularProgress, Hidden } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Dialogs } from '../constants/Dialogs';
import { getPermissionLabel } from '../constants/Permissions';
import { openDialog } from '../ducks/dialogSlice';
import { setMemberDialog } from '../ducks/group';
import { GroupMember } from '../types/GroupMember';
import { SortableProperty } from '../types/SortableProperty';
import { SortProperty } from '../types/SortProperty';
import { sortRows } from '../utils/sortUtils';
import { GroupMemberMenu } from './GroupMemberMenu';
import { Header, Table } from './Table';
import { TableControls } from './TableControls';
import { Dealer } from '../types/Dealer';
import { GroupMemberFilterMenu } from './GroupMemberFilterMenu';
import { I18nKeys } from '../constants/I18nKeys';
import { StatsDashboard } from './StatsDashboard';
import { useAppDispatch, useAppSelector } from '../hooks';

interface UserActionCellProps {
  row: GroupMember;
  showGroupMemberMenu: boolean;
}

const getUserActionComponent = (): React.FC<any> => {
  const UserActionCell: React.FC<UserActionCellProps> = ({ row, showGroupMemberMenu = true }: UserActionCellProps) => {
    const member = row as GroupMember;
    const { updating } = member;
    return (
      <div style={{ display: 'flex' }}>
        {updating && <CircularProgress size={24} color="primary" />}

        {!updating && showGroupMemberMenu && <GroupMemberMenu member={member} />}
      </div>
    );
  };

  return UserActionCell;
};

interface CustomCellProps {
  row: GroupMember;
}

const getPermissionCellComponent = (): React.FC<any> => {
  const PermissionsCell: React.FC<CustomCellProps> = ({ row }: CustomCellProps) => {
    const member = row as GroupMember;
    return (
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Box>{member.permissions.map((p) => getPermissionLabel(p)).join(', ')}</Box>
      </div>
    );
  };

  return PermissionsCell;
};

const getDealerLabels = (dealerKey: string, dealers: Dealer[]): string => {
  const dealer = dealers.find((deal) => dealerKey === deal.key);
  return dealer ? dealer.name : dealerKey;
};

const getDealerCellComponent = (dealers: Dealer[]): React.FC<any> => {
  const DealerCell: React.FC<CustomCellProps> = ({ row }: CustomCellProps) => {
    const member = row as GroupMember;
    return (
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Box>{member.dealers.map((dealer) => getDealerLabels(dealer, dealers)).join(', ')}</Box>
      </div>
    );
  };

  return DealerCell;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: { flex: 1 },
  button: {
    color: theme.palette.text.primary,
  },
  memberCards: {
    flex: 1,
    paddingBottom: '70px',
  },
  memberCard: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  memberCardHeader: { paddingBottom: '0' },
  memberCardActions: { paddingTop: '0' },
  memberCardContent: {
    paddingBottom: '0',
    paddingTop: '0',
  },
  memberCardContentLine: {
    color: theme.palette.text.secondary,
  },
}));

interface Props {
  loading: boolean;
  members: GroupMember[];
  statsAndStatus?: boolean;
}

export const GroupMembersTable: React.FC<Props> = ({ loading, members, statsAndStatus = false }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { availableDealers: dealers = [] } = useAppSelector((state) => state.currentUser);

  const [sortState, setSortState] = React.useState<SortProperty[]>([]);
  const sortableProperties: SortableProperty[] = [
    { i18nKey: I18nKeys.TableHeaderName, property: 'name' },
    { i18nKey: I18nKeys.TableHeaderEmail, property: 'email' },
    { i18nKey: I18nKeys.TableHeaderDealers, property: 'dealers' },
    { i18nKey: I18nKeys.TableHeaderPermissions, property: 'permissions' },
    { i18nKey: I18nKeys.TableHeaderStatus, property: 'status' },
  ];

  const headers: Header[] = [
    { i18nKey: I18nKeys.TableHeaderName, property: 'name' },
    { i18nKey: I18nKeys.TableHeaderEmail, property: 'email' },
    { i18nKey: I18nKeys.TableHeaderPhone, property: 'phone' },
    {
      i18nKey: I18nKeys.TableHeaderDealers,
      property: 'dealers',
      CellComponent: getDealerCellComponent(dealers),
      hidden: dealers.length < 1,
    },
    {
      i18nKey: I18nKeys.TableHeaderPermissions,
      property: 'permissions',
      CellComponent: getPermissionCellComponent(),
    },
    { i18nKey: '', property: 'actions', CellComponent: getUserActionComponent() },
  ];

  const openUserDialog = (member: GroupMember): void => {
    dispatch(setMemberDialog(member));
    dispatch(openDialog({ dialog: Dialogs.User }));
  };

  const stats = [];
  if (statsAndStatus) {
    headers.splice(5, 0, { i18nKey: I18nKeys.TableHeaderStatus, property: 'status' });

    const totalMembers = members.length;
    const activeMembers = members.filter((member) =>
      ['CONFIRMED', 'RESET_REQUIRED'].includes(member.status || ''),
    ).length;
    const inactiveMembers = members.filter((member) => member.status === 'FORCE_CHANGE_PASSWORD').length;

    stats.push({ i18nKey: I18nKeys.StatsTotalMembers, value: totalMembers });
    stats.push({ i18nKey: I18nKeys.StatsActiveMembers, value: activeMembers });
    stats.push({ i18nKey: I18nKeys.StatsInactiveMembers, value: inactiveMembers });
  }

  const handleSort = (sortProperties: SortProperty[]): void => {
    sortRows(members, sortProperties);
    setSortState(sortProperties);
  };

  return (
    <div className={classes.root}>
      {statsAndStatus && <StatsDashboard stats={stats} />}
      <TableControls
        filterRows={<GroupMemberFilterMenu count={members.length} />}
        sortProperties={sortState}
        sortableProperties={sortableProperties}
        onSort={handleSort}
      />
      <Hidden smDown>
        <Table
          headers={headers}
          sortProperties={sortState}
          sortableProperties={sortableProperties}
          onSort={handleSort}
          rows={members}
          loading={loading}
          handleRowClick={openUserDialog}
        />
      </Hidden>
      <Hidden smUp>
        <div className={classes.memberCards}>
          {members.map((member) => {
            const DealersComponent = getDealerCellComponent(dealers);
            const PermissionsComponent = getPermissionCellComponent();
            const UserActionComponent = getUserActionComponent();
            return (
              <Card className={classes.memberCard} key={member.username}>
                <CardHeader
                  className={classes.memberCardHeader}
                  action={<GroupMemberMenu member={member} />}
                  title={member.name}
                />
                <CardContent className={classes.memberCardContent}>
                  <Box className={classes.memberCardContentLine}>{member.email}</Box>
                </CardContent>
                <CardContent className={classes.memberCardContent}>
                  <Box className={classes.memberCardContentLine}>{member.phone}</Box>
                </CardContent>
                <CardContent className={classes.memberCardContent}>
                  {/* <Box className={classes.memberCardContentLine}>{getDealerLabels(member.dealers, dealers)}</Box> */}
                  <DealersComponent row={member} value={member.dealers} />
                </CardContent>
                <CardContent className={classes.memberCardContent}>
                  <PermissionsComponent row={member} value={member.permissions} />
                </CardContent>
                <CardActions disableSpacing className={classes.memberCardActions}>
                  <UserActionComponent row={member} showGroupMemberMenu={false} />
                </CardActions>
              </Card>
            );
          })}
        </div>
      </Hidden>
    </div>
  );
};
