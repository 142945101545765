import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { PriceCalculation } from '@idearoom/types';
import { Check } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Table } from './Table';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { setSelectedComponentId } from '../ducks/pricingSlice';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import {
  BasicPriceColumns,
  DisplayColumns,
  HelperColumns,
  PricingCalculationColumns,
  PricingColumns,
  componentColumnMap,
} from '../constants/ComponentPricing';
import { I18nKeys } from '../constants/I18nKeys';
import { areDiffValuesDifferent, getRowDiffColumnToFromValues } from '../utils/clientDataUtils';
import { ComponentCategoryItem } from '../types/ComponentPricing';
import {
  displayVaryByRegion,
  filterComponentCategoryItems,
  getDefaultParsedComponentPricingColumnValue,
  getPricingTypeLabel,
  pricingVariesByRegion,
} from '../utils/componentPricingUtils';
import { useComponentPricingRepo } from '../hooks/useComponentPricingRepo';
import { SortProperty } from '../types/SortProperty';
import { sortRows } from '../utils/sortUtils';
import { AppState } from '../types/AppState';
import { formatPrice, hasDuplicateLabels } from '../utils/pricingUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';

const useStyles = makeStyles({
  content: {
    flex: 1,
    minHeight: 0,
  },
  tableGrid: {
    height: '100%',
  },
  modified: {
    backgroundColor: '#E4A76733 !important',
  },
  cell: {
    '&:first-child': {
      paddingLeft: '32px',
    },
    '&:last-child': {
      paddingRight: '32px',
    },
  },
  componentLabel: {
    fontSize: '14px',
  },
  componentKey: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  priceHeader: {
    textAlign: 'right',
    paddingRight: '4px',
  },
  priceCalculationCell: {
    textAlign: 'left',
    paddingLeft: '6px',
  },
  priceCell: {
    paddingRight: '26px',
    textAlign: 'right',
  },
  variesByRegionHeader: {
    textAlign: 'center',
  },
  variesByRegionCell: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

type Props = {
  searchValue?: string;
};

export const PricingComponentCategoryItems: React.FC<Props> = ({ searchValue = '' }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    viewer: { currency },
    pricing: {
      component: { region },
    },
  } = useAppSelector((state: AppState) => state);

  const { clientTableColumns } = useClientDataRepo({
    useClientTablesColumns: true,
  });
  const {
    componentCategoryItems = [],
    componentCategoryItemDiffs = [],
    clientUpdateRegions: regions,
  } = useComponentPricingRepo({
    useComponentCategoryItemDiffs: true,
    useSelectedComponentCategoryItems: true,
    useClientUpdateRegions: true,
  });
  const priceColumn = regions?.find(({ regionKey }) => regionKey === region)?.priceColumn || PricingColumns.Price;

  const [filteredComponents, setFilteredComponents] = useState(componentCategoryItems);

  useEffect(() => {
    setFilteredComponents(filterComponentCategoryItems(searchValue, componentCategoryItems));
  }, [componentCategoryItems, searchValue]);

  const headers = useMemo(
    () =>
      [
        ...componentCategoryItems
          .flatMap((row) => Object.entries(row))
          .reduce((acc, [key, value]) => {
            // Column must have a value to be displayed
            if (!acc.includes(key) && PricingColumns.UpgradePrice === key && (!!value || value === 0)) {
              return [...acc, key];
            }
            if (!acc.includes(key) && ([DisplayColumns.Label, PricingColumns.Price] as string[]).includes(key)) {
              return [...acc, key];
            }
            return acc;
          }, [] as string[]),
        PricingCalculationColumns.PriceCalculation,
        ...(componentCategoryItems.some(({ table }) => displayVaryByRegion(regions || [], clientTableColumns[table]))
          ? [HelperColumns.VariesByRegion]
          : []),
      ]
        .sort((a, b) => (componentColumnMap[a].order || 0) - (componentColumnMap[b].order || 0))
        .map((column: string) => {
          let label = compoundCaseToTitleCase(column);
          if (Object.values(PricingCalculationColumns).some((v) => v === column)) {
            label = t(I18nKeys.PricingCalculationField);
          }
          return {
            i18nKey: label,
            property: column,
            sortKey: column === DisplayColumns.Label ? 'sortLabel' : column,
            valueFormatter: (property: string, row: any): string => {
              if (property === PricingCalculationColumns.PriceCalculation) {
                return getPricingTypeLabel(row[property]).toLowerCase();
              }
              // Don't display 0 for upgrade price if it's not set
              if (property === PricingColumns.UpgradePrice && !row[property] && row[property] !== 0) {
                return '';
              }
              if ((Object.values(PricingColumns) as string[]).includes(property)) {
                return formatPrice(row[property], currency, 0);
              }
              return row[property];
            },
          };
        }),
    [componentCategoryItems, regions, clientTableColumns, t],
  );

  const getHeaderClass = useCallback(
    (property: string): string => {
      const classList: string[] = [classes.cell];

      if (BasicPriceColumns.includes(property)) {
        classList.push(classes.priceHeader);
      } else if (property === PricingCalculationColumns.PriceCalculation) {
        classList.push(classes.priceCalculationCell);
      } else if (property === HelperColumns.VariesByRegion) {
        classList.push(classes.variesByRegionHeader);
      }

      return classList.join(' ');
    },
    [classes],
  );

  const getCellClass = useCallback(
    (row: any, property: string): string => {
      const classList: string[] = [classes.cell];

      const diff = componentCategoryItemDiffs
        .flatMap(({ changes }) => changes || [])
        .find((d) => d.rowId === row[ClientDataFixedColumns.RowId]);

      if (
        diff &&
        Object.keys(row).some((column) => {
          const columnDiff = getRowDiffColumnToFromValues(diff, column);
          return areDiffValuesDifferent(columnDiff?.from, columnDiff?.to);
        })
      ) {
        classList.push(classes.modified);
      }

      if (BasicPriceColumns.includes(property)) {
        classList.push(classes.priceCell);
      } else if (property === PricingCalculationColumns.PriceCalculation) {
        classList.push(classes.priceCalculationCell);
      } else if (property === HelperColumns.VariesByRegion) {
        classList.push(classes.variesByRegionCell);
      }

      return classList.join(' ');
    },
    [componentCategoryItemDiffs, classes, componentCategoryItems],
  );

  const rows = useMemo(
    () =>
      filteredComponents
        .map((row) =>
          Object.fromEntries(
            Object.keys(row).map((column) => [
              column,
              getDefaultParsedComponentPricingColumnValue(column as keyof ComponentCategoryItem, row),
            ]),
          ),
        )
        .map((row) => {
          const showKey = hasDuplicateLabels(row, componentCategoryItems);
          // Display default price calculation for tables without price calculation
          return {
            [PricingCalculationColumns.PriceCalculation]: PriceCalculation.Amount,
            ...row,
            label: showKey ? (
              <Typography className={classes.componentLabel}>
                {row.label} <span className={classes.componentKey}> ({row.key})</span>
              </Typography>
            ) : (
              row.label
            ),
            sortLabel: `${row.label}${showKey ? ` (${row.key})` : ''}`,
            // The displayed price is the region specific price (if any) or the default price
            [PricingColumns.Price]: row[priceColumn] || row[PricingColumns.Price],
            [HelperColumns.VariesByRegion]: pricingVariesByRegion(row) ? <Check /> : null,
          };
        }),
    [filteredComponents, priceColumn],
  );

  const [sortState, setSortState] = React.useState<SortProperty[]>([]);

  const handleSort = (sortProperties: SortProperty[]): void => {
    sortRows(rows, sortProperties);
    setSortState(sortProperties);
  };

  return (
    <Grid container className={classes.content} spacing={1}>
      <Grid item xs={12} className={classes.tableGrid}>
        <Table
          headers={headers}
          rows={rows}
          loading={false}
          sortProperties={sortState}
          sortableProperties={headers}
          onSort={handleSort}
          handleRowClick={(row): void => {
            dispatch(setSelectedComponentId(row[ClientDataFixedColumns.RowId]));
            dispatch(openDialog({ dialog: Dialogs.PricingComponentEdit }));
          }}
          getCellClass={getCellClass}
          getHeaderClass={getHeaderClass}
          noDataMessage={I18nKeys.PricingComponentNoComponentsFound}
        />
      </Grid>
    </Grid>
  );
};
