import { QueryParamsToPassBy } from './QueryParamsToPassBy';

export interface Order {
  readonly billingAddress: string;
  readonly customerEmail: string;
  readonly customerFullName: string;
  readonly customerName: string;
  readonly customerPhone: string;
  readonly salesRepEmail: string;
  readonly date: string;
  readonly emailId: string;
  readonly hash: string;
  readonly link: string;
  readonly notes: string;
  readonly shippingAddress: string;
  readonly city: string;
  readonly zipCode: string;
  readonly versionedEmailId: string;
  readonly ipAddress: string;
  readonly dealerKey: string;
  readonly dealerName: string;
  readonly dealerEmail: string;
  readonly clientId: string;
  readonly buildingImage: string;
  readonly buildingSize: string;
  readonly totalPrice: number;
  readonly buildingStyle: string;
  readonly uuid: string;
  readonly portalInfo: any;
  readonly submitStatus: SubmitStatus;
  readonly queryParamsToPassBy: QueryParamsToPassBy[];

  // The following are not readonly because they are are extracted
  // from portalInfo and changable in the UI.
  orderDealerKey: string;
  orderDealerEmail: string;
  orderDealerName: string;
  orderDealerLink: string;
  ownerName: string;
  ownerEmail: string;
  salesRepName: string;
  orderStatusId: string;
  orderStatusName: string;
  orderStatusI18nKey: string;
  orderStatusFontColor: string;
  orderStatusBackgroundColor: string;
}

export enum SubmitStatus {
  Draft = 'draft',
  Save = 'save',
  SaveToList = 'save-to-list',
  Quote = 'quote',
  Deposit = 'deposit',
  DepositPaying = 'deposit-now-paying',
  DepositToken = 'deposit-now-token',
  DepositCharged = 'deposit-now-charged',
  DepositPayLater = 'deposit-later',
  ExternalCheckout = 'external-checkout',
  Invoice = 'invoice',
  Popup = 'popup',
}
