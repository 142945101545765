import { Box, Stack, Typography, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import { SearchInput } from './SearchInput';
import { useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { useComponentPricingRepo } from '../hooks/useComponentPricingRepo';
import { ComponentCategory, ComponentCategoryItem } from '../types/ComponentPricing';
import {
  filterComponentCategories,
  filterComponentCategoryItems,
  getComponentSearchCountText,
} from '../utils/componentPricingUtils';
import { PricingComponentRegionSelect } from './PricingComponentRegionSelect';

export const PRICING_COMPONENT_SEARCH_HEIGHT = 100;

const useStyles = makeStyles(() => ({
  searchSection: {
    height: PRICING_COMPONENT_SEARCH_HEIGHT,
    padding: '20px 30px',
    width: '100%',
  },
  searchField: {
    width: '250px',
  },
}));

type Props = {
  searchValue?: string;
  setSearchValue: (value: string) => void;
};

export const PricingComponentSearch: React.FC<Props> = ({ searchValue = '', setSearchValue }: Props) => {
  const classes = useStyles();

  const {
    component: { selectedComponentCategoryKey },
  } = useAppSelector((state: AppState) => state?.pricing);

  const {
    componentCategoriesList,
    isLoadingComponentCategoriesList,
    componentCategoryItems,
    isLoadingComponentCategoryItems,
  } = useComponentPricingRepo({
    useComponentCategories: true,
    useSelectedComponentCategoryItems: true,
  });

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [filteredItems, setFilteredItems] = useState<ComponentCategory[] | ComponentCategoryItem[] | undefined>(
    undefined,
  );

  useEffect(() => {
    setFilteredItems(
      selectedComponentCategoryKey
        ? filterComponentCategoryItems(searchValue, componentCategoryItems)
        : filterComponentCategories(searchValue, componentCategoriesList),
    );
  }, [selectedComponentCategoryKey, searchValue, componentCategoryItems, componentCategoriesList]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
      className={classes.searchSection}
    >
      <SearchInput
        classes={classes}
        inputId="component-search"
        searchTerm={searchValue}
        onChange={(val) => setSearchValue(val)}
        onClearClick={() => setSearchValue('')}
        startAdornment={<SearchIcon />}
        variant="outlined"
        size="small"
        margin="dense"
      />
      <Box ref={containerRef} style={{ overflow: 'hidden', flex: 1 }}>
        <Slide
          direction="right"
          in={
            selectedComponentCategoryKey
              ? !!componentCategoryItems && !isLoadingComponentCategoryItems
              : !!componentCategoriesList && !isLoadingComponentCategoriesList
          }
          container={containerRef.current}
        >
          <Typography
            variant="subtitle1"
            component="div"
            color="text.secondary"
            style={{
              marginTop: '1rem',
            }}
          >
            {getComponentSearchCountText(
              searchValue,
              selectedComponentCategoryKey,
              filteredItems,
              componentCategoryItems,
              componentCategoriesList,
            )}
          </Typography>
        </Slide>
      </Box>
      <PricingComponentRegionSelect />
    </Stack>
  );
};
