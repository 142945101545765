/* eslint-disable react-hooks/rules-of-hooks */
import useWindowFocus from 'use-window-focus';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { unknownGroup } from '../constants/Group';
import { useAppSelector } from '../hooks';
import {
  useDeleteBranchMutation,
  useGetBranchDiffMergesQuery,
  useGetClientDataBranchDiffQuery,
  usePublishClientDataMutation,
} from '../services/clientDataApi';
import { AppState } from '../types/AppState';
import { Commit } from '../types/Commit';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { OnlyExistingKeys } from '../types/Repo';
import {
  useGetComponentCategoriesQuery,
  useGetComponentCategoryItemsQuery,
  useGetClientUpdateRegionsQuery,
} from '../services/pricingApi';
import { ComponentCategoryKey } from '../constants/ComponentCategoryKey';
import { ComponentCategory, ComponentCategoryItem } from '../types/ComponentPricing';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataTableRowDiff } from '../types/ClientData';
import { Region } from '../types/Region';

const EMPTY_COMMITS_REFERENCE: ReadonlyArray<Commit> = [];
const EMPTY_CLIENT_UPDATE_REGIONS: ReadonlyArray<Region> = [];

type UseComponentPricingRepoReturn<
  UseComponentCategories,
  UseComponentCategoryItemDiffs,
  UseSelectedComponentCategoryItems,
  UseClientUpdateBranchMergeCommit,
  UseClientUpdateRegions,
> = OnlyExistingKeys<{
  isLoadingComponentCategoriesList: UseComponentCategories extends true ? boolean : never;
  componentCategoriesList: UseComponentCategories extends true ? ComponentCategory[] | undefined : never;
  isLoadingComponentCategoryItemDiffs: UseComponentCategoryItemDiffs extends true ? boolean : never;
  componentCategoryItemDiffs: UseComponentCategoryItemDiffs extends true
    ? {
        table: string;
        changes: ClientDataTableRowDiff[];
      }[]
    : never;
  isLoadingComponentCategoryItems: UseSelectedComponentCategoryItems extends true ? boolean : never;
  componentCategoryItems: UseSelectedComponentCategoryItems extends true ? ComponentCategoryItem[] | undefined : never;
  clientUpdateBranchMergeCommit: UseClientUpdateBranchMergeCommit extends true ? Commit : never;
  isLoadingClientUpdateRegions: UseClientUpdateRegions extends true ? boolean : never;
  clientUpdateRegions: UseClientUpdateRegions extends true ? Region[] | undefined : never;
}>;

export const useComponentPricingRepo = <
  UseComponentCategories extends boolean = false,
  UseComponentCategoryItemDiffs extends boolean = false,
  UseSelectedComponentCategoryItems extends boolean = false,
  UseClientUpdateBranchMergeCommit extends boolean = false,
  UseClientUpdateRegions extends boolean = false,
>({
  useComponentCategories,
  useComponentCategoryItemDiffs,
  useSelectedComponentCategoryItems,
  useClientUpdateBranchMergeCommit,
  useClientUpdateRegions,
}: {
  useComponentCategories?: UseComponentCategories;
  useComponentCategoryItemDiffs?: UseComponentCategoryItemDiffs;
  useSelectedComponentCategoryItems?: UseSelectedComponentCategoryItems;
  useClientUpdateBranchMergeCommit?: UseClientUpdateBranchMergeCommit;
  useClientUpdateRegions?: UseClientUpdateRegions;
} = {}): UseComponentPricingRepoReturn<
  UseComponentCategories,
  UseComponentCategoryItemDiffs,
  UseSelectedComponentCategoryItems,
  UseClientUpdateBranchMergeCommit,
  UseClientUpdateRegions
> => {
  const clientId = useAppSelector(
    ({ viewer: { selectedClientId, selectedTabId }, clientData: { clientId: clientDataClientId } }: AppState) =>
      getClientIdFromClientSupplier(clientDataClientId || selectedTabId || selectedClientId || ''),
  );
  const { group: { groupId } = unknownGroup } = useAppSelector((state: AppState) => state?.currentUser);
  const isWindowFocused = useWindowFocus();

  const {
    component: {
      pricingDataBranch: clientUpdateDataBranch = ClientDataBranch.Main,
      selectedComponentCategoryKey: componentCategoryKey,
    },
  } = useAppSelector((state) => state?.pricing);
  const [, { isLoading: isDeletingBranch }] = useDeleteBranchMutation({
    fixedCacheKey: 'revert',
  });
  const [, { isLoading: isPublishing }] = usePublishClientDataMutation();

  const result: any = {};

  if (useComponentCategories) {
    const { currentData: componentCategoriesList, isLoading: isLoadingComponentCategoriesList } =
      useGetComponentCategoriesQuery(
        {
          groupId,
          clientId,
          branch: clientUpdateDataBranch,
        },
        {
          skip: !clientId || isDeletingBranch || isPublishing,
        },
      );
    result.isLoadingComponentCategoriesList = componentCategoriesList === undefined || isLoadingComponentCategoriesList;
    result.componentCategoriesList = componentCategoriesList;
  }

  let tables: string[] = [];
  if (useSelectedComponentCategoryItems || useClientUpdateBranchMergeCommit || useComponentCategoryItemDiffs) {
    const { currentData: componentCategoryItems, isLoading: isLoadingComponentCategoryItems } =
      useGetComponentCategoryItemsQuery(
        {
          groupId,
          clientId,
          branch: clientUpdateDataBranch,
          category: componentCategoryKey as ComponentCategoryKey,
        },
        {
          skip: !clientId || isDeletingBranch || !componentCategoryKey || isPublishing,
          refetchOnMountOrArgChange: true,
        },
      );
    result.isLoadingComponentCategoryItems = componentCategoryItems === undefined || isLoadingComponentCategoryItems;
    result.componentCategoryItems = componentCategoryItems;

    const uniqueTables = new Set<string>();
    componentCategoryItems?.forEach((c) => uniqueTables.add(c.table));
    tables = Array.from(uniqueTables);
  }

  if (useComponentCategoryItemDiffs) {
    const { currentData: componentCategoryItemDiffs = [], isLoading: isLoadingComponentCategoryItemDiffs } =
      useGetClientDataBranchDiffQuery(
        {
          clientId,
          dataType: ClientDataType.Supplier,
          branch: clientUpdateDataBranch,
          tables,
        },
        {
          skip: !clientId || isDeletingBranch || tables.length === 0 || isPublishing,
          refetchOnMountOrArgChange: true,
        },
      );
    result.isLoadingComponentCategoryItemDiffs = isLoadingComponentCategoryItemDiffs;
    result.componentCategoryItemDiffs = componentCategoryItemDiffs;
  }

  if (useClientUpdateBranchMergeCommit) {
    const skip =
      !clientId || isDeletingBranch || clientUpdateDataBranch !== ClientDataBranch.ClientUpdate || isPublishing;
    const { currentData: clientUpdateBranchMergeCommit = EMPTY_COMMITS_REFERENCE } = useGetBranchDiffMergesQuery(
      {
        dataType: ClientDataType.Supplier,
        clientId,
        groupId,
        branch: ClientDataBranch.ClientUpdate,
        limit: 1,
        fromBranch: ClientDataBranch.Main,
        tables,
      },
      {
        skip,
        refetchOnMountOrArgChange: true,
        // refetch every minute
        // stops pooling if window is not focused
        pollingInterval: isWindowFocused ? 60000 : undefined,
      },
    );
    result.clientUpdateBranchMergeCommit = clientUpdateBranchMergeCommit ? clientUpdateBranchMergeCommit[0] : undefined;
  }

  if (useClientUpdateRegions) {
    const { currentData: clientUpdateRegions, isLoading: isLoadingClientUpdateRegions } =
      useGetClientUpdateRegionsQuery(
        {
          groupId,
          clientId,
          branch: clientUpdateDataBranch,
        },
        {
          skip: !clientId || isDeletingBranch || isPublishing,
          refetchOnMountOrArgChange: true,
        },
      );
    result.isLoadingClientUpdateRegions = clientUpdateRegions === undefined || isLoadingClientUpdateRegions;

    let regions = clientUpdateRegions;
    // For regions with duplicate price columns, only show the first one
    if (regions) {
      regions = clientUpdateRegions?.filter(
        (region, i, arr) => !arr.slice(0, i).some(({ priceColumn }) => priceColumn === region.priceColumn),
      );
    }
    result.clientUpdateRegions = regions || EMPTY_CLIENT_UPDATE_REGIONS;
  }

  return result;
};
