/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { WrappedFieldProps } from 'redux-form';

type Props = WrappedFieldProps & TextFieldProps;

export const InputField: React.FC<Props> = (props: Props) => {
  const {
    rows,
    multiline,
    input,
    meta: { touched, error },
  } = props;

  return (
    <TextField
      variant="standard"
      rows={rows}
      multiline={multiline}
      error={!!(touched && error)}
      helperText={touched && error}
      {...props}
      {...input}
    />
  );
};
