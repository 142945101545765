import { AppBar, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from '../constants/I18nKeys';
import { PricingAdjustment } from './PricingAdjustment';
import { PricingBase } from './PricingBase';
import { PricingComponent } from './PricingComponent';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { setClientId } from '../ducks/clientDataSlice';
import { unknownUser } from '../types/User';
import { Loading } from './Loading';
import { openConfirmationDialog } from '../ducks/confirmation';
import { i18n } from '../i18n';
import { AppRoutes } from '../constants/AppRoutes';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { isIdeaRoomUser } from '../utils/userUtils';
import { PricingTab } from '../constants/Pricing';
import { getPathPart } from '../utils/urlUtils';
import { getUnsavedChangesMessage } from '../utils/viewerUtils';
import { setSearchHidden } from '../ducks/search';
import { setSelectedPricingTabId } from '../ducks/viewerSlice';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    minHeight: 0,
    width: '100%',
  },
});

export const Pricing: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { selectedPricingTabId: tab } = useAppSelector((state: AppState) => state?.viewer);
  const { draftEqualsActive } = useAppSelector((state) => state.pricingAdjustment);

  const clientId = useAppSelector((state: AppState) =>
    getClientIdFromClientSupplier(state?.viewer?.selectedTabId || state?.viewer?.selectedClientId || ''),
  );
  const { clientId: clientDataClientId } = useAppSelector((state: AppState) => state?.clientData);
  const { user } = useAppSelector((state) => state.currentUser || unknownUser);

  const { pricingEnableClientManaged, pricingEnableClientUpdates, isLoadingPricingEnabled } = usePricingRepo({
    usePricingEnabled: true,
  });

  const tabs = [
    {
      label: t(I18nKeys.Base),
      value: PricingTab.Base,
      route: PricingTab.Base,
      id: 'base-tab',
      enabled: isIdeaRoomUser(user) || pricingEnableClientManaged,
    },
    {
      label: t(I18nKeys.ComponentTab),
      value: PricingTab.Component,
      route: PricingTab.Component,
      id: 'component-tab',
      enabled: isIdeaRoomUser(user) || pricingEnableClientUpdates,
    },
    {
      label: t(I18nKeys.Adjustments),
      value: PricingTab.Adjustment,
      route: PricingTab.Adjustment,
      id: 'adjustment-tab',
      enabled: true,
    },
  ];

  useLayoutEffect(() => {
    dispatch(setSearchHidden(true));
  }, [dispatch]);

  useEffect(() => {
    if (clientId && clientId !== clientDataClientId) {
      dispatch(setClientId(clientId));
      dispatch(setSelectedPricingTabId(undefined));
    }
  }, [clientId, clientDataClientId, dispatch]);

  useMemo(() => {
    const route = getPathPart(3) as PricingTab;

    if (!tab && !isLoadingPricingEnabled) {
      if (route === PricingTab.Adjustment) {
        dispatch(setSelectedPricingTabId(PricingTab.Adjustment));
      } else if (route === PricingTab.Component && tabs.find((pricingTab) => pricingTab.value === route)?.enabled) {
        dispatch(setSelectedPricingTabId(PricingTab.Component));
      } else if (route === PricingTab.Base && tabs.find((pricingTab) => pricingTab.value === route)?.enabled) {
        if (isIdeaRoomUser(user) || pricingEnableClientManaged) {
          dispatch(setSelectedPricingTabId(PricingTab.Base));
        } else if (isIdeaRoomUser(user) || pricingEnableClientUpdates) {
          dispatch(setSelectedPricingTabId(PricingTab.Component));
        }
      } else {
        window.history.replaceState(null, '', `${AppRoutes.Pricing}/${PricingTab.Adjustment}`);
        dispatch(setSelectedPricingTabId(PricingTab.Adjustment));
      }
    }
  }, [pricingEnableClientManaged, pricingEnableClientUpdates, tab, isLoadingPricingEnabled, user]);

  const changeTab = (newTab: PricingTab) => {
    if (tab === PricingTab.Adjustment && !draftEqualsActive) {
      dispatch(
        openConfirmationDialog(
          [setSelectedPricingTabId(newTab)],
          [],
          i18n.t(I18nKeys.UnsavedChangesConfirmationTitle),
          getUnsavedChangesMessage(AppRoutes.Pricing, i18n.t),
          i18n.t(I18nKeys.UnsavedChangesConfirmationConfirmButton),
        ),
      );
      dispatch(openDialog({ dialog: Dialogs.Confirmation }));
    } else {
      dispatch(setSelectedPricingTabId(newTab));
    }
    const route = tabs.find((pricingTab) => pricingTab.value === newTab)?.route;
    window.history.replaceState(null, '', `${AppRoutes.Pricing}/${route}`);
  };

  // Hook that updates when breakpoint value changes to/from xs
  const tabsVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'fullWidth' : undefined;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
        <Tabs
          value={tab}
          onChange={(event: React.SyntheticEvent<Element, Event>, newValue: PricingTab): void => {
            changeTab(newValue);
          }}
          aria-label="pricing-tabs"
          variant={tabsVariant}
          indicatorColor="primary"
        >
          {tabs
            .filter((priceTab) => priceTab.enabled)
            .map((appBarTab) => (
              <Tab
                key={appBarTab.id}
                label={appBarTab.label}
                value={appBarTab.value}
                id={appBarTab.id}
                aria-controls={appBarTab.id}
              />
            ))}
        </Tabs>
      </AppBar>

      <div className={classes.content}>
        {!tab && isLoadingPricingEnabled && <Loading />}
        {tab === PricingTab.Base && <PricingBase />}
        {tab === PricingTab.Component && <PricingComponent />}
        {(tab === PricingTab.Adjustment || (!tab && !isLoadingPricingEnabled && !pricingEnableClientManaged)) && (
          <PricingAdjustment />
        )}
      </div>
    </div>
  );
};
