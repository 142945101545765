/* eslint-disable no-param-reassign */
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { VendorDataState } from '../types/VendorDataState';
import { Vendor } from '../types/VendorData';
import { PublishingResult, SiteDetail } from '../types/ClientData';

export const InitialVendorDataState: VendorDataState = {
  vendorData: undefined,
  vendorLogos: [],
  vendorEmailLogos: [],
  vendorWatermarks: [],
  imageProcessing: false,
  errors: [],
  publishMergeResult: undefined,
};

export const vendorDataSlice = createSlice({
  name: 'vendorData',
  initialState: InitialVendorDataState,
  reducers: {
    setVendorData: (state, action: PayloadAction<Vendor | undefined>) => {
      const { payload: vendorData } = action;
      state.vendorData = vendorData;
    },
    setVendorLogos: (state, action: PayloadAction<string[]>) => {
      const { payload: vendorLogos = [] } = action;
      state.vendorLogos = [...new Set(vendorLogos)];
    },
    setVendorEmailLogos: (state, action: PayloadAction<string[]>) => {
      const { payload: vendorEmailLogos = [] } = action;
      state.vendorEmailLogos = [...new Set(vendorEmailLogos)];
    },
    setVendorWatermarks: (state, action: PayloadAction<string[]>) => {
      const { payload: vendorWatermarks = [] } = action;
      state.vendorWatermarks = [...new Set(vendorWatermarks)];
    },
    setImageProcessing: (state, action: PayloadAction<boolean>) => {
      const { payload: imageProcessing = false } = action;
      state.imageProcessing = imageProcessing;
    },
    addVendorLogo: (state, action: PayloadAction<string>) => {
      const { payload: vendorLogo } = action;
      state.vendorLogos.push(vendorLogo);
    },
    addVendorEmailLogo: (state, action: PayloadAction<string>) => {
      const { payload: vendorEmailLogo } = action;
      state.vendorEmailLogos.push(vendorEmailLogo);
    },
    addVendorWatermark: (state, action: PayloadAction<string>) => {
      const { payload: vendorWatermark } = action;
      state.vendorWatermarks.push(vendorWatermark);
    },
    addSitesErrors: (state, action: PayloadAction<string[]>) => {
      const { payload: error } = action;
      state.errors.push(...error);
    },
    clearSitesErrors: (state) => {
      state.errors = [];
    },
    setSiteDetailPublishMergeResult(
      state,
      action: PayloadAction<{
        data: PublishingResult | undefined;
        error: any | undefined;
        isSuccess: boolean;
      }>,
    ) {
      state.publishMergeResult = action.payload;
    },
  },
});

export const {
  setVendorData,
  setVendorLogos,
  setVendorEmailLogos,
  setVendorWatermarks,
  setImageProcessing,
  addVendorLogo,
  addVendorEmailLogo,
  addVendorWatermark,
  addSitesErrors,
  clearSitesErrors,
  setSiteDetailPublishMergeResult,
} = vendorDataSlice.actions;

export const getLogosAndWatermarks = createAction('vendorData/getLogosAndWatermarks');

export const uploadLogoImage = createAction('vendorData/uploadLogoImage', (payload: { image: Blob }) => ({
  payload,
}));

export const uploadEmailLogoImage = createAction('vendorData/uploadEmailLogoImage', (payload: { image: Blob }) => ({
  payload,
}));

export const uploadWatermarkImage = createAction(
  'vendorData/uploadWatermarkImage',
  (payload: { image: Blob; data: SiteDetail }) => ({
    payload,
  }),
);

export const deleteFileFromS3 = createAction('vendorData/deleteFileFromS3', (payload: { path: string }) => ({
  payload,
}));
