import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { Mark, PricingAdjustmentCondition, SurchargeRangeCondition, SurchargeRule } from '../types/PricingAdjustment';
import { PricingSurchargeCondition } from '../constants/PricingAdjustment';
import { updateSurchargeRuleCondition as updateSurchargeRuleConditionFunc } from '../ducks/pricingAdjustment';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
  ariaLabel: {
    color: 'white',
  },
});

interface OwnProps {
  anchorEl: Element | null;
  pricingAdjustmentCondition?: PricingAdjustmentCondition;
  rule?: SurchargeRule;
  onClose(): void;
}

interface DispatchProps {
  updateSurchargeRuleCondition(
    id: number,
    type: PricingSurchargeCondition,
    range: { minimum: number; maximum: number },
  ): void;
}

type Props = OwnProps & DispatchProps;

const PricingRangeConditionMenuComponent: React.FC<Props> = ({
  anchorEl,
  onClose,
  pricingAdjustmentCondition,
  rule,
  updateSurchargeRuleCondition,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const saveRangeCondition = (
    range: number | number[],
    type?: PricingSurchargeCondition,
    surchargeRule?: SurchargeRule,
  ): void => {
    if (type && surchargeRule) {
      let { minimum = 0, maximum = 0 } = surchargeRule.conditions
        .filter((condition) => condition.type === type)
        .shift() as SurchargeRangeCondition;

      minimum = Array.isArray(range) ? range[0] : range;
      maximum = Array.isArray(range) ? range[1] : range;

      updateSurchargeRuleCondition(surchargeRule.id, type, { minimum, maximum });
      onClose();
    }
  };

  const getMinValue = (sizeOptions: PricingAdjustmentCondition | undefined): number =>
    sizeOptions ? Math.min(...sizeOptions.options.map((option) => Number(option.key))) : 0;
  const getMaxValue = (sizeOptions: PricingAdjustmentCondition | undefined): number =>
    sizeOptions ? Math.max(...sizeOptions.options.map((option) => Number(option.key))) : 0;

  const minValue = getMinValue(pricingAdjustmentCondition);
  const maxValue = getMaxValue(pricingAdjustmentCondition);

  const [range, setRange] = React.useState<number[]>([minValue, maxValue]);

  React.useEffect(() => {
    const defaultSurchargeRangeCondition = { minimum: 0, maximum: 0 };
    const { minimum, maximum } =
      rule && pricingAdjustmentCondition
        ? (rule.conditions.filter(
            (condition) => condition.type === pricingAdjustmentCondition.value,
          )[0] as SurchargeRangeCondition) || defaultSurchargeRangeCondition
        : defaultSurchargeRangeCondition;
    const currentRange = [minimum || minValue, maximum || maxValue];
    setRange(currentRange);
  }, [rule, pricingAdjustmentCondition, minValue, maxValue]);

  const handleSliderChange = (_: any, newValue: number | number[]): void => {
    setRange(newValue as number[]);
  };

  const valueText = (value: number): string => {
    if (pricingAdjustmentCondition) {
      const selectedOption = pricingAdjustmentCondition.options.find(
        (option) => Math.round(Number(option.key)) === value,
      );
      return `${selectedOption ? selectedOption.label : value}`;
    }
    return `${value}`;
  };

  // Get evenly distributed values between the min and max
  const evenlyDistributedMarks = (min: number, max: number, steps: number): number[] => {
    // minimum step size
    let stepsize = (max - min) / steps;
    // increase the step size to a nice boundary
    // for example, 1/10th of the 10^n range that includes it
    const pow = Math.trunc(Math.log10(stepsize)) - 1;
    stepsize = Math.trunc(stepsize / 10 ** pow) * 10 ** pow;
    const result = [min];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let newMin = Math.trunc(min / 10 ** pow) * 10 ** pow;
    for (let i = 0; i < steps - 1; i += 1) {
      newMin += stepsize;
      result.push(Math.round(newMin));
    }
    result.push(max);
    return result;
  };

  const markLabelValues = evenlyDistributedMarks(minValue, maxValue, 3);

  // Get the marks for the slider based on the values in options
  // Sort the array based on the numeric key values
  // Map the options to a Mark datatype
  // Only show the label if the key is the closest value to a markLabelValue
  const rangeConditionOptions = pricingAdjustmentCondition ? (pricingAdjustmentCondition.options || []).slice(0) : [];
  const marks =
    rangeConditionOptions.length > 0
      ? rangeConditionOptions
          .sort((a, b) => (Number(a.key) > Number(b.key) ? 1 : -1))
          .map(
            (option, index) =>
              ({
                value: Number(option.key),
                label: markLabelValues.some(
                  (val) =>
                    Number(option.key) === val ||
                    (Number(option.key) < val &&
                      Number(rangeConditionOptions[index + 1] ? rangeConditionOptions[index + 1].key : -1) > val),
                )
                  ? option.label
                  : '',
              } as Mark),
          )
      : false;

  return (
    <Dialog open={Boolean(anchorEl)} onClose={onClose}>
      <DialogTitle id="form-dialog-title">
        {t(I18nKeys.PricingRangeTitle, { condition: pricingAdjustmentCondition && pricingAdjustmentCondition.label })}
      </DialogTitle>
      <DialogContent className={classes.root}>
        <Typography id="range-slider" className={classes.ariaLabel} gutterBottom>
          {t(I18nKeys.PricingRangeSliderLabel, {
            condition: pricingAdjustmentCondition && pricingAdjustmentCondition.label,
          })}
        </Typography>
        <Slider
          value={range}
          defaultValue={[minValue, maxValue]}
          min={minValue}
          max={maxValue}
          onChange={handleSliderChange}
          valueLabelDisplay="on"
          aria-labelledby="range-slider"
          marks={marks}
          step={null}
          valueLabelFormat={valueText}
          getAriaValueText={valueText}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button
          color="primary"
          onClick={(): void => {
            saveRangeCondition(
              range,
              pricingAdjustmentCondition ? (pricingAdjustmentCondition.value as PricingSurchargeCondition) : undefined,
              rule,
            );
          }}
        >
          {t(I18nKeys.DialogSaveButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateSurchargeRuleCondition: (
    id: number,
    type: PricingSurchargeCondition,
    range: { minimum: number; maximum: number },
  ): void => {
    dispatch(updateSurchargeRuleConditionFunc(id, type, range));
  },
});

export const PricingRangeConditionMenu = connect(undefined, mapDispatchToProps)(PricingRangeConditionMenuComponent);
