import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppState } from '../types/AppState';
import { PricingCalculation } from './PricingCalculation';
import { PricingSelector } from './PricingSelector';
import { SystemOrderCalculations, SystemOrderSupplierCalculations } from '../constants/PricingAdjustment';
import { PricingAmount } from './PricingAmount';
import {
  fetchConditions,
  fetchInitialSurcharge,
  PricingAdjustmentActionType,
  setPricingClientAndSupplier,
  setPricingLoading,
  closeConfiguratorModal as closeConfiguratorModalFunc,
} from '../ducks/pricingAdjustment';
import { unknownGroup } from '../constants/Group';
import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { PricingSupplierSelector } from './PricingSupplierSelector';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { ConfiguratorModal } from './ConfiguratorModal';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  getClientIdFromClientSupplier,
  getSupplierKeyFromClientSupplier,
  getVendorFromClientId,
  mapConfiguratorToClientId,
} from '../utils/clientIdUtils';
import { hasSuppliers, viewingDealer } from '../utils/supplierUtils';
import { Supplier } from '../types/Supplier';

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  rootGrid: {
    padding: '2%',
    flex: 1,
  },
  spacing: {
    width: '100%',
    marginTop: '25px',
  },
  exportButton: {
    marginRight: '25%',
  },
  exportText: {
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: '6px',
  },
});

export const PricingAdjustment: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const selectedViewerId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId,
  );
  const { clientId = '', supplierKey = '' } = useAppSelector((state: AppState) => state?.pricingAdjustment);
  const { group: { configurators } = unknownGroup } = useAppSelector((state: AppState) => state?.currentUser);
  const { pricingSupplierSelection, pricingSelection, loading, configuratorModalOpen } = useAppSelector(
    (state: AppState) => state?.pricingAdjustment,
  );

  const selectedConfig = (configurators || []).find((configurator) => configurator.clientId === clientId);
  const isDealer = viewingDealer(selectedViewerId, selectedConfig);
  const suppliersExist = hasSuppliers(selectedConfig);
  const filteredConfigurators = (configurators || []).filter((c) =>
    getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.PricingEnabled, true),
  );

  const loadSurcharge = (client: string, supplier: string): PricingAdjustmentActionType =>
    dispatch(fetchInitialSurcharge(client, supplier));
  const setClientIdAndSupplierForPricing = (client: string, supplier: string): PricingAdjustmentActionType =>
    dispatch(setPricingClientAndSupplier(client, supplier));

  const closeConfiguratorModal = (): void => {
    dispatch(closeConfiguratorModalFunc());
  };

  const setClientAndSupplier = (tabClientId: string, tabSupplierKey: string): void => {
    if (tabClientId !== clientId || tabSupplierKey !== supplierKey) {
      dispatch(setPricingLoading());
      setClientIdAndSupplierForPricing(tabClientId, tabSupplierKey);
    }
  };

  const handleConfiguratorModalClose = (): void => {
    closeConfiguratorModal();
  };

  // Effect that loads the vendor conditions
  React.useEffect(() => {
    if (clientId) {
      dispatch(fetchConditions(clientId));
    }
  }, [dispatch, clientId]);

  React.useEffect(() => {
    // No client ID is selected or the client ID is invalid
    if ((!clientId || !selectedConfig) && filteredConfigurators.length > 0) {
      const initialClientId = getClientIdFromClientSupplier(selectedViewerId);
      const initialSupplierKey = getSupplierKeyFromClientSupplier(selectedViewerId);

      if (
        filteredConfigurators.some(
          (c) =>
            mapConfiguratorToClientId(c) === initialClientId &&
            (!initialSupplierKey ||
              getVendorFromClientId(initialClientId) === initialSupplierKey ||
              c.suppliers?.some((s) => s.supplierKey === initialSupplierKey)),
        )
      ) {
        // Initial viewer ID is valid
        setClientIdAndSupplierForPricing(initialClientId, initialSupplierKey);
      } else {
        // Use the first configurator and supplier (if it exists)
        setClientIdAndSupplierForPricing(
          mapConfiguratorToClientId(filteredConfigurators[0]),
          hasSuppliers(filteredConfigurators[0])
            ? (filteredConfigurators[0]?.suppliers || [])[0]?.supplierKey || ''
            : '',
        );
      }
    }
    if (clientId && selectedConfig && suppliersExist && !supplierKey) {
      setClientAndSupplier(clientId, (selectedConfig.suppliers as Supplier[])[0].supplierKey);
    }
    if (clientId && selectedConfig && suppliersExist && supplierKey) {
      loadSurcharge(clientId, supplierKey);
    }
    if (clientId && selectedConfig && !suppliersExist && !supplierKey) {
      loadSurcharge(clientId, supplierKey);
    }
  }, [clientId, supplierKey, selectedConfig, suppliersExist]);

  return (
    <div className={classes.root}>
      {!loading && (
        <Grid
          className={classes.rootGrid}
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <>
            {isDealer && (
              <Grid item>
                <Box>
                  <PricingSupplierSelector />
                </Box>
              </Grid>
            )}
            <Grid item>
              <Typography variant="h6" component="h6">
                {isDealer ? t(I18nKeys.PricingChangesSupplierPrefix) : ''}
                {t(I18nKeys.PricingChangesTitle)}
              </Typography>
              <Box ml={4}>
                <PricingSelector
                  dealer={isDealer}
                  disabled={pricingSupplierSelection === SystemOrderSupplierCalculations.Inherit}
                />
              </Box>
            </Grid>
            {pricingSelection &&
              pricingSelection !== SystemOrderCalculations.None &&
              pricingSupplierSelection !== SystemOrderSupplierCalculations.Inherit && (
                <>
                  <Grid item className={classes.spacing}>
                    <Typography variant="h6" component="h6">
                      {t(I18nKeys.PricingChangesCalculation)}
                    </Typography>
                    <Box ml={4}>
                      <PricingCalculation clientId={clientId} />
                    </Box>
                  </Grid>
                  <Grid item className={classes.spacing}>
                    <Typography variant="h6" component="h6">
                      {t(I18nKeys.PricingChangesAmounts)}
                    </Typography>
                    <Box ml={4}>
                      <PricingAmount />
                    </Box>
                  </Grid>
                </>
              )}
          </>
        </Grid>
      )}
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <CircularProgress style={{ alignSelf: 'center' }} color="primary" />
        </div>
      )}
      <ConfiguratorModal
        open={configuratorModalOpen}
        clientId={clientId}
        onClose={handleConfiguratorModalClose}
        previewMode
      />
    </div>
  );
};
