import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown, Check } from '@mui/icons-material';
import { IStatusPanelParams } from 'ag-grid-community';
import { Button, ButtonGroup } from '@mui/material';
import { I18nKeys } from '../constants/I18nKeys';
import { AnchorMenu } from './AnchorMenu';
import { useAppDispatch, useAppSelector } from '../hooks';
import { generateRowFromObject, addRows as addRowsFunc, getRowOrders, getOrderByIndex } from '../utils/clientDataUtils';
import { AppState } from '../types/AppState';
import { goToCellRange, setAddRowsQuantity } from '../ducks/clientDataSlice';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { addRowsQuantities } from '../constants/ClientData';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { clearSelections } from '../utils/selectionUtils';
import { mapClientAndDataTypeAndTableToUndoStackId } from '../utils/clientIdUtils';

export const AddMultipleRows: React.FC<IStatusPanelParams> = ({ api, columnApi }: IStatusPanelParams) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { clientId, clientDataType, selectedTable, addRowsQuantity } = useAppSelector(
    (state: AppState) => state?.clientData,
  );
  const { clientTableColumns } = useClientDataRepo({ useClientTablesColumns: true });
  const selectedViewerId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId,
  );
  const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(selectedViewerId, clientDataType, selectedTable);

  const buttonRef = React.useRef<HTMLDivElement>(null);

  const [numberOfRows, setNumberOfRows] = React.useState<number>(addRowsQuantity);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const addRows = (numRows: number) => {
    const rowModel = api.getModel();
    const addIndex = rowModel.getRowCount();

    const [prevOrder, nextOrder] = [addIndex - 1, addIndex].map((i) => getOrderByIndex(i, api));
    const rowOrders = getRowOrders(prevOrder, nextOrder, numRows, api);
    const rows = Array.from({ length: numRows }, (_el, i) =>
      generateRowFromObject(clientId, clientTableColumns[selectedTable], rowOrders[i]),
    );

    addRowsFunc(clientDataTableId, rows, dispatch);

    const rowId = rowModel.getRow(addIndex)?.id;
    const [firstColumnId] = (columnApi?.getAllGridColumns() || [])
      .map((col) => col.getColId())
      .filter((col) => !([ClientDataFixedColumns.Index] as string[]).includes(col));
    if (!rowId || !firstColumnId) return;

    // Navigate to the first cell of the new range
    clearSelections(api);
    dispatch(
      goToCellRange({
        table: selectedTable,
        location: { startRowId: rowId, endRowId: rowId, columns: [firstColumnId] },
        rootGridApi: api,
        rootGridColApi: columnApi,
      }),
    );
  };

  return (
    <div
      role="presentation"
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        margin: '0px 14px',
      }}
    >
      <ButtonGroup variant="text" ref={buttonRef}>
        <Button
          style={{ fontWeight: 700, color: '#000000', border: 'none', padding: '5px', textTransform: 'none' }}
          onClick={() => addRows(numberOfRows)}
        >
          {t(I18nKeys.StatusBarAddMultipleRows, { number: numberOfRows })}
        </Button>
        <Button
          onClick={() => setMenuAnchorEl(buttonRef?.current)}
          disableRipple
          style={{ minWidth: 'unset', padding: '0px' }}
        >
          <ArrowDropDown style={{ color: '#727272' }} />
        </Button>
      </ButtonGroup>
      <AnchorMenu
        menuAnchorEl={menuAnchorEl}
        options={addRowsQuantities.map((number) => ({
          key: number,
          label: t(I18nKeys.StatusBarRowOption, { number }),
          value: number,
        }))}
        selectedOptions={[numberOfRows]}
        checkedIcon={<Check />}
        onClick={(val) => {
          setNumberOfRows(val);
          dispatch(setAddRowsQuantity(val));
          setMenuAnchorEl(null);
          // Adding rows on option change
          addRows(val);
        }}
        onClose={() => setMenuAnchorEl(null)}
        menuProps={{
          PaperProps: {
            style: {
              width: '23ch',
            },
          },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      />
    </div>
  );
};
