import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Field, Form, FormErrors, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { passwordValidation, required } from '../utils/reduxFormUtils';
import { LoadingButton } from './LoadingButton';
import { InputField } from './redux-form/InputField';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    marginBottom: '1rem',
  },
  error: {
    color: 'red',
    marginTop: '10px',
    marginBottom: '20px',
  },
  field: {
    width: '100%',
    marginBottom: '2em',
  },
  button: {
    flex: '0 0 auto',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  form: {
    width: '80%',
    maxWidth: '800px',
    minWidth: '300px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

interface FormData {
  [PasswordFormFields.CurrentPassword]: string;
  [PasswordFormFields.NewPassword]: string;
  [PasswordFormFields.ConfirmNewPassword]: string;
}

interface DispatchProps {
  onSubmit(data: FormData): Promise<void>;
}

type Props = DispatchProps & InjectedFormProps<FormData>;

const PasswordFormComponent: React.FC<Props> = ({ error, handleSubmit, onSubmit, submitting }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownNewPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography className={classes.subtitle} variant="subtitle1">
        {t(I18nKeys.PasswordSubtitle)}
      </Typography>
      <Form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Field
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          validate={passwordValidation}
          variant="outlined"
          className={classes.field}
          name={PasswordFormFields.CurrentPassword}
          component={InputField}
          label={`${t(I18nKeys.PasswordCurrentPassword)}*`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Field
          autoComplete="new-password"
          type={showNewPassword ? 'text' : 'password'}
          validate={passwordValidation}
          variant="outlined"
          className={classes.field}
          name={PasswordFormFields.NewPassword}
          component={InputField}
          label={`${t(I18nKeys.PasswordNewPassword)}*`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownNewPassword}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Field
          autoComplete="new-password"
          type={showConfirmPassword ? 'text' : 'password'}
          validate={required}
          variant="outlined"
          className={classes.field}
          name={PasswordFormFields.ConfirmNewPassword}
          component={InputField}
          label={`${t(I18nKeys.FieldConfirmNewPassword)}*`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {!!error && <Typography className={classes.error}>{error}</Typography>}

        <LoadingButton
          key="create-group-btn"
          className={classes.button}
          type="submit"
          variant="contained"
          color="primary"
          loading={submitting}
          disabled={submitting}
        >
          {t(I18nKeys.ProfileUpdatePassword)}
        </LoadingButton>
      </Form>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: (values: FormData): Promise<void> =>
    new Promise((resolve, reject): void => {
      dispatch({
        type: `${Forms.Password}_SUBMIT`,
        values,
        resolve,
        reject,
      });
    }),
});

const validate = ({
  [PasswordFormFields.NewPassword]: password,
  [PasswordFormFields.ConfirmNewPassword]: confirmPassword,
}: FormData): FormErrors<FormData, string> => {
  if (password && confirmPassword && password !== confirmPassword) {
    return { [PasswordFormFields.ConfirmNewPassword]: i18n.t(I18nKeys.PasswordPasswordsDontMatchError) };
  }

  return {};
};

export const PasswordForm = reduxForm<FormData>({ form: Forms.Password, validate })(
  connect(undefined, mapDispatchToProps)(PasswordFormComponent),
);
