/* eslint-disable no-param-reassign */
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { FilterType, MenuStatus, TableFilterType, ToastMessageType, defaultVisibleColumns } from '../constants/Viewer';
import { Theme, ViewerState } from '../types/ViewerState';
import { LocalStorage } from '../constants/LocalStorage';
import { CustomFilterType, SelectedFilterValues } from '../types/CustomFilter';
import { Language } from '../constants/Languages';

/* global localStorage */

export const initialState: ViewerState = {
  menuStatus: MenuStatus.Expanded,
  displayPublishBar: false,
  visibleColumns: [],
  warning: '',
  tourRun: false,
  tourSteps: [],
  tourStepIndex: 0,
  groupFilters: [],
  tableFilterType: TableFilterType.Order,
  filterType: '',
  availableFilterValues: [],
  selectedFilterValues: [],
  languages: [],
};

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<Theme>) {
      state.theme = action.payload;
    },
    fetchVisibleColumns(state) {
      state.visibleColumns = [];
    },
    fetchVisibleColumnsError(state) {
      state.visibleColumns = defaultVisibleColumns;
    },
    fetchVisibleColumnsSuccess(state, action: PayloadAction<string[]>) {
      state.visibleColumns = action.payload;
    },
    setVisibleColumns(state, action: PayloadAction<string[]>) {
      state.visibleColumns = action.payload;
    },
    setVisibleColumnsError(state) {
      state.visibleColumns = defaultVisibleColumns;
    },
    setVisibleColumnsSuccess(state, action: PayloadAction<string[]>) {
      state.visibleColumns = action.payload;
    },
    setWarningText(state, action: PayloadAction<string>) {
      state.warning = action.payload;
    },
    clearWarningText(state) {
      state.warning = '';
    },
    setTourSteps(state, action: PayloadAction<any>) {
      state.tourSteps = action.payload;
    },
    openTour(state) {
      state.tourRun = true;
    },
    tourNextOrPrevious(state, action: PayloadAction<number>) {
      state.tourStepIndex = action.payload;
    },
    endTour(state) {
      localStorage.setItem(LocalStorage.TourIndex, '1');
      state.tourRun = false;
    },
    setFilterDialog(
      state,
      action: PayloadAction<{
        groupId: string | undefined;
        tableFilterType: TableFilterType;
        filterType: FilterType | string;
        availableFilterValues: CustomFilterType;
      }>,
    ) {
      const { groupId, tableFilterType, filterType, availableFilterValues } = action.payload;

      let selectedFilterValues: SelectedFilterValues[] = [];
      const groupFilters = state.groupFilters.find((groupFilter) => groupFilter.groupId === groupId);
      const tableFiltersOfType = groupFilters
        ? groupFilters.tableFilters.filter((tableFilter) => tableFilter.tableFilterType === tableFilterType) || []
        : [];
      if (tableFiltersOfType.length > 0 && tableFiltersOfType[0].filters) {
        const filtersOfType = tableFiltersOfType[0].filters.filter((filter) => filter.filterType === filterType) || [];
        if (filtersOfType[0] && filtersOfType[0].selectedFilterValues) {
          selectedFilterValues = filtersOfType[0].selectedFilterValues;
        }
      }
      state.tableFilterType = tableFilterType;
      state.filterType = filterType;
      state.availableFilterValues = availableFilterValues;
      state.selectedFilterValues = selectedFilterValues;
    },
    setFilter(state, action: PayloadAction<any>) {
      state.selectedFilterValues = action.payload || [];
    },
    setGroupFiltersSuccess(state, action: PayloadAction<any>) {
      state.groupFilters = action.payload || [];
    },
    fetchGroupFiltersSuccess(state, action: PayloadAction<any>) {
      state.groupFilters = action.payload || [];
    },
    clearFiltersSuccess(state, action: PayloadAction<any>) {
      state.groupFilters = action.payload || [];
    },
    resetFilter(state) {
      state.filterType = '';
      state.selectedFilterValues = [];
    },
    clearFilters(state, action: PayloadAction<TableFilterType>) {
      state.tableFilterType = action.payload;
      state.filterType = '';
      state.selectedFilterValues = [];
    },
    setSelectedClientId(state, action: PayloadAction<string | undefined>) {
      state.selectedClientId = action.payload;
    },
    setDefaultClientId(state, action: PayloadAction<string | undefined>) {
      state.defaultClientId = action.payload;
    },
    setSelectedTabId(state, action: PayloadAction<string | undefined>) {
      state.selectedTabId = action.payload;
    },
    setSelectedPricingTabId(state, action: PayloadAction<string | undefined>) {
      state.selectedPricingTabId = action.payload;
    },
    resetClientIds(state) {
      state.defaultClientId = undefined;
      state.selectedClientId = undefined;
      state.selectedTabId = undefined;
    },
    setVisibleLanguages(state, action: PayloadAction<Language[] | undefined>) {
      state.languages = action.payload;
    },
    setCurrency(state, action: PayloadAction<string | undefined>) {
      state.currency = action.payload;
    },
    setUseMetricUnits(state, action: PayloadAction<boolean | undefined>) {
      state.useMetricUnits = action.payload;
    },
    setMenuStatus(state, action: PayloadAction<MenuStatus>) {
      state.menuStatus = action.payload;
    },
    setPublishBarDisplay(state, action: PayloadAction<boolean>) {
      state.displayPublishBar = action.payload;
    },
  },
});

export const {
  setCurrency,
  setUseMetricUnits,
  setTheme,
  fetchVisibleColumns,
  fetchVisibleColumnsError,
  fetchVisibleColumnsSuccess,
  setVisibleColumns,
  setVisibleColumnsError,
  setVisibleColumnsSuccess,
  setWarningText,
  clearWarningText,
  setTourSteps,
  openTour,
  tourNextOrPrevious,
  endTour,
  setFilterDialog,
  setFilter,
  setGroupFiltersSuccess,
  fetchGroupFiltersSuccess,
  resetFilter,
  clearFilters,
  clearFiltersSuccess,
  setSelectedClientId,
  setDefaultClientId,
  setSelectedTabId,
  setSelectedPricingTabId,
  resetClientIds,
  setVisibleLanguages,
  setMenuStatus,
  setPublishBarDisplay,
} = viewerSlice.actions;

export const setToastMessage = createAction<{ type: ToastMessageType; message: string }>('viewer/setToastMessage');

export const getTheme = createAction('viewer/getTheme');

export const setTourViewed = createAction('viewer/setTourViewed');

export const setGroupFilters = createAction('viewer/setGroupFilters');

export const fetchGroupFilters = createAction('viewer/fetchGroupFilters');

export const getVisibleLanguages = createAction<string[]>('viewer/getVisibleLanguages');
