/* eslint-disable react-hooks/rules-of-hooks */
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import { AppBar, CircularProgress, Grid, Tab, Tabs, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ClientDataBranchReport } from './ClientDataBranchReport';
import { EmailSuppressionReport } from './EmailSuppressionReport';

const useStyles = makeStyles({
  root: {
    flex: 1,
    width: '100%',
  },
  gridWrapper: {
    height: 'calc(100% - 48px)',
    width: '100%',
  },
});

export const loadingComponent: React.FC<any> = () => (
  <CircularProgress style={{ alignSelf: 'center' }} color="primary" />
);
export const noOverlayComponent: React.FC<any> = () => <div />;

const enum ReportTabs {
  ClientDataBranches = 'client-data-branches',
  EmailSuppressionList = 'email-suppression-list',
}

const tabs = [
  {
    label: 'Client Data Datasets',
    value: ReportTabs.ClientDataBranches,
    id: 'client-data-branch-tab',
  },
  {
    label: 'Email Suppression List',
    value: ReportTabs.EmailSuppressionList,
    id: 'email-suppression-tab',
  },
];

export const Reports: React.FC = () => {
  const classes = useStyles();
  const [tab, setTab] = React.useState(ReportTabs.ClientDataBranches);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
    }),
    [],
  );

  // Hook that updates when breakpoint value changes to/from xs
  const tabsVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'fullWidth' : undefined;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
        <Tabs
          value={tab}
          onChange={(event: React.SyntheticEvent<Element, Event>, newValue: ReportTabs): void => {
            setTab(newValue);
          }}
          aria-label="settings-tabs"
          variant={tabsVariant}
          indicatorColor="primary"
        >
          {tabs.map((appBarTab) => (
            <Tab
              key={appBarTab.id}
              label={appBarTab.label}
              value={appBarTab.value}
              id={appBarTab.id}
              aria-controls={appBarTab.id}
            />
          ))}
        </Tabs>
      </AppBar>
      <Grid container className={classes.gridWrapper}>
        {tab === ReportTabs.ClientDataBranches && <ClientDataBranchReport defaultColDef={defaultColDef} />}
        {tab === ReportTabs.EmailSuppressionList && <EmailSuppressionReport defaultColDef={defaultColDef} />}
      </Grid>
    </div>
  );
};
