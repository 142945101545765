import React from 'react';
import { formValueSelector } from 'redux-form';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../hooks';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { PricingBaseSheetNameForm, FormData } from './PricingBaseSheetNameForm';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { PricingBaseSheetNameFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { AppState } from '../types/AppState';
import { updateSheetTitle } from '../utils/pricingSheetUtils';
import { mapClientAndDataTypeAndTableToUndoStackId } from '../utils/clientIdUtils';
import { ClientDataType } from '../constants/ClientDataType';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  attributeHeader: {
    marginTop: '16px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  list: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  listItem: {
    paddingTop: '0px',
    marginTop: '0px',
  },
}));

export const PricingBaseSheetNameDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pricingBaseSheets } = usePricingRepo({
    usePricingSheetBase: true,
  });

  const { selectedPricingSheetId } = useAppSelector((state) => state.pricing.base);
  const selectedPricingSheet = pricingBaseSheets.find((sheet) => sheet.id === selectedPricingSheetId);
  const initialSheetName = selectedPricingSheet?.prices[0]?.priceSetLabel || '';
  const initialValues = {
    [PricingBaseSheetNameFormFields.SheetTitle]: initialSheetName,
  } as FormData;

  const placeholder = `Pricing Sheet ${selectedPricingSheet?.id || ''}`;
  const currentSheetName = useAppSelector((state: AppState) =>
    formValueSelector(Forms.PricingBaseSheetName)(state, PricingBaseSheetNameFormFields.SheetTitle),
  );

  const saveDisabled = currentSheetName === initialSheetName;

  const selectedViewerId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId,
  );

  const selectedTable = selectedViewerId?.startsWith('shedview') ? 'basePrice' : 'pricingBase';

  const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(
    selectedViewerId,
    ClientDataType.Supplier,
    selectedTable,
  );

  const onSave = () => {
    if (selectedPricingSheet) {
      updateSheetTitle(currentSheetName, clientDataTableId, selectedPricingSheet, dispatch);
      dispatch(closeDialogFunc());
    }
  };

  return (
    <Dialog dialogKey={Dialogs.PricingBaseSheetName}>
      <DialogTitle className={classes.dialogTitle}>{t(I18nKeys.PricingBaseSheetNameDialogTitle)}</DialogTitle>
      <DialogContent>
        <PricingBaseSheetNameForm placeholder={placeholder} initialValues={initialValues} />
        {selectedPricingSheet?.attributes.map((attrib) => {
          const count = attrib.value.split(',').length;
          return (
            <div key={attrib.type}>
              <Typography className={classes.attributeHeader}>{`${count} ${attrib.type}${
                count > 1 ? 's' : ''
              }`}</Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>{attrib.label || attrib.value}</li>
              </ul>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions style={{ padding: '0px 8px 8px 8px' }}>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button onClick={onSave} color="primary" disabled={saveDisabled}>
          {t(I18nKeys.DialogSaveButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
