/* eslint-disable react/no-danger */
import React from 'react';
import { Card, CardContent, FormGroup, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import { AppState } from '../types/AppState';
import { PricingAdjustmentState } from '../types/PricingAdjustmentState';
import {
  SystemOrderCalculations,
  PricingSurchargeCondition,
  MaximumDigits,
  PricingAdjustmentErrorType,
} from '../constants/PricingAdjustment';
import { PricingSelectableChip } from './PricingSelectableChip';
import {
  addPricingRule,
  addPricingAdjustmentConditionToAllRules,
  removePricingAdjustmentConditionFromAllRules,
  updateSurchargeCalculation,
} from '../ducks/pricingAdjustment';
import { PricingRule } from './PricingRule';
import {
  countUniqueLabels,
  findMatchingSurchargeCalculation,
  parseRoundTo,
  getCurrencySymbol,
} from '../utils/pricingUtils';
import { isCarportView } from '../utils/clientIdUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppSelector, useAppDispatch } from '../hooks';

const useStyles = makeStyles({
  card: {
    backgroundColor: '#f0f0f0',
    width: '100%',
  },
  textField: {
    '& > *': {
      width: '15ch',
    },
    marginRight: '10px',
  },
  buttonCardContent: {
    '&:last-child': {
      padding: '5px 15px',
    },
    padding: '5px 15px',
  },
  addIcon: {
    color: 'green',
  },
  onClick: {
    marginTop: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  spacing: {
    marginTop: '10px',
  },
});

export const PricingAmount: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { currency } = useAppSelector((state: AppState) => state?.viewer);
  const {
    pricingSelection,
    draftSurchargeCalculations,
    conditionOptions,
    clientId = '',
    supplierKey,
    errors: validationErrors,
  } = useAppSelector((state: AppState) => state?.pricingAdjustment) as PricingAdjustmentState;

  const { calculation, rules } = findMatchingSurchargeCalculation(
    clientId,
    draftSurchargeCalculations,
    supplierKey,
    pricingSelection,
  );
  const typeText =
    pricingSelection === SystemOrderCalculations.Surcharge
      ? t(I18nKeys.PricingAmountSurchargeType)
      : t(I18nKeys.PricingAmountChangeType);

  const handleCalculationValueChange = (property: string, newValue?: string, percentage = false): void => {
    let valueAsFloat;
    if (newValue !== undefined && newValue !== '') {
      if (percentage) {
        const valueAsString =
          newValue.length > MaximumDigits.PercentChange ? newValue.slice(0, MaximumDigits.PercentChange) : newValue;
        valueAsFloat = parseFloat(valueAsString) / 100;
      } else {
        valueAsFloat = property === 'roundTo' ? parseRoundTo(newValue) : parseFloat(newValue);
      }
    }
    dispatch(
      updateSurchargeCalculation({
        ...calculation,
        [property]: valueAsFloat,
      }),
    );
  };

  const handleChipSelection = (selectedValue: string, selected: boolean): void => {
    if (selected) {
      // already selected so remove all conditions that use selectedValue as a condition type
      dispatch(removePricingAdjustmentConditionFromAllRules(selectedValue));
    } else {
      // new condition type so add it to all rules
      dispatch(addPricingAdjustmentConditionToAllRules(selectedValue));
    }
  };

  const [pricingConditionOptions, setPricingConditionOptions] = React.useState(conditionOptions);
  React.useEffect(() => {
    let filteredConditionOptions = conditionOptions.filter(
      (option) => countUniqueLabels(option.options) > 1 || option.value === PricingSurchargeCondition.Subtotal,
    );
    if (isCarportView(clientId)) {
      filteredConditionOptions = filteredConditionOptions.filter(
        (option) =>
          option.value !== PricingSurchargeCondition.Style &&
          option.value !== PricingSurchargeCondition.Gauge &&
          option.value !== PricingSurchargeCondition.Length,
      );
      if (pricingSelection === SystemOrderCalculations.Surcharge) {
        setPricingConditionOptions(
          filteredConditionOptions.filter((option) => option.value !== PricingSurchargeCondition.Region),
        );
        dispatch(removePricingAdjustmentConditionFromAllRules(PricingSurchargeCondition.Region));
      } else if (pricingSelection === SystemOrderCalculations.LineItem) {
        setPricingConditionOptions(
          filteredConditionOptions.filter(
            (option) =>
              option.value !== PricingSurchargeCondition.State && option.value !== PricingSurchargeCondition.Subtotal,
          ),
        );
        dispatch(removePricingAdjustmentConditionFromAllRules(PricingSurchargeCondition.State));
      }
    } else {
      filteredConditionOptions.filter((option) => option.value !== PricingSurchargeCondition.State);
      setPricingConditionOptions(filteredConditionOptions);
    }
  }, [pricingSelection, conditionOptions, clientId, removePricingAdjustmentConditionFromAllRules]);

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography>{t(I18nKeys.PricingAmountDefault, { type: typeText })}</Typography>
          <FormGroup row className={classes.spacing}>
            <TextField
              className={classes.textField}
              variant="standard"
              label={t(I18nKeys.PricingPercentChange)}
              type="number"
              value={
                calculation.percentChange
                  ? parseFloat((calculation.percentChange * 100).toFixed(7)).toString()
                  : calculation.percentChange
              }
              onChange={(event): void => {
                handleCalculationValueChange('percentChange', event.target.value, true);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              error={
                validationErrors.some(
                  (error) =>
                    error.ruleId === -1 &&
                    error.errorType === PricingAdjustmentErrorType.Calculation &&
                    error.type === 'percentChange',
                )
                  ? true
                  : undefined
              }
            />
            {pricingSelection && pricingSelection === SystemOrderCalculations.Surcharge && (
              <TextField
                className={classes.textField}
                variant="standard"
                label={t(I18nKeys.PricingAmountChange)}
                type="number"
                value={calculation.amountChange}
                onChange={(event): void => {
                  handleCalculationValueChange('amountChange', event.target.value);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                }}
                error={
                  validationErrors.some(
                    (error) =>
                      error.ruleId === -1 &&
                      error.errorType === PricingAdjustmentErrorType.Calculation &&
                      error.type === 'amountChange',
                  )
                    ? true
                    : undefined
                }
              />
            )}
            <TextField
              className={classes.textField}
              variant="standard"
              label={t(I18nKeys.PricingRoundToNearest)}
              type="number"
              value={calculation.roundTo}
              onChange={(event): void => {
                handleCalculationValueChange('roundTo', event.target.value);
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
              }}
              error={
                validationErrors.some(
                  (error) =>
                    error.ruleId === -1 &&
                    error.errorType === PricingAdjustmentErrorType.Calculation &&
                    error.type === 'roundTo',
                )
                  ? true
                  : undefined
              }
            />
          </FormGroup>
        </CardContent>
      </Card>
      <Grid container className={classes.spacing} direction="row" spacing={2} alignItems="center">
        <Grid item>
          <Typography>{t(I18nKeys.PricingAmountVaryBy, { type: typeText })}</Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={1}>
            {pricingConditionOptions.map((option) => (
              <Grid key={`${option.value}-grid`} item>
                <PricingSelectableChip
                  chip={option}
                  selected={rules.some((rule) => rule.conditions.some((condition) => condition.type === option.value))}
                  handleChipSelection={handleChipSelection}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <PricingRule />
      {rules.length > 0 && (
        <Grid container className={classes.onClick} onClick={() => dispatch(addPricingRule())}>
          <Card className={classes.card}>
            <CardContent className={classes.buttonCardContent}>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item>
                  <AddCircleIcon className={classes.addIcon} />
                </Grid>
                <Grid item>
                  <Typography>{t(I18nKeys.PricingAmountAddRule, { type: typeText })}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};
