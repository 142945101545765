import { PricingAdjustmentErrorType } from '../constants/PricingAdjustment';
import {
  PricingAdjustmentCondition,
  PricingAdjustmentError,
  SurchargeCalculation,
  SurchargeCondition,
  SurchargeKeyCondition,
  SurchargeRangeCondition,
  SurchargeSystemOrderCalculation,
} from '../types/PricingAdjustment';
import { pluralizeString, concatenateList } from './stringUtils';

export const conditionIsValid = (
  condition: SurchargeCondition | SurchargeKeyCondition | SurchargeRangeCondition,
  conditionOptions: PricingAdjustmentCondition[],
): boolean => {
  const completeCondition = conditionOptions.find((option) => option.value === condition.type);
  let currCondition;
  if (completeCondition && completeCondition.range) {
    currCondition = condition as SurchargeRangeCondition;
    const hasMax = !!currCondition.maximum && typeof currCondition.maximum === 'number';
    const hasMin = !!currCondition.minimum && typeof currCondition.minimum === 'number';
    return hasMax && hasMin;
  }
  if (completeCondition && !completeCondition.range) {
    currCondition = condition as SurchargeKeyCondition;
    return currCondition.keys && currCondition.keys.length > 0;
  }
  return false;
};

export const validateSurchargeCalculation = (
  surchargeCalculation: SurchargeCalculation,
  ruleId = -1,
): PricingAdjustmentError[] => {
  const errors: PricingAdjustmentError[] = [];

  if (surchargeCalculation.percentChange === undefined || Number.isNaN(surchargeCalculation.percentChange)) {
    errors.push({ ruleId, errorType: PricingAdjustmentErrorType.Calculation, type: 'percentChange' });
  }

  if (surchargeCalculation.amountChange === undefined || Number.isNaN(surchargeCalculation.amountChange)) {
    errors.push({ ruleId, errorType: PricingAdjustmentErrorType.Calculation, type: 'amountChange' });
  }

  if (
    surchargeCalculation.roundTo === undefined ||
    Number.isNaN(surchargeCalculation.roundTo) ||
    surchargeCalculation.roundTo < 0
  ) {
    errors.push({ ruleId, errorType: PricingAdjustmentErrorType.Calculation, type: 'roundTo' });
  }

  return errors;
};

export const validateSurcharge = (
  surcharge: SurchargeSystemOrderCalculation,
  conditionOptions: PricingAdjustmentCondition[],
): PricingAdjustmentError[] => {
  const errors: PricingAdjustmentError[] = [];

  // Validate surcharge rules
  surcharge.rules.forEach((rule) => {
    rule.conditions.forEach((condition) => {
      // Validate rule conditions
      if (!conditionIsValid(condition, conditionOptions))
        errors.push({ ruleId: rule.id, errorType: PricingAdjustmentErrorType.Condition, type: condition.type });
    });
    // Validate surcharge run calculation
    errors.push(...validateSurchargeCalculation(rule.calculation, rule.id));
  });

  // Validate surcharge calculation
  errors.push(...validateSurchargeCalculation(surcharge.calculation));

  return errors;
};

const getMissingConditionErrorMessage = (
  conditionErrors: PricingAdjustmentError[],
  conditionOptions: PricingAdjustmentCondition[],
): string => {
  const missingConditionsLabels: string[] = [];
  conditionErrors.forEach((error) => {
    const conditionOption = conditionOptions.find((option) => option.value === error.type);
    if (!conditionOption) return;
    const newLabel = pluralizeString(conditionOption.label).toLowerCase();
    if (!missingConditionsLabels.some((label) => label === newLabel)) {
      missingConditionsLabels.push(newLabel);
    }
  });
  const missingConditions = concatenateList(missingConditionsLabels);
  const missingConditionsString = `Please select the ${missingConditions} that will use the price variation.</br>`;
  return missingConditionsString;
};

const getCalculationErrorMessage = (errors: PricingAdjustmentError[]): string => {
  let message = '';

  if (errors.some((error) => error.type === 'amountChange')) {
    message += 'Must provide a value for Amount Change.</br>';
  }

  if (errors.some((error) => error.type === 'percentChange')) {
    message += 'Must provide a value for Percent Change.</br>';
  }

  if (errors.some((error) => error.type === 'roundTo')) {
    message += 'Must provide a positive value for Round To.</br>';
  }

  return message;
};

export const getValidationErrorMessage = (
  errors: PricingAdjustmentError[],
  conditionOptions: PricingAdjustmentCondition[],
): string => {
  let message = '';
  if (errors.some((error) => error.errorType === PricingAdjustmentErrorType.Condition)) {
    message += getMissingConditionErrorMessage(
      errors.filter((error) => error.errorType === PricingAdjustmentErrorType.Condition),
      conditionOptions,
    );
  }

  if (errors.some((error) => error.errorType === PricingAdjustmentErrorType.Calculation)) {
    message += getCalculationErrorMessage(
      errors.filter((error) => error.errorType === PricingAdjustmentErrorType.Calculation),
    );
  }

  return message;
};
