/* eslint-disable react/jsx-props-no-spreading */
import { Column, ICellRendererParams } from 'ag-grid-community';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Theme } from '@mui/material';
import Dropzone, { FileRejection, ErrorCode, Accept } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { hasCellMetadataProperty, updateValues } from '../utils/clientDataUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { CellMetadataProperty } from '../constants/ClientData';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { I18nKeys } from '../constants/I18nKeys';
import { S3Buckets } from '../constants/S3';
import { useAppDispatch, useAppSelector } from '../hooks';
import { mapClientAndDataTypeAndTableToUndoStackId, mapClientIdToConfiguratorAndVendor } from '../utils/clientIdUtils';
import { toBase64 } from '../utils/fileUtils';
import { s3Api } from '../services/s3Api';

const useStyles = makeStyles<Theme>((theme) => ({
  noteHandle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderBottom: '7px solid transparent',
    borderRight: '7px solid #4994EC',
    right: '-1px',
    top: '0px',
  },
  indexColumn: {
    backgroundColor: 'var(--ag-header-background-color)',
    justifyContent: 'center',
  },
  checkbox: {
    padding: '0px',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    maxWidth: 'none',
  },
  image: {
    height: '100%',
  },
  dropzoneDiv: {
    height: '100%',
  },
}));

interface ClientDataFileProps {
  bucketPath?: string;
  fileType?: string;
  accept?: Accept;
  maxFileSize?: number;
}

type ClientDataFileCellProps = ClientDataFileProps & ICellRendererParams;

export const ClientDataFileCell: React.FC<ClientDataFileCellProps> = ({
  data,
  column,
  value = '',
  fileType,
  bucketPath = '',
  accept,
  maxFileSize = 3000000,
}: ClientDataFileCellProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const rowId = data[ClientDataFixedColumns.RowId];
  const columnId = (column as Column).getColId();
  const dispatch = useAppDispatch();
  const { clientId, selectedTable, clientDataType } = useAppSelector((state) => state?.clientData);

  const { cellMetadata } = useClientDataRepo({ useCellMetadata: true });
  const hasNote = hasCellMetadataProperty(cellMetadata, rowId, columnId, CellMetadataProperty.Note);

  const valueIsFullUrl = value && value.startsWith('http');

  // If the value is a full URL, grab just the filename
  const formattedValue = valueIsFullUrl ? value.split('/').pop() : value;

  const handleOnDrop = async (newFile: File[]) => {
    if (newFile.length > 0) {
      try {
        const base64 = await toBase64(newFile[0]);

        const fileExtension = newFile[0].name.split('.').pop();

        const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);
        const name = `${fileType}-${configurator}-${clientDataType}-${vendor}-${Date.now()}.${fileExtension}`;
        await dispatch(
          s3Api.endpoints.uploadFile.initiate({
            bucket: S3Buckets.Assets,
            path: `${bucketPath}/`,
            file: {
              name,
              content: base64,
              contentType: newFile[0].type,
            },
          }),
        );

        const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(clientId, clientDataType, selectedTable);

        updateValues(
          clientDataTableId,
          [
            {
              table: selectedTable,
              data,
              column: columnId,
              oldValue: value,
              newValue: name,
            },
          ],
          cellMetadata,
          dispatch,
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        toast.error(t(I18nKeys.ClientDataUploadFileUnknownError));
      }
    }
  };

  const handleOnDropRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
      switch (fileRejections[0].errors[0].code) {
        case ErrorCode.FileTooLarge:
          toast.error(t(I18nKeys.ClientDataUploadFileTooLarge, { maxFileSize: maxFileSize / 1000000 }));
          break;
        case ErrorCode.FileInvalidType:
          toast.error(t(I18nKeys.ClientDataUploadFileInvalidType));
          break;
        default:
          toast.error(t(I18nKeys.ClientDataUploadFileUnknownError));
          break;
      }
    }
  };

  return (
    <span>
      <Dropzone
        accept={accept}
        onDrop={handleOnDrop}
        onDropRejected={handleOnDropRejected}
        multiple={false}
        maxSize={maxFileSize}
        noClick
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.dropzoneDiv}>
            <input {...getInputProps()} />
            {formattedValue || t(I18nKeys.ClientDataUploadFileDropzone)}
          </div>
        )}
      </Dropzone>
      {hasNote && <div className={classes.noteHandle} />}
    </span>
  );
};
