import { PriceCalculation } from '@idearoom/types';
import { Dispatch } from 'react';
import { change } from 'redux-form';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { ClientDataType } from '../constants/ClientDataType';
import {
  DisplayColumns,
  PricingColumns,
  PricingCalculationColumns,
  COMPONENT_CATEGORY_MAPPING,
  PRICING_TYPE_MAP,
  HelperColumns,
  componentColumnMap,
  RegionPriceColumns,
} from '../constants/ComponentPricing';
import { PricingComponentEditFields } from '../constants/FormFields';
import { ComponentCategory, ComponentCategoryItem } from '../types/ComponentPricing';
import { TableData } from '../types/DataGrid';
import { addDispatchCommandToUndo } from './undoManagerUtils';
import { updatePricingComponentRows } from '../ducks/pricingSlice';
import { arePriceValuesDifferent, formatNumber, getValidatedNewValue } from './pricingUtils';
import { UpdateClientDataRow } from '../ducks/clientDataSlice';
import { FormData } from '../components/PricingComponentEditForm';
import { mapClientAndDataTypeAndTableToUndoStackId } from './clientIdUtils';
import { AppDispatch } from '../hooks';
import { compoundCaseToTitleCase, fuzzyMatchIncludes, kebabCaseToTitleCase, pluralizeString } from './stringUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { ComponentCategoryKey } from '../constants/ComponentCategoryKey';
import { Forms } from '../constants/Forms';
import { Region } from '../types/Region';

/**
 * Returns a parsed and formatted price value for a given field and row/form data.
 *
 * @param column column name
 * @param rowData data for the component row
 * @returns parsed and formatted price value
 */
export const parsePriceValue = (column: string, rowData: any): number | string | null => {
  const value = `${rowData?.[column]}` || '';
  const valueWithoutCurrency = value.replace(/[$€£¥₣,]/g, '');

  if (rowData?.priceExpression) return i18n.t(I18nKeys.PricingComponentPriceExpressionPrice) as string;
  return parseFloat(valueWithoutCurrency || '0')?.toFixed(2) || null;
};

/**
 * Returns whether the given region column is using the default price column's value.
 *
 * @param column column name
 * @param rowData data for the component row
 * @returns whether the region column is using the default price column's value
 */
export const usingDefaultRegionPrice = (column: string, rowData: any) => {
  // If the value is null or undefined, it falls back to the default price
  if (rowData[column] === undefined || rowData[column] === null) return true;
  return (
    parsePriceValue(column as keyof ComponentCategoryItem, rowData as any) ===
    parsePriceValue(PricingColumns.Price, rowData)
  );
};

/**
 * Returns whether any of the component category item's region prices differ from the default price.
 *
 * @param rowData data for the component row
 * @returns whether any region prices differ from the default price
 */
export const pricingVariesByRegion = (rowData: any) =>
  Object.keys(rowData).some((col) => RegionPriceColumns.includes(col) && !usingDefaultRegionPrice(col, rowData));

export const getComponentFieldLabel = (field: keyof FormData, regions: Region[]) => {
  if (field === DisplayColumns.PriceExpression) return '';

  if (RegionPriceColumns.includes(field)) {
    const regionLabel = regions.find(({ priceColumn }) => priceColumn === (field as string))?.label;
    if (regionLabel) return regionLabel;
  }

  return compoundCaseToTitleCase(field);
};

/**
 * Responds to an edit of a component category item's price field and updates any region prices that are using the default price.
 *
 * @param event event that triggered the change
 * @param currentValues current form data
 * @param dispatch
 * @returns
 */
export const onComponentFieldChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  currentValues: FormData,
  dispatch: Dispatch<any>,
) => {
  const { name: field, value } = event.target;

  if (field !== PricingColumns.Price) return;

  Object.keys(currentValues).forEach((f) => {
    if (!RegionPriceColumns.includes(f) || !usingDefaultRegionPrice(f as keyof ComponentCategoryItem, currentValues))
      return;
    dispatch(change(Forms.PricingComponentEdit, f, value));
  });
};

/**
 * Gets the starting value for a component pricing field.
 *
 * @param column column name
 * @param rowData data for the component row
 * @returns initial value for the component pricing field
 */
export const getDefaultParsedComponentPricingColumnValue = (
  column: keyof ComponentCategoryItem,
  rowData?: ComponentCategoryItem,
) => {
  const { t } = i18n;

  const value = rowData?.[column];
  if (Object.values(PricingCalculationColumns).some((v) => v === column)) {
    return (
      (rowData?.priceExpression && t(I18nKeys.PricingComponentPriceExpressionCalculation)) ||
      value ||
      PriceCalculation.Amount
    );
  }

  if (Object.values(PricingColumns).some((v) => v === column)) {
    if (value && typeof value === 'string') {
      return parsePriceValue(column, rowData);
    }
    if (RegionPriceColumns.includes(column) && !value) {
      return parsePriceValue(PricingColumns.Price, rowData);
    }
  }
  return value;
};

/**
 * Finds all available component pricing form fields for the given component ID's table columns
 * and includes initial values if data exists.
 * Pricing calculation fields default to 'Amount' if no value exists.
 *
 * @param componentRowId component row ID
 * @param componentCategoryItems all component data for the category
 * @returns component pricing form fields and initial values
 */
export const getComponentPricingFormInitialValues = (
  componentRowId: string,
  componentCategoryItems: ComponentCategoryItem[],
): Record<string, string | null | undefined> | undefined => {
  const componentData = componentCategoryItems.find((item) => item.rowId === componentRowId);

  if (!componentData) {
    return undefined;
  }

  const columns = [
    ...Object.keys(componentData).filter((c) =>
      [DisplayColumns, PricingColumns].some((e) => Object.values(e).some((v) => v === c)),
    ),
    // Always include price calculation column
    PricingCalculationColumns.PriceCalculation,
  ];
  return Object.fromEntries(
    [
      [PricingComponentEditFields.Component, componentRowId],
      [PricingComponentEditFields.Table, componentData.table],
      [HelperColumns.Key, componentData.key],
      ...columns
        .map((c) => [c, getDefaultParsedComponentPricingColumnValue(c as keyof ComponentCategoryItem, componentData)])
        // Do not display price expression column if a value does not exist
        .filter(
          ([c, v]) =>
            !([DisplayColumns.PriceExpression, PricingColumns.UpgradePrice] as string[]).includes(c as string) || !!v,
        ),
      [HelperColumns.VariesByRegion, pricingVariesByRegion(componentData)],
    ].sort(([a]: any, [b]: any) => (componentColumnMap[a]?.order || 0) - (componentColumnMap[b]?.order || 0)),
  );
};

export const getChangedValues = (updates: { data: TableData; column: string; oldValue: any; newValue: any }[]) => {
  const newValues: { data: TableData; column: string; oldValue: any; newValue: any }[] = [];

  updates.forEach(({ data, column, oldValue, newValue }) => {
    let newVal: string | null = `${newValue}`.trim();
    let oldVal: string = `${oldValue}`.trim();

    let valueChanged = oldVal !== newVal;
    if (Object.values(PricingColumns).some((v) => v === column)) {
      newVal = newValue === null ? newValue : getValidatedNewValue(newValue, oldVal);
      oldVal = getValidatedNewValue(oldVal, oldVal);
      valueChanged = arePriceValuesDifferent(oldVal, newVal);
    } else if (Object.values(PricingCalculationColumns).some((v) => v === column)) {
      valueChanged = valueChanged && (!!oldValue || newVal !== PriceCalculation.Amount);
    }

    if (valueChanged) {
      newValues.push({ data: { ...data, [column]: newVal }, column, oldValue, newValue });
    }
  });

  return newValues;
};

const updateValues = (
  clientDataTableId: string,
  updates: { data: TableData; column: string; oldValue: any; newValue: any }[],
  dispatch: AppDispatch,
) => {
  const [, , table] = clientDataTableId.split(':');

  const newValues = getChangedValues(updates);

  const newRows: (UpdateClientDataRow & { table: string })[] = [];
  const oldRows: (UpdateClientDataRow & { table: string })[] = [];

  newValues.forEach(({ data, column, oldValue, newValue }) => {
    oldRows.push({ table, rowData: data, column, value: oldValue, formula: undefined });
    newRows.push({ table, rowData: data, column, value: newValue, formula: undefined });
  });

  if (newRows.length > 0) {
    addDispatchCommandToUndo(
      dispatch,
      [updatePricingComponentRows(oldRows)],
      [updatePricingComponentRows(newRows)],
      clientDataTableId,
      true,
    );
  }
};

export const onComponentEditSubmit = (
  clientId: string,
  formData: FormData,
  componentCategoryItems: ComponentCategoryItem[],
  dispatch: AppDispatch,
) => {
  const componentId = formData[PricingComponentEditFields.Component];
  const componentData = componentCategoryItems.find(
    ({ [ClientDataFixedColumns.RowId]: rowId }) => rowId === componentId,
  );

  if (!componentData) return;

  const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(
    clientId,
    ClientDataType.Supplier,
    componentData.table,
  );

  const relevantComponentTableData = { [ClientDataFixedColumns.RowId]: componentData.rowId };

  const updates = Object.entries(formData)
    .filter(([c]) => {
      // Only allow edits to editable columns
      if (![PricingColumns, [DisplayColumns.Label]].flatMap((e) => Object.values(e)).includes(c)) return false;

      // Ignore changes to prices if a price expression exists
      if ((Object.values(PricingColumns) as string[]).includes(c) && formData[DisplayColumns.PriceExpression])
        return false;

      // If the previous region price was null, and the default price is still being used, ignore changes
      if (
        RegionPriceColumns.includes(c) &&
        componentData[c as keyof ComponentCategoryItem] === null &&
        usingDefaultRegionPrice(c, formData)
      )
        return false;

      return true;
    })
    .map(([c, newValue]) => {
      let value = newValue;
      const oldValue = componentData[c as keyof ComponentCategoryItem];
      // If disabling varies by region, set all region prices to the default price if they aren't already
      if (
        !formData[HelperColumns.VariesByRegion] &&
        RegionPriceColumns.includes(c) &&
        !usingDefaultRegionPrice(c, formData)
      ) {
        // If the old value is the default, reuse the old value to avoid creating a diff
        value = usingDefaultRegionPrice(c, { [c]: oldValue, formData }) ? oldValue : null;
      }

      if (Object.values(PricingColumns).includes(c as PricingColumns) && value !== null) {
        value = formatNumber(value);
      }

      return { data: relevantComponentTableData, column: c, oldValue, newValue: value };
    });

  if (!updates.length) return;

  updateValues(clientDataTableId, updates, dispatch);
};

export const filterComponentCategoryItems = (searchValue: string, componentCategoryItems?: ComponentCategoryItem[]) =>
  (componentCategoryItems || []).filter(
    (item) =>
      !searchValue ||
      Object.entries(item)
        .filter(([column]) =>
          (
            [PricingCalculationColumns, DisplayColumns, PricingColumns].flatMap((e) => Object.values(e)) as string[]
          ).includes(column),
        )
        .some(([_, val]) => fuzzyMatchIncludes(`${val}`, searchValue)),
  );

export const filterComponentCategories = (searchValue: string, componentCategories?: ComponentCategory[]) =>
  (componentCategories || []).filter(
    ({ key }) =>
      !searchValue ||
      [COMPONENT_CATEGORY_MAPPING[key]?.label, key].some((val) => fuzzyMatchIncludes(`${val}`, searchValue)),
  );

export const getComponentSearchCountText = (
  searchValue: string,
  categoryKey?: ComponentCategoryKey,
  filteredItems?: ComponentCategory[] | ComponentCategoryItem[],
  componentItems?: ComponentCategoryItem[],
  categories?: ComponentCategory[],
) => {
  const { total = 0, label } = categoryKey
    ? { total: componentItems?.length, label: 'component' }
    : { total: categories?.length, label: 'category' };
  const pluralizedLabel = total > 1 ? pluralizeString(label) : label;

  if (!searchValue) return `${total} ${pluralizedLabel}`;
  return `${filteredItems?.length || 0} of ${total} ${pluralizedLabel}`;
};

export const getPricingTypeLabel = (type: PriceCalculation) => {
  const { t } = i18n;
  const uniqueKey = PRICING_TYPE_MAP[type];
  return uniqueKey ? t(uniqueKey) : kebabCaseToTitleCase(type);
};

/**
 * Determines whether vary by region UI elements should be displayed.
 * For instance, extra pricing fields in the component pricing edit form or the "Vary By Region" checkbox in the items table.
 *
 * @param regions - regions for the vendor
 * @param tableColumns - columns that are available in the current table
 * @returns whether vary by region UI elements should be displayed
 */
export const displayVaryByRegion = (regions: Region[], tableColumns: string[]) =>
  !!regions.length &&
  tableColumns.some(
    (col) => RegionPriceColumns.includes(col) && regions.some(({ priceColumn }) => priceColumn === col),
  );
