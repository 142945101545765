import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Box, FormControl } from '@mui/material';
import React, { useEffect } from 'react';
import ColorPicker from '@idearoom/mui-color-picker';
import { useTranslation } from 'react-i18next';
import { SiteDetail } from '../types/ClientData';

const useStyles = makeStyles((theme: Theme) => ({
  textContainer: {
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  colorPickerContainer: {
    '&>:nth-child(2)': {
      '&>:nth-child(1)': {
        // Align lower right corner of color picker to
        // upper right corner of color option
        [theme.breakpoints.down('xl')]: {
          right: '0px',
          bottom: '55px',
        },
        // Align lower left corner of color picker to
        // lower right corner of color option
        [theme.breakpoints.up('md')]: {
          left: '100%',
          bottom: '0px',
        },
      },
    },
  },
  filledInput: {
    '& div': {
      height: '55px',
      backgroundColor: '#F0F0F0',
      padding: '12px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    '& input': {
      color: 'black',
    },
  },
  swatch: {
    height: '55px',
    width: '55px',
    display: 'inline-block',
    borderRadius: 5,
    borderStyle: 'solid',
    borderColor: '#878787',
    borderWidth: '1px',
  },
  swatchControl: {
    flexBasis: '55px',
    flexGrow: 0,
    flexShrink: 0,
    marginRight: '16px',
  },
}));

interface Props {
  i18nKey: string;
  name: string;
  vendorPropChange: Function;
  data: SiteDetail | undefined;
  errors: string[];
  disabled?: boolean;
}

export const ColorOption: React.FC<Props> = ({
  vendorPropChange,
  data = {},
  i18nKey = '',
  name = '',
  errors = [],
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const swatchId = `swatch-${name}`;
  const colorPickerId = `color-picker-${name}`;
  const value = (data as any)[name];

  useEffect(() => {
    const swatch = document.getElementById(swatchId) as any;
    const swatchStyle = swatch.style as any;
    swatchStyle.backgroundColor = value;

    const colorPicker = document.getElementById(colorPickerId) as any;
    if (colorPicker && value) {
      colorPicker.value = value.toUpperCase();
    }
  }, [value, swatchId, colorPickerId]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={5} md={6} className={classes.textContainer}>
        <Typography>{t(i18nKey)}</Typography>
      </Grid>
      <Grid item xs={7} md={6} className={classes.textContainer}>
        <FormControl className={classes.swatchControl}>
          <Box id={swatchId} className={classes.swatch} />
        </FormControl>
        <FormControl fullWidth className={classes.colorPickerContainer}>
          <ColorPicker
            id={colorPickerId}
            name={name}
            className={classes.filledInput}
            defaultValue={value}
            value={value}
            onChange={(color: string): void => {
              vendorPropChange(name, color);
            }}
            variant="standard"
            error={!!errors.find((error: any) => error === name)}
            disabled={disabled}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
