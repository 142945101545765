import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { formValueSelector, submit } from 'redux-form';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { LoadingButton } from './LoadingButton';
import { Forms } from '../constants/Forms';
import { PublishFields } from '../constants/FormFields';
import { usePublishClientDataMutation, useSaveBranchMetadataMutation } from '../services/clientDataApi';
import { ClientDataType } from '../constants/ClientDataType';
import { FormData, PublishForm } from './PublishForm';
import { unknownGroup } from '../constants/Group';
import { ClientDataBranchMetadata } from '../types/ClientData';

const useStyles = makeStyles({
  dialogActions: { padding: '0px 8px 8px 8px' },
  firstButton: {
    marginRight: 'auto',
  },
});

interface StateProps {
  dialogKey: Dialogs;
  dialogTitle: string | undefined;
  onPublishClientData: Function;
  branch: ClientDataBranchMetadata | undefined;
  dataType: ClientDataType;
  dialogTopContent: ReactNode;
}

export const PublishDialog: React.FC<StateProps> = ({
  branch,
  dialogKey,
  dataType,
  dialogTitle,
  onPublishClientData,
  dialogTopContent,
}) => {
  const [publishClientData, { isLoading: isPublishing }] = usePublishClientDataMutation();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const [saveBranchMetadata] = useSaveBranchMetadataMutation();

  const { clientId } = useAppSelector((state) => state?.clientData);

  const { branchType, description = '' } = branch || {};

  const formDataDescription = useAppSelector((state) =>
    formValueSelector(Forms.Publish)(state, PublishFields.Description),
  );

  const saveBranchDescription = () => {
    if (branchType && formDataDescription !== description) {
      saveBranchMetadata({
        branch: branchType,
        dataType,
        clientId,
        description: formDataDescription,
        groupId,
      });
    }
  };

  return (
    <Dialog dialogKey={dialogKey} maxWidth="sm" disableClose={isPublishing} onClosed={saveBranchDescription}>
      <DialogTitle id="publish-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        {dialogTopContent}
        <PublishForm
          initialValues={{
            [PublishFields.Description]: description,
          }}
          isPublishing={isPublishing}
          onSubmit={(formData: FormData) => {
            if (branchType) {
              onPublishClientData(
                publishClientData({
                  dataType,
                  branch: branchType,
                  clientId,
                  groupId,
                  message: formData[PublishFields.Description],
                }).unwrap(),
              );
            }
          }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          disabled={isPublishing}
          onClick={(): void => {
            saveBranchDescription();
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <LoadingButton
          onClick={(): void => {
            dispatch(submit(Forms.Publish));
          }}
          loading={isPublishing}
          disabled={isPublishing}
          color="primary"
        >
          {t(I18nKeys.ClientDataPublishButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
