import { AnyAction, ThunkDispatch, createListenerMiddleware } from '@reduxjs/toolkit';
import { AppState } from '../types/AppState';
import {
  setPricingBaseDataBranch,
  updatePricingMetadata,
  updatePricingBaseRows,
  updatePricingComponentRows,
  setPricingComponentDataBranch,
} from '../ducks/pricingSlice';
import { GridData, TableData } from '../types/DataGrid';
import { CellMetadata } from '../types/ClientData';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import {
  UpdateClientDataMetadata,
  saveClientDataComplete,
  saveClientDataStart,
  setCreatingBranch,
  setCreatingBranchComplete,
} from '../ducks/clientDataSlice';
import { COMMIT_SAVE_PREFIX } from '../constants/ClientData';
import { ClientDataType } from '../constants/ClientDataType';
import { clientDataApi } from '../services/clientDataApi';
import { unknownGroup } from '../constants/Group';
import { getUpdatedCellsMetadata } from '../utils/metadataUtils';

export const pricingListener = createListenerMiddleware<AppState>();

/**
 * Updates/Delete rows from the database
 *
 * @returns
 */
const updateDataEffect = async (
  data: GridData,
  metadata: {
    [table: string]: CellMetadata[];
  } | null,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  state: AppState,
  branch: ClientDataBranch,
  newBranch = false,
) => {
  try {
    dispatch(saveClientDataStart());

    const { clientData, currentUser } = state;
    const { clientId } = clientData;
    const { user, group: { groupId } = unknownGroup } = currentUser;

    await dispatch(
      clientDataApi.endpoints.updateClientData.initiate({
        clientId,
        groupId,
        dataType: ClientDataType.Supplier,
        branch,
        data,
        metadata,
        message: `${COMMIT_SAVE_PREFIX} ${clientId}`,
        newBranch,
        user,
      }),
    );
  } finally {
    dispatch(setCreatingBranchComplete());
    dispatch(saveClientDataComplete());
  }
};

pricingListener.startListening({
  actionCreator: updatePricingBaseRows,
  effect: async (action, { dispatch, getState }) => {
    const { payload: rows } = action;
    const state = getState();
    const {
      clientData: { clientId },
      pricing: {
        base: { pricingDataBranch },
      },
    } = state;

    let selectedBranch = pricingDataBranch || ClientDataBranch.Pricing;
    let newBranch = false;
    if (!pricingDataBranch || pricingDataBranch === ClientDataBranch.Main) {
      dispatch(setCreatingBranch());
      selectedBranch = ClientDataBranch.Pricing;
      newBranch = true;
    }

    const table = clientId.startsWith('shedview') ? 'basePrice' : 'pricingBase';

    const tablesData: { [table: string]: TableData[] } = {};
    const tablesCellMetadata: { [table: string]: CellMetadata[] } = {};
    rows.forEach(({ rowData, column, value }) => {
      tablesData[table] = tablesData[table] || [];
      const { [ClientDataFixedColumns.RowId]: rowId } = rowData;

      const existingRow = tablesData[table].find((r) => r[ClientDataFixedColumns.RowId] === rowId);

      const updatedRow = existingRow || { ...rowData };
      updatedRow[column] = value;
      if (!existingRow) {
        tablesData[table] = [...tablesData[table], updatedRow];
      }
    });

    await updateDataEffect(tablesData, tablesCellMetadata, dispatch, state, selectedBranch, newBranch).then(() => {
      dispatch(setPricingBaseDataBranch(selectedBranch));
    });
  },
});

pricingListener.startListening({
  actionCreator: updatePricingComponentRows,
  effect: async (action, { dispatch, getState }) => {
    const { payload: rows } = action;
    const state = getState();
    const {
      pricing: {
        component: { pricingDataBranch },
      },
    } = state;

    let selectedBranch = pricingDataBranch || ClientDataBranch.ClientUpdate;
    let newBranch = false;
    if (!pricingDataBranch || pricingDataBranch === ClientDataBranch.Main) {
      dispatch(setCreatingBranch());
      selectedBranch = ClientDataBranch.ClientUpdate;
      newBranch = true;
    }

    const tablesData: { [table: string]: TableData[] } = {};
    const tablesCellMetadata: { [table: string]: CellMetadata[] } = {};

    rows.forEach(({ table, rowData, column, value }) => {
      tablesData[table] = tablesData[table] || [];
      const { [ClientDataFixedColumns.RowId]: rowId } = rowData;

      const existingRow = tablesData[table].find((r) => r[ClientDataFixedColumns.RowId] === rowId);

      const updatedRow = existingRow || { ...rowData };
      updatedRow[column] = value;
      if (!existingRow) {
        tablesData[table] = [...tablesData[table], updatedRow];
      }
    });

    await updateDataEffect(tablesData, tablesCellMetadata, dispatch, state, selectedBranch, newBranch).then(() => {
      dispatch(setPricingComponentDataBranch(selectedBranch));
    });
  },
});

/**
 * Updates/Delete cell metadata from the database
 *
 * @returns
 */
const updatePricingMetadataEffect = async (
  updates: UpdateClientDataMetadata[],
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  state: AppState,
) => {
  // const { clientId, clientDataType: dataType, clientDataBranch, selectedTable: table } = state.clientData;
  const { clientData, currentUser, pricing } = state;
  const { clientId } = clientData;
  const {
    base: { pricingDataBranch },
  } = pricing;
  const { group: { groupId } = unknownGroup } = currentUser;
  const table = clientId.startsWith('shedview') ? 'basePrice' : 'pricingBase';

  let selectedBranch = pricingDataBranch || ClientDataBranch.Pricing;
  let newBranch = false;
  if (!pricingDataBranch || pricingDataBranch === ClientDataBranch.Main) {
    dispatch(setCreatingBranch());
    selectedBranch = ClientDataBranch.Pricing;
    newBranch = true;
  }

  const cellMetadataUpdates = getUpdatedCellsMetadata(clientId, table, updates).filter(Boolean);

  if (!cellMetadataUpdates.length) return;

  try {
    dispatch(saveClientDataStart());

    if (cellMetadataUpdates.length === 1) {
      const [cellMetadata] = cellMetadataUpdates;
      await dispatch(
        clientDataApi.endpoints.updateClientDataCellMetadata.initiate({
          dataType: ClientDataType.Supplier,
          clientId,
          groupId,
          table,
          cellMetadata,
          branch: selectedBranch,
          rowId: cellMetadata.rowId,
          newBranch,
          message: `${COMMIT_SAVE_PREFIX} ${clientId}`,
        }),
      );
    } else {
      await dispatch(
        clientDataApi.endpoints.updateClientDataCellsMetadata.initiate({
          dataType: ClientDataType.Supplier,
          clientId,
          groupId,
          table,
          cellsMetadata: cellMetadataUpdates,
          branch: selectedBranch,
          newBranch,
          message: `${COMMIT_SAVE_PREFIX} ${clientId}`,
        }),
      );
    }

    dispatch(setPricingBaseDataBranch(selectedBranch));
  } finally {
    dispatch(setCreatingBranchComplete());
    dispatch(saveClientDataComplete());
  }
};

pricingListener.startListening({
  actionCreator: updatePricingMetadata,
  effect: async (action, { dispatch, getState }) => {
    const { payload: updates = [] } = action;
    const state = getState();
    await updatePricingMetadataEffect(updates, dispatch, state);
  },
});
