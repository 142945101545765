import { Fab, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Dialogs } from '../constants/Dialogs';
import { DockedDrawerWidth } from '../constants/DockedDrawerWidth';
import { openDialog } from '../ducks/dialogSlice';
import { fetchGroup, setMemberDialog } from '../ducks/group';
import { AppState } from '../types/AppState';
import { GroupMember } from '../types/GroupMember';
import { unknownUser } from '../types/User';
import { isIdeaRoomUser } from '../utils/userUtils';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { GroupMembersTable } from './GroupMembersTable';
import { Dealer } from '../types/Dealer';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    [theme.breakpoints.down('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2) },
    [theme.breakpoints.up('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2 + DockedDrawerWidth / 8) },
  },
  fabText: {
    padding: theme.spacing(1),
  },
}));

interface OwnProps {
  groupId?: string;
}

interface StateProps {
  members: GroupMember[];
  availableDealers: Dealer[];
  loading: boolean;
  searchTerm: string;
}

interface DispatchProps {
  getGroup(groupId: string): void;
  openUserDialog(): void;
}

type Props = OwnProps & StateProps & DispatchProps;

const UsersComponent: React.FC<Props> = ({
  groupId,
  members,
  availableDealers,
  loading,
  searchTerm,
  getGroup,
  openUserDialog,
}: Props) => {
  const classes = useStyles();
  const [filteredGroupMembers, setFilteredGroupMembers] = React.useState<GroupMember[]>([]);

  // Effect that filters members by searchTerm anytime either members or searchTerm change
  React.useEffect(() => {
    if (searchTerm.length === 0) {
      setFilteredGroupMembers(members);
      return;
    }
    const tests = [
      (groupMember: GroupMember): boolean => stringToLowerIncludes(groupMember.email, searchTerm),
      (groupMember: GroupMember): boolean => stringToLowerIncludes(groupMember.name, searchTerm),
      (groupMember: GroupMember): boolean =>
        stringToLowerIncludes(
          availableDealers
            .filter((dealer) => groupMember.dealers.includes(dealer.key))
            .map((dealer) => dealer.name)
            .join(', '),
          searchTerm,
        ),
      (groupMember: GroupMember): boolean => stringToLowerIncludes(groupMember.permissions.join(', '), searchTerm),
    ];

    setFilteredGroupMembers(members.filter((groupMember) => tests.some((test) => test(groupMember))));
  }, [members, availableDealers, searchTerm]);

  React.useEffect(() => {
    if (groupId) {
      getGroup(groupId);
    }
  }, [groupId, getGroup]);

  return (
    <>
      <GroupMembersTable loading={loading} members={filteredGroupMembers} />
      <Fab color="primary" className={classes.fab} variant="extended" onClick={openUserDialog} aria-label="add member">
        <AddIcon />
        <Typography className={classes.fabText}>Add</Typography>
      </Fab>
    </>
  );
};

const mapStateToProps = ({
  group: { group, loading },
  search: { searchTerm },
  currentUser: { user = unknownUser, availableDealers = [] },
}: AppState): StateProps => ({
  // Filter out users from the users list to avoid them editing and breaking their permissions (except idearoom users)
  members: ((group && (group.filteredMembers || group.members)) || []).filter(
    (member: GroupMember) => isIdeaRoomUser(user) || member.email !== user.email,
  ),
  availableDealers,
  loading,
  searchTerm,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getGroup: (groupId: string): AnyAction => dispatch(fetchGroup(groupId)),
  openUserDialog: (): void => {
    dispatch(setMemberDialog());
    dispatch(openDialog({ dialog: Dialogs.User }));
  },
});

export const Users = connect(mapStateToProps, mapDispatchToProps)(UsersComponent);
