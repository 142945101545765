import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getAttributeLabel } from '../constants/PricingSheetAttributeType';
import { PricingSheet } from '../types/PricingSheet';
import { splitByMatches } from '../utils/searchUtils';

type Props = {
  classes?: Record<string, string>;
  pricingBaseSheet: PricingSheet;
  setRef: (instance: HTMLSpanElement | null) => void;
  searchTerm?: string;
};

export const PricingBaseAttributesLabel: React.FC<Props> = ({
  classes = {},
  pricingBaseSheet,
  setRef,
  searchTerm = '',
}) => {
  const { t } = useTranslation();

  const labels = [
    pricingBaseSheet.prices[0]?.priceSetLabel ||
      pricingBaseSheet.attributes.map((attribute) => getAttributeLabel(attribute, t)),
  ]
    .flat()
    .sort((a, b) => (a || '').localeCompare(b || ''));

  return (
    <ul className={classes.list}>
      {/* Per design the title is shown with a single bullet point, sorted alphabetically */}
      {labels.map((label = '') => (
        <li key={label}>
          <Typography ref={setRef} className={classes.attributes} variant="body2">
            {splitByMatches(label, searchTerm, {}).map((part, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={`${label}-${index}`} style={{ backgroundColor: part.highlight ? '#FDFF80' : undefined }}>
                {part.text}
              </span>
            ))}
          </Typography>
        </li>
      ))}
    </ul>
  );
};
