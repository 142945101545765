import React from 'react';
import { Box, Divider, IconButton, Link, ListItemIcon, ListItemText, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Check, ListAlt, Search, OpenInNew } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AppState } from '../types/AppState';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AnchorMenu } from './AnchorMenu';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientTable, KeyValue } from '../types/ClientData';
import { TableAppBarBranchLabel } from './TableAppBarBranchLabel';
import { BranchMenuItem } from './BranchMenuItem';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { CheckMenuItem } from './CheckMenuItem';
import { I18nKeys } from '../constants/I18nKeys';
import { getBranchAuthorsSummaryLabel, getClientDataLink, getClientDataTableFromUrl } from '../utils/clientDataUtils';
import { unknownUser } from '../types/User';
import { CELL_METADATA_TABLE } from '../constants/ClientData';
import { fuzzyMatchIncludes } from '../utils/stringUtils';
import { SearchInput } from './SearchInput';
import { onTableMenuKeyboardShortcut } from '../utils/keyboardShortcutUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { setChangeSummaryOpen } from '../ducks/clientDataSlice';
import { unknownGroup } from '../constants/Group';

const useStyles = makeStyles(() => ({
  search: {
    padding: '16px',
    paddingTop: '8px',
  },
  tabs: {
    top: 'auto',
    bottom: 0,
    '& .MuiButtonBase-root': {
      textTransform: 'none',
    },
  },
}));

export const SEARCH_INPUT_ID = 'client-data-table-search-input';

interface Props {
  searchRef: React.RefObject<HTMLDivElement>;
  menuButtonRef: React.RefObject<HTMLDivElement>;
  clientId: string;
  clientDataType: ClientDataType;
  changeTab(table: string): void;
  menuAnchorEl: null | HTMLElement;
  setMenuAnchorEl(el: null | HTMLElement): void;
}

export const TableAppBar = React.forwardRef<HTMLDivElement, Props>(
  ({ searchRef, menuButtonRef, clientId, clientDataType, changeTab, menuAnchorEl, setMenuAnchorEl }: Props, ref) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { user = unknownUser } = useAppSelector((state: AppState) => state?.currentUser);
    const dispatch = useAppDispatch();
    const { group: { groupId } = unknownGroup } = useAppSelector((state: AppState) => state?.currentUser);
    const [tables, setTables] = React.useState<ClientTable[]>([]);
    const [tableOptions, setTableOptions] = React.useState<{ key: string; value: string }[]>([]);
    const [tableFilter, setTableFilter] = React.useState<null | string[]>(null);

    const [searchValue, setSearchValue] = React.useState<string>('');
    const resetSearch = React.useCallback(() => setSearchValue(''), []);
    const [matchingOptions, setMatchingOptions] = React.useState<KeyValue[]>([]);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

    const { clientDataBranch, selectedTable, isCreatingBranch } = useAppSelector(
      (state: AppState) => state?.clientData,
    );

    const { activeBranches, clientTables, cellMetadataDiff, isLoadingClientTables, isLoadingClientTableColumns } =
      useClientDataRepo({
        useBranches: true,
        useClientTables: true,
        useClientTablesColumns: true,
        useCellMetadataDiff: true,
      });

    const changedTables =
      (activeBranches.find((metadata) => metadata.branchType === clientDataBranch) || {}).changedTables?.filter(
        (table) => table !== CELL_METADATA_TABLE,
      ) || [];
    const metadataOnlyChangedTables = (cellMetadataDiff
      // If a note is added and then removed in the same group of changes, it should be ignored
      .filter(
        ({ diffType, to_metadata: changedMetadata }) => diffType !== 'added' || Object.keys(changedMetadata).length,
      )
      .map(
        ({ to_table: changedTable }) =>
          clientTables.find((table) => table.tableName === changedTable)?.formattedTableName,
      )
      .filter((table) => !!table && !changedTables.includes(table)) || []) as string[];

    const handleClicked = (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
      setMenuAnchorEl(event.currentTarget);
    };

    const getChildNodeById = (parentEl: HTMLElement | null, id: string): HTMLElement | undefined =>
      Array.from(parentEl?.children || []).find((node) => node?.id === id) as HTMLElement | undefined;

    React.useEffect(() => {
      if (menuAnchorEl) {
        getChildNodeById(searchRef?.current?.firstChild as HTMLElement, SEARCH_INPUT_ID)?.focus();
      }
    }, [menuAnchorEl]);

    const menuListRef = React.useRef<HTMLUListElement>(null);

    const focusMenuItem = (index: number) => {
      setSelectedIndex(index);
    };

    const handleCloseMenu = (): void => {
      setMenuAnchorEl(null);
      resetSearch();
    };

    const clickMenuItem = () => {
      const id = matchingOptions[selectedIndex]?.key;
      if (id) {
        const child = getChildNodeById(menuListRef?.current as HTMLUListElement, id);
        child?.click();
        handleCloseMenu();
      }
    };

    const removeTableFilter = (): void => {
      setTableFilter(null);
      handleCloseMenu();
    };

    const onBranchChange = (): void => {
      handleCloseMenu();
      removeTableFilter();
      handleCloseMenu();
    };

    const filterTablesByChangedTables = (): void => {
      setTableFilter([...changedTables, ...metadataOnlyChangedTables]);
      handleCloseMenu();
    };

    React.useEffect(() => {
      if (clientId && clientDataType) {
        removeTableFilter();
      }
    }, [clientId, clientDataType, clientDataBranch]);

    React.useEffect(() => {
      if (clientTables) {
        const filteredTables = tableFilter
          ? clientTables.filter((table) => tableFilter.includes(table.formattedTableName))
          : clientTables;
        setTables(filteredTables);
        setTableOptions(
          filteredTables.map((table) => {
            const url = getClientDataLink(
              groupId,
              `${clientId}:${clientDataType}`,
              clientDataBranch,
              table.formattedTableName,
            );
            return {
              key: table.formattedTableName,
              value: table.label,
              keyboardShortcut: (
                <Link href={url} target="_blank" rel="noopener noreferrer" onClick={(event) => event.stopPropagation()}>
                  <Tooltip enterDelay={300} disableInteractive title={`Open ${table.label} in new tab`}>
                    <ListItemIcon>
                      <OpenInNew style={{ minWidth: '24px' }} />
                    </ListItemIcon>
                  </Tooltip>
                </Link>
              ),
            };
          }),
        );

        if (filteredTables.length > 0) {
          // On the vendor dataset, select the vendor table, otherwise select the first table
          const fallbackTable = filteredTables.find((table) => table.tableName === 'ven_vendor') || filteredTables[0];
          if (selectedTable) {
            const newTab = (filteredTables.find((table) => table.formattedTableName === selectedTable) || fallbackTable)
              .formattedTableName;
            changeTab(newTab);
          } else {
            const pathTable = getClientDataTableFromUrl();
            const newTab = (filteredTables.find((table) => table.formattedTableName === pathTable) || fallbackTable)
              .formattedTableName;
            changeTab(newTab);
          }
        }
      }
    }, [clientTables, tableFilter]);

    React.useEffect(() => {
      let displayedOptions;
      if (searchValue.length < 2) {
        displayedOptions = tableOptions;
      } else {
        displayedOptions = tableOptions.filter((option) => fuzzyMatchIncludes(option.value, searchValue));
      }

      setMatchingOptions(displayedOptions);
      focusMenuItem(0);
    }, [searchValue, tableOptions]);

    const changedTablesFilterOptions =
      (activeBranches.find((metadata) => metadata.branchType === clientDataBranch)?.changedTables?.length || 0) > 0 &&
      clientDataBranch
        ? [
            ...(changedTables.length > 0
              ? [
                  <CheckMenuItem
                    key="all-sheets-filter"
                    onClick={removeTableFilter}
                    checkIcon={<Check />}
                    checked={!tableFilter}
                  >
                    <ListItemText>{t(I18nKeys.ClientDataTableMenuFilterAllSheets)}</ListItemText>
                  </CheckMenuItem>,
                  <CheckMenuItem
                    key="sheets-with-changes-filter"
                    onClick={filterTablesByChangedTables}
                    checkIcon={<Check />}
                    checked={!!tableFilter}
                  >
                    <ListItemText>
                      {t(I18nKeys.ClientDataTableMenuFilterSheetsWithChanges, {
                        count: [...changedTables, ...metadataOnlyChangedTables].length,
                      })}
                    </ListItemText>
                  </CheckMenuItem>,
                ]
              : []),
            <CheckMenuItem
              key="change-summary"
              checkIcon={<Check />}
              onClick={() => {
                dispatch(setChangeSummaryOpen(true));
                handleCloseMenu();
              }}
            >
              <ListItemText>{`${t(I18nKeys.ClientDataTableMenuChangeSummary)} ${getBranchAuthorsSummaryLabel(
                t,
                activeBranches,
                clientDataBranch,
                user,
              )}`}</ListItemText>
            </CheckMenuItem>,
            <Divider key="divider-changed-tables-filter" />,
          ]
        : [];

    return (
      <Box ref={ref} position="sticky" className={classes.tabs}>
        <Stack direction="row" spacing={0}>
          <Stack ref={menuButtonRef} direction="row" spacing={0} onClick={handleClicked}>
            <IconButton id="long-button">
              <ListAlt />
            </IconButton>
            <TableAppBarBranchLabel />
          </Stack>
          <AnchorMenu
            listRef={menuListRef}
            topMenuOptions={[
              <BranchMenuItem key={ClientDataBranch.Main} branch={ClientDataBranch.Main} onChange={onBranchChange} />,
              <BranchMenuItem
                key={ClientDataBranch.Unpublished}
                branch={ClientDataBranch.Unpublished}
                onChange={onBranchChange}
              />,
              ...(activeBranches.some((branch) => branch.branchType === ClientDataBranch.Pricing)
                ? [
                    <BranchMenuItem
                      key={ClientDataBranch.Pricing}
                      branch={ClientDataBranch.Pricing}
                      onChange={onBranchChange}
                    />,
                  ]
                : []),
              ...(activeBranches.some((branch) => branch.branchType === ClientDataBranch.ClientUpdate)
                ? [
                    <BranchMenuItem
                      key={ClientDataBranch.ClientUpdate}
                      branch={ClientDataBranch.ClientUpdate}
                      onChange={onBranchChange}
                    />,
                  ]
                : []),
              <BranchMenuItem
                key={ClientDataBranch.Hotfix}
                branch={ClientDataBranch.Hotfix}
                onChange={onBranchChange}
                allowCreateBranch
              />,
              <Divider key="divider-1" />,
              ...changedTablesFilterOptions,
            ]}
            menuAnchorEl={menuAnchorEl}
            options={matchingOptions}
            selectedOptions={[matchingOptions[selectedIndex]?.key]}
            onClick={(val: string) => {
              setMenuAnchorEl(null);
              changeTab(val);
            }}
            onClose={handleCloseMenu}
            searchInput={
              /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
              <div
                className={classes.search}
                onKeyDown={(e): void => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              >
                <SearchInput
                  ref={searchRef}
                  searchTerm={searchValue}
                  startAdornment={<Search />}
                  onClearClick={resetSearch}
                  onChange={setSearchValue}
                  size="small"
                  inputId={SEARCH_INPUT_ID}
                  onKeyDown={(e) =>
                    onTableMenuKeyboardShortcut(e, { currentIndex: selectedIndex, focusMenuItem, clickMenuItem })
                  }
                />
              </div>
            }
            onKeyDown={(i: number) => (e: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) =>
              onTableMenuKeyboardShortcut(e, { currentIndex: i, focusMenuItem, clickMenuItem })}
            maxMenuHeight="85%"
          />
          <Tabs
            value={selectedTable}
            onChange={(_, newValue: string) => changeTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tables && Array.isArray(tables)
              ? tables.map((table) => (
                  <Tab
                    disabled={isLoadingClientTableColumns || isLoadingClientTables || isCreatingBranch}
                    key={`${table.tableName}_tab`}
                    label={`${table.label}${
                      [...changedTables, ...metadataOnlyChangedTables].includes(table.formattedTableName) ? ' *' : ''
                    }`}
                    value={table.formattedTableName}
                  />
                ))
              : null}
          </Tabs>
        </Stack>
      </Box>
    );
  },
);
