import {
  PricingAdjustmentCondition,
  PricingAdjustmentError,
  SurchargeCalculation,
  SurchargeCondition,
  SurchargeRule,
  SurchargeSystemOrderCalculation,
} from '../types/PricingAdjustment';
import { I18nKeys } from './I18nKeys';

export enum SystemOrderSupplierCalculations {
  Inherit = 'inherit',
  Off = 'off',
}

export enum SystemOrderCalculations {
  None = 'none',
  Surcharge = 'surcharge',
  LineItem = 'line-item-surcharge',
}

export enum PricingAdjustmentStatus {
  Draft = 'Draft',
  Active = 'Active',
  Deleted = 'Deleted',
}

export const pricingOptions: { value: string; label: string; dealerOption: boolean }[] = [
  { value: SystemOrderCalculations.None, label: I18nKeys.PricingOptionNoChange, dealerOption: true },
  { value: SystemOrderCalculations.Surcharge, label: I18nKeys.PricingOptionSurcharge, dealerOption: true },
  { value: SystemOrderCalculations.LineItem, label: I18nKeys.PricingOptionLineItem, dealerOption: false },
];

export enum PricingCategoryValues {
  None = 'none',
  BasePrice = 'base-price',
  Structure = 'structure',
  DoorsAndWindows = 'doors-and-windows',
  AddOns = 'add-ons',
  Services = 'services',
  Subtotal = 'subtotal',
  CustomDiscountExpense = 'custom-discount-expense',
}

export const PricingCategories: { value: string; label: string; tooltip: string }[] = [
  {
    value: PricingCategoryValues.BasePrice,
    label: I18nKeys.PricingCategoriesBasePrice,
    tooltip: I18nKeys.PricingCategoriesBasePriceTooltip,
  },
  {
    value: PricingCategoryValues.Structure,
    label: I18nKeys.PricingCategoriesStructure,
    tooltip: I18nKeys.PricingCategoriesStructureTooltip,
  },
  {
    value: PricingCategoryValues.DoorsAndWindows,
    label: I18nKeys.PricingCategoriesDoorsWindows,
    tooltip: I18nKeys.PricingCategoriesDoorsWindowsTooltip,
  },
  {
    value: PricingCategoryValues.AddOns,
    label: I18nKeys.PricingCategoriesAddOns,
    tooltip: I18nKeys.PricingCategoriesAddOnsTooltip,
  },
  {
    value: PricingCategoryValues.Services,
    label: I18nKeys.PricingCategoriesServices,
    tooltip: I18nKeys.PricingCategoriesServicesTooltip,
  },
  {
    value: PricingCategoryValues.CustomDiscountExpense,
    label: I18nKeys.PricingCategoriesCustomDiscountExpense,
    tooltip: I18nKeys.PricingCategoriesCustomDiscountExpenseTooltip,
  },
];

export const shedPricingCategories: { value: string; surchargeLabel: string; lineItemLabel: string }[] = [
  {
    value: PricingCategoryValues.BasePrice,
    surchargeLabel: I18nKeys.ShedPricingCategoriesSurchargeBasePrice,
    lineItemLabel: I18nKeys.ShedPricingCategoriesLineItemBasePrice,
  },
  {
    value: PricingCategoryValues.Subtotal,
    surchargeLabel: I18nKeys.ShedPricingCategoriesSurchargeSubtotal,
    lineItemLabel: I18nKeys.ShedPricingCategoriesLineItemSubtotal,
  },
];

export enum PricingSurchargeCondition {
  None = 'none',
  Length = 'length',
  Width = 'width',
  Size = 'size',
  Style = 'style',
  Siding = 'siding',
  Roof = 'roofing',
  Gauge = 'gauge',
  Region = 'region',
  State = 'state',
  Subtotal = 'subtotal',
}

export enum PricingAdjustmentErrorType {
  Calculation = 'calculation',
  Condition = 'condition',
}

export const PricingSurchargeConditions: PricingAdjustmentCondition[] = [
  {
    value: PricingSurchargeCondition.Length,
    label: I18nKeys.PricingSurchargeConditionLength,
    range: true,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeCondition.Width,
    label: I18nKeys.PricingSurchargeConditionWidth,
    range: true,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeCondition.Size,
    label: I18nKeys.PricingSurchargeConditionSize,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeCondition.Style,
    label: I18nKeys.PricingSurchargeConditionStyle,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeCondition.Siding,
    label: I18nKeys.PricingSurchargeConditionSiding,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeCondition.Roof,
    label: I18nKeys.PricingSurchargeConditionRoofType,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeCondition.Gauge,
    label: I18nKeys.PricingSurchargeConditionGauge,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeCondition.Region,
    label: I18nKeys.PricingSurchargeConditionRegion,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeCondition.State,
    label: I18nKeys.PricingSurchargeConditionState,
    range: false,
    options: [],
    excludeFromExport: true,
  },
  {
    value: PricingSurchargeCondition.Subtotal,
    label: I18nKeys.PricingSurchargeConditionSubtotal,
    range: true,
    options: [],
    excludeFromExport: true,
  },
];

export enum DefaultSurchargeValues {
  AmountChange = 0,
  PercentChange = 0,
  RoundTo = 0.01,
}

export enum InitialSurchargeValues {
  AmountChange = 0,
  PercentChange = 0,
  RoundTo = 1,
}

export enum NoSurchargeValues {
  AmountChange = 0,
  PercentChange = 0,
  RoundTo = 1,
}

export enum MaximumDigits {
  PercentChange = 6,
}

export const defaultSurchargeLabel = 'Material Surcharge';
export const selectAllLabel = '(Select All)';

export const defaultSurchargeCalculation: SurchargeCalculation = {
  amountChange: InitialSurchargeValues.AmountChange,
  percentChange: InitialSurchargeValues.PercentChange,
  roundTo: InitialSurchargeValues.RoundTo,
};

export const defaultSurchargeSystemOrderCalculation: SurchargeSystemOrderCalculation = {
  key: SystemOrderCalculations.Surcharge,
  categories: [PricingCategoryValues.BasePrice],
  label: '',
  isTaxable: true,
  affectsDeposit: true,
  calculation: defaultSurchargeCalculation,
  rules: [],
};

export const noSurchargeCalculation: SurchargeCalculation = {
  amountChange: NoSurchargeValues.AmountChange,
  percentChange: NoSurchargeValues.PercentChange,
  roundTo: NoSurchargeValues.RoundTo,
};

export const noSurchargeSystemOrderCalculation: SurchargeSystemOrderCalculation = {
  key: SystemOrderCalculations.None,
  categories: [PricingCategoryValues.BasePrice],
  label: '',
  isTaxable: true,
  affectsDeposit: true,
  calculation: noSurchargeCalculation,
  rules: [],
};

export const newSurchargeRule = (id: number, conditions: SurchargeCondition[]): SurchargeRule => ({
  id,
  conditions,
  calculation: defaultSurchargeCalculation,
});

export const defaultPricingAdjustmentErrors: PricingAdjustmentError[] = [];

export const clientSupplierDelimiter = ':';

export const maxRoundTo = 100;

export const pricingText: any = {
  revertConfirmationTitle: I18nKeys.PricingTextRevertConfirmationTitle,
  revertConfirmationBody: I18nKeys.PricingTextRevertConfirmationBody,
  publishConfirmationTitle: I18nKeys.PricingTextPublishConfirmationTitle,
  publishConfirmationBody: I18nKeys.PricingTextPublishConfirmationBody,
  previewInstructions: I18nKeys.PricingTextPreviewInstructions,
  publishInstructions: I18nKeys.PricingTextPublishInstructions,
};
export const environmentToken = '$env';
