export enum Dialogs {
  AllowedHtmlTags = 'ALLOWED_HTML_TAGS',
  ClientDataGoToSourceData = 'CLIENT_DATA_GO_TO_SOURCE_DATA',
  ClientDataNote = 'CLIENT_DATA_NOTE',
  ClientDataHotfixBranch = 'CLIENT_DATA_HOTFIX_BRANCH',
  ClientDataPublish = 'CLIENT_DATA_PUBLISH',
  ClientDataPublishUpdates = 'CLIENT_DATA_PUBLISH_UPDATES',
  ClientDataPublishResult = 'CLIENT_DATA_PUBLISH_RESULT',
  ClientDataNewSupplierUpdates = 'CLIENT_DATA_NEW_SUPPLIER_UPDATES',
  ClientDataPreview = 'CLIENT_DATA_PREVIEW',
  ClientDataCantPublish = 'CLIENT_DATA_CANT_PUBLISH',
  ClientDataRevertBranch = 'CLIENT_DATA_REVERT_BRANCH',
  Configurator = 'CONFIGURATOR',
  ClientDataRollback = 'CLIENT_DATA_ROLLBACK',
  Confirmation = 'CONFIRMATION',
  Dealer = 'DEALER',
  EmailDomain = 'EMAIL_DOMAIN',
  FilterSelection = 'FILTER_SELECTION',
  Impersonation = 'IMPERSONATION',
  I18n = 'I18N',
  Language = 'LANGUAGE',
  Notification = 'NOTIFICATION',
  PricingBaseNote = 'PRICING_BASE_NOTE',
  PricingBasePreview = 'PRICING_BASE_PREVIEW',
  PricingBaseSheetName = 'PRICING_BASE_SHEET_NAME',
  PricingContactSupport = 'PRICING_CONTACT_SUPPORT',
  PricingComponentEdit = 'PRICING_COMPONENT_EDIT',
  PricingComponentPreview = 'PRICING_COMPONENT_PREVIEW',
  PricingPublish = 'PRICING_PUBLISH',
  PricingPublishResult = 'PRICING_PUBLISH_RESULT',
  User = 'USER',
  VerifiedQuotes = 'VERIFIED_QUOTES',
  WhatsNew = 'WHATS_NEW',
  WhatsNewPreview = 'WHATS_NEW_PREVIEW',
  WhatsNewSalesView = 'WHATS_NEW_SALESVIEW',
  SceneEnvironment = 'SCENE_ENVIRONMENT',
  SiteDetailPublishResult = 'SITE_DETAIL_PUBLISH_RESULT',
}
