import { Checkbox, ListItemText } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import {
  PricingAdjustmentCondition,
  PricingAdjustmentConditionOption,
  SurchargeKeyCondition,
  SurchargeRule,
} from '../types/PricingAdjustment';
import { PricingSurchargeCondition, selectAllLabel } from '../constants/PricingAdjustment';
import { updateSurchargeRuleCondition as updateSurchargeRuleConditionFunc } from '../ducks/pricingAdjustment';
import { sortObjectsByAlphanumericStrings } from '../utils/sortUtils';
import { allKeysAreSelected } from '../utils/pricingUtils';
import { useAppDispatch } from '../hooks';

interface Props {
  anchorEl: Element | null;
  pricingAdjustmentCondition?: PricingAdjustmentCondition;
  rule?: SurchargeRule;
  onClose(): void;
}

export const PricingKeyConditionMenu: React.FC<Props> = ({
  anchorEl,
  onClose,
  pricingAdjustmentCondition,
  rule,
}: Props) => {
  const dispatch = useAppDispatch();

  const handleCheckboxClicked = (
    type: PricingSurchargeCondition,
    pricingAdjustmentConditionOptions: PricingAdjustmentConditionOption[],
    surchargeRule: SurchargeRule,
    checked: boolean,
  ): void => {
    // Find condition's current selected keys
    const keys = [
      ...((surchargeRule.conditions.find((condition) => condition.type === type) as SurchargeKeyCondition)?.keys || []),
    ];

    if (pricingAdjustmentCondition) {
      pricingAdjustmentConditionOptions.forEach((selectedOption) => {
        // Find all keys with a matching label
        const matchingKeys = pricingAdjustmentCondition.options
          .filter((option) => option.label === selectedOption.label)
          .map((option) => option.key);
        // Add or remove matches
        matchingKeys.forEach((match) => {
          if (!checked && !keys.includes(match)) keys.push(match);
          else if (checked && keys.includes(match)) keys.splice(keys.indexOf(match), 1);
        });
      });
    }
    dispatch(updateSurchargeRuleConditionFunc(surchargeRule.id, type, keys));
  };

  let uniqueConditionOptions: PricingAdjustmentConditionOption[] = [];
  let allOptionsAreSelected = false;
  if (rule && pricingAdjustmentCondition && pricingAdjustmentCondition.options) {
    // Only list options with unique labels
    uniqueConditionOptions.push(
      ...pricingAdjustmentCondition.options.filter(
        (option, i) => !pricingAdjustmentCondition.options.slice(0, i).some((o) => o.label === option.label),
      ),
    );
    uniqueConditionOptions = sortObjectsByAlphanumericStrings(uniqueConditionOptions, 'label') || [];
    allOptionsAreSelected = allKeysAreSelected(
      rule,
      pricingAdjustmentCondition.value as PricingSurchargeCondition,
      uniqueConditionOptions.map((option) => option.key),
    );
  }

  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {rule &&
        pricingAdjustmentCondition &&
        uniqueConditionOptions.length > 0 && [
          // (Select All) Option
          <MenuItem
            key="all"
            onClick={(): void => {
              handleCheckboxClicked(
                pricingAdjustmentCondition.value as PricingSurchargeCondition,
                uniqueConditionOptions,
                rule,
                allOptionsAreSelected,
              );
            }}
          >
            <ListItemIcon>
              <Checkbox checked={allOptionsAreSelected} />
            </ListItemIcon>
            <ListItemText primary={selectAllLabel} />
          </MenuItem>,
          ...uniqueConditionOptions.map((option) => {
            const checked = allKeysAreSelected(rule, pricingAdjustmentCondition.value as PricingSurchargeCondition, [
              option.key,
            ]);
            return (
              <MenuItem
                key={option.key}
                onClick={(): void => {
                  handleCheckboxClicked(
                    pricingAdjustmentCondition.value as PricingSurchargeCondition,
                    [option],
                    rule,
                    checked,
                  );
                }}
              >
                <ListItemIcon>
                  <Checkbox checked={checked} />
                </ListItemIcon>
                <ListItemText primary={option.label} />
              </MenuItem>
            );
          }),
        ]}
    </Menu>
  );
};
