import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  pricingOptions,
  SystemOrderSupplierCalculations,
  SystemOrderCalculations,
} from '../constants/PricingAdjustment';
import { AppState } from '../types/AppState';
import { setPricingSelection } from '../ducks/pricingAdjustment';

interface OwnProps {
  disabled: boolean;
  dealer: boolean;
}

interface StateProps {
  pricingSelection: SystemOrderCalculations;
}

interface DispatchProps {
  updatePricingSelection(selection: string): void;
}

type Props = OwnProps & StateProps & DispatchProps;

const PricingSelectorComponent: React.FC<Props> = ({
  disabled,
  dealer = false,
  pricingSelection,
  updatePricingSelection,
}: Props) => {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selection = (event.target as HTMLInputElement).value;
    updatePricingSelection(selection);
  };

  return (
    <FormControl component="fieldset" disabled={disabled}>
      <RadioGroup aria-label="pricing-options" name="pricingOptions" value={pricingSelection} onChange={handleChange}>
        {pricingOptions.map(
          (option) =>
            ((dealer && option.dealerOption) || !dealer) && (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" />}
                label={t(option.label)}
              />
            ),
        )}
      </RadioGroup>
    </FormControl>
  );
};

const mapStateToProps = ({
  pricingAdjustment: { pricingSelection: selection, pricingSupplierSelection },
}: AppState): StateProps => {
  // Give the illusion that "No Change" is selected when inheriting
  const pricingSelection =
    pricingSupplierSelection === SystemOrderSupplierCalculations.Inherit ? SystemOrderCalculations.None : selection;
  return {
    pricingSelection,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updatePricingSelection: (selection): void => {
    dispatch(setPricingSelection(selection));
  },
});

export const PricingSelector = connect(mapStateToProps, mapDispatchToProps)(PricingSelectorComponent);
