import { i18n } from '../i18n';
import { SelectedFilterValues } from '../types/CustomFilter';
import { I18nKeys } from './I18nKeys';

export enum Permissions {
  Admin = 'ADMIN',
  DealManager = 'DEAL_MANAGER',
  Manager = 'MANAGER',
  Sales = 'SALES',
  SalesTools = 'SALES_TOOLS',
  SalesView = 'SALES_VIEW',
}

export const getPermissionLabel = (permission: Permissions): string | undefined => {
  switch (permission) {
    case Permissions.Admin: {
      return i18n.t(I18nKeys.PermissionAdmin);
    }
    case Permissions.SalesView:
    case Permissions.DealManager:
    case Permissions.Manager: {
      return i18n.t(I18nKeys.PermissionManager);
    }
    case Permissions.SalesTools:
    case Permissions.Sales: {
      return i18n.t(I18nKeys.PermissionSales);
    }
    default: {
      return undefined;
    }
  }
};

export const availablePermissions: SelectedFilterValues[] = [
  { key: Permissions.Admin, name: getPermissionLabel(Permissions.Admin) || i18n.t(I18nKeys.PermissionAdmin) },
  { key: Permissions.Manager, name: getPermissionLabel(Permissions.Manager) || i18n.t(I18nKeys.PermissionManager) },
  { key: Permissions.Sales, name: getPermissionLabel(Permissions.Sales) || i18n.t(I18nKeys.PermissionSales) },
];
