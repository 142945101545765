export enum EventKey {
  // CustomPageView is our own "page_view" event. "page_view" is sent automatically before we have a change to attach
  //   information. "page_view" and "custom-page-view" count should match 1:1 if we setup correctly.
  CustomPageView = 'custom-page-view',
  // Occurs when the "Apply a surcharge or discount" radio button is clicked on a Shed site
  PricingAdjApplySurcharge = 'pricing-adj-apply-surcharge-selected',
  // Occurs when the "Change prices of line items" radio button is clicked
  PricingAdjChangeApplyLineItem = 'pricing-adj-change-line-item-selected',
  // Occurs when the "Base price only" adjustment is published - Sheds only
  PricingAdjSurchargeCalcBasePricePublished = 'pricing-adj-surcharge-calc-base-price-published',
  // Occurs when the "Subtotal" adjustment is published - Sheds only
  PricingAdjSurchargeCalcSubtotalPublished = 'pricing-adj-surcharge-calc-subtotal-published',
  // Occurs when any "Vary Surcharges By" option is published
  PricingAdjSurchargeVarySurcharge = 'pricing-adj-surcharge-vary-surcharge',
  // Occurs when an "Add Surcharge" option is published (or more than one adjustment is published together)
  PricingAdjSurchargeAddSurcharge = 'pricing-adj-surcharge-add-surcharge',
  // Occurs when the Base Price is included in the published surcharge
  PricingAdjCalcIncBasePrice = 'pricing-adj-calc-include-base-price',
  // Occurs when the Structure is included in the published surcharge
  PricingAdjCalcIncStructure = 'pricing-adj-calc-include-structure',
  // Occurs when the Doors & Windows is included in the published surcharge
  PricingAdjCalcIncDoorsWindows = 'pricing-adj-calc-include-doors-win',
  // Occurs when the Addons is included in the published surcharge
  PricingAdjCalcIncAddons = 'pricing-adj-calc-include-addons',
  // Occurs when the Services is included in the published surcharge
  PricingAdjCalcIncServices = 'pricing-adj-calc-include-services',
}

export enum EventCategory {
  ApiCall = 'api-call',
  Configurator = 'configurator',
  Dealer = 'dealer',
  Feature = 'feature',
  Group = 'group',
  Menu = 'menu',
  Navigation = 'navigation',
  PageView = 'page-view',
  PricingBase = 'pricing-base',
  PricingComponent = 'pricing-component',
  PricingAdjustment = 'pricing-adjustment',
  User = 'user',
}

export enum EventName {
  Add = 'add',
  Change = 'change',
  Click = 'click',
  Delete = 'delete',
  Forgot = 'forgot',
  Publish = 'publish',
  Revert = 'revert',
  Save = 'save',
  SignIn = 'sign_in',
  Update = 'update',
}

export type StandardEventProps = {
  eventCategory: EventCategory | string; // semi-structured
  eventDescription: string;
  [otherProp: string]: string | number | boolean;
};
