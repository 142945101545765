import React, { useEffect } from 'react';
import { CircularProgress, Grid, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { PricingBaseAttributes } from './PricingBaseAttributes';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { setHighlightedCell, setPricingBaseDataBranch, setSelectedPricingSheetId } from '../ducks/pricingSlice';
import { PricingPublishDialog } from './PricingPublishDialog';
import { PricingPublishResultDialog } from './PricingPublishResultDialog';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { PricingBasePreviewDialog } from './PricingBasePreviewDialog';
import { unknownGroup } from '../constants/Group';
import { LocalStorage } from '../constants/LocalStorage';
import { PricingBaseAccordion } from './PricingBaseAccordion';
import { Loading } from './Loading';
import { PricingBaseEmptyLayout } from './PricingBaseEmptyLayout';
import { getClientIdFromClientSupplier, getVendorFromClientId } from '../utils/clientIdUtils';
import { PricingBaseSheetNameDialog } from './PricingBaseSheetNameDialog';
import { notifyOfDataChange } from '../utils/pricingUtils';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles({
  content: {
    flex: 1,
    minHeight: 0,
  },
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  line: { border: `1px solid #DADADA` },
  two: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: alpha('#fff', 0.5),
    zIndex: 100,
  },
  wrapper: {
    position: 'absolute',
    top: '40%',
    left: '50%',
  },
});

export const PricingBase: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [errorLoadingPricingSheets, setErrorLoadingPricingSheets] = React.useState(false);

  const {
    isErrorPricingBaseSheets,
    isFetchingPricingBaseSheets,
    isLoadingPricingBaseSheets,
    pricingBaseSheets,
    pricingBranchMergeCommit,
    activeBranches,
  } = usePricingRepo({
    usePricingSheetBase: true,
    usePricingBranchMergeCommit: true,
    useBranches: true,
  });
  const pricingBranchExists = activeBranches.find((branch) => branch.branchType === ClientDataBranch.Pricing);

  const viewerClientId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId || '',
  );
  const clientId = getClientIdFromClientSupplier(viewerClientId);
  const {
    clientData: { clientId: clientDataClientId },
    pricing: {
      base: { pricingDataBranch = ClientDataBranch.Main, selectedPricingSheetId },
    },
    currentUser: { group: { groupId } = unknownGroup },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (clientId && clientId !== clientDataClientId) {
      dispatch(setPricingBaseDataBranch(ClientDataBranch.Main));
      dispatch(setHighlightedCell(undefined));
      dispatch(setSelectedPricingSheetId(undefined));
      setErrorLoadingPricingSheets(false);
    }
  }, [clientId, clientDataClientId, dispatch]);

  useEffect(() => {
    notifyOfDataChange({
      groupId,
      clientId,
      latestCommit: pricingBranchMergeCommit,
      key: LocalStorage.LastPricingBaseMergeCommit,
      dispatch,
      t,
    });
  }, [pricingBranchMergeCommit, dispatch, t, groupId, clientId]);

  useEffect(() => {
    const existsAndFromClientId =
      pricingBranchExists && pricingBranchExists.name.includes(getVendorFromClientId(clientId));
    if (existsAndFromClientId && pricingDataBranch !== ClientDataBranch.Pricing) {
      dispatch(setPricingBaseDataBranch(ClientDataBranch.Pricing));
    }
    if (!existsAndFromClientId && pricingDataBranch !== ClientDataBranch.Main) {
      dispatch(setPricingBaseDataBranch(ClientDataBranch.Main));
    }
  }, [pricingBranchExists, clientId, pricingDataBranch, dispatch]);

  useEffect(() => {
    if (!selectedPricingSheetId && pricingBaseSheets?.length > 0) {
      const [sheet] = pricingBaseSheets;
      dispatch(setSelectedPricingSheetId(sheet.id));
    }
  }, [selectedPricingSheetId, pricingBaseSheets, dispatch]);

  if (!errorLoadingPricingSheets && !isLoadingPricingBaseSheets && isErrorPricingBaseSheets) {
    setErrorLoadingPricingSheets(true);
  }

  if (pricingBaseSheets.length === 0) {
    if (!errorLoadingPricingSheets && isLoadingPricingBaseSheets) {
      return <Loading />;
    }

    if (!isErrorPricingBaseSheets && !isLoadingPricingBaseSheets && !isFetchingPricingBaseSheets) {
      return <PricingBaseEmptyLayout />;
    }
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.content}>
        <PricingBaseAttributes />
        <div className={classes.line} />
        <PricingBaseAccordion />
      </Grid>

      <PricingBaseSheetNameDialog />
      <PricingBasePreviewDialog />
      <PricingPublishDialog
        pricingBranch={ClientDataBranch.Pricing}
        title={I18nKeys.PricingBasePublishDialogTitle}
        content={I18nKeys.PricingBasePublishDialogText}
      />
      <PricingPublishResultDialog />

      {!isLoadingPricingBaseSheets && isFetchingPricingBaseSheets && pricingBaseSheets.length === 0 && (
        <div className={classes.two}>
          <div className={classes.wrapper}>
            <CircularProgress color="primary" />
          </div>
        </div>
      )}
    </div>
  );
};
