import { makeStyles } from '@mui/styles';
import { Grid, FormControl, MenuItem, IconButton, ImageListItem, ImageListItemBar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { AnyAction } from 'redux';
import { useTranslation } from 'react-i18next';
import { SiteDetail, SiteDetailField } from '../types/ClientData';
import { sitesFieldsMap, SiteDetailDataFields } from '../constants/VendorData';
import { LoadingSelect } from './LoadingSelect';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openNotificationDialog } from '../ducks/notification';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { disabledSiteDetailField } from '../utils/vendorDataUtils';
import { SiteInputLabel } from './SiteInputLabel';
import { unknownGroup } from '../constants/Group';
import { unknownUser } from '../types/User';
import { isIdeaRoomUser as isIdeaRoomUserFunc } from '../utils/userUtils';
import { useAppDispatch, useAppSelector } from '../hooks';
import { deleteFileFromS3 } from '../ducks/vendorDataSlice';

const useStyles = makeStyles({
  fieldContainer: {
    padding: '8px',
    // This hid the spinner and dropdown 🔽 icon, commenting out because i think we want to show.
    // '& svg': {
    //   display: 'none',
    // },
  },
  inputLabel: {
    marginTop: '16px',
  },
  imageSelect: {
    fontSize: 16,
    height: '128px',
  },
  image: {
    // @ts-expect-error actually...
    objectFit: 'contain !important',
    width: '100%', // potentially fill horizontal space let objectFit handle "visual" width.
    minHeight: '50px',
    maxHeight: '80px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  imageListItemBarTitleWrap: {
    padding: '0px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

interface Props {
  data?: SiteDetail;
  field: SiteDetailDataFields.EmailLogoUrl | SiteDetailDataFields.LogoUrl | SiteDetailDataFields.Watermark;
  images: string[];
  imageSelectChange: Function;
}

export const ImageSelect: React.FC<Props> = ({ data = {}, field, images, imageSelectChange }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const confirmRemoval = (
    confirmAction: AnyAction,
    confirmFunction: any,
    confirmationTitle: string,
    description: string,
  ): void => {
    const confirmFunctions = [];
    if (confirmFunction) confirmFunctions.push(confirmFunction);
    dispatch(openConfirmationDialog([confirmAction], confirmFunctions, confirmationTitle, description));
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  };

  const openNotification = (title: string, content: string): void => {
    dispatch(openNotificationDialog(title, content));
    dispatch(openDialog({ dialog: Dialogs.Notification }));
  };

  const { group: { groupId: selectedGroupId } = unknownGroup, user = unknownUser } = useAppSelector(
    (state) => state.currentUser,
  );
  const { imageProcessing } = useAppSelector((state) => state.vendorData);

  const isIdeaRoomUser = isIdeaRoomUserFunc(user);

  const { i18nKey } = sitesFieldsMap[field] as SiteDetailField;

  const getImageNameFromUrl = (url: string | null): string => {
    const splitUrl = (url || '').split('/');
    return splitUrl[splitUrl.length - 1];
  };

  const selectedValue = data[field] || '';

  const imagesSet = new Set(images);
  if (selectedValue) {
    imagesSet.add(selectedValue);
  }
  const allImages = Array.from(imagesSet);

  const noneLabel = t(I18nKeys.SitesLogosNoneSelected);

  return (
    <Grid item xs={12} sm={6} className={classes.fieldContainer}>
      <FormControl fullWidth>
        <SiteInputLabel shrink className={classes.inputLabel} htmlFor={`${field}-select`} i18nKey={i18nKey} />
        <LoadingSelect
          disabled={disabledSiteDetailField(field, data, isIdeaRoomUser, selectedGroupId)}
          alerttext={
            disabledSiteDetailField(field, data, isIdeaRoomUser, selectedGroupId)
              ? t(I18nKeys.SitesProSubscriptionAlert)
              : undefined
          }
          loading={imageProcessing}
          value={selectedValue}
          onChange={(e) => imageSelectChange(e.target.name, e.target.value, field)}
          inputProps={{
            displayEmpty: true, // Make sure empty value renders as we tell it to (None).
            name: field,
            id: `${field}-select`,
          }}
          id={`${field}-select`}
          renderValue={() =>
            // How item will render when no dropdown is shown (selected state)
            selectedValue ? (
              <img src={selectedValue} alt={`${field}`} className={classes.image} />
            ) : (
              <div>{noneLabel}</div>
            )
          }
          className={classes.imageSelect}
          variant="filled"
        >
          <MenuItem key="none" value="" divider>
            <div className={classes.image}>{noneLabel}</div>
          </MenuItem>
          {allImages.map((image: string, i: number) => (
            <MenuItem key={image} value={image} divider>
              {/* How item will render when in dropdown */}
              <Grid container justifyContent="center" alignItems="center">
                <Grid container xs={10} justifyContent="center" alignItems="center">
                  <ImageListItem key={image} cols={1} rows={1} classes={{ img: classes.image }}>
                    <img src={image} alt={`${field}-${i}`} loading="lazy" />
                    <ImageListItemBar
                      classes={{
                        titleWrap: classes.imageListItemBarTitleWrap,
                      }}
                      title={getImageNameFromUrl(image)}
                      position="below"
                    />
                  </ImageListItem>
                </Grid>
                <Grid item xs={2} justifyContent="center" alignItems="center">
                  <IconButton
                    aria-controls={`delete-${field}`}
                    aria-haspopup="true"
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
                      event.stopPropagation();
                      if (image !== selectedValue) {
                        confirmRemoval(
                          deleteFileFromS3({ path: image }),
                          selectedValue === image && imageSelectChange(field, selectedValue, field),
                          t(I18nKeys.SitesLogosDeleteLogoConfirmTitle),
                          t(I18nKeys.SitesLogosDeleteLogoConfirmMessage),
                        );
                      } else {
                        openNotification('', t(I18nKeys.SitesLogosDeletePublishImageWarning));
                      }
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </MenuItem>
          ))}
          <MenuItem key="new" value="new">
            <Grid container justifyContent="center" alignItems="center">
              {t(I18nKeys.SitesLogosUploadImageOption)}
            </Grid>
          </MenuItem>
        </LoadingSelect>
      </FormControl>
    </Grid>
  );
};
