import { TFunction } from 'react-i18next';
import { PricingSheetAttribute } from '../types/PricingSheetAttribute';
import { I18nKeys } from './I18nKeys';

export enum PricingSheetAttributeType {
  Style = 'style',
  Expression = 'expression',
  Siding = 'siding',
  SidingColor = 'siding-color',
  Roof = 'roof',
  RoofColor = 'roof-color',
  Height = 'height',
  TrimColor = 'trim-color',
  Section = 'section',
  Gauge = 'gauge',
  Leg = 'leg',
  SnowLoad = 'snow-load',
}

export const getAttributeLabel = (attribute: PricingSheetAttribute, t: TFunction): string | undefined => {
  const { type, value, label } = attribute;

  let count = value.split(',').length;
  const labelParts = [label || value];
  switch (type) {
    case PricingSheetAttributeType.Style: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeStyle, { count }));
      break;
    }
    case PricingSheetAttributeType.Expression: {
      count = value.split(/&&|\|\|/).length;
      labelParts.push(t(I18nKeys.PricingSheetAttributeExpression, { count }));
      break;
    }
    case PricingSheetAttributeType.Siding: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeSiding, { count }));
      break;
    }
    case PricingSheetAttributeType.SidingColor: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeSidingColor, { count }));
      break;
    }
    case PricingSheetAttributeType.Roof: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeRoof, { count }));
      break;
    }
    case PricingSheetAttributeType.RoofColor: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeRoofColor, { count }));
      break;
    }
    case PricingSheetAttributeType.Height: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeHeight, { count }));
      break;
    }
    case PricingSheetAttributeType.TrimColor: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeTrimColor, { count }));
      break;
    }
    case PricingSheetAttributeType.Section: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeSection, { count }));
      break;
    }
    case PricingSheetAttributeType.Gauge: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeGauge, { count }));
      break;
    }
    case PricingSheetAttributeType.Leg: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeLeg, { count }));
      break;
    }
    case PricingSheetAttributeType.SnowLoad: {
      labelParts.push(t(I18nKeys.PricingSheetAttributeSnowLoad, { count }));
      break;
    }
    default: {
      break;
    }
  }

  return labelParts.join(' ');
};
