import { Grid, Typography, Theme, alpha, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { ChevronRight } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSelectedComponentCategoryKey } from '../ducks/pricingSlice';
import { COMPONENT_CATEGORY_MAPPING } from '../constants/ComponentPricing';

export const PRICING_COMPONENT_HEADER_HEIGHT = 60.5;

const useStyles = makeStyles<
  Theme,
  {
    viewingCategory: boolean;
  }
>((theme: Theme) => ({
  breadcrumbs: {
    height: PRICING_COMPONENT_HEADER_HEIGHT,
    padding: '0px 30px 30px',
  },
  breadcrumb: {
    cursor: 'pointer',
  },
  allCategories: {
    color: ({ viewingCategory }) => alpha(theme.palette.text.primary, viewingCategory ? 0.38 : 1),
  },
  chevron: {
    color: alpha(theme.palette.text.primary, 0.54),
    padding: '0px 5px',
  },
  category: {
    color: theme.palette.text.primary,
  },
}));

export const PricingComponentHeader: React.FC<{ setSearchValue: (val: string) => void }> = ({ setSearchValue }) => {
  const dispatch = useAppDispatch();

  const {
    component: { selectedComponentCategoryKey: componentCategoryKey },
  } = useAppSelector((state) => state?.pricing);

  const classes = useStyles({ viewingCategory: !!componentCategoryKey });
  const containerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Grid className={classes.breadcrumbs} container item xs={12}>
      <Grid item>
        <Typography
          className={`${classes.breadcrumb} ${classes.allCategories}`}
          variant="body1"
          component="div"
          fontWeight={600}
          onClick={() => {
            setSearchValue('');
            dispatch(setSelectedComponentCategoryKey(undefined));
          }}
        >
          All Components
        </Typography>
      </Grid>
      <Grid ref={containerRef} item style={{ overflow: 'hidden', flex: 1 }}>
        {componentCategoryKey && (
          <Slide direction="right" in={!!componentCategoryKey} container={containerRef.current}>
            <Grid item display="inline-flex">
              <Typography className={`${classes.breadcrumb} ${classes.chevron}`} variant="body1" component="div">
                <ChevronRight />
              </Typography>
              <Typography className={`${classes.breadcrumb} ${classes.category}`} component="div" fontWeight={600}>
                {COMPONENT_CATEGORY_MAPPING[componentCategoryKey]?.label}
              </Typography>
            </Grid>
          </Slide>
        )}
      </Grid>
    </Grid>
  );
};
