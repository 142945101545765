import { alpha, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { ArrowBack, Search } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppSelector, useAppDispatch } from '../hooks';
import { SearchInput } from './SearchInput';
import { setSearchTerm } from '../ducks/search';
import { AppState } from '../types/AppState';
import { isColorLight } from '../utils/colorUtils';

interface StyleProps {
  logoBarColor: string;
  logoBarTextColor: string;
}

const useStyles = (props: StyleProps) => {
  const { logoBarColor, logoBarTextColor } = props;

  const createStyles = makeStyles<Theme>((theme: Theme) => {
    const isLight = isColorLight(logoBarColor);
    return {
      searchIcon: {
        color: logoBarTextColor,
        padding: '12px',
        width: '48px',
        height: '48px',
      },
      ...(!isLight
        ? {
            searchField: {
              color: logoBarColor,
              backgroundColor: alpha(logoBarTextColor, 0.5),
              borderRadius: theme.shape.borderRadius,
            },
          }
        : {}),
    };
  });

  return createStyles();
};

export const LogoBarSearch: React.FC = () => {
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    viewer: { theme: { logoBarColor = '#FFFFFF', logoBarTextColor = '#000' } = {} },
  } = useAppSelector((state: AppState) => state || {});

  const classes = useStyles({ logoBarColor, logoBarTextColor });

  const dispatch = useAppDispatch();
  const [searchExpanded, setSearchExpanded] = React.useState(false);

  const endSearch = (): void => {
    dispatch(setSearchTerm(''));
    setSearchExpanded(false);
  };

  const {
    search: { searchTerm, hideSearch },
  } = useAppSelector((state) => state || {});

  if (hideSearch) return null;

  return searchExpanded ? (
    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
      <SearchInput
        classes={classes}
        searchTerm={searchTerm}
        startAdornment={mobileView ? <ArrowBack /> : <Search />}
        onStartAdornmentClick={() => {
          if (mobileView) endSearch();
        }}
        onClearClick={endSearch}
        onChange={(val: string) => dispatch(setSearchTerm(val))}
      />
    </div>
  ) : (
    <IconButton style={{ padding: 0 }} onClick={() => setSearchExpanded(true)} size="large">
      <Search className={classes.searchIcon} />
    </IconButton>
  );
};
