import { Reducer } from 'redux';
import { action } from 'typesafe-actions';
import {
  PricingAdjustmentCondition,
  SurchargeRule,
  SurchargeSystemOrderCalculation,
  PricingAdjustmentError,
} from '../types/PricingAdjustment';
import {
  defaultSurchargeCalculation,
  newSurchargeRule,
  PricingSurchargeCondition,
  PricingSurchargeConditions,
  SystemOrderCalculations,
  defaultPricingAdjustmentErrors,
  SystemOrderSupplierCalculations,
} from '../constants/PricingAdjustment';
import { PricingAdjustmentState } from '../types/PricingAdjustmentState';
import { reconcileSurchargeCalculations, findMatchingSurchargeCalculation } from '../utils/pricingUtils';

// Action types

export enum PricingAdjustmentActionTypes {
  SET_PRICING_LOADING = 'SET_PRICING_LOADING',
  SET_PRICING_CLIENT_SUPPLIER = 'SET_PRICING_CLIENT_SUPPLIER',
  FETCH_CONDITIONS = 'FETCH_CONDITIONS',
  FETCH_CONDITIONS_ERROR = 'FETCH_CONDITIONS_ERROR',
  FETCH_CONDITIONS_SUCCESS = 'FETCH_CONDITIONS_SUCCESS',
  FETCH_INITIAL_SURCHARGE = 'FETCH_INITIAL_SURCHARGE',
  FETCH_INITIAL_SURCHARGE_COMPLETE = 'FETCH_INITIAL_SURCHARGE_COMPLETE',
  SAVE_SURCHARGE = 'SAVE_SURCHARGE',
  REVERT_SURCHARGE = 'REVERT_SURCHARGE',
  PUBLISH_SURCHARGE = 'PUBLISH_SURCHARGE',
  PUBLISH_SURCHARGE_SUCCESS = 'PUBLISH_SURCHARGE_SUCCESS',
  SET_ACTIVE_SURCHARGE = 'SET_ACTIVE_SURCHARGE',
  SET_ACTIVE_SURCHARGE_CALCULATION = 'SET_ACTIVE_SURCHARGE_CALCULATION',
  SET_DRAFT_SURCHARGE = 'SET_DRAFT_SURCHARGE',
  SET_DRAFT_SURCHARGE_CALCULATIONS = 'SET_DRAFT_SURCHARGE_CALCULATIONS',
  SET_SUPPLIER_SURCHARGE_CALCULATION = 'SET_SUPPLIER_SURCHARGE_CALCULATION',
  SET_PRICING_BUTTON_STATES = 'SET_PRICING_BUTTON_STATES',
  UPDATE_PRICING_SUPPLIER_SELECTION = 'UPDATE_PRICING_SUPPLIER_SELECTION',
  SET_PRICING_SUPPLIER_SELECTION = 'SET_PRICING_SUPPLIER_SELECTION',
  SET_PRICING_SELECTION = 'SET_PRICING_SELECTION',
  SET_DRAFT_EQUALS_ACTIVE = 'SET_DRAFT_EQUALS_ACTIVE',
  SET_PRICING_CATEGORY = 'SET_PRICING_CATEGORY',
  ADD_PRICING_CATEGORY = 'ADD_PRICING_CATEGORY',
  REMOVE_PRICING_CATEGORY = 'REMOVE_PRICING_CATEGORY',
  ADD_PRICING_RULE = 'ADD_PRICING_RULE',
  REMOVE_PRICING_RULE = 'REMOVE_PRICING_RULE',
  UPDATE_SURCHARGE_CALCULATION = 'UPDATE_SURCHARGE_CALCULATION',
  UPDATE_PRICING_CALCULATION = 'UPDATE_PRICING_CALCULATION',
  ADD_PRICING_CONDITION_TO_ALL_RULES = 'ADD_PRICING_CONDITION_TO_ALL_RULES',
  REMOVE_PRICING_CONDITION_FROM_ALL_RULES = 'REMOVE_PRICING_CONDITION_FROM_ALL_RULES',
  UPDATE_SURCHARGE_RULE = 'UPDATE_SURCHARGE_RULE',
  UPDATE_SURCHARGE_RULE_CONDITION = 'UPDATE_SURCHARGE_RULE_CONDITION',
  OPEN_CONFIGURATOR_MODAL = 'OPEN_CONFIGURATOR_MODAL',
  CLOSE_CONFIGURATOR_MODAL = 'CLOSE_CONFIGURATOR_MODAL',
  ATTEMPT_TO_OPEN_CONFIGURATOR_MODAL = 'ATTEMPT_TO_OPEN_CONFIGURATOR_MODAL',
  ADD_PRICING_ERRORS = 'ADD_PRICING_ERRORS',
  SET_PRICING_ERRORS = 'SET_PRICING_ERRORS',
  CLEAR_PRICING_ERRORS = 'CLEAR_PRICING_ERRORS',
  DELETE_DRAFT_SURCHARGE = 'DELETE_DRAFT_SURCHARGE',
  DELETE_DRAFT_SURCHARGE_COMPLETE = 'DELETE_DRAFT_SURCHARGE_COMPLETE',
}

// Initial State

export const INITIAL_PRICING_ADJUSTMENT_STATE: PricingAdjustmentState = {
  pricingSupplierSelection: SystemOrderSupplierCalculations.Off,
  pricingSelection: SystemOrderCalculations.None,
  activeSurcharge: undefined,
  activeSurchargeCalculation: undefined,
  draftSurcharge: undefined,
  draftSurchargeCalculations: [],
  supplierSurchargeCalculation: undefined,
  conditionOptions: PricingSurchargeConditions,
  draftEqualsActive: true,
  publishEnabled: false,
  configuratorModalOpen: false,
  loading: false,
  clientId: undefined,
  supplierKey: undefined,
  errors: defaultPricingAdjustmentErrors,
};

// Reducer

export const pricingAdjustmentReducer: Reducer<PricingAdjustmentState> = (
  state = INITIAL_PRICING_ADJUSTMENT_STATE,
  pricingAction,
) => {
  switch (pricingAction.type) {
    case PricingAdjustmentActionTypes.SET_PRICING_CLIENT_SUPPLIER: {
      const {
        payload: { clientId, supplierKey },
      } = pricingAction;

      return { ...state, clientId, supplierKey: supplierKey || undefined };
    }
    case PricingAdjustmentActionTypes.FETCH_CONDITIONS: {
      return { ...state };
    }
    case PricingAdjustmentActionTypes.FETCH_CONDITIONS_SUCCESS: {
      const {
        payload: { conditions },
      } = pricingAction;

      return { ...state, conditionOptions: conditions };
    }
    case PricingAdjustmentActionTypes.FETCH_CONDITIONS_ERROR: {
      return { ...state, conditionOptions: [] };
    }
    case PricingAdjustmentActionTypes.SET_PRICING_LOADING:
    case PricingAdjustmentActionTypes.FETCH_INITIAL_SURCHARGE: {
      return { ...state, loading: true };
    }
    case PricingAdjustmentActionTypes.FETCH_INITIAL_SURCHARGE_COMPLETE: {
      return { ...state, loading: false };
    }
    case PricingAdjustmentActionTypes.SET_ACTIVE_SURCHARGE: {
      const {
        payload: { activeSurcharge },
      } = pricingAction;

      return { ...state, activeSurcharge };
    }
    case PricingAdjustmentActionTypes.SET_ACTIVE_SURCHARGE_CALCULATION: {
      const {
        payload: { activeSurchargeCalculation },
      } = pricingAction;

      return { ...state, activeSurchargeCalculation };
    }
    case PricingAdjustmentActionTypes.SET_DRAFT_SURCHARGE: {
      const {
        payload: { draftSurcharge },
      } = pricingAction;

      return { ...state, draftSurcharge };
    }
    case PricingAdjustmentActionTypes.SET_DRAFT_SURCHARGE_CALCULATIONS: {
      const {
        payload: { draftSurchargeCalculations },
      } = pricingAction;

      return { ...state, draftSurchargeCalculations };
    }
    case PricingAdjustmentActionTypes.SET_SUPPLIER_SURCHARGE_CALCULATION: {
      const {
        payload: { supplierSurchargeCalculation },
      } = pricingAction;

      return { ...state, supplierSurchargeCalculation };
    }
    case PricingAdjustmentActionTypes.SET_PRICING_SUPPLIER_SELECTION: {
      const {
        payload: { pricingSupplierSelection = SystemOrderSupplierCalculations.Off },
      } = pricingAction;

      return {
        ...state,
        publishEnabled: false,
        pricingSupplierSelection,
      };
    }
    case PricingAdjustmentActionTypes.SET_PRICING_SELECTION: {
      const {
        payload: { pricingSelection = SystemOrderCalculations.None },
      } = pricingAction;

      return {
        ...state,
        publishEnabled: false,
        pricingSelection,
      };
    }
    case PricingAdjustmentActionTypes.SET_DRAFT_EQUALS_ACTIVE: {
      const {
        payload: { draftEqualsActive = false },
      } = pricingAction;

      return { ...state, draftEqualsActive };
    }
    case PricingAdjustmentActionTypes.SAVE_SURCHARGE: {
      const {
        payload: { isExport },
      } = pricingAction;
      return { ...state, publishEnabled: !isExport };
    }
    case PricingAdjustmentActionTypes.PUBLISH_SURCHARGE: {
      return { ...state, publishEnabled: false };
    }
    case PricingAdjustmentActionTypes.REVERT_SURCHARGE: {
      return { ...state, publishEnabled: false };
    }
    case PricingAdjustmentActionTypes.ADD_PRICING_CATEGORY: {
      const {
        payload: { pricingCategory },
      } = pricingAction;

      const { clientId = '' } = state;
      const draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        state.draftSurchargeCalculations,
        state.supplierKey,
        state.pricingSelection,
      );
      return {
        ...state,
        publishEnabled: false,
        draftSurchargeCalculations: reconcileSurchargeCalculations(
          clientId,
          state.draftSurchargeCalculations,
          {
            ...draftSurchargeCalculation,
            categories: [...draftSurchargeCalculation.categories, pricingCategory],
          },
          false,
          true,
        ),
      };
    }
    case PricingAdjustmentActionTypes.REMOVE_PRICING_CATEGORY: {
      const {
        payload: { pricingCategory },
      } = pricingAction;

      const { clientId = '' } = state;
      const draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        state.draftSurchargeCalculations,
        state.supplierKey,
        state.pricingSelection,
      );
      return {
        ...state,
        publishEnabled: false,
        draftSurchargeCalculations: reconcileSurchargeCalculations(
          clientId,
          state.draftSurchargeCalculations,
          {
            ...draftSurchargeCalculation,
            categories: draftSurchargeCalculation.categories.filter((category) => category !== pricingCategory),
          },
          false,
          true,
        ),
      };
    }
    case PricingAdjustmentActionTypes.ADD_PRICING_CONDITION_TO_ALL_RULES: {
      const {
        payload: { pricingAdjustmentCondition },
      } = pricingAction;

      const { clientId = '' } = state;
      const draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        state.draftSurchargeCalculations,
        state.supplierKey,
        state.pricingSelection,
      );
      const newRules = [];
      newRules.push(...draftSurchargeCalculation.rules);

      // If no rules exist create one
      if (newRules.length === 0) {
        newRules.push({ id: 0, conditions: [], calculation: defaultSurchargeCalculation });
      }

      return {
        ...state,
        publishEnabled: false,
        draftSurchargeCalculations: reconcileSurchargeCalculations(
          clientId,
          state.draftSurchargeCalculations,
          {
            ...draftSurchargeCalculation,
            rules: newRules.map((rule) => ({
              ...rule,
              conditions: [...rule.conditions, { type: pricingAdjustmentCondition }],
            })),
          },
          false,
          true,
        ),
      };
    }
    case PricingAdjustmentActionTypes.REMOVE_PRICING_CONDITION_FROM_ALL_RULES: {
      const {
        payload: { pricingAdjustmentCondition },
      } = pricingAction;

      const { clientId = '' } = state;
      const draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        state.draftSurchargeCalculations,
        state.supplierKey,
        state.pricingSelection,
      );
      const { rules } = draftSurchargeCalculation;

      const rulesWithPricingAdjustmentConditionRemoved = rules.map((rule) => ({
        ...rule,
        conditions: [...rule.conditions.filter((condition) => condition.type !== pricingAdjustmentCondition)],
      }));

      const newRules = rulesWithPricingAdjustmentConditionRemoved.every((rule) => rule.conditions.length === 0)
        ? []
        : rulesWithPricingAdjustmentConditionRemoved;

      return {
        ...state,
        publishEnabled: false,
        draftSurchargeCalculations: reconcileSurchargeCalculations(
          clientId,
          state.draftSurchargeCalculations,
          {
            ...draftSurchargeCalculation,
            rules: newRules,
          },
          false,
          true,
        ),
      };
    }
    case PricingAdjustmentActionTypes.UPDATE_SURCHARGE_RULE_CONDITION: {
      const {
        payload: { id, type, ruleCondition },
      } = pricingAction;

      const { clientId = '' } = state;
      const draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        state.draftSurchargeCalculations,
        state.supplierKey,
        state.pricingSelection,
      );
      const newRules = draftSurchargeCalculation.rules.map((rule) => {
        if (rule.id !== id) {
          return rule;
        }
        return {
          ...rule,
          conditions: rule.conditions.map((condition) => {
            if (condition.type !== type) {
              return condition;
            }
            if (Array.isArray(ruleCondition)) {
              return {
                type,
                keys: ruleCondition,
              };
            }
            return {
              type,
              minimum: ruleCondition.minimum,
              maximum: ruleCondition.maximum,
            };
          }),
        };
      });

      return {
        ...state,
        publishEnabled: false,
        draftSurchargeCalculations: reconcileSurchargeCalculations(
          clientId,
          state.draftSurchargeCalculations,
          {
            ...draftSurchargeCalculation,
            rules: newRules,
          },
          false,
          true,
        ),
      };
    }
    case PricingAdjustmentActionTypes.ADD_PRICING_RULE: {
      const { clientId = '' } = state;
      const draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        state.draftSurchargeCalculations,
        state.supplierKey,
        state.pricingSelection,
      );
      const newRuleId = Math.max(0, ...draftSurchargeCalculation.rules.map((rule) => rule.id)) + 1;
      // strip out any key values but keep the condition types
      const newConditions = draftSurchargeCalculation.rules[0].conditions.map(({ type }) => ({ type }));

      return {
        ...state,
        publishEnabled: false,
        draftSurchargeCalculations: reconcileSurchargeCalculations(
          clientId,
          state.draftSurchargeCalculations,
          {
            ...draftSurchargeCalculation,
            rules: [...draftSurchargeCalculation.rules, newSurchargeRule(newRuleId, newConditions)],
          },
          false,
          true,
        ),
      };
    }
    case PricingAdjustmentActionTypes.UPDATE_SURCHARGE_RULE: {
      const {
        payload: { rule },
      } = pricingAction;

      const { clientId = '' } = state;
      let draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        state.draftSurchargeCalculations,
        state.supplierKey,
        state.pricingSelection,
      );
      const ruleExists = draftSurchargeCalculation.rules.find((draftRule) => draftRule.id === rule.id);
      if (ruleExists) {
        const ruleIndex = draftSurchargeCalculation.rules.findIndex((draftRule) => draftRule.id === rule.id);
        draftSurchargeCalculation = {
          ...draftSurchargeCalculation,
          rules: [
            ...draftSurchargeCalculation.rules.slice(0, ruleIndex),
            rule,
            ...draftSurchargeCalculation.rules.slice(ruleIndex + 1, draftSurchargeCalculation.rules.length),
          ],
        };
      }
      return {
        ...state,
        publishEnabled: false,
        draftSurchargeCalculations: reconcileSurchargeCalculations(
          clientId,
          state.draftSurchargeCalculations,
          draftSurchargeCalculation,
          false,
          true,
        ),
      };
    }
    case PricingAdjustmentActionTypes.REMOVE_PRICING_RULE: {
      const {
        payload: { id },
      } = pricingAction;

      const { clientId = '' } = state;
      const draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        state.draftSurchargeCalculations,
        state.supplierKey,
        state.pricingSelection,
      );
      return {
        ...state,
        publishEnabled: false,
        draftSurchargeCalculations: reconcileSurchargeCalculations(
          clientId,
          state.draftSurchargeCalculations,
          {
            ...draftSurchargeCalculation,
            rules: draftSurchargeCalculation.rules.filter((rule) => rule.id !== id),
          },
          false,
          true,
        ),
      };
    }
    case PricingAdjustmentActionTypes.UPDATE_PRICING_CALCULATION: {
      const {
        payload: {
          pricingCalculation: { label, isTaxable, affectsDeposit },
        },
      } = pricingAction;

      // TODO: What triggers this as a "Save" operation? Is it a preview/publish or something else?
      // Also how do we save/delete on a switch to "None"

      const { clientId = '' } = state;
      const draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        state.draftSurchargeCalculations,
        state.supplierKey,
        state.pricingSelection,
      );
      return {
        ...state,
        publishEnabled: false,
        draftSurchargeCalculations: reconcileSurchargeCalculations(
          clientId,
          state.draftSurchargeCalculations,
          {
            ...draftSurchargeCalculation,
            label,
            isTaxable,
            affectsDeposit,
          },
          false,
          true,
        ),
      };
    }
    case PricingAdjustmentActionTypes.UPDATE_SURCHARGE_CALCULATION: {
      const {
        payload: { calculation },
      } = pricingAction;

      const { clientId = '' } = state;
      const draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        state.draftSurchargeCalculations,
        state.supplierKey,
        state.pricingSelection,
      );
      return {
        ...state,
        publishEnabled: false,
        draftSurchargeCalculations: reconcileSurchargeCalculations(
          clientId,
          state.draftSurchargeCalculations,
          {
            ...draftSurchargeCalculation,
            calculation,
          },
          false,
          true,
        ),
      };
    }
    case PricingAdjustmentActionTypes.OPEN_CONFIGURATOR_MODAL: {
      return { ...state, configuratorModalOpen: true };
    }
    case PricingAdjustmentActionTypes.CLOSE_CONFIGURATOR_MODAL: {
      return { ...state, configuratorModalOpen: false };
    }
    case PricingAdjustmentActionTypes.ADD_PRICING_ERRORS: {
      const {
        payload: { errors },
      } = pricingAction;
      return {
        ...state,
        errors: [...state.errors, ...errors],
      };
    }
    case PricingAdjustmentActionTypes.SET_PRICING_ERRORS: {
      const {
        payload: { errors },
      } = pricingAction;
      return {
        ...state,
        errors,
      };
    }
    case PricingAdjustmentActionTypes.CLEAR_PRICING_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }
    default: {
      return state;
    }
  }
};

// Action creators

export type PricingAdjustmentActionType = { type: PricingAdjustmentActionTypes };

export const setPricingLoading = (): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_PRICING_LOADING, {});

export const setPricingClientAndSupplier = (clientId: string, supplierKey: string): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_PRICING_CLIENT_SUPPLIER, { clientId, supplierKey });

export const fetchConditions = (clientId: string): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.FETCH_CONDITIONS, { clientId });

export const fetchConditionsError = (): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.FETCH_CONDITIONS_ERROR);

export const fetchConditionsSuccess = (conditions: PricingAdjustmentCondition[]): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.FETCH_CONDITIONS_SUCCESS, { conditions });

export const fetchInitialSurcharge = (clientId: string, supplierKey: string | undefined): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.FETCH_INITIAL_SURCHARGE, { clientId, supplierKey });

export const fetchInitialSurchargeComplete = (): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.FETCH_INITIAL_SURCHARGE_COMPLETE);

export const saveSurcharge = (clientId: string, isExport = false): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SAVE_SURCHARGE, { clientId, isExport });

export const revertSurcharge = (clientId: string): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.REVERT_SURCHARGE, { clientId });

export const publishSurcharge = (clientId: string): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.PUBLISH_SURCHARGE, { clientId });

export const publishSurchargeSuccess = (surcharge: SurchargeSystemOrderCalculation): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.PUBLISH_SURCHARGE_SUCCESS, { surcharge });

export const setActiveSurcharge = (activeSurcharge?: SurchargeSystemOrderCalculation[]): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_ACTIVE_SURCHARGE, { activeSurcharge });

export const setActiveSurchargeCalculation = (
  activeSurchargeCalculation?: SurchargeSystemOrderCalculation,
): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_ACTIVE_SURCHARGE_CALCULATION, { activeSurchargeCalculation });

export const setDraftSurcharge = (draftSurcharge?: SurchargeSystemOrderCalculation[]): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_DRAFT_SURCHARGE, { draftSurcharge });

export const setDraftSurchargeCalculations = (
  draftSurchargeCalculations?: SurchargeSystemOrderCalculation[],
): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_DRAFT_SURCHARGE_CALCULATIONS, { draftSurchargeCalculations });

export const setSupplierSurchargeCalculation = (
  supplierSurchargeCalculation?: SurchargeSystemOrderCalculation,
): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_SUPPLIER_SURCHARGE_CALCULATION, { supplierSurchargeCalculation });

export const setPricingSupplierSelection = (pricingSupplierSelection?: string): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_PRICING_SUPPLIER_SELECTION, { pricingSupplierSelection });

export const updatePricingSupplierSelection = (pricingSupplierSelection?: string): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.UPDATE_PRICING_SUPPLIER_SELECTION, { pricingSupplierSelection });

export const setPricingSelection = (pricingSelection?: string): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_PRICING_SELECTION, { pricingSelection });

export const setDraftEqualsActive = (draftEqualsActive: boolean): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_DRAFT_EQUALS_ACTIVE, { draftEqualsActive });

export const addPricingCategory = (pricingCategory: string): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.ADD_PRICING_CATEGORY, { pricingCategory });

export const removePricingCategory = (pricingCategory: string): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.REMOVE_PRICING_CATEGORY, { pricingCategory });

export const updatePricingCalculation = (pricingCalculation: any): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.UPDATE_PRICING_CALCULATION, { pricingCalculation });

export const updateSurchargeCalculation = (calculation: any): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.UPDATE_SURCHARGE_CALCULATION, { calculation });

export const addPricingRule = (): PricingAdjustmentActionType => action(PricingAdjustmentActionTypes.ADD_PRICING_RULE);

export const updateSurchargeRule = (rule: SurchargeRule): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.UPDATE_SURCHARGE_RULE, { rule });

export const removePricingRule = (id: number): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.REMOVE_PRICING_RULE, { id });

export const addPricingAdjustmentConditionToAllRules = (
  pricingAdjustmentCondition: string,
): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.ADD_PRICING_CONDITION_TO_ALL_RULES, { pricingAdjustmentCondition });

export const removePricingAdjustmentConditionFromAllRules = (
  pricingAdjustmentCondition: string,
): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.REMOVE_PRICING_CONDITION_FROM_ALL_RULES, { pricingAdjustmentCondition });

export const updateSurchargeRuleCondition = (
  id: number,
  type: PricingSurchargeCondition,
  ruleCondition: string[] | { minimum: number; maximum: number },
): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.UPDATE_SURCHARGE_RULE_CONDITION, { id, type, ruleCondition });

export const openConfiguratorModal = (): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.OPEN_CONFIGURATOR_MODAL);

export const closeConfiguratorModal = (): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.CLOSE_CONFIGURATOR_MODAL);

export const attemptToOpenConfiguratorModal = (): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.ATTEMPT_TO_OPEN_CONFIGURATOR_MODAL);

export const deleteDraftSurcharge = (clientId: string): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.DELETE_DRAFT_SURCHARGE, { clientId });

export const deleteDraftSurchargeComplete = (): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.DELETE_DRAFT_SURCHARGE_COMPLETE);

export const addPricingAdjustmentErrors = (errors: PricingAdjustmentError[]): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.ADD_PRICING_ERRORS, { errors });

export const setPricingAdjustmentErrors = (errors: PricingAdjustmentError[]): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.SET_PRICING_ERRORS, { errors });

export const clearPricingAdjustmentErrors = (): PricingAdjustmentActionType =>
  action(PricingAdjustmentActionTypes.CLEAR_PRICING_ERRORS);
