import React from 'react';
import { PriceCalculation } from '@idearoom/types';
import { Field, Form, InjectedFormProps, reduxForm, formValueSelector } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Theme, Grid, MenuItem, FormControl, FormHelperText, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Forms } from '../constants/Forms';
import { PricingComponentEditFields } from '../constants/FormFields';
import { CheckboxField } from './redux-form/CheckboxField';
import { InputField } from './redux-form/InputField';
import { RenderSelectField } from './RenderSelectField';
import { AppState } from '../types/AppState';
import { I18nKeys } from '../constants/I18nKeys';
import {
  COMPONENT_CATEGORY_MAPPING,
  DisplayColumns,
  HelperColumns,
  PricingCalculationColumns,
  PricingColumns,
  RegionPriceColumns,
  componentColumnMap,
} from '../constants/ComponentPricing';
import {
  displayVaryByRegion,
  getComponentFieldLabel,
  getPricingTypeLabel,
  onComponentFieldChange,
} from '../utils/componentPricingUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { useComponentPricingRepo } from '../hooks/useComponentPricingRepo';

const useStyles = makeStyles((theme: Theme) => ({
  fieldContainer: {
    marginTop: theme.spacing(2),
  },
  field: {
    margin: '0px',
    padding: '0px',
  },
  helperText: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    letterSpacing: 'inherit',
    lineHeight: 'inherit',
  },
  checkboxField: {
    marginRight: '8px',
  },
}));

export interface FormData {
  [PricingComponentEditFields.Component]: string;
  [PricingComponentEditFields.Table]: string;
  [DisplayColumns.Label]: string;
  [PricingColumns.Price]: number;
  [PricingColumns.Region1]: number;
  [PricingColumns.Region2]: number;
  [PricingColumns.Region3]: number;
  [PricingColumns.Region4]: number;
  [PricingColumns.Region5]: number;
  [PricingColumns.Region6]: number;
  [PricingColumns.Region7]: number;
  [PricingCalculationColumns.PriceCalculation]: string;
  [PricingColumns.UpgradePrice]: number;
  [DisplayColumns.PriceExpression]: string;
  [HelperColumns.VariesByRegion]: string;
  [HelperColumns.Key]: string;
}

interface CustomProps {
  formFields: (
    | PricingComponentEditFields
    | DisplayColumns
    | HelperColumns
    | PricingColumns
    | PricingCalculationColumns
  )[];
  showKeyAsHelperText?: boolean;
  onSubmit: { (values: FormData): void };
}

type FormProps = CustomProps & InjectedFormProps<FormData, CustomProps>;

const PricingComponentEditFormComponent: React.FC<FormProps> = ({
  handleSubmit,
  formFields,
  showKeyAsHelperText = false,
  onSubmit,
}: FormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    component: { selectedComponentCategoryKey: componentCategoryKey = '' },
  } = useAppSelector((state: AppState) => state?.pricing);

  const currentValues = useAppSelector((state) => formValueSelector(Forms.PricingComponentEdit)(state, ...formFields));

  const {
    clientTableColumns: { [currentValues[PricingComponentEditFields.Table]]: componentTableColumns = [] },
    isLoadingClientTableColumns,
  } = useClientDataRepo({
    useClientTablesColumns: true,
  });

  const { clientUpdateRegions: regions = [] } = useComponentPricingRepo({
    useClientUpdateRegions: true,
  });

  if (!componentCategoryKey) return null;

  const enableRegions = displayVaryByRegion(regions, componentTableColumns);
  const expressionExists = formFields.includes(DisplayColumns.PriceExpression);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowGap={2} columnGap={2} className={classes.fieldContainer}>
        {formFields.map((field) => {
          if (([DisplayColumns, PricingColumns].flatMap((e) => Object.values(e)) as string[]).includes(field)) {
            const isLabelField = DisplayColumns.Label === field;
            const isExpressionField = DisplayColumns.PriceExpression === field;
            const isPriceField = (Object.values(PricingColumns) as string[]).includes(field);
            const displayPricingCalculation = !!componentColumnMap[field as string]?.calculation;

            // Only display region prices that exist for the table and are used by the vendor's regions
            if (
              RegionPriceColumns.includes(field) &&
              (!currentValues[HelperColumns.VariesByRegion] ||
                !enableRegions ||
                regions.every(({ priceColumn }) => priceColumn !== (field as string)))
            )
              return null;
            return (
              <Grid
                container
                direction="row"
                key={field}
                spacing={2}
                xs={(Object.values(PricingColumns) as string[]).includes(field) && !displayPricingCalculation ? 6 : 12}
              >
                {displayPricingCalculation && (
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Field
                        component={RenderSelectField}
                        label={t(I18nKeys.PricingCalculationField)}
                        name={componentColumnMap[field as string]?.calculation}
                        variant="filled"
                        className={classes.field}
                        disabled
                        SelectProps={{
                          renderValue: (value: PriceCalculation) => getPricingTypeLabel(value),
                        }}
                      >
                        {Object.entries(PriceCalculation).map(([, value]) => (
                          <MenuItem key={value} value={value} />
                        ))}
                      </Field>
                      {!isLoadingClientTableColumns &&
                        !componentTableColumns.includes(PricingCalculationColumns.PriceCalculation) && (
                          <FormHelperText>
                            <Typography
                              component="span"
                              className={classes.helperText}
                              dangerouslySetInnerHTML={{
                                __html: t(I18nKeys.PricingComponentNoPricingType, {
                                  category: COMPONENT_CATEGORY_MAPPING[componentCategoryKey]?.label,
                                }),
                              }}
                            />
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={displayPricingCalculation ? 6 : 12}>
                  <FormControl fullWidth>
                    <Field
                      name={field}
                      autoComplete="off"
                      component={InputField}
                      label={getComponentFieldLabel(field, regions)}
                      variant="filled"
                      className={classes.field}
                      InputProps={{
                        multiline: isExpressionField,
                        minRows: 5,
                        maxRows: 5,
                        style: {
                          ...(isExpressionField ? { padding: '12px 8px', fontFamily: 'monaco' } : {}),
                        },
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        onComponentFieldChange(event, currentValues as FormData, dispatch)
                      }
                      disabled={isExpressionField || (isPriceField && expressionExists)}
                    />
                    {isExpressionField && (
                      <FormHelperText>
                        <Typography
                          component="span"
                          className={classes.helperText}
                          dangerouslySetInnerHTML={{
                            __html: t(I18nKeys.PricingComponentPriceExpressionHelp),
                          }}
                        />
                      </FormHelperText>
                    )}
                    {showKeyAsHelperText && isLabelField && (
                      <FormHelperText>
                        <Typography component="span" className={classes.helperText}>
                          {`${t(I18nKeys.PricingComponentKeyLabel)} ${currentValues[HelperColumns.Key]}`}
                        </Typography>
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            );
          }

          if (enableRegions && HelperColumns.VariesByRegion === field) {
            return (
              <Grid item xs={12}>
                <Box style={{ margin: '8px 0px' }}>
                  <Field
                    name={field}
                    autoComplete="off"
                    component={CheckboxField}
                    variant="filled"
                    className={`${classes.field} ${classes.checkboxField}`}
                  />
                  <Typography component="span" style={{ verticalAlign: 'middle' }}>
                    {t(I18nKeys.PricingVaryByRegion)}
                  </Typography>
                </Box>
              </Grid>
            );
          }

          return null;
        })}
      </Grid>
    </Form>
  );
};

export const PricingComponentEditForm = reduxForm<FormData, CustomProps>({
  form: Forms.PricingComponentEdit,
})(PricingComponentEditFormComponent);
