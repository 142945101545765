import { Check, Public } from '@mui/icons-material';
import { Typography, Menu, MenuItem, ListItemIcon, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { UserPreference } from '../constants/User';
import { AppState } from '../types/AppState';
import { useComponentPricingRepo } from '../hooks/useComponentPricingRepo';
import { LoadingButton } from './LoadingButton';
import { Region } from '../types/Region';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { setPricingComponentRegion } from '../ducks/pricingSlice';
import { saveUserPreferences } from '../ducks/currentUserSlice';

const useStyles = makeStyles(() => ({
  button: {
    margin: '8px 25px 0px 0px',
    padding: '8px 16px',
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    fontWeight: 500,
  },
}));

export const PricingComponentRegionSelect: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const viewerClientId = useAppSelector(
    ({ viewer: { selectedTabId, selectedClientId }, clientData: { clientId } }: AppState) =>
      getClientIdFromClientSupplier(clientId || selectedTabId || selectedClientId || ''),
  );
  const {
    pricing: { component: { region = '' } = {} },
    currentUser: { preferences: { [UserPreference.PricingComponentPreferences]: clientUpdatePreferences = {} } = {} },
  } = useAppSelector((state: AppState) => state);

  const { clientUpdateRegions: regions, isLoadingClientUpdateRegions: isLoading } = useComponentPricingRepo({
    useClientUpdateRegions: true,
  });

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const [selectedRegion, setSelectedRegion] = React.useState<Region | undefined>(undefined);

  React.useEffect(() => {
    if (!!selectedRegion && selectedRegion.regionKey !== region) {
      dispatch(setPricingComponentRegion(selectedRegion.regionKey));
    }
  }, [region, selectedRegion]);

  React.useEffect(() => {
    const { [viewerClientId]: { region: savedRegion = '' } = {} } = clientUpdatePreferences;
    if (!regions?.length) return;
    if (!savedRegion) setSelectedRegion(regions[0]);
    else setSelectedRegion(regions.find(({ regionKey }) => regionKey === savedRegion));
  }, [regions, clientUpdatePreferences, viewerClientId]);

  if (!regions?.length || !selectedRegion) return null;
  return (
    <Box>
      <LoadingButton
        loading={isLoading}
        startIcon={<Public />}
        className={classes.button}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        sx={{
          '& .MuiSvgIcon-root': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
        }}
      >
        {selectedRegion.label.toLocaleUpperCase()}
      </LoadingButton>
      <Menu
        id="filter-menu"
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        {regions.map(({ regionKey, label }) => (
          <MenuItem
            key={regionKey}
            onClick={() => {
              setSelectedRegion(regions.find(({ regionKey: key }) => key === regionKey));
              setMenuAnchorEl(null);
              dispatch(
                saveUserPreferences({
                  userPreference: UserPreference.PricingComponentPreferences,
                  preferences: {
                    ...clientUpdatePreferences,
                    [viewerClientId]: {
                      ...clientUpdatePreferences[viewerClientId],
                      region: regionKey,
                    },
                  },
                }),
              );
              dispatch(setPricingComponentRegion(regionKey));
            }}
          >
            <ListItemIcon>
              <Check
                style={{
                  color: selectedRegion.regionKey === regionKey ? undefined : 'transparent',
                }}
              />
            </ListItemIcon>
            <Typography component="span">{label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
