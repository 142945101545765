import { Configurator } from '@idearoom/sdk';
import { Dialog } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import ReactDOM from 'react-dom';
import { mapClientIdToConfiguratorAndVendor } from '../utils/clientIdUtils';
import { getConfiguratorUrlWithLocale } from '../utils/vendorUtils';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { useGetVendorDataQuery } from '../services/clientDataApi';

interface StyleProps {
  width?: string;
  height?: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  dialogPaper: (props: StyleProps) => ({
    minHeight: props.height,
    maxHeight: props.height,
    minWidth: props.width,
    maxWidth: props.width,
  }),
}));

interface OwnProps {
  open: boolean;
  clientId: string;
  previewMode?: boolean;
  clientBranch?: ClientDataBranch;
  structureBranch?: ClientDataBranch;
  systemBranch?: ClientDataBranch;
  onClose(): void;
}

type Props = OwnProps & StyleProps;

export const ConfiguratorModal: React.FC<Props> = ({
  clientId,
  open,
  onClose,
  previewMode = false,
  width = '90vw', // 90% width default
  height = '90vh', // 90% height default
  clientBranch,
  structureBranch,
  systemBranch,
}: Props) => {
  const classes = useStyles({ width, height });
  const embeddedConfiguratorContainer = 'embedded-configurator-container';
  const embeddedConfigurator = 'embedded-configurator';

  const { currentData: { locale = '', productionURL = '' } = {} } = useGetVendorDataQuery(
    { clientId },
    {
      skip: !clientId,
      refetchOnFocus: false,
    },
  );

  const embeddedConfiguratorDiv = <div id={embeddedConfigurator} style={{ display: 'flex', flex: '1' }} />;

  /**
   * Removes and re-adds the div that hosts the configurator.
   */
  const ensureEmbeddedConfiguratorDivExists = (): void => {
    const configuratorElement = document.getElementById(embeddedConfigurator);
    if (configuratorElement) {
      configuratorElement.remove();
    }
    const configuratorContainerElement = document.getElementById(embeddedConfiguratorContainer);
    if (configuratorContainerElement) {
      ReactDOM.render(embeddedConfiguratorDiv, configuratorContainerElement);
    }
  };

  // Check for the embedded configurator div to be rendered before loading the configurator.
  // Attempts to find the div every 100ms for 10 seconds.
  const waitForEmbeddedConfiguratorDiv = (): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      let attempts = 0;
      const interval = setInterval(() => {
        const configuratorElement = document.getElementById(embeddedConfigurator);
        if (configuratorElement) {
          clearInterval(interval);
          resolve();
        } else {
          attempts += 1;
          if (attempts >= 100) {
            clearInterval(interval);
            reject();
          }
        }
      }, 100);
    });

  React.useEffect(() => {
    // Watch for the modal to be open with a clientId set before getting the url to the configurator
    // and loading it.
    const loadConfigurator = async (): Promise<Configurator> => {
      await waitForEmbeddedConfiguratorDiv();
      const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);
      const url = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
      const configuratorUrl =
        clientBranch || structureBranch || systemBranch
          ? `${url}` +
            `${url.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2` +
            `&clientSettings=${clientBranch || ClientDataBranch.Main}` +
            `&structureSettings=${structureBranch || ClientDataBranch.Main}` +
            `&systemSettings=${systemBranch || ClientDataBranch.Main}`
          : url;
      return new Configurator(configuratorUrl, embeddedConfigurator, {
        previewMode,
        initializeWithVisibleFrame: true,
      });
    };
    if (open && clientId !== undefined && clientId !== '' && productionURL) {
      loadConfigurator();
    }
  }, [clientId, open, previewMode, productionURL]);

  const handleClose = (): void => {
    // When the modal closes remove and re-add an empty div to host the next configurator that is loaded.
    ensureEmbeddedConfiguratorDivExists();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <IconButton onClick={handleClose} style={{ width: '35px', height: '35px', position: 'absolute' }} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <div id={embeddedConfiguratorContainer} style={{ display: 'flex', flex: '1' }}>
        {embeddedConfiguratorDiv}
      </div>
    </Dialog>
  );
};
