/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { PriceCalculation as PricingType } from '@idearoom/types';
import { ComponentCategoryKey } from './ComponentCategoryKey';
import DoorCategoryImage from '../images/door-category.png';
import GarageCategoryImage from '../images/garage-category.png';
import FrameoutCategoryImage from '../images/frameout-category.png';
import WindowCategoryImage from '../images/window-category.png';
import TransomCategoryImage from '../images/transom-category.png';
import DormerCategoryImage from '../images/dormer-category.png';
import CupolaCategoryImage from '../images/cupola-category.png';
import ShutterCategoryImage from '../images/shutter-category.png';
import FlowerBoxCategoryImage from '../images/flowerbox-category.png';
import VentCategoryImage from '../images/vent-category.png';
import RampCategoryImage from '../images/ramp-category.png';
import ShelfCategoryImage from '../images/shelf-category.png';
import WorkbenchCategoryImage from '../images/workbench-category.png';
import FlooringCategoryImage from '../images/flooring-category.png';
import SidingCategoryImage from '../images/siding-category.png';
import NestingBoxCategoryImage from '../images/nestingbox-category.png';
import { I18nKeys } from './I18nKeys';

export enum PricingColumns {
  Price = 'price',
  Region1 = 'region1',
  Region2 = 'region2',
  Region3 = 'region3',
  Region4 = 'region4',
  Region5 = 'region5',
  Region6 = 'region6',
  Region7 = 'region7',
  UpgradePrice = 'upgradePrice',
}
export const RegionPriceColumns: string[] = Object.values(PricingColumns).filter(
  (col) => ![PricingColumns.Price, PricingColumns.UpgradePrice].includes(col),
);
export const BasicPriceColumns: string[] = Object.values(PricingColumns).filter(
  (col) => ![PricingColumns.UpgradePrice].includes(col),
);

export enum PricingCalculationColumns {
  PriceCalculation = 'priceCalculation',
}

export enum DisplayColumns {
  Label = 'label',
  PriceExpression = 'priceExpression',
}

export enum HelperColumns {
  Key = 'key',
  VariesByRegion = 'variesByRegion',
}

// Use to associate columns with order to be displayed in item table and price calculation fields
export const componentColumnMap: {
  [pricingField: string]: { calculation?: PricingCalculationColumns; order: number };
} = {
  [DisplayColumns.Label]: { order: 1 },
  [PricingColumns.Price]: { calculation: PricingCalculationColumns.PriceCalculation, order: 2 },
  [PricingCalculationColumns.PriceCalculation]: { order: 3 },
  [PricingColumns.UpgradePrice]: { order: 4 },
  [DisplayColumns.PriceExpression]: { order: 5 },
  [HelperColumns.VariesByRegion]: { order: 6 },
  [PricingColumns.Region1]: { order: 7 },
  [PricingColumns.Region2]: { order: 8 },
  [PricingColumns.Region3]: { order: 9 },
  [PricingColumns.Region4]: { order: 10 },
  [PricingColumns.Region5]: { order: 11 },
  [PricingColumns.Region6]: { order: 12 },
  [PricingColumns.Region7]: { order: 13 },
};

export const COMPONENT_CATEGORY_MAPPING: {
  [key in ComponentCategoryKey]: {
    label: string;
    image?: string;
  };
} = {
  [ComponentCategoryKey.Door]: {
    label: 'Doors',
    image: DoorCategoryImage,
  },
  [ComponentCategoryKey.GarageDoor]: {
    label: 'Garage Doors',
    image: GarageCategoryImage,
  },
  [ComponentCategoryKey.Frameout]: {
    label: 'Frameouts',
    image: FrameoutCategoryImage,
  },
  [ComponentCategoryKey.Window]: {
    label: 'Windows',
    image: WindowCategoryImage,
  },
  [ComponentCategoryKey.WindowTransom]: {
    label: 'Windows (Transom)',
    image: TransomCategoryImage,
  },
  [ComponentCategoryKey.Dormer]: {
    label: 'Dormers',
    image: DormerCategoryImage,
  },
  [ComponentCategoryKey.Cupola]: {
    label: 'Cupolas',
    image: CupolaCategoryImage,
  },
  [ComponentCategoryKey.Shutter]: {
    label: 'Shutters',
    image: ShutterCategoryImage,
  },
  [ComponentCategoryKey.FlowerBox]: {
    label: 'Flower Boxes',
    image: FlowerBoxCategoryImage,
  },
  [ComponentCategoryKey.NestingBox]: {
    label: 'Nesting Boxes',
    image: NestingBoxCategoryImage,
  },
  [ComponentCategoryKey.Vent]: {
    label: 'Vents',
    image: VentCategoryImage,
  },
  [ComponentCategoryKey.Ramp]: {
    label: 'Ramps',
    image: RampCategoryImage,
  },
  [ComponentCategoryKey.Shelf]: {
    label: 'Shelves',
    image: ShelfCategoryImage,
  },
  [ComponentCategoryKey.Workbench]: {
    label: 'Workbenches',
    image: WorkbenchCategoryImage,
  },
  [ComponentCategoryKey.Flooring]: {
    label: 'Flooring',
    image: FlooringCategoryImage,
  },
  [ComponentCategoryKey.Siding]: {
    label: 'Siding',
    image: SidingCategoryImage,
  },
};

export const PRICING_TYPE_MAP: {
  [key in PricingType]?: I18nKeys;
} = {
  [PricingType.Amount]: I18nKeys.PricingComponentPricingTypeAmount,
};
