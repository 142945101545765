import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  FormControl,
  Typography,
  Grid,
  TextField,
  FormGroup,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { PricingCategories, SystemOrderCalculations, shedPricingCategories } from '../constants/PricingAdjustment';
import { AppState } from '../types/AppState';
import { addPricingCategory, removePricingCategory, updatePricingCalculation } from '../ducks/pricingAdjustment';
import { PricingSelectableChip } from './PricingSelectableChip';
import { isCarportView } from '../utils/clientIdUtils';
import { findMatchingSurchargeCalculation } from '../utils/pricingUtils';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles({
  root: {
    flex: 1,
    margin: '2%',
  },
  textField: {
    '& > *': {
      width: '35ch',
    },
  },
  spacing: {
    marginTop: '10px',
  },
});

interface OwnProps {
  clientId: string;
}
interface StateProps {
  pricingSelection: string;
  categories: string[];
  label: string;
  isTaxable: boolean;
  affectsDeposit: boolean;
}

interface DispatchProps {
  newPricingCategory(category: string): void;
  deletePricingCategory(category: string): void;
  updatePricingCalculationValues(values: any): void;
}

type Props = OwnProps & StateProps & DispatchProps;

const PricingCalculationComponent: React.FC<Props> = ({
  clientId,
  pricingSelection,
  categories,
  label,
  isTaxable,
  affectsDeposit,
  newPricingCategory,
  deletePricingCategory,
  updatePricingCalculationValues,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isCarportViewClient = isCarportView(clientId);
  const handleChipClicked = (category: string): void => {
    if (categories.includes(category)) {
      deletePricingCategory(category);
    } else {
      newPricingCategory(category);
    }
  };

  const handleRadioChange = (category: string): void => {
    if (!categories.includes(category)) {
      if (categories.length > 0) {
        categories.forEach((cat: string) => deletePricingCategory(cat));
      }
      newPricingCategory(category);
    }
  };

  const handleCalculationChange = (prop: string, value: any): void => {
    const values: { [key: string]: any } = {
      label,
      isTaxable,
      affectsDeposit,
    };
    values[prop] = value;
    updatePricingCalculationValues(values);
  };

  return (
    <>
      <FormControl component="fieldset">
        {isCarportViewClient ? (
          <>
            <Typography>{t(I18nKeys.PricingCalculationInclude)}</Typography>
            <Grid key="category-grid" container direction="row" alignItems="flex-start" spacing={1}>
              {PricingCategories.map((category) => (
                <Grid key={`${category.value}-grid`} item>
                  <PricingSelectableChip
                    chip={category}
                    selected={categories.includes(category.value)}
                    handleChipSelection={handleChipClicked}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <RadioGroup
            aria-label="pricing-calculations"
            name="pricingCalculations"
            value={categories[0]}
            onChange={(_, value): void => handleRadioChange(value)}
          >
            {shedPricingCategories.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" />}
                label={t(
                  pricingSelection === SystemOrderCalculations.Surcharge ? option.surchargeLabel : option.lineItemLabel,
                )}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
      {pricingSelection === SystemOrderCalculations.Surcharge ? (
        <Grid container className={classes.spacing} alignItems="center">
          <FormGroup row>
            <TextField
              className={classes.textField}
              variant="standard"
              label={t(I18nKeys.PricingCalculationLineItemDescription)}
              value={label}
              onChange={(e): void => handleCalculationChange('label', e.target.value)}
            />
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Checkbox
                  name="taxable"
                  color="primary"
                  checked={!!isTaxable}
                  onChange={(e): void => handleCalculationChange('isTaxable', e.currentTarget.checked)}
                />
              }
              label={t(I18nKeys.PricingCalculationTaxable)}
            />
            {isCarportViewClient && (
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Checkbox
                    name="affects-dealer-deposit"
                    color="primary"
                    checked={!!affectsDeposit}
                    onChange={(e): void => handleCalculationChange('affectsDeposit', e.currentTarget.checked)}
                  />
                }
                label={t(I18nKeys.PricingCalculationIncludeDealerDeposit)}
              />
            )}
          </FormGroup>
        </Grid>
      ) : (
        <Typography color="textSecondary" variant="body2">
          {isCarportViewClient
            ? t(I18nKeys.PricingCalculationCarportTaxation)
            : t(I18nKeys.PricingCalculationShedTaxation)}
        </Typography>
      )}
    </>
  );
};

const mapStateToProps = ({
  pricingAdjustment: { clientId = '', pricingSelection, draftSurchargeCalculations, supplierKey },
}: AppState): StateProps => {
  const draftSurchargeCalculation = findMatchingSurchargeCalculation(
    clientId,
    draftSurchargeCalculations,
    supplierKey,
    pricingSelection,
  );
  return {
    pricingSelection,
    categories: draftSurchargeCalculation.categories,
    label: draftSurchargeCalculation.label,
    isTaxable: draftSurchargeCalculation.isTaxable,
    affectsDeposit: draftSurchargeCalculation.affectsDeposit,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  newPricingCategory: (category: string): void => {
    dispatch(addPricingCategory(category));
  },
  deletePricingCategory: (category: string): void => {
    dispatch(removePricingCategory(category));
  },
  updatePricingCalculationValues: (values: any): void => {
    dispatch(updatePricingCalculation(values));
  },
});

export const PricingCalculation = connect(mapStateToProps, mapDispatchToProps)(PricingCalculationComponent);
