import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { Dialogs } from '../constants/Dialogs';
import {
  PricingAdjustmentConditionOption,
  SurchargeSystemOrderCalculation,
  PricingAdjustmentError,
} from '../types/PricingAdjustment';
import {
  noSurchargeSystemOrderCalculation,
  PricingAdjustmentStatus,
  SystemOrderCalculations,
  PricingCategoryValues,
  defaultSurchargeLabel,
  SystemOrderSupplierCalculations,
} from '../constants/PricingAdjustment';
import { openDialog } from '../ducks/dialogSlice';
import { openNotificationDialog } from '../ducks/notification';
import {
  fetchConditionsError,
  fetchConditionsSuccess,
  fetchInitialSurcharge as fetchInitialSurchargeFunc,
  fetchInitialSurchargeComplete,
  PricingAdjustmentActionTypes,
  setActiveSurcharge,
  setDraftSurcharge,
  setPricingSelection,
  addPricingAdjustmentErrors,
  openConfiguratorModal,
  clearPricingAdjustmentErrors,
  setPricingAdjustmentErrors,
  setActiveSurchargeCalculation,
  setDraftSurchargeCalculations,
  setSupplierSurchargeCalculation,
  setPricingSupplierSelection as setPricingSupplierSelectionFunc,
  publishSurchargeSuccess,
  deleteDraftSurchargeComplete,
} from '../ducks/pricingAdjustment';
import { AppState } from '../types/AppState';
import { PricingAdjustmentState } from '../types/PricingAdjustmentState';
import { User } from '../types/User';
import {
  mapPricingAdjustmentConditionOptions,
  hasSurchargeCalculation,
  findMatchingSurchargeCalculation,
  reconcileSurchargeCalculations,
  getIdFromSurcharges,
} from '../utils/pricingUtils';
import { config } from '../config/config';
import { conditionIsValid, getValidationErrorMessage, validateSurcharge } from '../utils/pricingValidation';
import { getVendorFromClientId } from '../utils/clientIdUtils';
import { extractErrorProps } from '../utils/errorUtils';
import { pricingAdjustmentApi } from '../services/pricingAdjustmentApi';

const defaultErrorMessage = 'Something went wrong. Please try again.';

const formatSurchargeCalculation = (
  surchargeCalculation: SurchargeSystemOrderCalculation,
  supplierKey: string | undefined,
): SurchargeSystemOrderCalculation => {
  let formattedSurchargeCalculation: SurchargeSystemOrderCalculation = {
    ...surchargeCalculation,
    label: hasSurchargeCalculation(surchargeCalculation) ? surchargeCalculation.label || defaultSurchargeLabel : '',
    isSubtotal: surchargeCalculation.categories.includes(PricingCategoryValues.Subtotal),
    supplierKey,
  };

  // If the surcharge order calculation is line item
  // Remove isTaxable and affectsDeposit as they are inherited by the line items
  // Set amountChange in calculation to 0 as this cannot be applied to line items
  if (surchargeCalculation.key === SystemOrderCalculations.LineItem) {
    formattedSurchargeCalculation = {
      ...formattedSurchargeCalculation,
      label: '',
      isTaxable: false,
      affectsDeposit: false,
      calculation: { ...formattedSurchargeCalculation.calculation, amountChange: 0 },
      rules: [
        ...formattedSurchargeCalculation.rules.map((rule) => ({
          ...rule,
          calculation: { ...rule.calculation, amountChange: 0 },
        })),
      ],
    };
  }

  return formattedSurchargeCalculation;
};

function* fetchConditions({ payload: { clientId } }: any): Generator {
  try {
    if (!clientId) {
      yield put(fetchConditionsSuccess([]));
    } else {
      const getConditionsFetch = (yield put(
        pricingAdjustmentApi.endpoints.getConditions.initiate({
          clientId,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      getConditionsFetch.unsubscribe();

      const { data: conditions } = (yield getConditionsFetch) as {
        data: PricingAdjustmentConditionOption[];
      };

      yield put(fetchConditionsSuccess(mapPricingAdjustmentConditionOptions(conditions)));
    }
  } catch (error) {
    yield put(fetchConditionsError());
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* fetchActiveSurcharge(clientId: string, supplierKey: string | undefined): Generator {
  try {
    if (!clientId) {
      yield put(setActiveSurcharge(undefined));
    }

    const getSurchargeFetch = (yield put(
      pricingAdjustmentApi.endpoints.getSurchargeByStatus.initiate({
        clientId,
        status: PricingAdjustmentStatus.Active,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getSurchargeFetch.unsubscribe();

    const { data: activeSurcharges = [] } = (yield getSurchargeFetch) as { data: SurchargeSystemOrderCalculation[] };

    // If supplier key is provided, find the surcharge associated to that supplier key
    // If supplier key is not provided, find the surcharge with the vendor key or no supplier key
    const vendor = getVendorFromClientId(clientId);
    const activeSurcharge = activeSurcharges.find(
      (surcharge) => (surcharge.supplierKey || vendor) === (supplierKey || vendor),
    );

    yield put(setActiveSurcharge(activeSurcharges));
    yield put(setActiveSurchargeCalculation(activeSurcharge));
  } catch (error) {
    yield put(setActiveSurcharge(undefined));
    yield put(setActiveSurchargeCalculation(undefined));
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* fetchDraftSurcharge(clientId: string): Generator {
  try {
    if (!clientId) {
      yield put(setDraftSurcharge(undefined));
    }

    const getSurchargeFetch = (yield put(
      pricingAdjustmentApi.endpoints.getSurchargeByStatus.initiate({
        clientId,
        status: PricingAdjustmentStatus.Draft,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getSurchargeFetch.unsubscribe();

    const { data: draftSurcharges } = (yield getSurchargeFetch) as {
      data: SurchargeSystemOrderCalculation[];
    };

    yield put(setDraftSurcharge(draftSurcharges));
  } catch (error) {
    yield put(setDraftSurcharge(undefined));
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* fetchSupplierSurcharge(clientId: string, supplierKey: string | undefined): Generator {
  try {
    if (!clientId) {
      yield put(setSupplierSurchargeCalculation(undefined));
    }
    const supplierClientId = `${clientId.split('-').shift()}-${supplierKey}`;

    const getSurchargeFetch = (yield put(
      pricingAdjustmentApi.endpoints.getSurchargeByStatus.initiate({
        clientId: supplierClientId,
        status: PricingAdjustmentStatus.Active,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getSurchargeFetch.unsubscribe();

    const { data: supplierSurcharges = [] } = (yield getSurchargeFetch) as {
      data: SurchargeSystemOrderCalculation[];
    };

    const supplierSurchargeCalculation = supplierSurcharges.length ? supplierSurcharges[0] : undefined;
    yield put(setSupplierSurchargeCalculation(supplierSurchargeCalculation));

    const { activeSurchargeCalculation } = (yield select(
      ({ pricingAdjustment }: AppState) => pricingAdjustment,
    )) as PricingAdjustmentState;
    // If the client does not have an existing surcharge, inherit the suppliers surcharge
    if (!activeSurchargeCalculation) {
      yield put(setPricingSupplierSelectionFunc(SystemOrderSupplierCalculations.Inherit));
    } else {
      yield put(
        setPricingSupplierSelectionFunc(
          activeSurchargeCalculation.supplierSurcharge || SystemOrderSupplierCalculations.Off,
        ),
      );
    }
  } catch (error) {
    yield put(setSupplierSurchargeCalculation(undefined));
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* fetchInitialSurcharge({ payload: { clientId, supplierKey } }: any): Generator {
  try {
    // Fetch active and draft surcharges, if any
    yield all([call(fetchDraftSurcharge, clientId), call(fetchActiveSurcharge, clientId, supplierKey)]);

    // Load supplier surcharge if supplierKey exists
    if (supplierKey && supplierKey !== getVendorFromClientId(clientId)) {
      yield call(fetchSupplierSurcharge, clientId, supplierKey);
    } else {
      yield put(setPricingSupplierSelectionFunc(SystemOrderSupplierCalculations.Off));
    }

    // Draft surcharge calculations should start with active surcharge
    const { activeSurchargeCalculation, draftSurcharge, activeSurcharge } = (yield select(
      ({ pricingAdjustment }: AppState) => pricingAdjustment,
    )) as PricingAdjustmentState;
    const draftId = getIdFromSurcharges(draftSurcharge);
    let draftSurchargeCalculations: SurchargeSystemOrderCalculation[] = (activeSurcharge || []).map((calc) => ({
      ...calc,
      status: PricingAdjustmentStatus.Draft,
      id: draftId,
    }));
    const draftSurchargeCalculation = activeSurchargeCalculation
      ? {
          ...activeSurchargeCalculation,
          supplierKey,
          status: PricingAdjustmentStatus.Draft,
          id: draftId,
        }
      : undefined;
    draftSurchargeCalculations = draftSurchargeCalculation
      ? reconcileSurchargeCalculations(clientId, draftSurchargeCalculations, draftSurchargeCalculation, false, false)
      : draftSurchargeCalculations;

    yield put(setDraftSurchargeCalculations(draftSurchargeCalculations));
    if (activeSurchargeCalculation) {
      yield put(setPricingSelection(activeSurchargeCalculation.key));
    } else {
      yield put(setPricingSelection(SystemOrderCalculations.None));
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  } finally {
    yield put(fetchInitialSurchargeComplete());
  }
}

function* updatePricingSupplierSelection({ payload: { pricingSupplierSelection } }: any): Generator {
  try {
    const {
      clientId = '',
      supplierSurchargeCalculation = noSurchargeSystemOrderCalculation,
      activeSurchargeCalculation,
      draftSurchargeCalculations,
      supplierKey,
    } = (yield select(({ pricingAdjustment }: AppState) => pricingAdjustment)) as PricingAdjustmentState;

    let pricingSelection = SystemOrderCalculations.None;
    let newDraftCalculation = noSurchargeSystemOrderCalculation;
    let newDraftCalculations = draftSurchargeCalculations;
    if (pricingSupplierSelection === SystemOrderSupplierCalculations.Inherit && supplierSurchargeCalculation) {
      // Inherit supplier calculation, replace selected draft with supplier calculation
      pricingSelection = supplierSurchargeCalculation.key;
      newDraftCalculation = supplierSurchargeCalculation;
    }
    if (
      pricingSupplierSelection === SystemOrderSupplierCalculations.Off &&
      activeSurchargeCalculation &&
      activeSurchargeCalculation.supplierSurcharge !== SystemOrderSupplierCalculations.Inherit
    ) {
      // Do not inherit supplier calculation and active calculation that is not supplier's exists
      pricingSelection = activeSurchargeCalculation.key;
      newDraftCalculation = activeSurchargeCalculation;
      if (supplierSurchargeCalculation.key !== newDraftCalculation.key) {
        // Reconcile to update this selection in addition to supplier's selection
        newDraftCalculations = reconcileSurchargeCalculations(
          clientId,
          newDraftCalculations,
          {
            ...newDraftCalculation,
            supplierKey,
            supplierSurcharge: pricingSupplierSelection,
            key: activeSurchargeCalculation.key,
          },
          false,
          true,
        );
      }
    }
    yield put(setPricingSelection(pricingSelection || SystemOrderCalculations.None));
    yield put(setPricingSupplierSelectionFunc(pricingSupplierSelection || SystemOrderSupplierCalculations.Off));
    yield put(
      setDraftSurchargeCalculations(
        reconcileSurchargeCalculations(
          clientId,
          newDraftCalculations,
          {
            ...newDraftCalculation,
            supplierKey,
            supplierSurcharge: pricingSupplierSelection,
            key: supplierSurchargeCalculation.key,
          },
          false,
          true,
        ),
      ),
    );
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* updateSurchargeRuleCondition({ payload: { id, type, ruleCondition } }: any): Generator {
  try {
    const { errors } = (yield select(({ pricingAdjustment }: AppState) => pricingAdjustment)) as PricingAdjustmentState;

    // Check if an error for this surcharge rule condition exists
    const surchargeRuleConditionError = errors.find((error) => error.ruleId === id && error.type === type);
    if (surchargeRuleConditionError) {
      // Error exists for this surcharge rule condition
      const { conditionOptions } = (yield select(
        ({ pricingAdjustment }: AppState) => pricingAdjustment,
      )) as PricingAdjustmentState;
      const pricingAdjustmentCondition = Array.isArray(ruleCondition) ? { keys: ruleCondition } : ruleCondition;

      // Check to see if the condition is valid
      if (conditionIsValid({ type, ...pricingAdjustmentCondition }, conditionOptions)) {
        // Remove the error now that the condition is valid
        yield put(setPricingAdjustmentErrors(errors.filter((error) => error.ruleId === id && error.type !== type)));
      }
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* saveSurcharge({ payload: { clientId } }: any): Generator {
  try {
    // Clear any validation errors
    yield put(clearPricingAdjustmentErrors());

    const {
      draftSurchargeCalculations,
      draftSurcharge,
      activeSurcharge,
      supplierKey,
      pricingSelection,
      pricingSupplierSelection,
      conditionOptions,
    } = (yield select(({ pricingAdjustment }: AppState) => pricingAdjustment)) as PricingAdjustmentState;
    // Find the draft calculation that matches the current pricing selection and supplier key
    const draftSurchargeCalculation = findMatchingSurchargeCalculation(
      clientId,
      draftSurchargeCalculations,
      supplierKey,
      pricingSelection,
    );

    // Validate the draft calculation being saved before trying to save
    const errors: PricingAdjustmentError[] = [];
    if (draftSurchargeCalculation) {
      // Validate the surcharge being saved before trying to save
      errors.push(...validateSurcharge(draftSurchargeCalculation, conditionOptions));
    } else {
      throw new Error();
    }

    if (errors.length > 0) {
      // There are validation errors, don't save, open notification dialog with error messages
      const notificationTitle = '';
      const notificationContent = getValidationErrorMessage(errors, conditionOptions);

      yield put(addPricingAdjustmentErrors(errors));
      yield put(openNotificationDialog(notificationTitle, notificationContent));
      yield put(openDialog({ dialog: Dialogs.Notification }));
    } else {
      const groupId = (yield select(({ currentUser: { group } }: AppState) => group?.groupId)) as string;
      const { email } = (yield select(({ currentUser: { user } }: AppState) => user)) as User;
      const draftSurchargeId = getIdFromSurcharges(draftSurcharge);
      const newDraftSurchargeCalculation = {
        ...formatSurchargeCalculation(draftSurchargeCalculation, supplierKey),
        id: draftSurchargeId,
        supplierSurcharge: pricingSupplierSelection,
      };
      // The saved draft will be the current active surcharges with the new draft calculation for the current supplier key
      // This allows the active surcharges for other supplier keys to be displayed when previewing
      const newDraftSurchargeCalculations: SurchargeSystemOrderCalculation[] = (activeSurcharge || []).map((calc) => ({
        ...calc,
        status: PricingAdjustmentStatus.Draft,
        id: draftSurchargeId,
      }));
      const newDraftSurcharge = reconcileSurchargeCalculations(
        clientId,
        newDraftSurchargeCalculations,
        newDraftSurchargeCalculation,
        false,
        false,
      );
      const draftExists = draftSurchargeId !== undefined;
      if (!draftExists) {
        // If a draft surcharge does not exist, create a draft
        const addSurchargeFetch = (yield put(
          pricingAdjustmentApi.endpoints.addSurcharge.initiate({
            groupId,
            clientId,
            surcharge: {
              calculation: newDraftSurcharge,
              status: PricingAdjustmentStatus.Draft,
              createdBy: email,
            },
          }) as any,
        )) as QueryActionCreatorResult<any>;
        addSurchargeFetch.unsubscribe();

        yield addSurchargeFetch;
      } else {
        // If a draft surcharge exists, update the existing draft
        const saveSurchargeFetch = (yield put(
          pricingAdjustmentApi.endpoints.saveSurcharge.initiate({
            groupId,
            clientId,
            key: `${draftSurchargeId}`,
            surcharge: {
              calculation: newDraftSurcharge,
              status: PricingAdjustmentStatus.Draft,
              updatedBy: email,
            },
          }) as any,
        )) as QueryActionCreatorResult<any>;
        saveSurchargeFetch.unsubscribe();

        yield saveSurchargeFetch;
      }

      // Update draft calculations to saved draft surcharge
      yield fetchDraftSurcharge(clientId);
      const { draftSurcharge: savedDraftSurcharge = [] } = (yield select(
        ({ pricingAdjustment }: AppState) => pricingAdjustment,
      )) as PricingAdjustmentState;
      const savedDraftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        savedDraftSurcharge,
        supplierKey,
        pricingSelection,
      );
      const updatedDraftSurchargeCalculations = reconcileSurchargeCalculations(
        clientId,
        draftSurchargeCalculations,
        savedDraftSurchargeCalculation,
        false,
        true,
      );
      yield put(setDraftSurchargeCalculations(updatedDraftSurchargeCalculations));
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

/** Deletes all pricing selection draft calculations and removes draft surcharge for current supplier key.
 * Draft surcharge calculation is updated to active surcharge calculation. */
function* revertSurcharge({ payload: { clientId } }: any): Generator {
  try {
    const { username } = (yield select(({ currentUser: { user } }: AppState) => user)) as User;
    const groupId = (yield select(({ currentUser: { group } }: AppState) => group?.groupId)) as string;
    const { activeSurchargeCalculation, draftSurcharge, supplierKey, pricingSelection } = (yield select(
      ({ pricingAdjustment }: AppState) => pricingAdjustment,
    )) as PricingAdjustmentState;

    let draftSurchargeId = getIdFromSurcharges(draftSurcharge);
    if (draftSurchargeId && draftSurcharge) {
      // Delete the draft surcharge for the current supplier key
      const draftSurchargeCalculation = findMatchingSurchargeCalculation(
        clientId,
        draftSurcharge,
        supplierKey,
        pricingSelection,
      );
      const newDraftSurcharge = reconcileSurchargeCalculations(
        clientId,
        draftSurcharge,
        draftSurchargeCalculation,
        true,
      );
      const status = newDraftSurcharge.length > 0 ? PricingAdjustmentStatus.Draft : PricingAdjustmentStatus.Deleted;
      const saveSurchargeFetch = (yield put(
        pricingAdjustmentApi.endpoints.saveSurcharge.initiate({
          groupId,
          clientId,
          key: `${draftSurchargeId}`,
          surcharge: {
            calculation: newDraftSurcharge,
            status,
            createdBy: username,
          },
        }) as any,
      )) as QueryActionCreatorResult<any>;
      saveSurchargeFetch.unsubscribe();

      yield saveSurchargeFetch;
      yield fetchDraftSurcharge(clientId);
      // Draft id no longer exists, so don't add to new draft calculation
      if (status === PricingAdjustmentStatus.Deleted) draftSurchargeId = undefined;
    }
    const newDraftSurchargeCalculation = {
      ...(activeSurchargeCalculation || noSurchargeSystemOrderCalculation),
      id: draftSurchargeId,
      supplierKey,
    };
    // Set draft calculations to only include either active calculation
    yield put(setDraftSurchargeCalculations([newDraftSurchargeCalculation]));
    yield put(setPricingSelection(newDraftSurchargeCalculation.key));
    yield put(
      setPricingSupplierSelectionFunc(
        newDraftSurchargeCalculation.supplierSurcharge || SystemOrderSupplierCalculations.Off,
      ),
    );
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* publishSurcharge({ payload: { clientId } }: any): Generator {
  try {
    const { username } = (yield select(({ currentUser: { user } }: AppState) => user)) as User;
    const groupId = (yield select(({ currentUser: { group } }: AppState) => group?.groupId)) as string;
    const {
      draftSurchargeCalculations,
      draftSurcharge,
      activeSurcharge,
      supplierKey,
      pricingSelection,
      pricingSupplierSelection,
    } = (yield select(({ pricingAdjustment }: AppState) => pricingAdjustment)) as PricingAdjustmentState;
    const draftSurchargeCalculation = findMatchingSurchargeCalculation(
      clientId,
      draftSurchargeCalculations,
      supplierKey,
      pricingSelection,
    );

    if (draftSurchargeCalculation) {
      let notificationTitle = '';
      let notificationContent = '';
      const activeSurchargeId = getIdFromSurcharges(activeSurcharge);
      const newActiveSurchargeCalculation = {
        ...formatSurchargeCalculation(draftSurchargeCalculation, supplierKey),
        id: activeSurchargeId,
        supplierSurcharge: pricingSupplierSelection,
      };
      // Update the newActiveSurchargeCalculation in the activeSurcharge
      const newActiveSurcharge = reconcileSurchargeCalculations(
        clientId,
        activeSurcharge,
        newActiveSurchargeCalculation,
        false,
        false,
      );
      const activeExists = activeSurchargeId !== undefined;
      if (!activeExists) {
        // If an active surcharge does not exist, create a new one
        const addSurchargeFetch = (yield put(
          pricingAdjustmentApi.endpoints.addSurcharge.initiate({
            groupId,
            clientId,
            surcharge: {
              calculation: newActiveSurcharge,
              status: PricingAdjustmentStatus.Active,
              updatedBy: username,
            },
          }) as any,
        )) as QueryActionCreatorResult<any>;
        addSurchargeFetch.unsubscribe();

        yield addSurchargeFetch;
      } else {
        // If an active surcharge exists, update the existing one
        const saveSurchargeFetch = (yield put(
          pricingAdjustmentApi.endpoints.saveSurcharge.initiate({
            groupId,
            clientId,
            key: `${activeSurchargeId}`,
            surcharge: {
              calculation: newActiveSurcharge,
              status: PricingAdjustmentStatus.Active,
              updatedBy: username,
            },
          }) as any,
        )) as QueryActionCreatorResult<any>;
        saveSurchargeFetch.unsubscribe();

        yield saveSurchargeFetch;
      }

      // Remove all draft calculations for the current supplier key
      const newDraftSurcharge = reconcileSurchargeCalculations(
        clientId,
        draftSurcharge,
        draftSurchargeCalculation,
        true,
      );
      const draftSurchargeId = getIdFromSurcharges(draftSurcharge);
      const draftExists = draftSurchargeId !== undefined;
      if (draftExists) {
        // If a draft surcharge exists, update if there are calculations, otherwise delete
        const saveSurchargeFetch = (yield put(
          pricingAdjustmentApi.endpoints.saveSurcharge.initiate({
            groupId,
            clientId,
            key: `${draftSurchargeId}`,
            surcharge: {
              calculation: newDraftSurcharge,
              status: newDraftSurcharge.length > 0 ? PricingAdjustmentStatus.Draft : PricingAdjustmentStatus.Deleted,
              createdBy: username,
            },
          }) as any,
        )) as QueryActionCreatorResult<any>;
        saveSurchargeFetch.unsubscribe();

        yield saveSurchargeFetch;
      }
      yield put(fetchInitialSurchargeFunc(clientId, supplierKey));

      notificationTitle = 'Pricing Changes Published';
      notificationContent = `Changes have been published to ${config.environment.STAGE}.`;
      yield put(openNotificationDialog(notificationTitle, notificationContent));
      yield put(openDialog({ dialog: Dialogs.Notification }));

      yield put(publishSurchargeSuccess(newActiveSurchargeCalculation));
    } else {
      yield call(toast.error, 'There are no draft surcharges to publish. Please save draft and try again.');
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

/**
 * Delete draft surcharges for the clientId
 * @param clientId
 */
function* deleteDraftSurcharge({ payload: { clientId } }: any): Generator {
  try {
    const groupId = (yield select(({ currentUser: { group } }: AppState) => group?.groupId)) as string;
    const getSurchargeFetch = (yield put(
      pricingAdjustmentApi.endpoints.getSurchargeByStatus.initiate({
        clientId,
        status: PricingAdjustmentStatus.Draft,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getSurchargeFetch.unsubscribe();

    const { data: draftSurcharges } = (yield getSurchargeFetch) as {
      data: SurchargeSystemOrderCalculation[];
    };

    const draftSurchargeId = getIdFromSurcharges(draftSurcharges);
    if (draftSurchargeId) {
      const saveSurchargeFetch = (yield put(
        pricingAdjustmentApi.endpoints.saveSurcharge.initiate({
          groupId,
          clientId,
          key: `${draftSurchargeId}`,
          surcharge: {
            calculation: [],
            status: PricingAdjustmentStatus.Deleted,
            createdBy: '',
          },
        }) as any,
      )) as QueryActionCreatorResult<any>;
      saveSurchargeFetch.unsubscribe();

      yield saveSurchargeFetch;
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
  yield put(deleteDraftSurchargeComplete());
}

function* attemptToOpenConfiguratorModal(): Generator {
  try {
    const { errors } = (yield select(({ pricingAdjustment }: AppState) => pricingAdjustment)) as PricingAdjustmentState;
    if (errors.length === 0) {
      yield put(openConfiguratorModal());
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* watchUpdatePricingSupplierSelection(): Generator {
  yield takeLatest(PricingAdjustmentActionTypes.UPDATE_PRICING_SUPPLIER_SELECTION, updatePricingSupplierSelection);
}

function* watchFetchConditions(): Generator {
  yield takeLatest(PricingAdjustmentActionTypes.FETCH_CONDITIONS, fetchConditions);
}

function* watchFetchInitialSurcharge(): Generator {
  yield takeLatest(PricingAdjustmentActionTypes.FETCH_INITIAL_SURCHARGE, fetchInitialSurcharge);
}

function* watchUpdateSurchargeRuleCondition(): Generator {
  yield takeLatest(PricingAdjustmentActionTypes.UPDATE_SURCHARGE_RULE_CONDITION, updateSurchargeRuleCondition);
}

function* watchSaveSurcharge(): Generator {
  yield takeLatest(PricingAdjustmentActionTypes.SAVE_SURCHARGE, saveSurcharge);
}

function* watchRevertSurcharge(): Generator {
  yield takeLatest(PricingAdjustmentActionTypes.REVERT_SURCHARGE, revertSurcharge);
}

function* watchPublishSurcharge(): Generator {
  yield takeLatest(PricingAdjustmentActionTypes.PUBLISH_SURCHARGE, publishSurcharge);
}

function* watchDeleteDraftSurcharge(): Generator {
  yield takeLatest(PricingAdjustmentActionTypes.DELETE_DRAFT_SURCHARGE, deleteDraftSurcharge);
}

function* watchAttemptToOpenConfiguratorModal(): Generator {
  yield takeLatest(PricingAdjustmentActionTypes.ATTEMPT_TO_OPEN_CONFIGURATOR_MODAL, attemptToOpenConfiguratorModal);
}

export function* pricingAdjustmentSaga(): Generator {
  yield all([
    watchUpdatePricingSupplierSelection(),
    watchFetchConditions(),
    watchFetchInitialSurcharge(),
    watchUpdateSurchargeRuleCondition(),
    watchSaveSurcharge(),
    watchRevertSurcharge(),
    watchPublishSurcharge(),
    watchAttemptToOpenConfiguratorModal(),
    watchDeleteDraftSurcharge(),
  ]);
}
