import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { A, setLinkProps } from 'hookrouter';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AppRoutes } from '../constants/AppRoutes';
import { AuthStatus } from '../constants/AuthStatus';
import { ForgotPasswordFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { AppState } from '../types/AppState';
import { required } from '../utils/reduxFormUtils';
import { LoadingButton } from './LoadingButton';
import { InputField } from './redux-form/InputField';
import { i18n } from '../i18n';
import { IdeaRoomLogo } from './IdeaRoomLogo';
import { setCurrentUserAuthStatus } from '../ducks/currentUserSlice';

const useStyles = makeStyles((theme: Theme) => ({
  signInMessage: {
    float: 'right',
    marginBottom: '10px',
    fontSize: '0.85rem',
  },
  link: {
    color: '#1b69b6',
    textDecoration: 'none',
  },
  error: {
    color: 'red',
    marginTop: '10px',
    marginBottom: '20px',
  },
  image: {
    width: '100px',
    marginBottom: '20px',
  },
  paper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    padding: theme.spacing(3, 3),
  },
  field: {
    marginBottom: '10px',
    width: '100%',
  },
  button: {
    textTransform: 'none',
    width: '100%',
  },
}));

interface FormData {
  [ForgotPasswordFormFields.Email]: string;
  [ForgotPasswordFormFields.VerificationCode]: string;
  [ForgotPasswordFormFields.NewPassword]: string;
  [ForgotPasswordFormFields.ConfirmNewPassword]: string;
}

interface StateProps {
  authStatus: AuthStatus;
}

interface DispatchProps {
  resendVerificationCode(): void;
  onSubmit(data: FormData): Promise<any>;
}

type Props = StateProps & DispatchProps & InjectedFormProps<FormData>;

const ForgotPasswordComponent: React.FC<Props> = ({
  authStatus,

  error,
  handleSubmit,
  resendVerificationCode,
  onSubmit,
  submitting,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownNewPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Paper className={classes.paper}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <IdeaRoomLogo className={classes.image} />

        {authStatus !== AuthStatus.ForgotPasswordCodeSent && authStatus !== AuthStatus.PasswordReset && (
          <>
            <Typography paragraph>{t(I18nKeys.ForgotPasswordMessage)}</Typography>

            <Field
              autoComplete="username"
              validate={required}
              className={classes.field}
              name={ForgotPasswordFormFields.Email}
              component={InputField}
              label={`${t(I18nKeys.FieldEmail)}*`}
            />

            <Typography className={classes.signInMessage}>
              {t(I18nKeys.SignInAlreadyHaveLoginMessage)}
              <A className={classes.link} href={AppRoutes.SignIn}>
                {t(I18nKeys.SignInButton)}
              </A>
            </Typography>

            {!!error && <Typography className={classes.error}>{error}</Typography>}

            <LoadingButton
              className={classes.button}
              variant="contained"
              size="medium"
              color="primary"
              type="submit"
              loading={submitting}
              disabled={submitting}
            >
              {t(I18nKeys.ForgotPasswordSendVerificationCodeButton)}
            </LoadingButton>
          </>
        )}

        {authStatus === AuthStatus.ForgotPasswordCodeSent && (
          <>
            <Typography paragraph>{t(I18nKeys.ForgotPasswordResetMessage)}</Typography>

            <Field
              validate={required}
              className={classes.field}
              name={ForgotPasswordFormFields.VerificationCode}
              component={InputField}
              label={`${t(I18nKeys.ForgotPasswordVerificationCodeInput)}*`}
            />

            <Field
              autoComplete="new-password"
              type={showNewPassword ? 'text' : 'password'}
              validate={required}
              className={classes.field}
              name={ForgotPasswordFormFields.NewPassword}
              component={InputField}
              label={`${t(I18nKeys.ForgotPasswordNewPasswordInput)}*`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownNewPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Field
              autoComplete="new-password"
              type={showConfirmPassword ? 'text' : 'password'}
              validate={required}
              className={classes.field}
              name={ForgotPasswordFormFields.ConfirmNewPassword}
              component={InputField}
              label={`${t(I18nKeys.ForgotPasswordConfirmNewPasswordInput)}*`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {!!error && <Typography className={classes.error}>{error}</Typography>}

            <LoadingButton
              className={classes.button}
              variant="contained"
              size="medium"
              color="primary"
              type="submit"
              loading={submitting}
              disabled={submitting}
            >
              {t(I18nKeys.ForgotPasswordResetPasswordButton)}
            </LoadingButton>

            <A className={classes.link} onClick={resendVerificationCode} href="">
              <Typography>{t(I18nKeys.ForgotPasswordResendVerificationLink)}</Typography>
            </A>
          </>
        )}

        {authStatus === AuthStatus.PasswordReset && (
          <>
            <Typography paragraph>{t(I18nKeys.ForgotPasswordResetSuccessMessage)}</Typography>

            <Button
              {...setLinkProps({ href: AppRoutes.SignIn })} // eslint-disable-line react/jsx-props-no-spreading
              className={classes.button}
              variant="contained"
              size="medium"
              color="primary"
              disabled={submitting}
            >
              <Typography>{t(I18nKeys.ForgotPasswordReturnToSignInButton)}</Typography>
            </Button>
          </>
        )}
      </Form>
    </Paper>
  );
};

const mapStateToProps = ({ currentUser: { authStatus } }: AppState): StateProps => ({ authStatus });

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  resendVerificationCode: (): AnyAction => dispatch(setCurrentUserAuthStatus(AuthStatus.SignedOut)),
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise((resolve, reject): void => {
      dispatch({
        type: `${Forms.ForgotPassword}_SUBMIT`,
        values,
        resolve,
        reject,
      });
    }),
});

const validate = ({
  [ForgotPasswordFormFields.NewPassword]: password,
  [ForgotPasswordFormFields.ConfirmNewPassword]: confirmPassword,
}: FormData): any => {
  if (password && confirmPassword && password !== confirmPassword) {
    return {
      [ForgotPasswordFormFields.ConfirmNewPassword]: `${i18n.t(I18nKeys.ForgotPasswordConfirmNewPasswordErrorMessage)}`,
    };
  }

  return {};
};

export const ForgotPassword = reduxForm<FormData>({ form: Forms.ForgotPassword, validate })(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordComponent),
);
