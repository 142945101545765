import lodash from 'lodash';
import { compareSupplierSurcharge, hasSurchargeCalculation } from './pricingUtils';
import { SurchargeSystemOrderCalculation } from '../types/PricingAdjustment';
import { VendorData as VendorDataType } from '../types/VendorData';

export enum DraftComparisonType {
  Surcharge = 'SURCHARGE',
  VendorData = 'VENDOR_DATA',
}

/**
 * Returns a copy of the object with all undefined and null values removed.
 * @param object object to remove null and undefined values from
 * @returns cleaned object
 */
export const removeMissingProps = (object: any): any => {
  if (lodash.isArray(object)) return object.map((element) => removeMissingProps(element));
  if (!lodash.isPlainObject(object) || Object.keys(object).length === 0) return object;
  const cleanObject = lodash.omitBy({ ...object }, lodash.isNil);
  // For each key in cleanObject, the key should equal the result of removeMissingProps on the value
  return lodash.mapValues(cleanObject, (value) => removeMissingProps(value));
};

/**
 * Compares two objects either by ignoring a list of properies with ignoreProps set to true
 * or only comparing the specified props if false. To deep compare without ignoring/limiting
 * any props, have props as an empty array and set ignoreProps to true (ignoring no props).
 * Returns boolean representing equality.
 *
 * @param a first object to compare
 * @param b second object to compare
 * @param props properties to ignore or compare
 * @param ignoreProps true if props should be ignored, otherwise only compares props if false
 */
const compareEquality = (a: any, b: any, props: string[] = [], ignoreProps = false): boolean => {
  const aCopy = removeMissingProps(a) || {};
  const bCopy = removeMissingProps(b) || {};
  if (ignoreProps) {
    // Remove properties to ignore before deep equal comparison
    props.forEach((prop) => {
      if (Object.prototype.hasOwnProperty.call(aCopy, prop)) delete aCopy[prop];
      if (Object.prototype.hasOwnProperty.call(bCopy, prop)) delete bCopy[prop];
    });
    return lodash.isEqual(aCopy, bCopy);
  }
  // Only compare specified properties
  const results: boolean[] = [];
  props.forEach((prop) => {
    if (Object.prototype.hasOwnProperty.call(aCopy, prop) && Object.prototype.hasOwnProperty.call(bCopy, prop))
      results.push(lodash.isEqual(aCopy[prop], bCopy[prop]));
  });
  return results.reduce((prev, curr) => prev && curr, true);
};

// These are properties that will be ignored in compareDraftEquality's main comparison
const propsToIgnore = {
  [DraftComparisonType.Surcharge]: ['id', 'status', 'supplierKey', 'supplierSurcharge', 'clientId'],
  [DraftComparisonType.VendorData]: ['id', 'status', 'clientId'],
};

// These are any additional comparision functions that are needed for a draft and active to
// be equal
const additionalComparisons = {
  [DraftComparisonType.Surcharge]: [compareSupplierSurcharge],
  [DraftComparisonType.VendorData]: [],
};

// These are functions that are used to determine if both the draft and active are empty and equal
const areDraftAndActiveEmpty = {
  [DraftComparisonType.Surcharge]: (
    draft: SurchargeSystemOrderCalculation,
    active: SurchargeSystemOrderCalculation,
  ): boolean =>
    !hasSurchargeCalculation(draft) && !hasSurchargeCalculation(active) && compareEquality(draft, active, ['label']),
  [DraftComparisonType.VendorData]: (draft: VendorDataType, active: VendorDataType): boolean =>
    (!draft || !draft.vendor) && (!active || !active.vendor),
};

/**
 * Compare draft and active items to see if a change has been made to the draft
 *
 * @param draft draft item to compare
 * @param active active item to compare
 * @param draftComparisonType category of comparision
 * @returns true or false based on whether the items match or a change has been made
 */
export const compareDraftEquality = (draft: any, active: any, draftComparisonType: DraftComparisonType): boolean => {
  // Run any added comparisons needed to evaluate draft and active equality
  const comparisonResults: boolean[] = [];
  additionalComparisons[draftComparisonType].forEach((comparison) => comparisonResults.push(comparison(draft, active)));
  const passedAdditionalComparisons = comparisonResults.reduce((prev, curr) => prev && curr, true);

  // See if both the draft and the active are empty and equal
  // This is separate from the main comparison, since most props don't need to be compared
  if (areDraftAndActiveEmpty[draftComparisonType](draft, active)) return passedAdditionalComparisons;

  return compareEquality(draft, active, propsToIgnore[draftComparisonType], true) && passedAdditionalComparisons;
};
