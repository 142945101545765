import { Button, Paper, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { A } from 'hookrouter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppRoutes } from '../constants/AppRoutes';
import { I18nKeys } from '../constants/I18nKeys';
import { AppState } from '../types/AppState';
import { IdeaRoomLogo } from './IdeaRoomLogo';
import { oauthSignIn, setCurrentUserAuthProvider } from '../ducks/currentUserSlice';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    padding: theme.spacing(3, 3),
  },
  image: {
    width: '100px',
    marginBottom: '20px',
    marginLeft: 'auto',
  },
  error: {
    color: 'red',
    marginTop: '30px',
    marginBottom: '20px',
  },
  field: {
    marginBottom: '10px',
    width: '100%',
  },
  button: {
    textTransform: 'none',
    width: '100%',
  },
  link: {
    color: '#1b69b6',
    display: 'block',
    textDecoration: 'none',
    marginTop: '10px',
  },
}));

interface DispatchProps {
  setProvider(provider: string | undefined): void;
  onSubmit(): void;
}

interface StateProps {
  urlProvider: string | undefined;
  authProvider: string | undefined;
  errors: any;
}

type Props = StateProps & DispatchProps;

const SingleSignOnComponent: React.FC<Props> = ({
  urlProvider,
  authProvider,
  errors,
  setProvider,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  React.useEffect(() => {
    setProvider(urlProvider || authProvider);
  }, [urlProvider, authProvider, setProvider]);

  return (
    <Paper className={classes.paper}>
      <IdeaRoomLogo className={classes.image} />

      {!urlProvider && (
        <>
          <Typography paragraph>{t(I18nKeys.SingleSignOnOrganizationCodeTitle)}</Typography>
          <TextField
            className={classes.field}
            variant="standard"
            placeholder={`${t(I18nKeys.SingleSignOnOrganizationCodePlaceholder)}*`}
            value={authProvider || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setProvider(event.target.value)}
            error={!!errors.provider}
            helperText={errors.provider && errors.provider}
            onKeyPress={(e): void => {
              if (e.key === 'Enter') {
                onSubmit();
              }
            }}
          />
        </>
      )}

      <Button
        className={classes.button}
        variant="contained"
        size="medium"
        color="primary"
        onClick={(): void => onSubmit()}
      >
        {t(I18nKeys.SingleSignOnSubmitButton)}
      </Button>

      <A className={classes.link} href={AppRoutes.SignIn}>
        <Typography>{t(I18nKeys.SingleSignOnUsernamePasswordButton)}</Typography>
      </A>
    </Paper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setProvider: (provider: string | undefined): void => {
    dispatch(setCurrentUserAuthProvider(provider));
  },
  onSubmit: (): void => {
    dispatch(oauthSignIn());
  },
});

const mapStateToProps = ({ currentUser: { authProvider, errors = {} } }: AppState): StateProps => {
  const currentPath = window.location.pathname.split('/');
  const urlProvider =
    currentPath && currentPath.length > 0 && currentPath[currentPath.length - 1] !== 'sso'
      ? currentPath[currentPath.length - 1]
      : undefined;

  return {
    urlProvider,
    authProvider,
    errors,
  };
};

export const SingleSignOn = connect(mapStateToProps, mapDispatchToProps)(SingleSignOnComponent);
