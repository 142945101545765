import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from '@mui/icons-material/Store';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import WebIcon from '@mui/icons-material/Web';
import SettingsIcon from '@mui/icons-material/Settings';
import { mdiCashMultiple } from '@mdi/js';
import Icon from '@mdi/react';
import { navigate, usePath } from 'hookrouter';
import React from 'react';
import { AnyAction } from 'redux';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, OpenInNew } from '@mui/icons-material';
import menuOrdersIcon from '../images/menuOrdersIcon.svg';
import menuPaymentsIcon from '../images/menuPaymentsIcon.svg';
import { AppRoutes, AppRoutesWithConfirmation, routesWithConfirmation } from '../constants/AppRoutes';
import { DockedDrawerWidth } from '../constants/DockedDrawerWidth';
import { menuItems, MenuItems } from '../constants/MenuItems';
import { setFocusedMenuItem } from '../ducks/menu';
import { unknownGroup } from '../constants/Group';
import { unknownUser } from '../types/User';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { resetSearch } from '../ducks/search';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { useAppDispatch, useAppSelector } from '../hooks';
import { isIdeaRoomGroup as isIdeaRoomGroupFunc } from '../utils/userUtils';
import { MenuStatus, MenuWidthMap } from '../constants/Viewer';
import { UserMenuItem } from './UserMenuItem';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';
import { getUnsavedChangesMessage, includesPath } from '../utils/viewerUtils';
import { setMenuStatus, setSelectedClientId as setSelectedClientIdFunc } from '../ducks/viewerSlice';

const getMenuItemIcon = (menuItem: MenuItems) => {
  switch (menuItem) {
    case MenuItems.Users:
    case MenuItems.Groups: {
      return <GroupIcon />;
    }
    case MenuItems.Leads: {
      return <Icon path={mdiCashMultiple} size="24px" />;
    }
    case MenuItems.Orders: {
      return <img src={menuOrdersIcon} width="24px" alt="Orders" />;
    }
    case MenuItems.Usage: {
      return <SignalCellularAltIcon />;
    }
    case MenuItems.Dealers: {
      return <StoreIcon />;
    }
    case MenuItems.Payments: {
      return <img src={menuPaymentsIcon} width="24px" alt="Payments" />;
    }
    case MenuItems.Pricing: {
      return <Icon path={mdiCashMultiple} size="24px" />;
    }
    case MenuItems.Reports: {
      return <AssessmentIcon />;
    }
    case MenuItems.Settings: {
      return <SettingsIcon />;
    }
    case MenuItems.Sites:
    case MenuItems.ClientData: {
      return <WebIcon />;
    }
    default: {
      return null;
    }
  }
};

const useStyles = makeStyles<Theme, { menuStatus: MenuStatus; mobileView: boolean }>((theme: Theme) => ({
  root: {
    width: DockedDrawerWidth,
  },
  currentItem: {
    backgroundColor: '#EEEEF2',
    borderRadius: '4px',
  },
  topSpacer: { backgroundColor: '#E0E0E0', height: '3vh', width: '100%', marginBottom: '30px' },
  // Align the button on the right side of the drawer
  menuClose: { position: 'absolute', right: '0px', top: '0px' },
  drawer: {
    flexShrink: 0,
    position: 'fixed',
    transition: theme.transitions.create(['width', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: ({ menuStatus }) => `${MenuWidthMap[menuStatus]}px`,
  },
  drawerPaper: {
    overflow: 'hidden',
  },
  menuItemText: {
    width: ({ menuStatus }) => (menuStatus === MenuStatus.Expanded ? 'auto' : '0px'),
    textWrap: 'nowrap',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
  },
}));

export const Menu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const path = usePath();

  const {
    group = unknownGroup,
    user = unknownUser,
    availableDealers = [],
  } = useAppSelector((state) => state.currentUser);
  const { defaultClientId = '', selectedClientId, menuStatus } = useAppSelector((state) => state.viewer);
  const { focusedMenuItem } = useAppSelector((state) => state.menu);
  const { draftEqualsActive } = useAppSelector((state) => state.pricingAdjustment);

  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const classes = useStyles({ menuStatus, mobileView });

  let groupConfigurators = group.configurators || [];
  const userDealers = user.dealers || [];

  if (userDealers.length && groupConfigurators.length) {
    if (userDealers.length === 1) {
      groupConfigurators = groupConfigurators.map((config: any) => {
        const dealer = availableDealers.find(
          (deal) => deal.clientId === `${config.key}-${config.vendor}` && userDealers.includes(deal.key),
        );
        return {
          ...config,
          url: dealer ? `${config.url}${config.url.includes('?') ? '&' : '?'}dealer=${dealer.key}` : config.url,
        };
      });
    } else {
      // Loop through each dealer and add the dealer key to the configurator url and set the name as the dealer name
      groupConfigurators = groupConfigurators.reduce((acc: any[], config: any) => {
        const dealers = availableDealers.filter(
          (dealer) => dealer.clientId === `${config.key}-${config.vendor}` && userDealers.includes(dealer.key),
        );
        return [
          ...acc,
          ...(dealers && dealers.length
            ? dealers.map((dealer) => ({
                ...config,
                url: `${config.url}${config.url.includes('?') ? '&' : '?'}dealer=${dealer.key}`,
                name: dealer.name,
              }))
            : [config]),
        ];
      }, []);
    }
  }

  const isIdearoomGroup = isIdeaRoomGroupFunc(group.groupId);
  const selectedGroupId = group.groupId;

  const confirmUnsavedChanges = (
    actions: AnyAction[],
    functions: Function[],
    appRoute: AppRoutesWithConfirmation,
  ): void => {
    dispatch(
      openConfirmationDialog(
        actions,
        functions,
        i18n.t(I18nKeys.UnsavedChangesConfirmationTitle),
        getUnsavedChangesMessage(appRoute, i18n.t),
        i18n.t(I18nKeys.UnsavedChangesConfirmationConfirmButton),
      ),
    );
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  };

  const dispatchAll = (actions: AnyAction[]): void => {
    actions.forEach((action) => dispatch(action));
  };

  const setSelectedClientId = (clientId: string): void => {
    dispatch(setSelectedClientIdFunc(clientId));
  };

  const handleChangeMenuItemClicked = (menuItem: MenuItems): void => {
    let route = '';
    switch (menuItem) {
      case MenuItems.Groups: {
        route = AppRoutes.Groups;
        break;
      }
      case MenuItems.Leads: {
        route = AppRoutes.Leads;
        break;
      }
      case MenuItems.Orders: {
        route = AppRoutes.Orders;
        break;
      }
      case MenuItems.Usage: {
        route = AppRoutes.Usage;
        break;
      }
      case MenuItems.Dealers: {
        route = AppRoutes.Dealers;
        break;
      }
      case MenuItems.Users: {
        route = AppRoutes.Users;
        break;
      }
      case MenuItems.Payments: {
        route = AppRoutes.Payments;
        break;
      }
      case MenuItems.Pricing: {
        route = AppRoutes.Pricing;
        break;
      }
      case MenuItems.Reports: {
        route = AppRoutes.Reports;
        break;
      }
      case MenuItems.Settings: {
        route = AppRoutes.Settings;
        break;
      }
      case MenuItems.Sites: {
        route = AppRoutes.Sites;
        break;
      }
      case MenuItems.ClientData: {
        route = `${AppRoutes.ClientData}/${selectedGroupId}`;
        break;
      }
      default:
        throw new Error(`menuItem not supported: ${menuItem}`);
    }
    const actions = [setFocusedMenuItem(menuItem), resetSearch()];
    if (!selectedClientId) {
      setSelectedClientId(defaultClientId);
    }
    if (!draftEqualsActive && includesPath(path, routesWithConfirmation)) {
      confirmUnsavedChanges(actions, [(): void => navigate(route)], path as AppRoutesWithConfirmation);
    } else {
      dispatchAll(actions);
      navigate(route);
    }
  };

  return (
    <Drawer
      variant={mobileView ? 'temporary' : 'permanent'}
      open={menuStatus !== MenuStatus.Closed}
      onClose={(): void => {
        dispatch(setMenuStatus(MenuStatus.Closed));
      }}
      anchor="left"
      className={classes.drawer}
      PaperProps={{
        className: `${classes.drawer} ${classes.drawerPaper}`,
      }}
    >
      <Typography component="div" className={classes.topSpacer} />

      {!mobileView && (
        <IconButton
          onClick={(): void => {
            dispatch(setMenuStatus(MenuStatus.Closed));
          }}
          className={classes.menuClose}
        >
          <ChevronLeft />
        </IconButton>
      )}

      <UserMenuItem />

      <Divider />

      <List>
        {Object.entries(menuItems)
          .filter(([_, menuItem]) => menuItem.visible(user, isIdearoomGroup, groupConfigurators, selectedGroupId))
          .map(([key, menuItem]) => (
            <ListItemButton
              className={focusedMenuItem === key ? classes.currentItem : undefined}
              key={menuItem.key}
              onClick={(): void => handleChangeMenuItemClicked(key as MenuItems)}
            >
              <ListItemIcon>{getMenuItemIcon(key as MenuItems)}</ListItemIcon>
              <ListItemText className={classes.menuItemText} primary={t(menuItem.i18nKey())} />
              {menuItem.ideaRoomOnly(user, selectedGroupId) && <IdeaRoomOnlyIndicator />}
            </ListItemButton>
          ))}

        <Divider />

        {groupConfigurators
          .filter(({ url }) => url)
          .map(({ vendor, key, url, name }) => (
            <ListItemButton key={`${key}-${vendor}-btn`} onClick={(): Window | null => window.open(url, '_blank')}>
              <ListItemIcon>
                <OpenInNew />
              </ListItemIcon>
              <ListItemText className={classes.menuItemText} primary={name} />
            </ListItemButton>
          ))}
      </List>
    </Drawer>
  );
};
