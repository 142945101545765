import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Search } from '@mui/icons-material';
import { alpha, styled } from '@mui/material/styles';
import { ButtonBase, Stack, Theme, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSelectedPricingSheetId } from '../ducks/pricingSlice';
import { PricingSheet } from '../types/PricingSheet';
import { I18nKeys } from '../constants/I18nKeys';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { SearchInput } from './SearchInput';
import { PricingBaseAttributesLabel } from './PricingBaseAttributesLabel';
import { matchExists } from '../utils/searchUtils';
import { getAttributeLabel } from '../constants/PricingSheetAttributeType';
import hiddenPriceSrc from '../images/hiddenPriceIcon.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    height: '100%',
  },
  searchField: {
    padding: '0px 0px 6px',
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    '& .MuiInputBase-root': {
      borderRadius: '8px',
    },
    '& input': {
      padding: '10px 0px',
    },
  },
  stack: {
    flex: 1,
    height: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    overflow: 'auto',
  },
  list: {
    textAlign: 'start',
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(4)}`,
    margin: 0,
  },
  toolTipList: {
    textAlign: 'start',
    padding: `${theme.spacing(2)}`,
    margin: 0,
  },
  sheetTitle: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  attributes: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}));

interface ButtonProps {
  selected?: boolean;
  changes?: number;
}

const AttributesButton = styled(ButtonBase)<ButtonProps>((props) => {
  // Background gradients to show the orange sheet changed indicator
  const background = props.changes
    ? `linear-gradient(to right, #E4A767CC 7px, ${alpha('#000', 0.04)}, ${alpha('#000', 0.04)} 7px)`
    : `linear-gradient(0deg, ${alpha('#000', 0.04)}, ${alpha('#000', 0.04)})`;

  const backgroundSelected = props.changes
    ? `linear-gradient(to right, #E4A767CC 7px, ${alpha(props.theme.palette.primary.main, 0.12)} 7px)`
    : `linear-gradient(0deg, ${alpha(props.theme.palette.primary.main, 0.12)}, ${alpha(
        props.theme.palette.primary.main,
        0.12,
      )})`;

  return {
    border: '1px solid #DADADA',
    borderRadius: '8px',
    justifyContent: 'space-between',
    padding: '0px 16px 0px 0px',
    color: alpha('#000', 0.87),
    '&:hover': {
      background: props.changes
        ? `linear-gradient(to right, #E4A767CC 7px, ${alpha(props.theme.palette.primary.main, 0.12)} 7px)`
        : `linear-gradient(0deg, ${alpha(props.theme.palette.primary.main, 0.12)}, ${alpha(
            props.theme.palette.primary.main,
            0.12,
          )})`,
    },

    ...(props.selected
      ? {
          background: backgroundSelected,
        }
      : {
          background,
        }),
  };
});

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    disableInteractive
    disableHoverListener={props.disableHoverListener}
    TransitionProps={{ timeout: 600 }}
    title={props.title}
    classes={{ popper: className }}
  >
    {props.children}
  </Tooltip>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '600px',
  },
});

export const PricingBaseAttributes: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    pricing: {
      base: { selectedPricingSheetId },
    },
  } = useAppSelector((state) => state);

  const { pricingBaseSheets, cellMetadataDiff } = usePricingRepo({
    usePricingSheetBase: true,
    useCellMetadataDiff: true,
  });

  const [pricingSheetSearch, setPricingSheetSearch] = React.useState<string>('');
  const [filteredPricingBaseSheets, setFilteredPricingBaseSheets] = React.useState<PricingSheet[]>([]);

  useEffect(() => {
    setFilteredPricingBaseSheets(
      !pricingSheetSearch
        ? pricingBaseSheets
        : pricingBaseSheets.filter((sheet) =>
            [sheet.prices[0]?.priceSetLabel || sheet.attributes.map((attribute) => getAttributeLabel(attribute, t))]
              .flat()
              .some((label) => label && matchExists(label, pricingSheetSearch, {})),
          ),
    );
  }, [pricingBaseSheets, pricingSheetSearch]);

  // Not the most fancy code, but the tooltip will be removed in the near future.
  const [isOverflowed, setIsOverflow] = React.useState<boolean[]>([]);
  const arrayRef = React.useRef<HTMLElement[][]>([]);
  useEffect(() => {
    if (pricingBaseSheets.length > 0) {
      let change = false;
      arrayRef.current.forEach((elements, index) => {
        const overflowed = !!elements.find(
          (element: HTMLElement) =>
            element && (element.scrollWidth > element.clientWidth || element.clientHeight < element.scrollHeight),
        );
        if (overflowed !== isOverflowed[index]) {
          change = true;
          isOverflowed[index] = overflowed;
        }
      });
      if (change) {
        setIsOverflow([...isOverflowed]);
      }
    }
  }, [pricingBaseSheets, isOverflowed, setIsOverflow, arrayRef]);

  const handleAttributeClick = (sheet: PricingSheet): void => {
    dispatch(setSelectedPricingSheetId(sheet.id));
  };

  const checkSheetForChanges = (sheet: PricingSheet): number => {
    if (sheet.prices.some((price) => price.diff)) {
      return 1;
    }

    for (let i = 0; i < cellMetadataDiff.length; i += 1) {
      if (sheet.prices.some((price) => price.rowId === cellMetadataDiff[i].rowId)) {
        return 1;
      }
    }
    return 0;
  };

  return (
    <div className={classes.root}>
      <Stack className={classes.stack} direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        {!pricingBaseSheets.length && (
          <AttributesButton className={classes.list}>
            <Typography className={classes.attributes} variant="body2">
              {t(I18nKeys.PricingBaseAttributeEmpty)}
            </Typography>
          </AttributesButton>
        )}
        {!!pricingBaseSheets.length && (
          <SearchInput
            searchTerm={pricingSheetSearch}
            startAdornment={<Search />}
            resultsDisplay={{ count: filteredPricingBaseSheets.length, label: 'found' }}
            onClearClick={() => setPricingSheetSearch('')}
            onChange={(val: string) => setPricingSheetSearch(val)}
            classes={classes}
          />
        )}
        {!!pricingBaseSheets.length && !filteredPricingBaseSheets.length && (
          <Typography
            className={classes.attributes}
            variant="body2"
            color="text.secondary"
            style={{ textAlign: 'center' }}
          >
            {t(I18nKeys.PricingBaseAttributeNoneFound)}
          </Typography>
        )}
        {!!filteredPricingBaseSheets.length &&
          filteredPricingBaseSheets.map((sheet: PricingSheet, index) => {
            const isHiddenStyle = sheet.prices.every(({ hidden }) =>
              Object.values(hidden).reduce((acc, isHidden) => acc && isHidden, true),
            );
            return (
              <NoMaxWidthTooltip
                key={sheet.id}
                disableHoverListener={!isOverflowed[index]}
                title={
                  <ul className={classes.toolTipList}>
                    {sheet.attributes.map((attribute) => (
                      <li key={attribute.value}>
                        <Typography variant="body2">{attribute.label || attribute.value}</Typography>
                      </li>
                    ))}
                  </ul>
                }
              >
                <AttributesButton
                  changes={checkSheetForChanges(sheet)}
                  selected={sheet.id === selectedPricingSheetId}
                  onClick={(): void => handleAttributeClick(sheet)}
                >
                  <PricingBaseAttributesLabel
                    classes={classes}
                    pricingBaseSheet={sheet}
                    setRef={(instance) => {
                      if (instance && !sheet.prices[0]?.priceSetLabel) {
                        if (!arrayRef.current[index]) {
                          arrayRef.current[index] = [];
                        }
                        arrayRef.current[index].push(instance);
                      }
                    }}
                    searchTerm={pricingSheetSearch}
                  />
                  {isHiddenStyle && <img alt="Hidden price" src={hiddenPriceSrc} />}
                </AttributesButton>
              </NoMaxWidthTooltip>
            );
          })}
      </Stack>
    </div>
  );
};
