import { CircularProgress, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../hooks';
import { AppState } from '../types/AppState';
import { PricingComponentEditDialog } from './PricingComponentEditDialog';
import { setPricingComponentDataBranch } from '../ducks/pricingSlice';
import { useDeleteBranchMutation } from '../services/clientDataApi';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { notifyOfDataChange } from '../utils/pricingUtils';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { unknownGroup } from '../constants/Group';
import { LocalStorage } from '../constants/LocalStorage';
import { PricingComponentPreviewDialog } from './PricingComponentPreviewDialog';
import { PricingPublishDialog } from './PricingPublishDialog';
import { PricingPublishResultDialog } from './PricingPublishResultDialog';
import { useComponentPricingRepo } from '../hooks/useComponentPricingRepo';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { PRICING_COMPONENT_SEARCH_HEIGHT, PricingComponentSearch } from './PricingComponentSearch';
import { PRICING_COMPONENT_HEADER_HEIGHT, PricingComponentHeader } from './PricingComponentHeader';
import { PricingComponentCategories } from './PricingComponentCategories';
import { PricingComponentCategoryItems } from './PricingComponentCategoryItems';
import { I18nKeys } from '../constants/I18nKeys';
import { useClientDataRepo } from '../hooks/useClientDataRepo';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100% - 100px)',
  },
}));

export const PricingComponent: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    component: { pricingDataBranch: clientUpdateDataBranch = ClientDataBranch.Main, selectedComponentCategoryKey },
  } = useAppSelector((state: AppState) => state?.pricing);
  const { clientId: clientDataClientId } = useAppSelector((state: AppState) => state?.clientData);
  const clientId = useAppSelector((state: AppState) =>
    getClientIdFromClientSupplier(state?.viewer?.selectedTabId || state?.viewer?.selectedClientId || ''),
  );
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);

  const dispatch = useAppDispatch();

  const [, { isLoading: isDeletingBranch = false }] = useDeleteBranchMutation({
    fixedCacheKey: 'revert',
  });
  const { isLoadingClientTableColumns } = useClientDataRepo({
    useClientTablesColumns: true,
  });
  const { activeBranches } = usePricingRepo({
    useBranches: true,
  });
  const {
    clientUpdateBranchMergeCommit,
    isLoadingComponentCategoryItems,
    isLoadingComponentCategoryItemDiffs,
    isLoadingComponentCategoriesList,
  } = useComponentPricingRepo({
    useClientUpdateBranchMergeCommit: true,
    useComponentCategories: true,
    useComponentCategoryItemDiffs: true,
    useSelectedComponentCategoryItems: true,
  });
  const clientUpdateBranchExists = activeBranches.find((branch) => branch.branchType === ClientDataBranch.ClientUpdate);

  const [searchValue, setSearchValue] = React.useState('');

  useEffect(() => {
    notifyOfDataChange({
      groupId,
      clientId,
      latestCommit: clientUpdateBranchMergeCommit,
      key: LocalStorage.LastClientUpdateMergeCommit,
      dispatch,
      t,
    });
  }, [clientUpdateBranchMergeCommit, dispatch, t, groupId, clientId]);

  useEffect(() => {
    if (clientId && clientId !== clientDataClientId) {
      dispatch(setPricingComponentDataBranch(ClientDataBranch.Main));
    }
  }, [clientId, clientDataClientId, dispatch]);

  useEffect(() => {
    if (isDeletingBranch) return;
    if (clientUpdateBranchExists && clientUpdateDataBranch !== ClientDataBranch.ClientUpdate) {
      dispatch(setPricingComponentDataBranch(ClientDataBranch.ClientUpdate));
    }
    if (!clientUpdateBranchExists && clientUpdateDataBranch !== ClientDataBranch.Main) {
      dispatch(setPricingComponentDataBranch(ClientDataBranch.Main));
    }
  }, [clientUpdateBranchExists, clientUpdateDataBranch, dispatch, isDeletingBranch]);

  return (
    <div className={classes.root}>
      <Grid container direction="row" style={{ height: '100%' }}>
        <PricingComponentSearch searchValue={searchValue} setSearchValue={setSearchValue} />
        <PricingComponentHeader setSearchValue={setSearchValue} />

        <Grid
          item
          xs={12}
          justifyContent="center"
          style={{
            display: 'flex',
            height: `calc(100% - ${PRICING_COMPONENT_SEARCH_HEIGHT}px - ${PRICING_COMPONENT_HEADER_HEIGHT}px)`,
          }}
        >
          {((selectedComponentCategoryKey &&
            (isLoadingComponentCategoryItems || isLoadingComponentCategoryItemDiffs || isLoadingClientTableColumns)) ||
            (!selectedComponentCategoryKey && isLoadingComponentCategoriesList)) && (
            <CircularProgress style={{ alignSelf: 'center' }} color="primary" />
          )}

          {!selectedComponentCategoryKey &&
            !isLoadingComponentCategoriesList &&
            !isLoadingComponentCategoryItemDiffs && (
              <PricingComponentCategories searchValue={searchValue} setSearchValue={setSearchValue} />
            )}
          {selectedComponentCategoryKey && !isLoadingComponentCategoryItems && (
            <PricingComponentCategoryItems searchValue={searchValue} />
          )}
        </Grid>
      </Grid>

      <PricingComponentEditDialog />
      <PricingComponentPreviewDialog />
      <PricingPublishDialog
        pricingBranch={ClientDataBranch.ClientUpdate}
        title={I18nKeys.PricingComponentPublishDialogTitle}
        content={I18nKeys.PricingComponentPublishDialogText}
      />
      <PricingPublishResultDialog />
    </div>
  );
};
