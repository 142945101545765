export enum Forms {
  BulkUser = 'BULK_USER',
  ClientDataNote = 'CLIENT_DATA_NOTE',
  ClientDataHotfixBranch = 'CLIENT_DATA_HOTFIX_BRANCH',
  ClientDataPublish = 'CLIENT_DATA_PUBLISH',
  ClientDataPreview = 'CLIENT_DATA_PREVIEW',
  Configurator = 'CONFIGURATOR',
  Dealer = 'DEALER',
  ForgotPassword = 'FORGOT_PASSWORD',
  Impersonation = 'IMPERSONATION',
  I18n = 'I18N',
  Password = 'PASSWORD',
  Preview = 'PREVIEW',
  PricingBaseSheetName = 'PRICING_BASE_SHEET_NAME',
  PricingComponentEdit = 'PRICING_COMPONENT_EDIT',
  Profile = 'PROFILE',
  Preferences = 'PREFERENCES',
  Publish = 'PUBLISH',
  Language = 'LANGUAGE',
  SceneEnvironment = 'SCENE_ENVIRONMENT',
  SignIn = 'SIGN_IN',
  SignUp = 'SIGN_UP',
  User = 'USER',
  WhatsNew = 'WHATS_NEW',
}
