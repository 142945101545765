import React from 'react';
import { Card, CardContent, Chip, FormGroup, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { AppState } from '../types/AppState';
import {
  PricingAdjustmentCondition,
  SurchargeKeyCondition,
  SurchargeRangeCondition,
  SurchargeRule,
} from '../types/PricingAdjustment';
import {
  PricingAdjustmentErrorType,
  SystemOrderCalculations,
  PricingSurchargeCondition,
} from '../constants/PricingAdjustment';
import { removePricingRule, updateSurchargeRule } from '../ducks/pricingAdjustment';
import { PricingKeyConditionMenu } from './PricingKeyConditionMenu';
import { PricingRangeConditionMenu } from './PricingRangeConditionMenu';
import { PricingSubtotalConditionMenu } from './PricingSubtotalConditionMenu';
import { Red as RedColor, LightText as LightTextColor, RedMuted, Black } from '../constants/ChipColors';
import { findMatchingSurchargeCalculation, formatPrice, getCurrencySymbol, parseRoundTo } from '../utils/pricingUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';

const useStyles = makeStyles({
  card: {
    backgroundColor: '#f0f0f0',
    width: '100%',
  },
  textField: {
    '& > *': {
      width: '15ch',
    },
    marginRight: '10px',
  },
  removeIcon: {
    color: 'darkred',
    float: 'right',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  spacing: {
    marginTop: '10px',
  },
  chipError: {
    backgroundColor: RedColor,
    color: LightTextColor,
    '&:focus': {
      backgroundColor: RedMuted,
      color: Black,
    },
    '&:hover': {
      backgroundColor: RedMuted,
      color: Black,
    },
  },
});

export const PricingRule: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { currency } = useAppSelector((state: AppState) => state?.viewer);
  const {
    clientId = '',
    pricingSelection,
    draftSurchargeCalculations,
    conditionOptions,
    errors: validationErrors,
    supplierKey,
  } = useAppSelector((state: AppState) => state?.pricingAdjustment);

  const draftSurchargeCalculation = findMatchingSurchargeCalculation(
    clientId,
    draftSurchargeCalculations,
    supplierKey,
    pricingSelection,
  );
  const { rules } = draftSurchargeCalculation;

  const removeRule = (ruleId: number): void => {
    dispatch(removePricingRule(ruleId));
  };

  const [activePricingCondition, setActivePricingCondition] = React.useState<PricingAdjustmentCondition>();
  const [activeSurchargeRule, setActiveSurchargeRule] = React.useState<SurchargeRule>();
  const [pricingConditionMenuAnchorEl, setPricingConditionMenuAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const [pricingConditionRangeMenuAnchorEl, setPricingConditionRangeMenuAnchorEl] =
    React.useState<null | HTMLDivElement>(null);
  const [pricingConditionSubtotalMenuAnchorEl, setPricingConditionSubtotalMenuAnchorEl] =
    React.useState<null | HTMLDivElement>(null);

  const handlePricingConditionMenuClose = (): void => setPricingConditionMenuAnchorEl(null);
  const handlePricingConditionSizeMenuClose = (): void => setPricingConditionRangeMenuAnchorEl(null);
  const handlePricingConditionSubtotalMenuClose = (): void => setPricingConditionSubtotalMenuAnchorEl(null);

  const handleConditionChipClick = (event: any, rule: SurchargeRule, condition: PricingAdjustmentCondition): void => {
    setActivePricingCondition(condition);
    setActiveSurchargeRule(rule);
    if (condition.value === PricingSurchargeCondition.Subtotal) {
      setPricingConditionSubtotalMenuAnchorEl(event ? event.target : null);
    } else if (condition.range) {
      setPricingConditionRangeMenuAnchorEl(event ? event.target : null);
    } else {
      setPricingConditionMenuAnchorEl(event ? event.target : null);
    }
  };

  const createLabelForConditionChip = (
    condition: PricingAdjustmentCondition,
    conditions: SurchargeKeyCondition[] | SurchargeRangeCondition[],
  ): string => {
    const labels: string[] = [];
    if (condition.value === PricingSurchargeCondition.Subtotal) {
      const { minimum = 0, maximum = 0 } =
        (conditions as SurchargeRangeCondition[]).find((sc) => sc.type === condition.value) || {};
      labels.push(`${formatPrice(minimum, currency)} to ${formatPrice(maximum, currency)}`);
    } else if (condition.range) {
      const { minimum = 0, maximum = 0 } =
        (conditions as SurchargeRangeCondition[]).find((sc) => sc.type === condition.value) || {};
      labels.push(
        ...condition.options
          .slice(0)
          .sort((a, b) => (Number(a.key) > Number(b.key) ? 1 : -1))
          .filter((option) => option.label !== undefined && [minimum, maximum].includes(Number(option.key)))
          .map((option) => option.label || ''),
      );
    } else {
      const { keys = [] } = (conditions as SurchargeKeyCondition[]).find((sc) => sc.type === condition.value) || {};
      labels.push(
        ...condition.options
          .filter(
            (option, i) =>
              // Don't include duplicate labels
              keys.includes(option.key) && !condition.options.slice(0, i).some((o) => o.label === option.label),
          )
          .map((option) => option.label || ''),
      );
    }
    return `${t(condition.label)}${labels.length > 0 ? ': ' : ''}${
      condition.range ? labels.join(' - ') : labels.join(', ')
    }`;
  };

  const handleCalculationValueChange = (
    rule: SurchargeRule,
    property: string,
    newValue: string,
    percentage = false,
  ): void => {
    let parsedValue = percentage ? parseFloat(newValue) / 100 : parseFloat(newValue);
    if (property === 'roundTo') parsedValue = parseRoundTo(newValue);
    dispatch(
      updateSurchargeRule({
        ...rule,
        calculation: {
          ...rule.calculation,
          [property]: parsedValue,
        },
      }),
    );
  };

  React.useEffect(() => {
    // when rules have been updated in state and one is selected "re-load" it by it's id
    if (activeSurchargeRule) {
      setActiveSurchargeRule(rules.find((rule) => activeSurchargeRule.id === rule.id));
    }
  }, [rules, activeSurchargeRule]);

  return (
    <>
      <PricingKeyConditionMenu
        pricingAdjustmentCondition={activePricingCondition}
        rule={activeSurchargeRule}
        anchorEl={pricingConditionMenuAnchorEl}
        onClose={handlePricingConditionMenuClose}
      />
      <PricingRangeConditionMenu
        pricingAdjustmentCondition={activePricingCondition}
        rule={activeSurchargeRule}
        anchorEl={pricingConditionRangeMenuAnchorEl}
        onClose={handlePricingConditionSizeMenuClose}
      />
      <PricingSubtotalConditionMenu
        pricingAdjustmentCondition={activePricingCondition}
        rule={activeSurchargeRule}
        anchorEl={pricingConditionSubtotalMenuAnchorEl}
        onClose={handlePricingConditionSubtotalMenuClose}
      />
      {rules.length > 0 &&
        rules.map((rule, index) => (
          <Grid key={`rule-grid-${rule.id}`} container className={classes.spacing}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid>
                    <Grid container direction="row" spacing={2} alignItems="center">
                      <Grid item>
                        <Typography>
                          {t(I18nKeys.PricingRuleTitle, {
                            selection:
                              pricingSelection === SystemOrderCalculations.Surcharge
                                ? t(I18nKeys.PricingTypeSurcharge)
                                : t(I18nKeys.PricingTypeChange),
                          })}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" spacing={1}>
                          {conditionOptions
                            .filter((option) => rule.conditions.some((condition) => condition.type === option.value))
                            .map((condition) => (
                              <Grid key={`${condition.value}-grid`} item>
                                <Chip
                                  key={`${condition.value}-chip`}
                                  deleteIcon={<ArrowDropDownIcon />}
                                  onDelete={(event: any): void => handleConditionChipClick(event, rule, condition)}
                                  onClick={(event: any): void => handleConditionChipClick(event, rule, condition)}
                                  label={createLabelForConditionChip(
                                    condition,
                                    rule.conditions as SurchargeKeyCondition[],
                                  )}
                                  className={
                                    validationErrors.some(
                                      (error) =>
                                        error.ruleId === rule.id &&
                                        error.errorType === PricingAdjustmentErrorType.Condition &&
                                        error.type === condition.value,
                                    )
                                      ? classes.chipError
                                      : undefined
                                  }
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid>
                      <FormGroup row className={classes.spacing}>
                        <TextField
                          className={classes.textField}
                          variant="standard"
                          label={t(I18nKeys.PricingPercentChange)}
                          type="number"
                          value={
                            rule.calculation.percentChange
                              ? parseFloat((rule.calculation.percentChange * 100).toFixed(7)).toString()
                              : rule.calculation.percentChange
                          }
                          onChange={(event): void => {
                            handleCalculationValueChange(rule, 'percentChange', event.target.value, true);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          }}
                          error={
                            validationErrors.some(
                              (error) =>
                                error.ruleId === rule.id &&
                                error.errorType === PricingAdjustmentErrorType.Calculation &&
                                error.type === 'percentChange',
                            )
                              ? true
                              : undefined
                          }
                        />
                        {pricingSelection && pricingSelection === SystemOrderCalculations.Surcharge && (
                          <TextField
                            className={classes.textField}
                            variant="standard"
                            label={t(I18nKeys.PricingAmountChange)}
                            type="number"
                            value={rule.calculation.amountChange}
                            onChange={(event): void => {
                              handleCalculationValueChange(rule, 'amountChange', event.target.value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>
                              ),
                            }}
                            error={
                              validationErrors.some(
                                (error) =>
                                  error.ruleId === rule.id &&
                                  error.errorType === PricingAdjustmentErrorType.Calculation &&
                                  error.type === 'amountChange',
                              )
                                ? true
                                : undefined
                            }
                          />
                        )}
                        <TextField
                          className={classes.textField}
                          variant="standard"
                          label={t(I18nKeys.PricingRoundToNearest)}
                          type="number"
                          value={rule.calculation.roundTo}
                          onChange={(event): void => handleCalculationValueChange(rule, 'roundTo', event.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>
                            ),
                          }}
                          error={
                            validationErrors.some(
                              (error) =>
                                error.ruleId === rule.id &&
                                error.errorType === PricingAdjustmentErrorType.Calculation &&
                                error.type === 'roundTo',
                            )
                              ? true
                              : undefined
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid className={classes.removeIcon}>
                    {index > 0 && (
                      <RemoveCircleIcon className={classes.removeIcon} onClick={(): void => removeRule(rule.id)} />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
    </>
  );
};
