import {
  ColDef,
  GridApi,
  ProcessDataFromClipboardParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { Dispatch } from 'redux';
import { PricingSheetPrice } from '../types/PricingSheetPrice';
import { BaseTableData } from '../types/DataGrid';
import { formatPrice, getValidatedNewValue, arePriceValuesDifferent } from './pricingUtils';
import { getCellRangeInfo, getPropertyFromCellMetadata, processUpdatedValues } from './clientDataUtils';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';
import { addDispatchCommandToUndo } from './undoManagerUtils';
import { updatePricingBaseRows, updatePricingMetadata } from '../ducks/pricingSlice';
import { PriceColumn } from '../constants/PriceColumn';
import { openDialog } from '../ducks/dialogSlice';
import { openNotificationDialog } from '../ducks/notification';
import { Dialogs } from '../constants/Dialogs';
import { LocalStorage } from '../constants/LocalStorage';
import { UpdateClientDataMetadata, UpdateClientDataRow } from '../ducks/clientDataSlice';
import { CellMetadata, ClipboardData } from '../types/ClientData';
import { COLUMN_DELIMITER, ROW_DELIMITER } from '../constants/ClientData';
import { PricingSheet } from '../types/PricingSheet';
import { GridViewType } from '../constants/GridViewType';
import { Region } from '../types/Region';

export interface PriceRow extends BaseTableData {
  [key: string]: any;
}

export const getAllWidths = (prices: PricingSheetPrice[]) => {
  const allWidths = prices.reduce((allWidthsSeen: string[], price: PricingSheetPrice) => {
    if (!allWidthsSeen.includes(`${price.width}`)) {
      allWidthsSeen.push(`${price.width}`);
    }
    return allWidthsSeen;
  }, []);
  return allWidths.sort((a, b) => Number.parseInt(a, 10) - Number.parseInt(b, 10));
};

export const getAllLengths = (prices: PricingSheetPrice[]) => {
  const allLengths = prices.reduce((allLengthsSeen: string[], price: PricingSheetPrice) => {
    if (!allLengthsSeen.includes(`${price.length}`)) {
      allLengthsSeen.push(`${price.length}`);
    }
    return allLengthsSeen;
  }, []);
  return allLengths.sort((a, b) => Number.parseInt(a, 10) - Number.parseInt(b, 10));
};

export const getAvailablePricesPerRegionDiff = (
  availablePricePerRegion = new Map<string, number>(),
  regions: Region[],
  prices: PricingSheetPrice[],
): Map<string, number> => {
  const hasDefaultRegion = regions.find((r) => r.priceColumn === PriceColumn.price);
  if (!hasDefaultRegion) return new Map();

  regions.forEach((r) => {
    if (r.priceColumn !== PriceColumn.price) {
      let sumDiff = 0;
      prices.forEach((p) => {
        if (p[r.priceColumn] !== p[PriceColumn.price]) sumDiff += 1;
      });
      availablePricePerRegion.set(r.rowId, sumDiff);
    }
  });
  return availablePricePerRegion;
};

export const getSubtitleText = (
  availablePricePerRegion: Map<string, number>,
  sumAvailablePrices: number | undefined,
  region: Region,
  isHiddenRegion: boolean,
) => {
  if (isHiddenRegion) {
    return i18n.t(I18nKeys.PricingBaseAccordionHiddenRegion);
  }

  if (region.priceColumn === PriceColumn.price) {
    return i18n.t(I18nKeys.PricingBaseAccordionDefaultLabel);
  }

  if (availablePricePerRegion.get(region.rowId) === 0) {
    return i18n.t(I18nKeys.PricingBaseAccordionNonePriceChanged, { total: sumAvailablePrices });
  }

  if (availablePricePerRegion.get(region.rowId) === sumAvailablePrices) {
    return i18n.t(I18nKeys.PricingBaseAccordionAllPricesChanged, { total: availablePricePerRegion.get(region.rowId) });
  }

  return i18n.t(I18nKeys.PricingBaseAccordionCountPricesChanged, {
    count: availablePricePerRegion.get(region.rowId),
    total: sumAvailablePrices || 0,
  });
};

/**
 * Gets whether the region has any prices that are set
 *
 * @param prices
 * @returns
 */
export const regionHasPrices = (prices: PricingSheetPrice[] | undefined, region: Region) =>
  prices &&
  prices.some(
    (price) =>
      price[region.priceColumn] !== undefined && price[region.priceColumn] !== null && price[region.priceColumn] !== '',
  );

/**
 * Creates the row of prices from the pricing sheet. Sorts the rows based on available lengths.
 * @param prices
 * @param currency
 * @returns
 */
export const getRowData = (
  prices: PricingSheetPrice[],
  unit: string,
  clientGridViewType: GridViewType,
  priceColumn: PriceColumn = PriceColumn.price,
  regions: Region[] = [
    {
      priceColumn: PriceColumn.price,
      supplierKey: '',
      enabled: true,
      exclusionZone: false,
      label: '',
      rowId: '',
      regionKey: '',
    },
  ],
) => {
  const rowData: PriceRow[] = [];

  // return row data based on grid view type
  if (clientGridViewType === GridViewType.Grid) {
    const lengths = getAllLengths(prices);
    lengths.forEach((length, idx) => {
      let lengthSpanLabel = '';
      if (idx === 0) {
        lengthSpanLabel = i18n.t(I18nKeys.PricingSheetLength);
      }
      const pricesForThisLength = prices.filter((price) => `${price.length}` === length);

      const row: PriceRow = {
        rowId: `${idx}`,
        length: `${length} ${unit}`,
        lengthSpanLabel,
      };
      pricesForThisLength.forEach((price) => {
        row[`${price.width}`] = {
          diff: price.diff,
          [priceColumn]: price[priceColumn] ? price[priceColumn] : '',
          clientDataRowId: price.rowId,
          hidden: price.hidden,
        };
      });
      rowData.push(row);
    });
  } else {
    [...prices]
      .sort((p1, p2) => p1.width - p1.width || p2.length - p2.length)
      .forEach((price, idx) => {
        let lengthSpanLabel = '';
        if (idx === 0) {
          lengthSpanLabel = `${i18n.t(I18nKeys.PricingSheetWidth)} x ${i18n.t(I18nKeys.PricingSheetLength)}`;
        }

        const row: PriceRow = {
          rowId: `${idx}`,
          length: `${price.width}x${price.length} ${unit}`,
          lengthSpanLabel,
        };

        for (let i = 0; i < regions.length; i += 1) {
          const region = regions[i];
          row[`${region.priceColumn}`] = {
            diff: price.diff,
            [region.priceColumn]: price[region.priceColumn] ? price[region.priceColumn] : '',
            clientDataRowId: price.rowId,
            hidden: price.hidden,
          };
        }
        rowData.push(row);
      });
  }

  return rowData;
};

const openCantEditDialog = (dispatch: Dispatch<any>) => {
  dispatch(openDialog({ dialog: Dialogs.PricingContactSupport }));
};

/**
 * AG Grid value setter for the pricing data. Handles the pricing value when the user edits a cell.
 *
 * @param clientDataTableId
 * @param params
 * @param cellMetadata
 * @param dispatch
 * @returns
 */
export const priceColumnValueSetter = (
  clientDataTableId: string,
  priceColumn: string,
  params: ValueSetterParams,
  dispatch: Dispatch<any>,
  t: Function,
) => {
  try {
    const { data, colDef, oldValue: pOldValue, newValue: pNewValue } = params;
    const oldValue = pOldValue ? `${pOldValue}`.trim() : pOldValue;
    let newValue = typeof pNewValue === 'number' ? pNewValue.toString() : pNewValue;
    const columnId = colDef.colId || params.column.getColId();

    const valueChanged = arePriceValuesDifferent(oldValue, newValue);

    if (!columnId || (!data[columnId] && valueChanged)) {
      openCantEditDialog(dispatch);
      return false;
    }

    if (valueChanged && colDef.editable) {
      // Data validation. Only allow prices that are valid numbers and greater than 0.
      newValue = getValidatedNewValue(newValue, oldValue);

      data[columnId][priceColumn] = newValue;
      const dataNew = { rowId: data[columnId].clientDataRowId, [priceColumn]: oldValue };

      if (!newValue && !localStorage.getItem(LocalStorage.HaveShownPricingBaseDeletingPriceDialog)) {
        dispatch(openNotificationDialog('', t(I18nKeys.PricingBaseDeletingPriceDialog)));
        dispatch(openDialog({ dialog: Dialogs.Notification }));
        localStorage.setItem(LocalStorage.HaveShownPricingBaseDeletingPriceDialog, '1');
      }

      const { newRows, oldRows } = processUpdatedValues(
        [{ data: dataNew, column: priceColumn, oldValue, newValue }],
        [],
      );
      if (newRows.length > 0) {
        addDispatchCommandToUndo(
          dispatch,
          [updatePricingBaseRows(oldRows)],
          [updatePricingBaseRows(newRows)],
          clientDataTableId,
          true,
        );
      }
    }
    return true;
  } catch (e) {
    console.error(`Failed to set value: `, e);
    return false;
  }
};

export const updateValues = (
  clientDataTableId: string,
  updates: { priceColumn: string; data: any; column: string; oldValue: any; newValue: any; colDef: ColDef }[],
  cellMetadata: CellMetadata[],
  dispatch: Dispatch<any>,
) => {
  try {
    const newValues: { table?: string; data: any; column: string; oldValue: any; newValue: any }[] = [];
    updates.forEach(({ priceColumn, data, colDef, column, oldValue: pOldValue, newValue: pNewValue }) => {
      const oldValue = pOldValue ? `${pOldValue}`.trim() : pOldValue;
      let newValue = typeof pNewValue === 'number' ? pNewValue.toString() : pNewValue;
      const columnId = colDef.colId || column;

      const valueChanged = arePriceValuesDifferent(oldValue, newValue);

      if (data[columnId] && valueChanged && colDef.editable) {
        newValue = getValidatedNewValue(newValue, oldValue);
        const dataNew = { rowId: data[columnId].clientDataRowId, [priceColumn]: oldValue };
        newValues.push({ data: dataNew, column: priceColumn, oldValue, newValue });
      }
    });

    const { newRows, oldRows } = processUpdatedValues(newValues, cellMetadata);
    if (newRows.length > 0) {
      addDispatchCommandToUndo(
        dispatch,
        [updatePricingBaseRows(oldRows)],
        [updatePricingBaseRows(newRows)],
        clientDataTableId,
        true,
      );
    }
    return true;
  } catch (e) {
    console.error(`Failed to set value: `, e);
    return false;
  }
};

export const removePrices = (
  clientDataTableId: string,
  nodes: any[],
  dataColumn: string | undefined,
  dispatch: Dispatch<any>,
) => {
  try {
    const updatedRows = [];
    for (let i = 0; i < nodes.length; i += 1) {
      const node = nodes[i];
      if (node) {
        const priceColumn = dataColumn || node.priceColumn;
        const { data } = node;
        const dataNew = { rowId: data.clientDataRowId, [priceColumn]: data[priceColumn] };
        updatedRows.push({
          data: dataNew,
          column: priceColumn,
          oldValue: data[priceColumn],
          newValue: '',
        });
      }
    }
    const { newRows, oldRows } = processUpdatedValues(updatedRows, []);

    if (newRows.length > 0) {
      addDispatchCommandToUndo(
        dispatch,
        [updatePricingBaseRows(oldRows)],
        [updatePricingBaseRows(newRows)],
        clientDataTableId,
        true,
      );
    }
  } catch (e) {
    console.error(`Failed to remove prices: `, e);
  }
};

/**
 * AG Grid formatter for the pricing data. Handles the currency to display
 *
 * @param params
 * @param currency
 * @returns
 */
export const priceColumnFormatter = (
  params: ValueFormatterParams,
  priceColumn: PriceColumn,
  formatPriceWithDecimal: boolean,
  regionKey: string,
  currency?: string,
) => {
  const {
    colDef: { colId = '' },
    data,
  } = params;
  const { [colId]: value } = data;

  let price;
  let minimumFractionDigits = 0;
  if (colId) {
    price = value ? value[priceColumn] : '';
    if (formatPriceWithDecimal && price) {
      minimumFractionDigits = 2;
    }
  }

  const formattedPrice = price ? formatPrice(price, currency, minimumFractionDigits) : '';
  if (value?.hidden[regionKey]) return i18n.t(I18nKeys.PricingBaseHiddenPrice);
  return formattedPrice;
};

/**
 * AG Grid value getter for the pricing data
 *
 * @param params
 * @param cellMetadata
 * @returns
 */
export const pricingColumnValueGetter = (params: ValueGetterParams, priceColumn: PriceColumn) => {
  const { data, colDef } = params;
  const { colId = '' } = colDef;
  // const cellMetadata = (context.cellMetadata || []) as CellMetadata[];
  let price;
  if (data[colId]) {
    price = data[colId] ? data[colId][priceColumn] : '';
  }
  return price || '';
};

/**
 * Gets an array of client data row ids from the selected cell range
 *
 * @param gridApi
 * @returns
 */
export const getClientDataRowIdsFromSelectedRange = (gridApi: GridApi): string[] => {
  const cellRanges = gridApi.getCellRanges();
  if (!cellRanges || !cellRanges.length) return [];
  const { startRowIndex, endRowIndex, columns } = getCellRangeInfo(cellRanges);
  if (startRowIndex === undefined || endRowIndex === undefined) return [];

  const numRows = Math.abs(endRowIndex - startRowIndex) + 1;
  const clientDataRowIdsfromSelection = [] as any[];
  for (let i = 0; i < numRows; i += 1) {
    columns.forEach((column) => {
      const row = gridApi.getDisplayedRowAtIndex(i + startRowIndex)?.data[column]?.clientDataRowId;
      if (row) {
        clientDataRowIdsfromSelection.push(row);
      }
    });
  }
  return clientDataRowIdsfromSelection;
};

/**
 * Update the cell metadata for a cell
 *
 * @param clientDataTableId
 * @param updates
 * @param dispatch
 * @param getUndoActions - optional function to get additional undo actions
 */
export const updateCellMetadata = (
  clientDataTableId: string,
  updates: UpdateClientDataMetadata[],
  dispatch: Dispatch<any>,
) => {
  const [{ cellsMetadata = [] } = {}] = updates;

  const undoUpdates = updates.map((update) => ({
    ...update,
    value: getPropertyFromCellMetadata(cellsMetadata, update.rowId, update.colId, update.metadataProperty),
  }));

  addDispatchCommandToUndo(
    dispatch,
    [updatePricingMetadata(undoUpdates)],
    [updatePricingMetadata(updates)],
    clientDataTableId,
    true,
  );
};

/**
 * Processes the data from the grid to the clipboard
 *
 * @param clientDataTableId
 * @param params Process Data From Clipboard event params
 * @param param1 Cell metadata and dispatch function
 * @returns
 */
export const processDataFromClipboard = (
  params: ProcessDataFromClipboardParams,
  {
    clientDataTableId,
    getPriceColumn,
    dispatch,
  }: { clientDataTableId: string; getPriceColumn: (colId: string) => PriceColumn; dispatch: Dispatch<any> },
) => {
  const { data: cellMatrix, api, columnApi } = params;
  const { startRowIndex, columns: selectedColumns } = getCellRangeInfo(api.getCellRanges());

  // Columns must be ordered for correct index
  const allColumnsOrdered = (columnApi?.getAllGridColumns() || [])
    .map((col) => col.getColId())
    // Sort so that columns pinned left are first, then unpinned, then pinned right
    .sort((a, b) => {
      const [aOrder, bOrder] = [a, b].map((col) => {
        const column = columnApi?.getColumn(col);
        if (column?.isPinnedLeft()) return -1;
        if (column?.isPinnedRight()) return 1;
        return 0;
      });
      return aOrder - bOrder;
    });
  const selectedColumnsOrdered = allColumnsOrdered.filter((col) => selectedColumns.includes(col));
  const [firstColumn] = selectedColumnsOrdered;
  const columnStartIndex = allColumnsOrdered.indexOf(firstColumn);

  if (startRowIndex === undefined || columnStartIndex === -1) return null;

  const newRows: UpdateClientDataRow[] = [];
  const oldRows: UpdateClientDataRow[] = [];

  const clipboardDataMatrix = JSON.parse(
    localStorage.getItem(LocalStorage.ClientDataClipboardData) || '[]',
  ) as ClipboardData[][];

  let gridDataMatrix = cellMatrix;
  // After joining with delimiters, compare the grid data to the clipboard data
  const joinedGridData = gridDataMatrix.map((row) => row.join(COLUMN_DELIMITER)).join(ROW_DELIMITER);
  const joinedClipboardData = clipboardDataMatrix
    .map((row) => row.map((cell) => cell.value).join(COLUMN_DELIMITER))
    .join(ROW_DELIMITER);
  if (joinedGridData === joinedClipboardData) {
    // If the data is the same, use the clipboard data matrix to get rid of any extra rows/columns
    // from \t and \n characters
    if (
      gridDataMatrix.length !== clipboardDataMatrix.length ||
      gridDataMatrix.every((row, i) => row.length !== clipboardDataMatrix[i].length)
    ) {
      gridDataMatrix = clipboardDataMatrix.map((row) => row.map((cell) => cell.value));
    }
  }

  for (let i = 0; i < gridDataMatrix.length; i += 1) {
    // Find the row where the data will be pasted
    const { id: rowId, data } = api.getDisplayedRowAtIndex(startRowIndex + i) || {};

    // If the row doesn't exist, ignore it
    if (!rowId || data.rowIsReadOnly) return null;

    // Repeat the cell matrix if there are more rows than cells
    const [row] = [gridDataMatrix, clipboardDataMatrix].map((matrix) => matrix[i % matrix.length] || []);

    (row as string[]).forEach((value, j) => {
      // If ran out columns, ignore this value
      if (columnStartIndex + j >= allColumnsOrdered.length) return;

      const column = allColumnsOrdered[columnStartIndex + j];

      // Don't add if there isn't a valid data entry for this column or if the value isinvalid
      if (!data[column] || value === undefined || value === null) return;
      const priceColumn = getPriceColumn(column);

      const oldValue = data[column][priceColumn];
      const validatedValue = getValidatedNewValue(value, oldValue);

      oldRows.push({
        rowData: { [priceColumn]: data[column][priceColumn], rowId: data[column].clientDataRowId },
        column: priceColumn,
        value: data[column][priceColumn],
        formula: undefined,
      });
      newRows.push({
        rowData: { [priceColumn]: data[column][priceColumn], rowId: data[column].clientDataRowId },
        column: priceColumn,
        value: validatedValue,
        formula: undefined,
      });
    });
  }

  addDispatchCommandToUndo(
    dispatch,
    [updatePricingBaseRows(oldRows)],
    [updatePricingBaseRows(newRows)],
    clientDataTableId,
    true,
  );
  return null;
};

/**
 * Handles sheet name updates, updating each row in the selected sheet with the new name
 * The priceSetLabel column holds the sheet name value.
 *
 * @param title - the new title for this sheet
 * @param clientDataTableId - the table to update
 * @param selectedPricingSheet - the sheet that is being operated on
 * @param dispatch
 * @returns
 */
export const updateSheetTitle = (
  title: string,
  clientDataTableId: string,
  selectedPricingSheet: PricingSheet,
  dispatch: Dispatch<any>,
) => {
  const newRows: UpdateClientDataRow[] = [];

  selectedPricingSheet.prices.forEach((price) => {
    if (price.priceSetLabel !== title) {
      newRows.push({
        rowData: { priceSetLabel: price.priceSetLabel, rowId: price.rowId },
        column: 'priceSetLabel',
        value: title,
        formula: undefined,
      });
    }
  });

  dispatch(updatePricingBaseRows(newRows));
};
