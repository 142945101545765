import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormControlLabel, FormControl, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SystemOrderSupplierCalculations, SystemOrderCalculations } from '../constants/PricingAdjustment';
import { AppState } from '../types/AppState';
import {
  updatePricingSupplierSelection as updatePricingSupplierSelectionFunc,
  setPricingSelection,
} from '../ducks/pricingAdjustment';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { openConfirmationDialog } from '../ducks/confirmation';
import { unknownGroup } from '../constants/Group';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';

interface StateProps {
  pricingSupplierSelection: string | undefined;
  supplierName: string | undefined;
  surcharge: boolean;
}

interface DispatchProps {
  updatePricingSupplierSelection(selection: string, supplierName: string | undefined, surcharge: boolean): void;
}

type Props = StateProps & DispatchProps;

const PricingSupplierSelectorComponent: React.FC<Props> = ({
  pricingSupplierSelection,
  supplierName,
  surcharge,
  updatePricingSupplierSelection,
}: Props) => {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selection = (event.target as HTMLInputElement).value;
    updatePricingSupplierSelection(selection, supplierName, surcharge);
  };

  return (
    <FormControl component="fieldset">
      <FormControlLabel
        control={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Checkbox
            checked={pricingSupplierSelection === SystemOrderSupplierCalculations.Inherit}
            name="pricingOptions"
            value={pricingSupplierSelection}
            onChange={handleChange}
            color="primary"
          />
        }
        key={pricingSupplierSelection}
        label={t(I18nKeys.PricingSupplierSelector, {
          supplierName,
          surchargeDiscount: surcharge ? 'surcharge' : 'discount',
        })}
      />
    </FormControl>
  );
};

const mapStateToProps = ({
  currentUser: { group: { configurators } = unknownGroup },
  pricingAdjustment: { pricingSupplierSelection, supplierSurchargeCalculation, supplierKey, clientId },
}: AppState): StateProps => {
  const configurator = configurators ? configurators.find((config) => config.clientId === clientId) : undefined;
  const supplier =
    configurator && configurator.suppliers && configurator.suppliers.length > 0
      ? configurator.suppliers.find((supp) => supp.supplierKey === supplierKey)
      : undefined;
  const supplierName = supplier ? supplier.supplierName || supplierKey : supplierKey;

  let surcharge = true;
  if (supplierSurchargeCalculation) {
    const amountChanged = supplierSurchargeCalculation.calculation.amountChange || 0;
    const percentChanged = supplierSurchargeCalculation.calculation.percentChange || 0;
    surcharge = amountChanged >= 0 && percentChanged >= 0;
  }

  return {
    pricingSupplierSelection,
    supplierName,
    surcharge,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updatePricingSupplierSelection: (selection, supplierName, surcharge): void => {
    if (selection === SystemOrderSupplierCalculations.Off) {
      dispatch(setPricingSelection(SystemOrderCalculations.None));
      dispatch(updatePricingSupplierSelectionFunc(SystemOrderSupplierCalculations.Inherit));
    } else {
      dispatch(
        openConfirmationDialog(
          [updatePricingSupplierSelectionFunc(SystemOrderSupplierCalculations.Off)],
          [],
          i18n.t(I18nKeys.PricingSupplierSelectorConfirmationTitle, {
            supplierName,
            surchargeDiscount: surcharge ? 'surcharge' : 'discount',
          }),
          ' ',
          i18n.t(I18nKeys.PricingSupplierSelectorConfirmationTurnOff),
        ),
      );
      dispatch(openDialog({ dialog: Dialogs.Confirmation }));
    }
  },
});

export const PricingSupplierSelector = connect(mapStateToProps, mapDispatchToProps)(PricingSupplierSelectorComponent);
