/* eslint-disable no-param-reassign */
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { PricingState } from '../types/PricingState';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { PublishingResult } from '../types/ClientData';
import { UpdateClientDataMetadata, UpdateClientDataRow } from './clientDataSlice';
import { PricingAdjustmentActionTypes } from './pricingAdjustment';
import { ComponentCategoryKey } from '../constants/ComponentCategoryKey';
import { resetClientIds } from './viewerSlice';

const initialState: PricingState = {
  highlightedCell: undefined,
  publishMergeResult: undefined,
  base: {
    pricingDataBranch: undefined,
    selectedPricingSheetId: undefined,
    pricingSheets: undefined,
  },
  component: {
    region: undefined,
    pricingDataBranch: undefined,
    selectedComponentCategoryKey: undefined,
    selectedComponentId: undefined,
  },
  fetchingEndpoints: {},
};

export const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setPricingBaseDataBranch(state, action: PayloadAction<ClientDataBranch | undefined>) {
      state.base.pricingDataBranch = action.payload;
    },
    setHighlightedCell(state, action: PayloadAction<{ region: string; colId?: string; rowId?: string } | undefined>) {
      const { payload } = action;
      state.highlightedCell = payload;
    },
    setPricingPublishMergeResult(
      state,
      action: PayloadAction<{
        data: PublishingResult | undefined;
        error: any | undefined;
        isSuccess: boolean;
      }>,
    ) {
      state.publishMergeResult = action.payload;
    },
    setSelectedPricingSheetId(state, action: PayloadAction<string | undefined>) {
      state.base.selectedPricingSheetId = action.payload;
    },
    setPricingBaseAccordionExpanded(state, action: PayloadAction<{ expandedStatus: string[] }>) {
      state.base.pricingBaseAccordion = action.payload.expandedStatus;
    },
    setPricingComponentRegion(state, action: PayloadAction<string>) {
      state.component.region = action.payload;
    },
    setPricingComponentDataBranch(state, action: PayloadAction<ClientDataBranch | undefined>) {
      state.component.pricingDataBranch = action.payload;
    },
    setSelectedComponentCategoryKey(state, action: PayloadAction<ComponentCategoryKey | undefined>) {
      state.component.selectedComponentCategoryKey = action.payload;
    },
    setSelectedComponentId(state, action: PayloadAction<string | undefined>) {
      state.component.selectedComponentId = action.payload;
    },
    addFetchingEndpoint(state, action: PayloadAction<{ promise: Promise<void>; endpointCacheKey: string }>) {
      state.fetchingEndpoints[action.payload.endpointCacheKey] = action.payload.promise;
    },
    removeFetchingEndpoint(state, action: PayloadAction<string>) {
      state.fetchingEndpoints[action.payload] = undefined;
    },
  },
  extraReducers: (builder) => {
    // Add case to reset client ID when viewer IDs are reset
    builder.addCase(resetClientIds.type, (state) => ({
      ...initialState,
      pricingBaseAccordion: state.base.pricingBaseAccordion,
    }));
    builder.addCase(PricingAdjustmentActionTypes.SET_PRICING_CLIENT_SUPPLIER, (state) => ({
      ...state,
      pricingDataBranch: undefined,
    }));
  },
});

export const {
  setPricingBaseDataBranch,
  setHighlightedCell,
  setPricingPublishMergeResult,
  setSelectedPricingSheetId,
  setPricingBaseAccordionExpanded,
  setPricingComponentDataBranch,
  setPricingComponentRegion,
  setSelectedComponentCategoryKey,
  setSelectedComponentId,
  addFetchingEndpoint,
  removeFetchingEndpoint,
} = pricingSlice.actions;

export const updatePricingComponentRows = createAction<(UpdateClientDataRow & { table: string })[]>(
  'pricing/updatePricingComponentRows',
);

export const updatePricingBaseRows = createAction<UpdateClientDataRow[]>('pricing/updatePricingBaseRows');

export const updatePricingMetadata = createAction<UpdateClientDataMetadata[]>('clientData/updatePricingMetadata');
