import { Dispatch } from 'redux';
import { TFunction } from 'i18next';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataBranchMetadata, PublishingResult } from '../types/ClientData';
import { PublishBarButton } from '../types/Viewer';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { setPricingBaseDataBranch, setPricingComponentDataBranch } from '../ducks/pricingSlice';
import { ClientDataType } from '../constants/ClientDataType';
import { SystemOrderCalculations, pricingText } from '../constants/PricingAdjustment';
import { SurchargeSystemOrderCalculation } from '../types/PricingAdjustment';
import { findMatchingSurchargeCalculation } from './pricingUtils';
import { DraftComparisonType, compareDraftEquality } from './draftAndActiveUtils';
import {
  attemptToOpenConfiguratorModal,
  publishSurcharge,
  revertSurcharge,
  saveSurcharge,
} from '../ducks/pricingAdjustment';
import { config } from '../config/config';
import { sitesText } from '../constants/VendorData';
import { setClientDataBranch } from '../ducks/clientDataSlice';
import { MergeStatus } from '../constants/ClientData';
import { setSiteDetailPublishMergeResult } from '../ducks/vendorDataSlice';
import { getConfiguratorPreviewUrl } from './clientDataUtils';
import { mapClientIdToConfiguratorAndVendor } from './clientIdUtils';
import { getConfiguratorUrlWithLocale } from './vendorUtils';

export const getSharedPricingButtons = ({
  dispatch,
  t,
  clientId,
  groupId,
  branch,
  activeBranches,
  deleteBranch,
  isPublishing,
}: {
  dispatch: Dispatch<any>;
  t: TFunction;
  clientId: string;
  groupId: string;
  branch: ClientDataBranch;
  activeBranches: ClientDataBranchMetadata[];
  deleteBranch: (args: {
    dataType: ClientDataType;
    branch: ClientDataBranch;
    clientId: string;
    groupId: string;
  }) => MutationActionCreatorResult<any>;
  isPublishing: boolean;
}): PublishBarButton[] => {
  // Disable buttons if branch does not exist
  const branchExists = !!activeBranches.find((b) => b.branchType === branch);
  return [
    {
      label: t(I18nKeys.RevertButton),
      onClick: (): void => {
        dispatch(
          openConfirmationDialog(
            [],
            [
              () => {
                if (branchExists) {
                  deleteBranch({
                    dataType: ClientDataType.Supplier,
                    branch,
                    clientId,
                    groupId,
                  }).unwrap();
                  dispatch(
                    branch === ClientDataBranch.ClientUpdate
                      ? setPricingComponentDataBranch(ClientDataBranch.Main)
                      : setPricingBaseDataBranch(ClientDataBranch.Main),
                  );
                }
              },
            ],
            t(
              branch === ClientDataBranch.ClientUpdate
                ? I18nKeys.PricingComponentRevertConfirmationTitle
                : I18nKeys.PricingBaseRevertConfirmationTitle,
            ),
            t(
              branch === ClientDataBranch.ClientUpdate
                ? I18nKeys.PricingComponentRevertConfirmationMessage
                : I18nKeys.PricingBaseRevertConfirmationMessage,
            ),
            t(I18nKeys.RevertButton),
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled: !branchExists,
      loading: isPublishing,
      emphasis: 'secondary',
    },
    {
      label: t(I18nKeys.PreviewButton),
      onClick: () => {
        dispatch(
          openDialog({
            dialog:
              branch === ClientDataBranch.ClientUpdate ? Dialogs.PricingComponentPreview : Dialogs.PricingBasePreview,
          }),
        );
      },
      disabled: !branchExists,
      loading: isPublishing,
      emphasis: 'primary',
    },
    {
      label: t(I18nKeys.PublishButton),
      onClick: () => {
        dispatch(openDialog({ dialog: Dialogs.PricingPublish }));
      },
      disabled: !branchExists,
      loading: isPublishing,
      emphasis: 'primary',
    },
  ];
};

export const getPricingAdjustmentButtons = ({
  dispatch,
  t,
  clientId,
  supplierKey,
  pricingSelection,
  draftSurchargeCalculations,
  activeSurchargeCalculation,
  publishEnabled,
  loading,
}: {
  dispatch: Dispatch<any>;
  t: TFunction;
  clientId: string;
  supplierKey: string;
  pricingSelection: SystemOrderCalculations;
  draftSurchargeCalculations: SurchargeSystemOrderCalculation[];
  activeSurchargeCalculation: SurchargeSystemOrderCalculation | undefined;
  publishEnabled: boolean;
  loading: boolean;
}): PublishBarButton[] => {
  const draftCalc = findMatchingSurchargeCalculation(
    clientId,
    draftSurchargeCalculations,
    supplierKey,
    pricingSelection,
  );
  const draftEqualToActive = compareDraftEquality(draftCalc, activeSurchargeCalculation, DraftComparisonType.Surcharge);
  return [
    {
      label: t(I18nKeys.RevertButton),
      onClick: (): void => {
        dispatch(
          openConfirmationDialog(
            [revertSurcharge(clientId)],
            [],
            t(pricingText.revertConfirmationTitle),
            t(pricingText.revertConfirmationBody),
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled: draftEqualToActive,
      loading,
      emphasis: 'secondary',
    },
    {
      label: t(I18nKeys.PreviewButton),
      onClick: () => {
        dispatch(saveSurcharge(clientId));
        dispatch(attemptToOpenConfiguratorModal());
      },
      disabled: draftEqualToActive,
      loading,
      emphasis: 'primary',
    },
    {
      label: t(I18nKeys.PublishButton),
      onClick: () => {
        dispatch(
          openConfirmationDialog(
            [publishSurcharge(clientId)],
            [],
            t(pricingText.publishConfirmationTitle),
            t(pricingText.publishConfirmationBody).replace('$env', config.environment.STAGE || ''),
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled: !publishEnabled,
      loading,
      emphasis: 'primary',
    },
  ];
};

export const getSitesButtons = ({
  dispatch,
  t,
  clientId,
  groupId,
  clientDataType,
  clientDataBranch,
  locale,
  productionURL,
  deleteBranch,
  publishClientData,
  isInitializingSelectedTableData,
  isCreatingBranch,
  isPublishing,
}: {
  dispatch: Dispatch<any>;
  t: TFunction;
  clientId: string;
  groupId: string;
  clientDataType: ClientDataType;
  clientDataBranch: ClientDataBranch | undefined;
  locale: string;
  productionURL: string;
  deleteBranch: (args: {
    dataType: ClientDataType;
    branch: ClientDataBranch;
    clientId: string;
    groupId: string;
  }) => MutationActionCreatorResult<any>;
  publishClientData: (args: {
    dataType: ClientDataType;
    branch: ClientDataBranch;
    clientId: string;
    groupId: string;
    message: string;
  }) => MutationActionCreatorResult<any>;
  isInitializingSelectedTableData: boolean;
  isCreatingBranch: boolean;
  isPublishing: boolean;
}): PublishBarButton[] => {
  const disabled =
    clientDataBranch !== ClientDataBranch.SiteDetail ||
    isInitializingSelectedTableData ||
    isCreatingBranch ||
    isPublishing;
  const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);
  const configuratorUrl = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
  return [
    {
      label: t(I18nKeys.RevertButton),
      onClick: (): void => {
        dispatch(
          openConfirmationDialog(
            [],
            [
              () => {
                if (clientDataBranch) {
                  deleteBranch({
                    dataType: clientDataType,
                    branch: clientDataBranch,
                    clientId,
                    groupId,
                  })
                    .unwrap()
                    .then(() => {
                      dispatch(setClientDataBranch(ClientDataBranch.Main));
                    });
                }
              },
            ],
            t(sitesText.revertConfirmationTitle),
            t(sitesText.revertConfirmationBody),
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled,
      loading: isPublishing,
      emphasis: 'secondary',
    },
    {
      label: t(I18nKeys.PreviewButton),
      onClick: (): void => {
        const previewUrl = getConfiguratorPreviewUrl(configuratorUrl, clientDataBranch, undefined, undefined);
        window.open(previewUrl, '_blank', 'noopener noreferrer');
      },
      disabled,
      loading: isPublishing,
      emphasis: 'primary',
    },
    {
      label: t(I18nKeys.PublishButton),
      onClick: (): void => {
        dispatch(
          openConfirmationDialog(
            [],
            [
              () => {
                if (clientDataBranch) {
                  publishClientData({
                    dataType: clientDataType,
                    branch: clientDataBranch,
                    clientId,
                    groupId,
                    message: `Publishing site details for ${clientId}`,
                  })
                    .unwrap()
                    .then((result) => {
                      const data = result as PublishingResult;
                      if (data?.mainMerge?.status === MergeStatus.Succeed) {
                        dispatch(setClientDataBranch(ClientDataBranch.Main));
                      }
                      dispatch(setSiteDetailPublishMergeResult({ data, isSuccess: true, error: undefined }));
                    })
                    .catch((error) => {
                      dispatch(setSiteDetailPublishMergeResult({ data: undefined, isSuccess: false, error }));
                      dispatch(openDialog({ dialog: Dialogs.SiteDetailPublishResult }));
                    })
                    .finally(() => {
                      dispatch(openDialog({ dialog: Dialogs.SiteDetailPublishResult }));
                    });
                }
              },
            ],
            t(sitesText.publishConfirmationTitle),
            t(sitesText.publishConfirmationBody, { env: config.environment.STAGE || '' }),
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled,
      loading: isPublishing,
      emphasis: 'primary',
    },
  ];
};
