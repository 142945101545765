import { createApi } from '@reduxjs/toolkit/query/react';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { API_NAMES } from '../constants/App';
import {
  amplifyAPIBaseQuery,
  getEndpointCacheKey,
  displayIfNotBranchNotFound,
  getRequestHeader,
} from '../utils/apiUtils';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ComponentCategoryKey } from '../constants/ComponentCategoryKey';
import { ComponentCategory, ComponentCategoryItem } from '../types/ComponentPricing';
import { GridData } from '../types/DataGrid';
import { AppState } from '../types/AppState';
import { addFetchingEndpoint, removeFetchingEndpoint } from '../ducks/pricingSlice';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { Decimal65, areDiffValuesDifferent, getRowDiffColumnToFromValues } from '../utils/clientDataUtils';
import {
  DisplayColumns,
  PricingCalculationColumns,
  PricingColumns,
  RegionPriceColumns,
} from '../constants/ComponentPricing';
import { ClientDataType } from '../constants/ClientDataType';
import { clientDataApi, getClientDataCacheTag } from './clientDataApi';
import { getUpdatedTableDataDiff } from '../ducks/client-data/getUpdatedTableDataDiff';
import { ClientDataCacheTagType, PricingDataCacheTagType } from '../constants/ClientData';
import { PricingSheet } from '../types/PricingSheet';
import { getAttributeLabel } from '../constants/PricingSheetAttributeType';
import { i18n } from '../i18n';
import { Region } from '../types/Region';
import { parsePriceValue } from '../utils/componentPricingUtils';

export const pricingApi = createApi({
  reducerPath: 'pricingApi',
  tagTypes: [
    ClientDataCacheTagType.Branches,
    ClientDataCacheTagType.BranchTableDiff,
    ClientDataCacheTagType.TableData,
    ClientDataCacheTagType.PublishedVersions,
    ClientDataCacheTagType.ChangesSummary,
    ClientDataCacheTagType.CellMetadata,
    ClientDataCacheTagType.TablesData,
    ClientDataCacheTagType.VendorData,
    ClientDataCacheTagType.ComponentCategoryItems,
    ClientDataCacheTagType.ComponentCategories,
    ClientDataCacheTagType.Regions,
    PricingDataCacheTagType.PricingSheets,
  ],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/pricing',
  }),
  endpoints: (builder) => ({
    /**
     * Get the list of component categories for a specific client
     */
    getComponentCategories: builder.query<
      ComponentCategory[],
      { groupId: string; clientId: string; branch: ClientDataBranch }
    >({
      query: ({ groupId, clientId, branch }) => ({
        url: `/components?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
        displayToastOnError: displayIfNotBranchNotFound(ClientDataBranch.ClientUpdate),
      }),
      providesTags: (result, error, { groupId, clientId, branch }) => [
        getClientDataCacheTag(ClientDataCacheTagType.ComponentCategories, {
          clientDataType: ClientDataType.Supplier,
          groupId,
          clientId,
          branch,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getComponentCategories', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    /**
     * Get the list of components with their prices for a specific ComponentCategory
     */
    getComponentCategoryItems: builder.query<
      ComponentCategoryItem[],
      { groupId: string; clientId: string; category: ComponentCategoryKey; branch: ClientDataBranch }
    >({
      query: ({ groupId, clientId, category, branch }) => ({
        url: `/components/${category}?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
        displayToastOnError: displayIfNotBranchNotFound(ClientDataBranch.ClientUpdate),
      }),
      providesTags: (result, error, { groupId, clientId, category, branch }) => [
        getClientDataCacheTag(ClientDataCacheTagType.ComponentCategoryItems, {
          clientDataType: ClientDataType.Supplier,
          groupId,
          clientId,
          category,
          branch,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getComponentCategoryItems', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    /**
     * Get all list of all possible client update pricing regions
     */
    getClientUpdateRegions: builder.query<Region[], { groupId: string; clientId: string; branch: ClientDataBranch }>({
      query: ({ groupId, clientId, branch }) => ({
        url: `/regions?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
        displayToastOnError: displayIfNotBranchNotFound(ClientDataBranch.ClientUpdate),
      }),
      providesTags: (result, error, { groupId, clientId, branch }) => [
        getClientDataCacheTag(ClientDataCacheTagType.Regions, {
          clientDataType: ClientDataType.Supplier,
          groupId,
          clientId,
          branch,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getClientUpdateRegions', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    getClientPricingSheets: builder.query<PricingSheet[], { clientId: string; groupId: string; branch: string }>({
      query: ({ clientId, groupId, branch }) => ({
        url: `/base/sheets?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
        displayToastOnError: displayIfNotBranchNotFound(ClientDataBranch.Pricing),
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getClientPricingSheets', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
      providesTags: [PricingDataCacheTagType.PricingSheets],
      transformResponse: (response: PricingSheet[]) =>
        response
          .map((sheet, index) => ({
            ...sheet,
            id: `${index + 1}`,
          }))
          .sort((a, b) => {
            const [labelA, labelB] = [a, b].map(
              (sheet) =>
                sheet.prices[0]?.priceSetLabel ||
                sheet.attributes.map((attribute) => getAttributeLabel(attribute, i18n.t)).join() ||
                '',
            );
            return labelA.localeCompare(labelB);
          }),
    }),
  }),
});

export const updateComponentCategoryItemsCache = (
  {
    groupId,
    clientId,
    data,
    fetchPromises,
    patches,
  }: {
    groupId: string;
    clientId: string;
    data: GridData;
    fetchPromises: Promise<void>[];
    patches: PatchCollection[];
  },
  isDelete: boolean,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  state: RootState<any, any, 'pricingApi'> & RootState<any, any, 'clientDataApi'> & AppState,
) => {
  const { pricingDataBranch: clientUpdateDataBranch = ClientDataBranch.Main, selectedComponentCategoryKey: category } =
    state.pricing.component;

  if (!category) return;

  const componentCategoryItemsQueryArgs = {
    groupId,
    clientId,
    category,
    branch: ClientDataBranch.ClientUpdate,
  };

  const fetchingComponentCategoryItemsPromise =
    state.pricing.fetchingEndpoints[getEndpointCacheKey('getComponentCategoryItems', componentCategoryItemsQueryArgs)];
  if (fetchingComponentCategoryItemsPromise) fetchPromises.push(fetchingComponentCategoryItemsPromise);

  const { data: originalComponentCategoryItems } = pricingApi.endpoints.getComponentCategoryItems.select(
    componentCategoryItemsQueryArgs,
  )(state);

  if (!originalComponentCategoryItems) return;

  const tableRows = Object.values(data).flat();
  tableRows.forEach((row) => {
    patches.push(
      dispatch(
        pricingApi.util.updateQueryData('getComponentCategoryItems', componentCategoryItemsQueryArgs, (draft) => {
          const { [ClientDataFixedColumns.RowId]: rowId, [PricingColumns.Price]: newDefaultPrice } = row;
          const draftComponentCategoryItems = [
            ...draft.filter((component) => component[ClientDataFixedColumns.RowId] !== rowId),
          ];
          if (!isDelete) {
            const originalItem = (draft.find((component) => component[ClientDataFixedColumns.RowId] === rowId) ||
              {}) as ComponentCategoryItem;
            const { [ClientDataFixedColumns.Order]: originalOrder, [PricingColumns.Price]: originalDefaultPrice } =
              originalItem;

            // Use original order if the updated row doesn't have an order
            const newOrder =
              row[ClientDataFixedColumns.Order] === undefined ? originalOrder : row[ClientDataFixedColumns.Order];

            // Find the index of the first row that has an order greater than the updated row
            const insertIndex = draftComponentCategoryItems.findIndex((r) => {
              const [rowOrder, updatedRowOrder] = [r?.order, newOrder].map((order) =>
                order !== undefined && order !== null ? new Decimal65(order as string) : new Decimal65(0),
              );
              return rowOrder.greaterThan(updatedRowOrder);
            });

            const updatedItem: ComponentCategoryItem = {
              ...originalItem,
              order: `${newOrder}`,
              ...Object.entries(row).reduce((acc, [key, value]) => {
                if ((Object.values(DisplayColumns) as string[]).includes(key)) {
                  acc[key] = `${value}`;
                }
                if ((Object.values(PricingColumns) as string[]).includes(key)) {
                  let price = `${value}`;
                  if (RegionPriceColumns.includes(key)) {
                    price = `${value || newDefaultPrice || originalDefaultPrice}`;
                  }
                  acc[key] = `${parsePriceValue(key, { [key]: price })}`;
                }
                return acc;
              }, {} as Record<string, string>),
            };

            // If insertIndex is -1, the row is being dragged to the end of the table
            if (insertIndex === -1) draftComponentCategoryItems.push(updatedItem);
            else draftComponentCategoryItems.splice(insertIndex, 0, updatedItem);
          }
          return draftComponentCategoryItems;
        }),
      ),
    );
  });

  const clientDataBranchDiffQueryArgs = {
    clientId,
    dataType: ClientDataType.Supplier,
    branch: clientUpdateDataBranch,
    tables: Array.from(new Set(originalComponentCategoryItems.map((component) => component.table))),
  };

  const fetchingGetClientDataBranchDiffPromise =
    state.clientData.fetchingEndpoints[getEndpointCacheKey('getClientDataBranchDiff', clientDataBranchDiffQueryArgs)];
  if (fetchingGetClientDataBranchDiffPromise) fetchPromises.push(fetchingGetClientDataBranchDiffPromise);

  const { data: originalBranchDiff = [] } =
    clientDataApi.endpoints.getClientDataBranchDiff.select(clientDataBranchDiffQueryArgs)(state);

  let componentCategoryChangesExist = false;
  Object.keys(data).forEach((table) => {
    patches.push(
      dispatch(
        clientDataApi.util.updateQueryData('getClientDataBranchDiff', clientDataBranchDiffQueryArgs, () => {
          let { changes: newTableDiffChanges } = originalBranchDiff.find((diff) => diff.table === table) || {
            table,
            changes: [],
          };
          const updatedRows = data[table];

          updatedRows.forEach((updatedRow) => {
            const originalItem =
              originalComponentCategoryItems.find(
                (component) => component[ClientDataFixedColumns.RowId] === updatedRow.rowId,
              ) || null;
            if (isDelete) {
              if (originalItem) {
                newTableDiffChanges = getUpdatedTableDataDiff(newTableDiffChanges, originalItem, null);
              }
            } else {
              newTableDiffChanges = getUpdatedTableDataDiff(newTableDiffChanges, originalItem, {
                ...originalItem,
                ...updatedRow,
              });
            }
          });

          const newTablesDiff = [
            ...originalBranchDiff.filter((diff) => diff.table !== table),
            { table, changes: newTableDiffChanges },
          ];
          componentCategoryChangesExist =
            componentCategoryChangesExist ||
            newTablesDiff
              .flatMap(({ changes }) => changes)
              .some(
                (diff) =>
                  diff &&
                  [PricingColumns, PricingCalculationColumns, DisplayColumns]
                    .flatMap((e) => Object.values(e))
                    .some((column) => {
                      const columnDiff = getRowDiffColumnToFromValues(diff, column);
                      return areDiffValuesDifferent(columnDiff?.from, columnDiff?.to);
                    }),
              );
          return newTablesDiff;
        }),
      ),
    );
  });

  patches.push(
    dispatch(
      pricingApi.util.updateQueryData(
        'getComponentCategories',
        { groupId, clientId, branch: clientUpdateDataBranch },
        (draft) => {
          const newDraft = [...draft];
          const insertIndex = draft.findIndex(({ key }) => key === category);
          if (insertIndex === -1) newDraft.push({ key: category, count: 0, changes: componentCategoryChangesExist });
          else
            newDraft.splice(insertIndex, 1, {
              ...draft[insertIndex],
              changes: componentCategoryChangesExist,
            });
          return newDraft;
        },
      ),
    ),
  );
};

export const {
  useGetComponentCategoriesQuery,
  useGetComponentCategoryItemsQuery,
  useGetClientUpdateRegionsQuery,
  useGetClientPricingSheetsQuery,
} = pricingApi;
