import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../hooks';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { Region } from '../types/Region';
import { I18nKeys } from '../constants/I18nKeys';
import hiddenPriceSrc from '../images/hiddenPriceIcon.svg';

const useStyles = makeStyles(() => ({
  item: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '14px',
  },
  container: {
    marginBottom: '20px',
  },
}));

export const PricingBaseHiddenPriceHeader: React.FC<{ regions: Region[] }> = ({ regions }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [hiddenRegions, setHiddenRegions] = React.useState<string[]>([]);
  const [styleIsHidden, setStyleIsHidden] = React.useState<boolean>(false);

  const { pricingBaseSheets = [], isLoadingRegions } = usePricingRepo({
    usePricingSheetBase: true,
    useSupplierRegions: true,
  });

  const {
    pricing: {
      base: { selectedPricingSheetId },
    },
    clientData: { clientId },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (!selectedPricingSheetId || isLoadingRegions) return;

    const selectedPricingSheet = pricingBaseSheets.find((sheet) => sheet.id === selectedPricingSheetId);
    if (!selectedPricingSheet) return;

    const { prices } = selectedPricingSheet;
    const hiddenRegionKeys = regions
      .filter(({ regionKey }) => prices.every(({ hidden }) => hidden[regionKey]))
      .map(({ regionKey }) => regionKey);
    setHiddenRegions(hiddenRegionKeys);
    setStyleIsHidden(regions.length === hiddenRegionKeys.length);
  }, [clientId, selectedPricingSheetId, isLoadingRegions, regions]);

  if (!styleIsHidden && !hiddenRegions.length) return null;
  return (
    <div className={classes.container}>
      <img alt="Hidden price" src={hiddenPriceSrc} className={classes.item} style={{ marginRight: '8px' }} />
      <Typography
        component="div"
        className={classes.item}
        dangerouslySetInnerHTML={{
          __html: t(
            styleIsHidden ? I18nKeys.PricingBaseHiddenStylePriceHeader : I18nKeys.PricingBaseHiddenRegionPriceHeader,
            {
              regions: hiddenRegions.length,
            },
          ),
        }}
      />
    </div>
  );
};
