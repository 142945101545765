import React, { useMemo } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { usePaymentRepo } from '../hooks/usePaymentRepo';
import { getDateTimeString } from '../utils/dateUtils';

// START TODO move this to a util file when we have the desired design
const getValueOrEmpty = (value?: string) => (value ? `${value} ` : '');

const addressFormatter = (address: { zip?: string; city?: string; address1?: string; state?: string }) =>
  `${getValueOrEmpty(address.address1)}${getValueOrEmpty(address.city)}${getValueOrEmpty(address.state)}${
    address.zip || ''
  }`;

const dateFormatter = (date?: string) => (date ? getDateTimeString(date) : '');

const columns: GridColDef[] = [
  { field: 'versionedEmailId', headerName: 'Reference Number', flex: 1 },
  { field: 'customerName', headerName: 'Customer Name', flex: 2 },
  { field: 'customerEmail', headerName: 'Customer Email', flex: 2 },
  { field: 'customerPhone', headerName: 'Customer Phone', flex: 1 },
  {
    field: 'billingAddress',
    headerName: 'Billing Address',
    flex: 2,
    valueFormatter: addressFormatter,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'shippingAddress',
    headerName: 'Shipping Address',
    flex: 2,
    valueFormatter: addressFormatter,
    headerClassName: 'super-app-theme--header',
  },
  { field: 'hash', headerName: 'Order Reference', flex: 1 },
  {
    field: 'date',
    headerName: 'Order Date',
    flex: 1,
    valueFormatter: dateFormatter,
    type: 'dateTime',
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'transactionId',
    headerName: 'Transaction Id',
    flex: 1,
    valueGetter: (value, row) => row.transaction.id,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    valueGetter: (value, row) => row.transaction.amount,
  },
  {
    field: 'provider',
    headerName: 'Checkout Provider',
    flex: 1,
    valueGetter: (value, row) => row.transaction.provider,
  },
  {
    field: 'paymentMethod',
    headerName: 'Payment Method',
    flex: 1,
    valueGetter: (value, row) => row.transaction.paymentMethod,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    valueGetter: (value, row) => row.transaction.status,
  },
  { field: 'link', headerName: 'Link', flex: 1 },
];
// END TODO move this to a util file when we have the desired design

export const Payments: React.FC = () => {
  const { payments, isLoadingPayments } = usePaymentRepo({ usePayments: true });

  const rows = useMemo(() => {
    if (payments) {
      return payments.map((o, id) => ({ ...o, id }));
    }
    return [];
  }, [payments]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            whiteSpace: 'break-spaces',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'rgba(0, 0, 0, 0.87)',
          },
        }}
        loading={isLoadingPayments}
        rows={rows}
        columns={columns}
      />
    </div>
  );
};
