import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';
import { ApiSurcharge, PricingAdjustmentConditionOption } from '../types/PricingAdjustment';

export const pricingAdjustmentApi = createApi({
  reducerPath: 'pricingAdjustmentApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/pricing',
  }),
  endpoints: (builder) => ({
    /**
     * Get pricing conditions by clientId
     */
    getConditions: builder.query<{ conditions: PricingAdjustmentConditionOption[] }, { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/condition`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Get surcharge by clientId
     */
    getSurcharge: builder.query<{ surcharge: ApiSurcharge }, { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/surcharge`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Get surcharge by clientId and status
     */
    getSurchargeByStatus: builder.query<{ surcharge: ApiSurcharge }, { clientId: string; status: string }>({
      query: ({ clientId, status }) => ({
        url: `/surcharge/status/${status}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Add surcharge by clientId
     */
    addSurcharge: builder.mutation<any, { groupId: string; clientId: string; surcharge: ApiSurcharge }>({
      query: ({ groupId, clientId, surcharge }) => ({
        url: `/surcharge`,
        method: 'post',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
          body: { surcharge: [surcharge] },
        },
      }),
    }),

    /**
     * Save surcharge by clientId and key
     */
    saveSurcharge: builder.mutation<any, { groupId: string; clientId: string; key: string; surcharge: ApiSurcharge }>({
      query: ({ groupId, clientId, key, surcharge }) => ({
        url: `/surcharge/${key}`,
        method: 'put',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
          body: { surcharge },
        },
      }),
    }),

    /**
     * Remove surcharge by clientId and key
     */
    removeSurcharge: builder.mutation<any, { groupId: string; clientId: string; key: string }>({
      query: ({ groupId, clientId, key }) => ({
        url: `/surcharge/${key}`,
        method: 'del',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
    }),
  }),
});

export const {
  useGetConditionsQuery,
  useGetSurchargeQuery,
  useGetSurchargeByStatusQuery,
  useAddSurchargeMutation,
  useSaveSurchargeMutation,
  useRemoveSurchargeMutation,
} = pricingAdjustmentApi;
