import { all, fork } from 'redux-saga/effects';
import { dealersSaga } from './dealersSaga';
import { groupsSaga } from './groupSaga';
import { orderSaga } from './orderSaga';
import { pricingAdjustmentSaga } from './pricingAdjustmentSaga';
import { settingsSaga } from './settingsSaga';
import { userSaga } from './userSaga';
import { clientDataSaga } from './clientDataSaga';

export function* rootSaga(): any {
  yield all([
    fork(clientDataSaga),
    fork(dealersSaga),
    fork(groupsSaga),
    fork(orderSaga),
    fork(pricingAdjustmentSaga),
    fork(settingsSaga),
    fork(userSaga),
  ]);
}
