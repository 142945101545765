import React, { useMemo, useEffect, useCallback } from 'react';
import { Dispatch } from 'redux';
import { makeStyles } from '@mui/styles';
import {
  CellClassParams,
  ColDef,
  Column,
  ColumnApi,
  GetContextMenuItemsParams,
  MenuItemDef,
  ProcessDataFromClipboardParams,
  RowNode,
  RowSpanParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
  CellFocusedEvent,
  PostProcessPopupParams,
  ColGroupDef,
  ModelUpdatedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { renderToStaticMarkup } from 'react-dom/server';
import { ContentCopy } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { DataGrid } from './DataGrid';
import { useAppDispatch, useAppSelector } from '../hooks';
import { CellMetadata, ColumnPinned } from '../types/ClientData';
import { AppState } from '../types/AppState';
import {
  getAllWidths,
  getRowData,
  priceColumnFormatter,
  priceColumnValueSetter,
  pricingColumnValueGetter,
  updateCellMetadata,
  getClientDataRowIdsFromSelectedRange,
  processDataFromClipboard,
  updateValues,
  getSubtitleText,
  getAvailablePricesPerRegionDiff,
} from '../utils/pricingSheetUtils';
import { arePriceValuesDifferent, getCurrencySymbol, isDecimalPrice } from '../utils/pricingUtils';
import { BaseTableData } from '../types/DataGrid';
import { I18nKeys } from '../constants/I18nKeys';
import { Dialogs } from '../constants/Dialogs';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { openDialog } from '../ducks/dialogSlice';
import { mapClientAndDataTypeToClientDataId, mapClientAndPricingSheetToUndoStackId } from '../utils/clientIdUtils';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import {
  getCellRangeInfo,
  getPropertyFromCellMetadata,
  getRowDiffColumnToFromValues,
  hasCellMetadataProperty,
} from '../utils/clientDataUtils';
import { CellMetadataProperty } from '../constants/ClientData';
import noteSrc from '../images/note.svg';
import deleteNoteSrc from '../images/deleteNote.svg';
import { setCellNote } from '../ducks/clientDataSlice';
import { onGridKeyboardShortcut, suppressPricingGridKeyboardEvent } from '../utils/keyboardShortcutUtils';

import { PriceColumn } from '../constants/PriceColumn';
import { SelectAllCellsHeader } from './SelectAllCellsHeader';
import { setHighlightedCell } from '../ducks/pricingSlice';
import { clearSelections } from '../utils/selectionUtils';
import { ClientDataTooltip } from './ClientDataTooltip';
import { Region } from '../types/Region';
import { GridViewType } from '../constants/GridViewType';
import { i18n } from '../i18n';
import { PricingGridTooltip } from './PricingGridTooltip';
import { getTextWidth } from '../utils/htmlUtil';
import { PricingSheetPrice } from '../types/PricingSheetPrice';

const useStyles = makeStyles<Theme, { clientGridViewType: GridViewType }>((styles) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    width: '100%',
    height: '100%',
    '& .ag-cell': {
      padding: '0 3px',
      borderTop: '1px transparent',
      textAlign: 'right',
    },
    '& .ag-header-cell': {
      paddingLeft: '7px',
      paddingRight: '6px',
      backgroundColor: 'D0D0D0',
    },
    '& .ag-header-cell-label': {
      justifyContent: 'right',
      fontSize: '13px',
      lineHeight: '20px',
    },
    '& .ag-grid-width-header-column': {
      headerColumnSeparatorDisplay: 'none',
      backgroundColor: 'var(--ag-header-background-color)',
      borderLeft: '1px solid var(--ag-row-border-color)',
    },
    ' & .ag-header-row .ag-focus-managed:first-child': {
      borderLeft: '1px solid var(--ag-row-border-color)',
      '&::after': {
        display: ({ clientGridViewType }) => (clientGridViewType === GridViewType.List ? 'none' : ''),
      },
    },
  },
  gridModifiedCell: {
    backgroundColor: '#E4A76733 !important',
  },
  gridRegionSameAsPriceCell: {
    color: '#00000061 !important',
    fontWeight: '400 !important',
  },
  noteHandle: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      borderBottom: '7px solid transparent',
      borderRight: '7px solid #4994EC',
      right: '-1px',
      top: '0px',
    },
  },
  hiddenPrice: {
    fontStyle: 'italic',
    color: 'rgba(0, 0, 0, 0.60)',
  },
  highlightCell: {
    backgroundColor: 'var(--ag-row-hover-color)',
  },
}));

interface ContextMenuParams {
  metadata: CellMetadata[];
  columns: string[];
  client: string;
  dataType: ClientDataType;
  clientDataId: string;
  table: string;
  branch?: ClientDataBranch;
}

export interface PriceRow extends BaseTableData {
  [key: string]: any;
}

export enum DefaultPricingColumnFieldNames {
  Length = 'length',
  LengthSpanLabel = 'lengthSpanLabel',
}

export const lengthSpanHeaderWidth = 29;
export const lengthColumnWidth = 54;
export const widthLengthColumnWidth = 69;
export const priceColumnWidth = 80;
export const decimalPriceColumnWidth = 92;

export const defaultColumn: ColDef = {
  resizable: false,
  sortable: false, // do not allow column sorting
  suppressMenu: true, // suppress the column hamburger menu
  suppressMovable: true, // do not allow column dragging
  cellClassRules: {
    'ag-grid-custom-black-out-cell-color': (params) => {
      const { data, colDef } = params;
      const columnId = colDef.colId || params.column.getColId();
      if (!params.value && !data[columnId]) {
        return true;
      }
      return false;
    },
  },
  editable: true,

  suppressKeyboardEvent: suppressPricingGridKeyboardEvent,
};

const getLengthColumnDef = (clientGridViewType: GridViewType): ColDef => ({
  ...defaultColumn,
  cellClassRules: { 'ag-grid-index-column': () => true },
  cellStyle: { fontWeight: 'bold', paddingRight: '6px', fontSize: '13px' },
  headerName: '',
  editable: false,
  headerTooltip: '',
  headerComponent: SelectAllCellsHeader,
  field: DefaultPricingColumnFieldNames.Length,
  colId: DefaultPricingColumnFieldNames.Length,
  initialWidth: clientGridViewType === GridViewType.Grid ? lengthColumnWidth : widthLengthColumnWidth,
});

const updateColumnWidths = (formatPriceWithDecimal: boolean, currencyWidth: number, columnApi?: ColumnApi) => {
  const columnState = columnApi?.getColumnState();

  columnState?.forEach((col) => {
    const { colId } = col;
    if (
      [DefaultPricingColumnFieldNames.LengthSpanLabel, DefaultPricingColumnFieldNames.Length].includes(
        colId as DefaultPricingColumnFieldNames,
      )
    ) {
      return;
    }
    if (formatPriceWithDecimal) {
      columnApi?.setColumnWidth(colId, decimalPriceColumnWidth + currencyWidth);
    } else {
      columnApi?.setColumnWidth(colId, priceColumnWidth + currencyWidth);
    }
  });
};

const updateLengthColumnsWidth = (columnApi?: ColumnApi) => {
  columnApi?.getColumns()?.forEach((col) => {
    const colId = col.getColDef().field;
    const columns = [];
    if (colId === DefaultPricingColumnFieldNames.Length) {
      columns.push(col);
    }
    if (columns.length > 0) {
      columnApi?.autoSizeColumns(columns);
    }
  });
};

const getRegionsColDefs = (
  clientDataTableId: string,
  prices: PricingSheetPrice[],
  regions: Region[],
  dispatch: Dispatch<any>,
  t: Function,
  getCellClass: (cellClassParam: CellClassParams<PriceRow>) => string[],
  formatPriceWithDecimal: boolean,
  groupingCellUpdates: boolean,
  availablePricePerRegion: Map<string, number>,
  onGroupUpdateEdit?: Function,
  currency?: string,
  hasDefaultRegion = false,
) => {
  const defs: ColDef[] = [];
  const onlyOneRegion = regions.length === 1;
  regions.forEach((region) => {
    const { regionKey } = region;
    const isHiddenRegion = !!prices?.every(({ hidden }) => hidden[region.regionKey]);
    defs.push({
      headerName: !onlyOneRegion
        ? region.label
        : t(I18nKeys.PricingBaseDefaultRegionHeaderListViewLabel, { defaultValue: region.label }),
      field: region.priceColumn,
      colId: region.priceColumn,
      valueGetter: (params: ValueGetterParams) => pricingColumnValueGetter(params, region.priceColumn),
      valueFormatter: (params: ValueFormatterParams) =>
        priceColumnFormatter(params, region.priceColumn, formatPriceWithDecimal, regionKey, currency),
      valueSetter: (params: ValueSetterParams) =>
        groupingCellUpdates && onGroupUpdateEdit
          ? onGroupUpdateEdit(params)
          : priceColumnValueSetter(clientDataTableId, region.priceColumn, params, dispatch, t),
      cellClass: getCellClass,
      headerTooltip: `${region.label}${
        hasDefaultRegion ? `\n${getSubtitleText(availablePricePerRegion, prices.length, region, isHiddenRegion)}` : ''
      }`,
      tooltipComponent: PricingGridTooltip,
      tooltipValueGetter: (params) => {
        const columnId = (params.column as Column).getColId();
        const { clientDataRowId } = params.data[columnId] || '';
        const { cellMetadata } = params.context;
        if (!hasCellMetadataProperty(cellMetadata, clientDataRowId, region.priceColumn, CellMetadataProperty.Note))
          return '';
        const note = getPropertyFromCellMetadata(
          cellMetadata,
          clientDataRowId,
          region.priceColumn,
          CellMetadataProperty.Note,
        );
        return note;
      },
      editable: ({ column, data }) => {
        const colId = column?.getColId();
        return !data[colId]?.hidden[regionKey];
      },
      suppressFillHandle: isHiddenRegion,
    });
  });
  return defs;
};

const getWidthColDefs = (
  clientDataTableId: string,
  prices: PricingSheetPrice[],
  regions: Region[],
  priceColumn: PriceColumn,
  allWidths: string[],
  dispatch: Dispatch<any>,
  t: Function,
  getCellClass: (cellClassParam: CellClassParams<PriceRow>) => string[],
  unit: string,
  formatPriceWithDecimal: boolean,
  groupingCellUpdates: boolean,
  onGroupUpdateEdit?: Function,
  currency?: string,
) => {
  const defs: ColGroupDef[] = [];
  const children: ColDef[] = [];
  const regionKey = regions[0]?.regionKey;
  const isHiddenRegion = !!prices?.every(({ hidden }) => hidden[regionKey]);
  allWidths.forEach((width) => {
    children.push({
      headerName: `${width} ${unit}`,
      colId: width,
      valueGetter: (params: ValueGetterParams) => pricingColumnValueGetter(params, priceColumn),
      valueFormatter: (params: ValueFormatterParams) =>
        priceColumnFormatter(params, priceColumn, formatPriceWithDecimal, regionKey, currency),
      valueSetter: (params: ValueSetterParams) =>
        groupingCellUpdates && onGroupUpdateEdit
          ? onGroupUpdateEdit(params)
          : priceColumnValueSetter(clientDataTableId, priceColumn, params, dispatch, t),
      cellClass: getCellClass,
      tooltipComponent: ClientDataTooltip,
      tooltipValueGetter: (params) => {
        const columnId = (params.column as Column).getColId();
        const { clientDataRowId } = params.data[columnId] || '';
        const { cellMetadata } = params.context;
        if (!hasCellMetadataProperty(cellMetadata, clientDataRowId, priceColumn, CellMetadataProperty.Note)) return '';
        const note = getPropertyFromCellMetadata(cellMetadata, clientDataRowId, priceColumn, CellMetadataProperty.Note);
        return note;
      },
      editable: ({ column, data }) => {
        const colId = column?.getColId();
        return !data[colId]?.hidden[regionKey];
      },
      suppressFillHandle: isHiddenRegion,
    });
  });
  defs.push({
    headerName: i18n.t(I18nKeys.PricingSheetWidth),
    headerClass: ['ag-grid-width-header-column'],
    children,
  });
  return defs;
};

const rowSpanFunc = (params: RowSpanParams, rowsToSpan: number): number => {
  const idx = params.node?.rowIndex || 1;
  const result = rowsToSpan - idx;
  return result;
};

const getLengthSpanHeader = (rowsToSpan: number): ColDef => ({
  ...defaultColumn,
  cellStyle: { fontWeight: 'bold' },
  pinned: 'left' as ColumnPinned,
  editable: false,
  headerName: '',
  headerComponent: SelectAllCellsHeader,
  colId: DefaultPricingColumnFieldNames.LengthSpanLabel,
  field: DefaultPricingColumnFieldNames.LengthSpanLabel,
  width: lengthSpanHeaderWidth,
  suppressNavigable: true,
  headerTooltip: '',
  rowSpan: (params) => rowSpanFunc(params, rowsToSpan),
  cellClassRules: {
    'ag-grid-pricing-length-row-span-label': (params) => params?.value,
  },
});

export const PricingBaseSheet: React.FC<{
  clientGridViewType: GridViewType;
  regions?: Region[];
  hasDefaultRegion?: boolean;
}> = ({ clientGridViewType, regions, hasDefaultRegion = false }) => {
  const { t } = useTranslation();
  const classes = useStyles({ clientGridViewType });
  const { currency = 'USD', useMetricUnits } = useAppSelector((state: AppState) => state?.viewer);
  const dispatch = useAppDispatch();
  const {
    base: { selectedPricingSheetId },
    highlightedCell,
  } = useAppSelector((state) => state.pricing);

  const { cellMetadata, pricingBaseSheets = [] } = usePricingRepo({
    usePricingSheetBase: true,
    useCellMetadata: true,
  });

  const { priceColumn = undefined, regionKey = undefined } = regions?.length === 1 ? regions[0] : {};
  const currencySymbol = getCurrencySymbol(currency);
  const currencyWidth =
    currencySymbol !== '$' ? getTextWidth(currencySymbol, 'calc(var(--ag-font-size) + 1px) var(--ag-font-family)') : 0;

  const getPriceColumn = useCallback(
    (colId: string | undefined): PriceColumn =>
      (colId && Object.values(PriceColumn).includes(colId as PriceColumn) ? (colId as PriceColumn) : priceColumn) ||
      PriceColumn.price,
    [priceColumn],
  );

  const selectedPricingSheet = pricingBaseSheets.find((sheet) => sheet.id === selectedPricingSheetId);
  // If any price contains a decimal value, make all prices show decimals.
  const formatPriceWithDecimal = !!selectedPricingSheet?.prices.find((price) => {
    if (regions) {
      for (let i = 0; i < regions.length; i += 1) {
        const region = regions[i];
        if (isDecimalPrice(price[region.priceColumn])) {
          return true;
        }
      }
    } else if (priceColumn) {
      if (isDecimalPrice(price[priceColumn])) {
        return true;
      }
    }
    return false;
  });

  const { clientId } = useAppSelector((state: AppState) => state?.clientData);

  const unitTranslation = t(useMetricUnits ? I18nKeys.PricingSheetMetricUnit : I18nKeys.PricingSheetImperialUnit);
  const clientDataTableId = mapClientAndPricingSheetToUndoStackId(clientId, selectedPricingSheetId);

  let widthColumns: string[] = [];
  if (selectedPricingSheet) {
    if (clientGridViewType === GridViewType.Grid) {
      widthColumns = getAllWidths(selectedPricingSheet.prices);
    } else {
      widthColumns = regions?.map((r) => r.priceColumn) || [PriceColumn.price];
    }
  }

  const contextMenuParams: ContextMenuParams = {
    metadata: cellMetadata,
    columns: widthColumns,
    client: clientId,
    dataType: ClientDataType.Supplier,
    clientDataId: mapClientAndDataTypeToClientDataId(clientId, ClientDataType.Supplier),
    table: clientDataTableId,
    branch: ClientDataBranch.Pricing,
  };

  const rootRef = React.useRef<HTMLDivElement>(null);
  const gridRef = React.useRef<AgGridReact>(null);
  const tableMenuButtonRef = React.useRef<HTMLDivElement>(null);
  const { api: gridApi, columnApi } = gridRef.current || {};
  const suppressRowTransform = true;

  const getContextMenuItems = useCallback(
    (
      { api, node, column }: GetContextMenuItemsParams,
      { metadata, branch }: ContextMenuParams,
    ): (string | MenuItemDef)[] => {
      const cellRanges = api.getCellRanges();
      const { rowCount, columns: selectedColumns } = getCellRangeInfo(cellRanges);

      const cellSelected = node && column;
      const rangeSelected = cellSelected && (rowCount > 1 || selectedColumns.length > 1);
      let data: any;
      let columnId: string;
      let cellNoteExists = false;
      let rangeNoteExists = false;
      let clientDataRowId: string;
      if (cellSelected) {
        ({ data } = (node || {}) as RowNode);
        columnId = (column as Column).getColId();
        clientDataRowId = data[columnId].clientDataRowId;
        cellNoteExists = hasCellMetadataProperty(
          metadata,
          clientDataRowId,
          getPriceColumn(columnId),
          CellMetadataProperty.Note,
        );
      }
      if (rangeSelected) {
        columnId = (column as Column).getColId();
        rangeNoteExists =
          cellNoteExists ||
          getClientDataRowIdsFromSelectedRange(api).some((r) =>
            selectedColumns.some((c) => hasCellMetadataProperty(metadata, r, c, CellMetadataProperty.Note)),
          );
      }

      const result: (string | MenuItemDef)[] = [
        ...(cellSelected && !column?.getColId().includes('length')
          ? [
              {
                name: `Copy`,
                action: () => api.copyToClipboard(),
                icon: renderToStaticMarkup(<ContentCopy className="ag-icon" />),
                shortcut: '⌘C',
              },
              {
                name: `Copy with Width Headers`,
                action: () => api.copyToClipboard({ includeHeaders: true }),
                icon: renderToStaticMarkup(<ContentCopy className="ag-icon" />),
                shortcut: '⇧⌘C',
              },
              'separator',
              ...(!column?.getColId().includes('length')
                ? [
                    {
                      name: `${cellNoteExists ? 'Edit' : 'Add'} Note${rangeSelected ? 's' : ''}`,
                      action: () => {
                        dispatch(
                          // Selected node should be first to be edited
                          setCellNote({
                            rowIds: getClientDataRowIdsFromSelectedRange(api),
                            colIds: [getPriceColumn(columnId)],
                          }),
                        );
                        dispatch(openDialog({ dialog: Dialogs.PricingBaseNote }));
                      },
                      icon: renderToStaticMarkup(<img alt="Note" src={noteSrc} className="ag-icon" />),
                      shortcut: '⇧F2',
                    },
                    ...(cellNoteExists || rangeNoteExists
                      ? [
                          {
                            name: `Delete Note${rangeSelected ? 's' : ''}`,
                            action: () => {
                              if (branch) {
                                updateCellMetadata(
                                  clientDataTableId,
                                  getClientDataRowIdsFromSelectedRange(api).flatMap((r) => ({
                                    cellsMetadata: metadata,
                                    colId: getPriceColumn(columnId),
                                    rowId: r,
                                    metadataProperty: CellMetadataProperty.Note,
                                    value: '',
                                  })),
                                  dispatch,
                                );
                              }
                            },
                            icon: renderToStaticMarkup(
                              <img alt="Delete Note" src={deleteNoteSrc} className="ag-icon" />,
                            ),
                          },
                        ]
                      : []),
                  ]
                : []),
            ]
          : []),

        'export',
      ];

      return result;
    },
    [clientDataTableId, dispatch, getPriceColumn],
  );

  const postProcessPopup = (params: PostProcessPopupParams) => {
    const { type, column, ePopup } = params;
    if (type !== 'contextMenu') {
      return;
    }
    let viewHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    // subtract 64px from the viewHeight to account for the Preview/Publish bar
    viewHeight -= 64;
    const columnId = column ? column.getColId() : undefined;
    if (columnId && ePopup) {
      // Adjust by 20% for submenu that goes lower than the popup
      const popUpBottomPos = ePopup.offsetHeight * 1.2 + ePopup.offsetTop;
      if (popUpBottomPos > viewHeight) {
        ePopup.style.top = `${viewHeight - ePopup.offsetHeight * 1.2}px`;
      }
    }
  };

  const rowDataPrices = useMemo(() => {
    if (selectedPricingSheet) {
      const rowData = getRowData(
        selectedPricingSheet.prices,
        unitTranslation,
        clientGridViewType,
        priceColumn,
        regions,
      );
      updateLengthColumnsWidth(columnApi);
      return rowData;
    }
    return [];
  }, [selectedPricingSheet, columnApi, priceColumn, unitTranslation, clientGridViewType, regions]);

  const getCellClass = useCallback(
    (cellClassParam: CellClassParams<PriceRow>) => {
      const classList: string[] = [];

      const { colId = '' } = cellClassParam.colDef;
      const {
        data,
        context: { regionKey: region },
      } = cellClassParam;
      const { cellMetadata: cellMetadataFromContext = [] } = cellClassParam.context;

      const { [colId]: cellData } = (data || {}) as PriceRow;

      if (
        cellData &&
        !([DefaultPricingColumnFieldNames.Length, DefaultPricingColumnFieldNames.LengthSpanLabel] as string[]).includes(
          colId,
        )
      ) {
        if (cellData.diff) {
          const columnDiff = data ? getRowDiffColumnToFromValues(cellData.diff, getPriceColumn(colId)) : undefined;

          if (columnDiff && arePriceValuesDifferent(columnDiff.from, columnDiff.to)) {
            classList.push(classes.gridModifiedCell);
          }
        }

        const { clientDataRowId } = cellData;
        if (clientDataRowId && colId) {
          const hasNote = hasCellMetadataProperty(
            cellMetadataFromContext,
            clientDataRowId,
            getPriceColumn(colId),
            CellMetadataProperty.Note,
          );
          if (hasNote) {
            classList.push(classes.noteHandle);
          }
        }

        const cellRegionKey =
          clientGridViewType === GridViewType.List
            ? regions?.find(({ priceColumn: col }) => colId === col)?.regionKey
            : region;
        if (cellData?.hidden[cellRegionKey]) {
          classList.push(classes.hiddenPrice);
        }
      }

      if (hasDefaultRegion && getPriceColumn(colId) !== PriceColumn.price && cellData && classList.length === 0) {
        const dataPrice = selectedPricingSheet?.prices?.find((p) => p.rowId === cellData.clientDataRowId);
        if (dataPrice && !arePriceValuesDifferent(cellData[getPriceColumn(colId)], dataPrice[PriceColumn.price])) {
          classList.push(classes.gridRegionSameAsPriceCell);
        }
      }

      return classList;
    },
    [
      regions,
      hasDefaultRegion,
      selectedPricingSheet,
      classes.gridModifiedCell,
      getPriceColumn,
      classes.gridRegionSameAsPriceCell,
      classes.noteHandle,
    ],
  );

  const [groupingCellUpdates, setGroupingCellUpdates] = React.useState(false);
  const [groupedCellUpdates, setGroupedCellUpdates] = React.useState<any[]>([]);

  const onGroupUpdateStart = () => {
    setGroupingCellUpdates(true);
    setGroupedCellUpdates([]);
  };

  const onGroupUpdateComplete = () => {
    updateValues(clientDataTableId, groupedCellUpdates, cellMetadata, dispatch);
    setGroupingCellUpdates(false);
    setGroupedCellUpdates([]);
  };

  const onGroupUpdateEdit = useCallback(
    ({ oldValue, newValue, data, column, colDef }: ValueSetterParams): boolean => {
      groupedCellUpdates.push({
        priceColumn: getPriceColumn(column.getColId()),
        oldValue,
        newValue,
        data,
        column: column.getColId(),
        colDef,
      });
      return true;
    },
    [getPriceColumn, groupedCellUpdates],
  );

  // Define the default column configuration with usememo because the default config doesn't change once initialized
  const selectedTableColumns = useMemo(() => {
    if (selectedPricingSheet) {
      const colDefs = [getLengthSpanHeader(rowDataPrices.length), getLengthColumnDef(clientGridViewType)];

      const { prices } = selectedPricingSheet;
      if (clientGridViewType === GridViewType.List && regions) {
        const availablePricePerRegion = getAvailablePricesPerRegionDiff(new Map(), regions, prices);

        colDefs.push(
          ...getRegionsColDefs(
            clientDataTableId,
            prices,
            regions || [],
            dispatch,
            t,
            getCellClass,
            formatPriceWithDecimal,
            groupingCellUpdates,
            availablePricePerRegion,
            onGroupUpdateEdit,
            currency,
            hasDefaultRegion,
          ),
        );
      } else {
        colDefs.push(
          ...getWidthColDefs(
            clientDataTableId,
            prices,
            regions || [],
            priceColumn || PriceColumn.price,
            getAllWidths(selectedPricingSheet.prices),
            dispatch,
            t,
            getCellClass,
            unitTranslation,
            formatPriceWithDecimal,
            groupingCellUpdates,
            onGroupUpdateEdit,
            currency,
          ),
        );
      }

      return colDefs;
    }

    return [];
  }, [
    clientDataTableId,
    rowDataPrices,
    selectedPricingSheet,
    unitTranslation,
    dispatch,
    t,
    currency,
    getCellClass,
    formatPriceWithDecimal,
    groupingCellUpdates,
    onGroupUpdateEdit,
    priceColumn,
    clientGridViewType,
    regions,
    hasDefaultRegion,
  ]);

  useEffect(() => {
    // Must refresh cells when data diff or cell metadata changes due to custom styling
    // FIXME: refresh only cells that changed in datadiff and cell metadata
    gridApi?.refreshCells({ force: true, suppressFlash: true });
    if (clientGridViewType === GridViewType.List) {
      gridApi?.refreshHeader();
    }
  }, [clientGridViewType, selectedPricingSheet, gridApi, cellMetadata]);

  useMemo(() => {
    const { region, colId, rowId } = highlightedCell || {};
    const focusedCell = gridApi?.getFocusedCell();
    const focusedRowId = focusedCell ? gridApi?.getDisplayedRowAtIndex(focusedCell.rowIndex)?.id : undefined;
    const focusedColId = focusedCell?.column?.getColId();

    if (selectedPricingSheet && region === getPriceColumn(focusedColId) && colId && rowId && !focusedCell) {
      setTimeout(() => {
        gridApi?.setFocusedCell(Number(rowId), colId);
      }, 1000);
    }
    if (region === getPriceColumn(focusedColId) && focusedCell && (colId !== focusedColId || rowId !== focusedRowId)) {
      setHighlightedCell({ region, colId: focusedColId, rowId: focusedRowId });
    }
  }, [gridApi, highlightedCell, getPriceColumn, selectedPricingSheet]);

  useMemo(() => {
    if (gridApi && priceColumn && highlightedCell?.region !== priceColumn) {
      clearSelections(gridApi);
    }
  }, [gridApi, priceColumn, highlightedCell]);

  useEffect(() => {
    // reset the column definitions for a pricing sheet change and decimal formatting
    gridApi?.setColumnDefs(selectedTableColumns);
    updateColumnWidths(formatPriceWithDecimal, currencyWidth, columnApi);
  }, [formatPriceWithDecimal, gridApi, columnApi, selectedPricingSheet, currencyWidth, selectedTableColumns]);

  useEffect(() => {
    const setCellFlashColor = undefined;
    const openTableMenu = () => tableMenuButtonRef.current;
    const onKeyDown = (e: KeyboardEvent) => {
      if (
        (!highlightedCell && priceColumn === PriceColumn.price) ||
        highlightedCell?.region === priceColumn ||
        clientGridViewType === GridViewType.List
      ) {
        onGridKeyboardShortcut(
          e,
          { dispatch, openTableMenu, columnApi, gridApi, setCellFlashColor, dataColumn: priceColumn },
          clientDataTableId,
          false,
          true,
        );
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [
    tableMenuButtonRef,
    clientDataTableId,
    highlightedCell,
    dispatch,
    gridApi,
    columnApi,
    getPriceColumn,
    priceColumn,
    clientGridViewType,
  ]);

  const onModelUpdated = (event: ModelUpdatedEvent) => {
    updateLengthColumnsWidth(event.columnApi);
  };

  return (
    <div className={classes.root} ref={rootRef}>
      <DataGrid
        className={classes.grid}
        gridRef={gridRef}
        data={rowDataPrices} // Price Data for Rows
        context={{
          regionKey,
        }}
        cellMetadata={cellMetadata}
        domLayout={selectedPricingSheet ? 'print' : 'normal'} // Auto-size grid width and height to fit all contents also does not use scrollbars.
        enableCellChangeFlash={false}
        getContextMenuItems={(params: GetContextMenuItemsParams) => getContextMenuItems(params, contextMenuParams)}
        getCellClass={getCellClass}
        initialHeaderHeight={30}
        initialRowHeight={30}
        onModelUpdated={onModelUpdated}
        onGroupUpdateStart={onGroupUpdateStart}
        onGroupUpdateComplete={onGroupUpdateComplete}
        selectedTable={clientDataTableId}
        defaultColDefinition={defaultColumn}
        selectedTableColumns={selectedTableColumns}
        readOnly={false}
        suppressRowTransform={suppressRowTransform}
        onCellFocused={({ rowIndex, column, api }: CellFocusedEvent) => {
          const colId = typeof column === 'string' ? column : column?.getColId();
          const rowId = rowIndex ? api.getDisplayedRowAtIndex(rowIndex)?.id : undefined;
          dispatch(setHighlightedCell({ region: getPriceColumn(colId), rowId, colId }));
        }}
        processDataFromClipboard={(params: ProcessDataFromClipboardParams) =>
          processDataFromClipboard(params, { clientDataTableId, getPriceColumn, dispatch })
        }
        popupParent={document.querySelector('body') || rootRef.current || undefined}
        postProcessPopup={(params: PostProcessPopupParams) => postProcessPopup(params)}
      />
    </div>
  );
};
