import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  Tooltip,
  alpha,
  Icon,
  Button,
  Popover,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import checkIcon from '../images/checkIcon.svg';
import selectGridIcon from '../images/selectGridIcon.svg';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { PricingBaseSheet } from './PricingBaseSheet';
import { useAppDispatch, useAppSelector } from '../hooks';
import { PriceColumn } from '../constants/PriceColumn';
import { Region } from '../types/Region';
import { I18nKeys } from '../constants/I18nKeys';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { setPricingBaseAccordionExpanded } from '../ducks/pricingSlice';
import { AppState } from '../types/AppState';
import { unknownGroup } from '../constants/Group';
import { unknownUser } from '../types/User';
import { PricingBaseNoteDialog } from './PricingBaseNoteDialog';
import { PricingContactSupportDialog } from './PricingContactSupportDialog';
import { UserPreference } from '../constants/User';
import { GridViewType } from '../constants/GridViewType';
import { getAvailablePricesPerRegionDiff, getSubtitleText, regionHasPrices } from '../utils/pricingSheetUtils';
import { getClientIdFromClientSupplier, isCarportView } from '../utils/clientIdUtils';
import { PricingBaseHiddenPriceHeader } from './PricingBaseHiddenPriceHeader';
import { saveUserPreferences } from '../ducks/currentUserSlice';

const useStyles = makeStyles(() => ({
  root: {
    flex: 3,
    padding: '24px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  accordion: {
    background: 'none',
    marginBottom: '16px',
    '&$expanded': {
      display: 'flex',
      flexDirection: 'column',
    },
    '&:before': {
      height: '0px',
    },
  },
  regionTitle: {
    margin: '0px 8px 0px 0px',
    fontWeight: '700',
    fontSize: '16px',
  },
  regionDiffs: {
    fontWeight: '400',
    fontSize: '14px',
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.60)',
  },
  accordionSummary: {
    padding: '0px',
    flexDirection: 'row-reverse',
  },
  accordionDetails: {
    padding: '0px',
    marginTop: '16px',
  },
  pricingSheetLabel: {
    fontSize: '20px',
    paddingBottom: '4px',
    fontWeight: 'bold',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover + $hiddenUntilHover': {
      display: 'flex',
    },
  },
  attributesLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingBottom: '12px',
    color: 'rgba(0, 0, 0, 0.60)',
  },
  hiddenUntilHover: {
    display: 'none',
    '&:hover': {
      display: 'flex',
    },
  },
  hoverContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  topContainer: {
    display: 'flex',
  },
  topTextContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  selectViewText: {
    fontWeight: '500',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    lineHeight: '16px',
  },
  selectViewIcon: {
    width: '24px',
    height: '24px',
  },
  selectViewButton: {
    height: 'fit-content',
    borderRadius: '4px',
    justifyContent: 'flex-start',
    color: alpha('#000', 0.87),
    background: alpha('#000', 0.08),
    padding: '8px 18px',
  },
  viewOptionText: {
    fontWeight: '400',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    lineHeight: '20px',
    textTransform: 'capitalize',
  },
  viewOptionIcon: {
    width: '24px',
    height: '24px',
    paddingRight: '30px',
  },
  viewOptionButton: {
    border: 'border: 1px solid #F4F4F4',
    height: 'fit-content',
    color: '#424242',
    justifyContent: 'flex-start',
    background: '#FFFFFF',
    padding: '8px 48px',
  },
}));

export const PricingBaseAccordion: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [sumAvailablePrices, setSumAvailablePrices] = React.useState<number>();
  const [availablePricePerRegion, setAvailablePricePerRegion] = React.useState<Map<string, number>>(new Map());
  const [regions, setRegions] = React.useState<Region[]>([]);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { supplierRegions = [], pricingBaseSheets = [] } = usePricingRepo({
    useSupplierRegions: true,
    usePricingSheetBase: true,
  });
  const hasDefaultRegion = supplierRegions.some((r) => r.priceColumn === PriceColumn.price);
  const { selectedPricingSheetId, pricingBaseAccordion = [] } = useAppSelector((state) => state.pricing.base);

  const selectedPricingSheet = pricingBaseSheets.find((sheet) => sheet.id === selectedPricingSheetId);
  const attributesLabel = selectedPricingSheet?.attributes?.reduce(
    (label, attr) => `${!label || !attr.label ? label : `${label}, `}${attr.label || ''}`,
    '',
  );
  const { prices } = selectedPricingSheet || {};
  // By design the sheet name is pulled from priceSetLabel from the first row in this sheet
  const sheetName = selectedPricingSheet?.prices[0]?.priceSetLabel;

  const viewerClientId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId || '',
  );

  const clientId = getClientIdFromClientSupplier(viewerClientId);
  const {
    user: { username } = unknownUser,
    group: { groupId } = unknownGroup,
    preferences: { [UserPreference.PricingBasePreferences]: pricingBasePreferences = { gridViewType: {} } } = {},
  } = useAppSelector((state) => state?.currentUser);
  let { [groupId]: { [viewerClientId]: clientGridViewType } = {} } = pricingBasePreferences.gridViewType;
  if (!clientGridViewType) {
    clientGridViewType = isCarportView(clientId) ? GridViewType.Grid : GridViewType.List;
  }

  const accordionKey = `${username}-${groupId}-${viewerClientId}-${selectedPricingSheetId}`;
  const handleExpand = (index: number) => {
    let expandedStatus: string[];
    const entryKey = `${accordionKey}-${index}`;
    if (pricingBaseAccordion.includes(entryKey)) {
      expandedStatus = pricingBaseAccordion.filter((value) => value !== entryKey);
      if (index === 0 && !expandedStatus.includes(`${entryKey}-closed`)) {
        expandedStatus.push(`${entryKey}-closed`);
      }
    } else {
      expandedStatus = [...pricingBaseAccordion, entryKey];
    }

    dispatch(
      setPricingBaseAccordionExpanded({
        expandedStatus,
      }),
    );
  };

  useEffect(() => {
    const entryKey = `${accordionKey}-${0}`;
    if (
      regions.length > 0 &&
      !pricingBaseAccordion.includes(entryKey) &&
      !pricingBaseAccordion.includes(`${entryKey}-closed`)
    ) {
      dispatch(
        setPricingBaseAccordionExpanded({
          expandedStatus: [...pricingBaseAccordion, `${entryKey}`],
        }),
      );
    }
  }, [regions, pricingBaseAccordion, accordionKey, dispatch]);

  useEffect(() => {
    if (regions.length > 0 && prices) {
      setSumAvailablePrices(prices.length);
      setAvailablePricePerRegion(getAvailablePricesPerRegionDiff(availablePricePerRegion, regions, prices));
    }
  }, [prices, availablePricePerRegion, regions]);

  useMemo(() => {
    const newRegions: { [key: string]: Region } = {};
    const newRegionsIncluded: { [key: string]: boolean } = {};

    for (let i = 0; i < supplierRegions.length; i += 1) {
      const region = supplierRegions[i];
      const regionPriceColumn = newRegions[region.priceColumn];
      if (regionPriceColumn) {
        if (Number(region.priority || 0) < Number(regionPriceColumn.priority || 0)) {
          newRegions[region.priceColumn] = region;
          newRegionsIncluded[region.priceColumn] = regions.some(
            (r) =>
              r.label === region.label && r.priceColumn === region.priceColumn && r.supplierKey === region.supplierKey,
          );
        }
      } else {
        newRegions[region.priceColumn] = region;
        newRegionsIncluded[region.priceColumn] = regions.some(
          (r) =>
            r.label === region.label && r.priceColumn === region.priceColumn && r.supplierKey === region.supplierKey,
        );
      }
    }

    if (Object.values(newRegionsIncluded).includes(false)) {
      setRegions(
        Object.values(newRegions).sort((a: Region, b: Region) => {
          if (a.priceColumn === PriceColumn.price) return -1;
          if (b.priceColumn === PriceColumn.price) return 1;
          return a.label.localeCompare(b.label);
        }),
      );
    }
  }, [supplierRegions, regions, setRegions]);

  const getOptionText = (gridViewType: GridViewType) =>
    t(gridViewType === GridViewType.Grid ? I18nKeys.PricingBaseViewTypeGrid : I18nKeys.PricingBaseViewTypeList);

  const handleOpenSelectView = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }

    setOpen(!open);
  };

  const handleCloseSelectView = () => {
    setOpen(false);
  };

  const handleSelectViewOption = (option: string) => {
    if (clientGridViewType !== option) {
      const prefs = {
        ...pricingBasePreferences,
        gridViewType: {
          ...pricingBasePreferences.gridViewType,
          [groupId]: { ...(pricingBasePreferences.gridViewType[groupId] || {}), [viewerClientId]: option },
        },
      };
      dispatch(saveUserPreferences({ userPreference: UserPreference.PricingBasePreferences, preferences: prefs }));
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.topContainer}>
        <div className={classes.topTextContainer}>
          {selectedPricingSheet && (
            <div className={classes.hoverContainer}>
              <Typography
                className={classes.pricingSheetLabel}
                onClick={() => {
                  dispatch(openDialog({ dialog: Dialogs.PricingBaseSheetName }));
                }}
                noWrap
              >
                {sheetName ||
                  t(I18nKeys.PricingBaseAccordionPricingSheetTitle, { pricingSheetId: selectedPricingSheet.id })}
              </Typography>
              <Tooltip
                title={t(I18nKeys.PricingBaseAccordionEditSheetTitleDialogTitle)}
                style={{ height: '20px' }}
                arrow
              >
                <IconButton
                  onClick={() => {
                    dispatch(openDialog({ dialog: Dialogs.PricingBaseSheetName }));
                  }}
                  className={classes.hiddenUntilHover}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}{' '}
          {/* According to design, no attribute label is shown if sheet is named */}
          {attributesLabel && !sheetName && (
            <Typography className={classes.attributesLabel} variant="body2" noWrap>
              {attributesLabel}
            </Typography>
          )}
          <PricingBaseHiddenPriceHeader regions={regions} />
        </div>

        <Button
          className={classes.selectViewButton}
          startIcon={
            <Icon className={classes.selectViewIcon}>
              <img alt="select grid view type" src={selectGridIcon} />
            </Icon>
          }
          onClick={handleOpenSelectView}
        >
          <Typography className={classes.selectViewText}>VIEW</Typography>
        </Button>
        <Popover
          open={open}
          onClose={handleCloseSelectView}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Stack spacing={0}>
            {Object.values(GridViewType).map((option) => (
              <Button
                key={option}
                onClick={() => {
                  handleSelectViewOption(option);
                }}
                className={classes.viewOptionButton}
                startIcon={
                  <Icon className={classes.viewOptionIcon}>
                    {clientGridViewType === option ? <img alt="checked" src={checkIcon} /> : undefined}
                  </Icon>
                }
              >
                <Typography className={classes.viewOptionText}>{getOptionText(option)}</Typography>
              </Button>
            ))}
          </Stack>
        </Popover>
      </div>

      {clientGridViewType === GridViewType.List && (
        <PricingBaseSheet
          clientGridViewType={clientGridViewType}
          regions={regions}
          hasDefaultRegion={hasDefaultRegion}
        />
      )}

      {regions.length === 1 && clientGridViewType === GridViewType.Grid && (
        <PricingBaseSheet clientGridViewType={clientGridViewType} regions={regions} />
      )}
      {regions.length > 1 &&
        clientGridViewType === GridViewType.Grid &&
        regions.map((region, index) => {
          const isHiddenRegion = !!prices?.every(({ hidden }) => hidden[region.regionKey]);
          return (
            <Accordion
              key={region.rowId}
              disableGutters
              elevation={0}
              square
              defaultExpanded={index === 0}
              classes={{ root: classes.accordion }}
              expanded={pricingBaseAccordion.includes(`${accordionKey}-${index}`)}
              onChange={() => handleExpand(index)}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                style={{ minHeight: '24px' }}
                expandIcon={<ExpandMoreIcon style={{ fontSize: '24px' }} />}
              >
                <Typography className={classes.regionTitle}>{region.label}</Typography>
                {(hasDefaultRegion || isHiddenRegion) && (
                  <Typography className={classes.regionDiffs}>
                    {getSubtitleText(availablePricePerRegion, sumAvailablePrices, region, isHiddenRegion)}
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {regionHasPrices(selectedPricingSheet?.prices, region) ? (
                  <PricingBaseSheet
                    clientGridViewType={clientGridViewType}
                    regions={[region]}
                    hasDefaultRegion={hasDefaultRegion}
                  />
                ) : (
                  // According to design, if region has no prices, a message is shown with the region name
                  <Typography>{t(I18nKeys.PricingBaseSheetHasNoPricing, { region: region.label })}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      <PricingBaseNoteDialog />
      <PricingContactSupportDialog />
    </div>
  );
};
