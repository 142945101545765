import API from '@aws-amplify/api';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import { extractErrorProps } from './errorUtils';
import { FetchError } from '../types/API';
import { compoundCaseToKebabCase } from './stringUtils';
import { ClientDataBranch } from '../constants/ClientDataBranch';

const defaultErrorMessage = 'Something went wrong. Please try again.';

export const displayIfNotBranchNotFound = (branch: ClientDataBranch) => (errorMessage: string, status?: number) =>
  !(status === 404 && !!errorMessage && errorMessage.includes(`${branch} does not exist`));

export const amplifyAPIBaseQuery =
  (
    { apiName, baseUrl }: { apiName: string; baseUrl: string } = { apiName: '', baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: 'get' | 'put' | 'post' | 'del';
      init?: {
        [key: string]: any;
      };
      displayToastOnError?: boolean | ((errorMessage: string, status?: number) => boolean);
    },
    unknown,
    FetchError
  > =>
  async ({ url, method, init = {}, displayToastOnError = true }) => {
    try {
      const result = await API[method](apiName, `${baseUrl}${url}`, init);
      return { data: result };
    } catch (error) {
      const { errorMessage = defaultErrorMessage, status } = extractErrorProps(error);

      let showToast = false;
      switch (typeof displayToastOnError) {
        case 'function': {
          showToast = displayToastOnError(errorMessage, status);
          break;
        }
        default:
          showToast = displayToastOnError;
      }
      if (showToast) {
        toast.error(errorMessage);
      }

      return {
        error: {
          status,
          data: errorMessage,
        },
      };
    }
  };

export const getRequestHeader = (fields: { [key: string]: any }) =>
  Object.keys(fields).reduce((acc, key) => ({ ...acc, [compoundCaseToKebabCase(key)]: `${fields[key]}` }), {});

export const getEndpointCacheKey = (endpoint: string, args: any) =>
  `${endpoint}(${JSON.stringify(args, Object.keys(args).sort())})`;
