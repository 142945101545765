import API from '@aws-amplify/api';
import { backOff } from 'exponential-backoff';
import { defaultI18nNamespace } from '../constants/I18n';
import { S3Buckets } from '../constants/S3';
import { API_NAMES } from '../constants/App';

class PortalService {
  apiName = API_NAMES.PORTAL;

  /**
   * Get verified quote hashes for a clientId
   *
   * @return {Promise} A promise that resolves to an object with response status and JSON data, if successful.
   */
  async getVerifiedQuotesMgmtLink(): Promise<{ link: string } | { message: string }> {
    return API.get(this.apiName, `/pricing/verify/getlink`, {});
  }

  /**
   * Get verified quote hashes for a clientId
   *
   * @param {string} clientId
   * @return {Promise} A promise that resolves to an object with response status and JSON data, if successful.
   */
  async getVerifiedQuotes(clientId: string): Promise<{ clientId: string; quoteList: string[] } | { error: string }> {
    return API.get(this.apiName, `/pricing/verify/getquotelist/${clientId}`, {});
  }

  /**
   * Run a single quote verification for a client
   *
   * @param {string} clientId
   * @param {string} hash
   * @param {string} clientBranch
   * @param {string} structureBranch
   * @param {string} systemBranch
   * @return {Promise} A promise that resolves to an object with response status and JSON data, if successful.
   */
  async runVerifiedQuote(
    clientId: string,
    hash: string,
    requestorEmail: string,
    clientBranch: string,
    structureBranch: string,
    systemBranch: string,
  ): Promise<
    | {
        priceDiffDetected: boolean;
        newQuoteDetected: boolean;
        priceDiffAmt: number;
      }
    | {
        invalidConfigurationDetected: true;
        stateChanges: Record<string, unknown>[];
      }
    | { message: string } // Error case
  > {
    // This endpoint calls another endpoint that is known to timeout _occasionally_ - https://app.shortcut.com/idearoominc/story/40643
    // As of this writing this will retry the request a couple extra times, which appears to resolve these occasional timeouts.
    return backOff(
      () =>
        API.post(this.apiName, `/pricing/verify/singlequote`, {
          body: {
            clientId,
            hash,
            requestorEmail,
            clientBranch,
            structureBranch,
            systemBranch,
          },
        }),
      {
        // number of total attempts before we actually hand off error to consumer code.
        numOfAttempts: 5,
      },
    );
  }

  /**
   * Create a CloudFront distribution
   *
   * @param {string} domain
   */
  async createDistribution(domain: string): Promise<any> {
    return API.post(this.apiName, `/configurator/distribution/${domain}`, {});
  }

  /**
   * Request a certificate from ACM
   *
   * @param {string} domain
   */
  async requestCertificate(domain: string): Promise<any> {
    return API.post(this.apiName, `/configurator/certificate/${domain}`, {});
  }

  /**
   * Gets configurator info for a clientId
   *
   * @param {string} clientId
   */
  async getConfiguratorInfo(clientId: string): Promise<any> {
    return API.get(this.apiName, `/configurator/info/${clientId}`, {});
  }

  /**
   * Adds or updates configurator info for a clientId
   *
   * @param {string} clientId
   * @param {string} attributes
   */
  async addConfiguratorInfo(clientId: string, attributes: any): Promise<any> {
    return API.post(this.apiName, `/configurator/info/${clientId}`, {
      body: {
        attributes,
      },
    });
  }

  /**
   * Checks to see if the given provider is valid
   *
   * @param provider
   */
  async getValidSsoProvider(provider: string): Promise<{ provider: string }> {
    return API.get(this.apiName, `/sso/validProvider/${provider}`, {});
  }

  /**
   * Gets the i18n SalesView data for a given language code
   * @param language
   * @returns {Promise}
   */
  async getSalesViewI18nData(language: string): Promise<any> {
    return API.get(this.apiName, `/i18n/${S3Buckets.IdeaRoomI18n}/${language}/${defaultI18nNamespace}.json`, {});
  }

  /**
   * Gets a JWT token for the given client ID and Metabase dashboard ID
   * @param clientId
   * @param dashboardId
   * @returns {Promise}
   */
  async getMetabaseToken(clientId: string, dashboardId: string): Promise<any> {
    return API.get(this.apiName, `/usage/token/${dashboardId}/${clientId}`, {});
  }
}

export const portalService = new PortalService();
