import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import React from 'react';
import { Field, FieldArrayFieldsProps, FieldIterate, Validator, WrappedFieldArrayProps } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';
import { useTranslation } from 'react-i18next';
import { DealerFormFields } from '../constants/FormFields';
import { DepositAmount } from '../types/DepositAmount';
import { getAsFloat } from '../utils/depositAmountUtils';
import { requiredZerosOK } from '../utils/reduxFormUtils';
import { InputField } from './redux-form/InputField';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { getCurrencySymbol } from '../utils/pricingUtils';

// eslint-disable-next-line no-shadow
const inBounds: Validator = (value, allValues, props, name) => {
  const depositAmounts = allValues[DealerFormFields.DepositAmounts];
  const depositAmountIndex = Number.parseInt(name.match(/\d+/g)[0], 10);

  if (depositAmountIndex < depositAmounts.length - 1) {
    const nextPrice = Number(depositAmounts[depositAmountIndex + 1].price);
    if (nextPrice && Number(value) >= nextPrice) {
      return i18n.t(I18nKeys.DepositAmountsOutOfOrder);
    }
  }

  return undefined;
};

const fromValidator = [requiredZerosOK, inBounds];

type ToFieldProps = {
  style: React.CSSProperties;
  label: number | string;
};

const ToField = (props: ToFieldProps): JSX.Element => {
  const { t: translation } = useTranslation();
  const { label, style } = props;

  return (
    <TextField
      style={style}
      variant="standard"
      InputProps={{
        readOnly: true,
        disabled: true,
        style: { color: 'inherit' },
        disableUnderline: true,
      }}
      label={translation(I18nKeys.DepositAmountsRangeDivider)}
      defaultValue={label}
      disabled
      placeholder={translation(I18nKeys.DepositAmountsPlaceholderAmount)}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

const renderDepositAmountEntry = (disabled: boolean, t: Function, currency?: string): FieldIterate<DepositAmount> =>
  function (name, index, fields): JSX.Element {
    const fieldStyle = { width: 95, margin: 8 };
    const iconButtonStyle = {};

    const currencyMask = createNumberMask({
      decimalPlaces: 2,
      allowEmpty: true,
    });

    const percentMask = createNumberMask({
      decimalPlaces: 1,
      allowEmpty: true,
    });

    const getNextFieldPrice = (
      fieldIndex: number,
      fieldsArray: FieldArrayFieldsProps<DepositAmount>,
    ): number | string => {
      const nextFieldIfExists = fieldsArray.get(fieldIndex + 1);
      if (nextFieldIfExists && !nextFieldIfExists.price) {
        return '';
      }
      return nextFieldIfExists
        ? currencyMask.format(getAsFloat(nextFieldIfExists.price) - 0.01)
        : (t(I18nKeys.DepositAmountsHigherText) as string);
    };

    return (
      <Box display="flex" alignItems="flex-end" key={name} flexWrap="wrap">
        <Field
          style={fieldStyle}
          component={InputField}
          props={{ disabled }}
          label={t(I18nKeys.DepositAmountsRangeStart)}
          name={`${name}.price`}
          format={currencyMask.format}
          normalize={currencyMask.normalize}
          required
          InputLabelProps={{
            shrink: true,
          }}
          validate={fromValidator}
          placeholder={t(I18nKeys.DepositAmountsPlaceholderAmount)}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
          }}
        />
        <ToField style={fieldStyle} label={getNextFieldPrice(index, fields)} />
        <Field
          style={fieldStyle}
          component={InputField}
          props={{ disabled }}
          label={t(I18nKeys.DepositAmountsRangeEnd)}
          name={`${name}.percent`}
          format={percentMask.format}
          normalize={percentMask.normalize}
          required
          validate={requiredZerosOK}
          placeholder={t(I18nKeys.DepositAmountsPlaceholderPercent)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />

        {!disabled && (
          <Box>
            <IconButton
              style={iconButtonStyle}
              onClick={(): void => {
                fields.insert(index + 1, { price: '', percent: '' });
              }}
              size="large"
            >
              <AddCircleIcon style={{ color: 'green' }} />
            </IconButton>
            <IconButton style={iconButtonStyle} onClick={(): void => fields.remove(index)} size="large">
              <RemoveCircleIcon style={{ color: 'red' }} />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };

interface OwnProps {
  disabled: boolean;
}

type Props = OwnProps & WrappedFieldArrayProps<DepositAmount>;

export const DepositAmounts: React.FC<Props> = ({ fields, disabled }: Props) => {
  const { t } = useTranslation();
  const { currency } = useAppSelector((state: AppState) => state?.viewer);
  return (
    <>
      {!disabled && fields.length === 0 && (
        <Button variant="contained" onClick={(): void => fields.push({ price: '0', percent: '' })}>
          {t(I18nKeys.DepositAmountsAddAmountsButton)}
        </Button>
      )}
      {fields.map((name, index, fieldz) => renderDepositAmountEntry(disabled, t, currency)(name, index, fieldz))}
    </>
  );
};
