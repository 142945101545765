import React, { MutableRefObject, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Theme, alpha } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine-no-font.css'; // Optional theme CSS
import 'ag-grid-community/styles/agGridMaterialFont.css';
import '../styles/ag-grid.css';
import {
  CellClassParams,
  CellClickedEvent,
  CellEditRequestEvent,
  CellFocusedEvent,
  CellKeyDownEvent,
  CellRendererSelectorFunc,
  ColDef,
  ColumnApi,
  ColumnEvent,
  DomLayoutType,
  DragStartedEvent,
  DragStoppedEvent,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetContextMenuItems,
  GetMainMenuItemsParams,
  GetRowIdFunc,
  GetRowIdParams,
  HeaderClassParams,
  ICellRendererParams,
  ModelUpdatedEvent,
  PostProcessPopupParams,
  ProcessCellForExportParams,
  ProcessDataFromClipboardParams,
  ProcessHeaderForExportParams,
  RangeSelectionChangedEvent,
  RowClassParams,
  RowClassRules,
  RowDragEndEvent,
  RowDragEnterEvent,
  RowDragEvent,
  SortChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
  ViewportChangedEvent,
  VirtualRowRemovedEvent,
} from 'ag-grid-community';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BaseTableData } from '../types/DataGrid';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import {
  columnFormatter,
  columnValueGetter,
  combineTableAndUserMetadata,
  sortColumnsByOrder,
} from '../utils/clientDataUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { ColumnDataType } from '../constants/ClientData';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { setLoadingUserPreferences } from '../ducks/clientDataSlice';
import { columnHeights, columnTypes, defaultColumnWidth } from '../constants/ClientDataColumn';
import { CellMetadata, ColumnMetadata, TableMetadata } from '../types/ClientData';
import { UserPreference } from '../constants/User';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  grid: {
    width: '100%',
    height: '100%',
    '& .ag-cell': {
      padding: '0 3px',
      borderTop: '1px transparent',
    },
    '& .ag-header-cell': {
      paddingLeft: '7px',
      paddingRight: '3px',
    },
    // Remove indent from first header cell in pinned left group for select all button
    '& .ag-pinned-left-header': {
      '& .ag-header-cell:first-child': {
        padding: '0px',
      },
    },
    // Making the floating filter input background white
    '& .ag-floating-filter-body': {
      '& .MuiInputBase-root': {
        backgroundColor: '#FFF',
      },
    },
  },
  statusPanelAction: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    margin: '0px 14px',
    '&:hover': {
      cursor: 'pointer',
      color: alpha(theme.palette.common.black, 0.6),
    },
  },
  firstAction: {
    margin: '0px 14px 0px 28px',
  },
  highlightCell: {
    backgroundColor: 'var(--ag-row-hover-color)',
  },
}));

// fade text to grey if row data is disabled with checkbox
const getRowClassRules: RowClassRules = {
  'ag-grid-custom-disabled-text-color': (params: RowClassParams) => params.data?.enabled === 0,
};

const loadingComponent: React.FC<any> = () => <CircularProgress style={{ alignSelf: 'center' }} color="primary" />;
const noOverlayComponent: React.FC<any> = () => <div />;

type Props<T> = {
  className?: string;
  clientId?: string;
  data: T[];
  getCellClass?: (cellClassParams: CellClassParams) => string[];
  getRowClass?: (params: RowClassParams) => string | string[] | undefined;
  getContextMenuItems: GetContextMenuItems<any> | undefined;
  getMainMenuItems?: (params: GetMainMenuItemsParams) => (string | any)[];
  getCellRenderer?: CellRendererSelectorFunc<any, any>;
  getCustomColumnHeaderTooltip?: (colId: string) => string;
  isLoading?: boolean;
  readOnly?: boolean;
  hideOverlay?: boolean;
  onCellEditRequest?: (params: CellEditRequestEvent) => void;
  onCellFocused?: (event: CellFocusedEvent) => void;
  selectedTable: string;
  selectedTableColumns?: (string | ColDef)[];
  defaultColDefinition: ColDef;
  showFormulas?: boolean;
  statusBarPanels?: any[];
  onCellClicked?: ((event: CellClickedEvent<any, any>) => void) | undefined;
  onCellKeyDown?: ((event: CellKeyDownEvent) => void) | undefined;
  onRangeSelectionChanged?: ((event: RangeSelectionChangedEvent) => void) | undefined;
  sendToClipboard?: ((event: any) => void) | undefined;
  context?: { [key: string]: any };
  popupParent?: HTMLElement;
  onGroupUpdateStart?: () => void;
  onGroupUpdateComplete?: () => void;
  onGroupUpdateEdit?: (params: ValueSetterParams) => boolean;
  onValueSetter?: (params: ValueSetterParams) => boolean;
  groupingCellUpdates?: boolean;
  onFilterChanged?: (event: FilterChangedEvent) => void;
  onSortChanged?: (event: SortChangedEvent) => void;
  processCellForClipboard?: (params: ProcessCellForExportParams) => string;
  processDataFromClipboard?: (params: ProcessDataFromClipboardParams) => string[][] | null;
  onColumnMoved?: (event: ColumnEvent) => void;
  onColumnResized?: (event: ColumnEvent) => void;
  onColumnPinned?: (event: ColumnEvent) => void;
  onColumnVisible?: (event: ColumnEvent) => void;
  onColumnEverythingChanged?: (event: ColumnEvent) => void;
  onRowDragEnd?: (event: RowDragEndEvent) => void;
  onRowDragMove?: (event: RowDragEvent) => void;
  onRowDragEnter?: (event: RowDragEnterEvent) => void;
  onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
  onModelUpdated?: (event: ModelUpdatedEvent) => void;
  onViewportChanged?: (event: ViewportChangedEvent) => void;
  onVirtualRowRemoved?: (event: VirtualRowRemovedEvent) => void;
  processHeaderForClipboard?: (params: ProcessHeaderForExportParams) => string;
  postProcessPopup?: (params: PostProcessPopupParams) => void;
  rowSelection?: 'single' | 'multiple';
  initialRowHeight?: number;
  initialHeaderHeight?: number;
  suppressRowDeselection?: boolean;
  suppressRowClickSelection?: boolean;
  suppressRowTransform?: boolean;
  suppressCellFocus?: boolean;
  enableRangeSelection?: boolean;
  enableCellChangeFlash?: boolean;
  highlightedCell?: { colId: string; rowId: string };
  cellMetadata?: CellMetadata[];
  tableMetadata?: TableMetadata;
  isLoadingTableMetadata?: boolean;
  disableStatusBar?: boolean;
  domLayout?: DomLayoutType;
};

export const DataGrid = <T extends BaseTableData>({
  className = '',
  clientId: displayedClientId,
  data,
  getCellClass,
  getRowClass,
  getCellRenderer,
  getContextMenuItems,
  getMainMenuItems,
  getCustomColumnHeaderTooltip,
  isLoading,
  readOnly = false,
  hideOverlay = false,
  onCellEditRequest,
  onCellFocused,
  selectedTable,
  selectedTableColumns,
  defaultColDefinition,
  showFormulas = false,
  statusBarPanels = [],
  context,
  onCellClicked,
  onCellKeyDown,
  onRangeSelectionChanged,
  popupParent,
  sendToClipboard,
  onGroupUpdateStart,
  onGroupUpdateComplete,
  onGroupUpdateEdit,
  onValueSetter = () => true,
  groupingCellUpdates,
  onFilterChanged,
  onSortChanged,
  processCellForClipboard,
  processDataFromClipboard,
  onColumnMoved,
  onColumnResized,
  onColumnPinned,
  onColumnVisible,
  onColumnEverythingChanged,
  onRowDragEnd,
  onRowDragMove,
  onRowDragEnter,
  onFirstDataRendered,
  onModelUpdated,
  onViewportChanged,
  onVirtualRowRemoved,
  processHeaderForClipboard,
  postProcessPopup,
  rowSelection = 'multiple', // allows multiple row selection
  suppressRowDeselection = false,
  suppressRowClickSelection = true,
  suppressRowTransform = false,
  enableRangeSelection = true,
  enableCellChangeFlash = true,
  suppressCellFocus = false,
  highlightedCell,
  cellMetadata,
  tableMetadata,
  isLoadingTableMetadata,
  disableStatusBar = false,
  gridRef,
  domLayout,
  initialRowHeight,
  initialHeaderHeight,
}: Props<T> & { gridRef?: Ref<AgGridReact | null> }) => {
  const classes = useStyles();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [rowHeight, setRowHeight] = useState<number>(initialRowHeight || 28);
  const [headerHeight, setHeaderHeight] = useState<number>(initialHeaderHeight || 35);

  const gridRefInternal = (gridRef as MutableRefObject<AgGridReact | null>) || useRef<AgGridReact | null>(null);
  const gridWrapperRef = useRef<HTMLDivElement>(null);
  const { api, columnApi } = gridRefInternal?.current || {};

  const dispatch = useAppDispatch();
  const { loadingUserPreferences, clientId, clientDataType } = useAppSelector((state: AppState) => state?.clientData);
  const { preferences: { [UserPreference.ClientDataPreferences]: clientDataPreferences = {} } = {} } = useAppSelector(
    (state: AppState) => state?.currentUser,
  );

  useEffect(() => {
    if (api) {
      if (isLoading) {
        api.showLoadingOverlay();
      } else if (data.length === 0) {
        api.showNoRowsOverlay();
      } else {
        api.hideOverlay();
        // Updates coming from API need to manually refresh cells
        api.refreshCells();
      }
    }
  }, [data, isLoading, api]);

  const getCellClassHandler = (cellClassParam: CellClassParams<T>, specificColumnCellClass?: ColDef['cellClass']) => {
    const colId = cellClassParam.colDef.field;
    const rowId = cellClassParam.data?.rowId;
    const { highlightedCell: highlightCellFromContext } = cellClassParam.context;
    const classList: string[] = [];

    if (highlightCellFromContext?.rowId === rowId || highlightCellFromContext?.colId === colId) {
      classList.push(classes.highlightCell);
    }

    if (specificColumnCellClass) {
      const specificColumnCellClassValue =
        typeof specificColumnCellClass === 'function'
          ? specificColumnCellClass(cellClassParam)
          : specificColumnCellClass;
      if (specificColumnCellClassValue) {
        classList.push(
          ...(Array.isArray(specificColumnCellClassValue)
            ? specificColumnCellClassValue
            : [specificColumnCellClassValue]),
        );
      }
    } else if (getCellClass) {
      classList.push(...getCellClass(cellClassParam));
    }
    return classList;
  };

  const getCellRendererHandler = (params: ICellRendererParams<T>) => {
    const resultFromCellRendererProp = getCellRenderer ? getCellRenderer(params) : undefined;
    if (resultFromCellRendererProp) {
      return resultFromCellRendererProp;
    }
    const colType = params.colDef?.type as ColumnDataType | undefined;
    const columnTypeCellRendererSelector = colType && columnTypes[colType]?.cellRendererSelector;
    return columnTypeCellRendererSelector ? columnTypeCellRendererSelector(params) : undefined;
  };

  const getHeaderClassHandler = (headerClassParam: HeaderClassParams<BaseTableData>) => {
    const colId = headerClassParam.column?.getColId();
    const classList: string[] = [];
    const { highlightedCell: highlightCellFromContext } = headerClassParam.context;
    if (highlightCellFromContext?.colId === colId) {
      classList.push('ag-column-hover');
    }
    return classList;
  };

  // Set columnDefs on selectedTableColumns or selectedTable change
  useEffect(() => {
    if (selectedTableColumns && !isLoadingTableMetadata) {
      // FIXME: Most of this logic is relevant for ClientData.tsx only - move into ClientData.tsx
      const combinedTableMetadata = combineTableAndUserMetadata(clientDataPreferences, tableMetadata);
      if (loadingUserPreferences) dispatch(setLoadingUserPreferences(false));
      const columns = sortColumnsByOrder(selectedTableColumns, combinedTableMetadata).map(
        (column: string | ColDef): ColDef => {
          let columnField: string | undefined;
          if (typeof column === 'string') {
            columnField = column;
          } else if (column?.field) {
            columnField = column.field;
          }
          const columnMetadata: ColumnMetadata =
            columnField !== undefined ? combinedTableMetadata?.metadata[columnField] : {};

          let headerTooltip =
            columnField !== undefined ? `${compoundCaseToTitleCase(columnField)}\n{{${columnField}}}` : undefined;

          if (getCustomColumnHeaderTooltip && columnField) {
            headerTooltip = getCustomColumnHeaderTooltip(columnField);
          }

          return {
            valueGetter: (params: ValueGetterParams) => columnValueGetter(params),
            valueFormatter: (params: ValueFormatterParams) => columnFormatter(params, showFormulas),
            valueSetter: (params: ValueSetterParams) =>
              groupingCellUpdates && onGroupUpdateEdit ? onGroupUpdateEdit(params) : onValueSetter(params),
            ...(typeof column === 'string'
              ? {
                  field: column,
                  headerClass: getHeaderClassHandler,
                  headerTooltip,
                  cellClass: (params) => getCellClassHandler(params),
                  pinned: columnMetadata?.pinned || null,
                  sort: columnMetadata?.sort || undefined,
                  sortIndex: columnMetadata?.sortIndex || undefined,
                  initialWidth: columnMetadata?.width || defaultColumnWidth,
                  width: columnMetadata?.width,
                  hide: columnMetadata?.hide !== undefined ? columnMetadata?.hide : false,
                  editable: columnMetadata?.editable !== undefined ? columnMetadata?.editable : true,
                  type: columnMetadata?.dataType || undefined,
                  cellRendererSelector: getCellRenderer ? getCellRendererHandler : undefined,
                }
              : {
                  headerClass: getHeaderClassHandler,
                  headerTooltip,
                  type: columnMetadata?.dataType || undefined,
                  ...column,
                  cellClass: (params) => getCellClassHandler(params, column.cellClass),
                }),
          };
        },
      );
      setColumnDefs(columns);
      api?.setSuppressRowDrag(columns.some((col) => !!col.sort));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // These props changing will force update AG-Grid's col defs.
    // Be thoughtful of what is being passed here to avoid unnecessary updates.
    highlightedCell, // Forces update col defs when highlighted cell changes due to header styling
    selectedTableColumns,
    showFormulas,
    tableMetadata,
    setColumnDefs,
    groupingCellUpdates,
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => defaultColDefinition, []);

  const getRowId = useMemo<GetRowIdFunc>(
    () => (params: GetRowIdParams) => params.data[ClientDataFixedColumns.RowId],
    [],
  );

  const updateRowHeight = () => {
    let height = columnHeights[ColumnDataType.Text];
    if (tableMetadata && columnApi) {
      const visibleColumns = (columnApi as ColumnApi)
        .getColumns()
        ?.filter((column) => column.isVisible())
        .map((column) => column.getColId());
      const dataTypes = Object.keys(tableMetadata.metadata)
        // Do not change row height for Shed's style table as not every row gets an icon
        .filter((column) => visibleColumns?.includes(column) && column !== 'value1')
        .map((column) => tableMetadata.metadata[column].dataType);
      if (dataTypes.includes(ColumnDataType.StyleIcon))
        height = Math.max(rowHeight, columnHeights[ColumnDataType.StyleIcon]);
      if (dataTypes.includes(ColumnDataType.Image)) height = Math.max(rowHeight, columnHeights[ColumnDataType.Image]);
    }
    setRowHeight(height);
  };

  useEffect(() => {
    updateRowHeight();
  }, [columnDefs]);

  const handleOnColumnVisible = useCallback(
    (event: ColumnEvent) => {
      updateRowHeight();
      if (onColumnVisible) onColumnVisible(event);
    },
    [onColumnVisible],
  );

  useEffect(() => {
    api?.resetRowHeights();
  }, [rowHeight]);

  return (
    <div
      ref={gridWrapperRef}
      className={`ag-theme-alpine ${classes.grid} ${className} ${disableStatusBar ? 'ag-grid-disable-status-bar' : ''}`}
    >
      {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
      <AgGridReact
        context={{
          ...context,
          cellMetadata,
          clientId: displayedClientId || clientId,
          clientDataType,
          selectedTable,
          tableMetadata,
          gridWrapperRef,
          highlightedCell,
        }}
        readOnlyEdit={readOnly} // Cell Editing will not update the data inside the grid. Instead the grid fires cellEditRequest events
        enterNavigatesVerticallyAfterEdit // Enter key will move down to next cell after editing
        ref={gridRefInternal} // Ref to allow access to grid API
        rowData={data} // Row Data for Rows
        columnDefs={columnDefs} // Column Defs for Columns
        defaultColDef={defaultColDef} // Default Column Properties
        domLayout={domLayout || 'normal'}
        columnTypes={columnTypes}
        loadingOverlayComponent={hideOverlay ? noOverlayComponent : loadingComponent} // Loading overlay component
        noRowsOverlayComponent={hideOverlay ? noOverlayComponent : undefined} // No rows overlay component
        animateRows // Optional - set to 'true' to have rows animate when sorted
        enableRangeSelection={enableRangeSelection} // allows copy / paste using cell ranges
        suppressMultiRangeSelection // disables multi range selections
        enableFillHandle={enableRangeSelection} // enables the fill handle
        allowContextMenuWithControlKey // allows context menu to be shown when ctrl key is pressed
        stopEditingWhenCellsLoseFocus // stops editing when cells lose focus
        enableCellChangeFlash={enableCellChangeFlash} // enables flashing to help see cell changes
        rowDragMultiRow // allows dragging of multiple rows
        rowSelection={rowSelection}
        suppressRowDeselection={suppressRowDeselection} // disables row deselection on row click
        suppressRowClickSelection={suppressRowClickSelection} // disables row selection on row click
        suppressRowTransform={suppressRowTransform} // allows row spanning
        suppressColumnVirtualisation // disables column virtualisation for auto-size all columns
        getContextMenuItems={getContextMenuItems}
        getMainMenuItems={getMainMenuItems}
        getRowId={getRowId}
        getRowClass={getRowClass}
        rowHeight={rowHeight}
        tooltipShowDelay={0}
        tooltipHideDelay={6000}
        headerHeight={headerHeight}
        suppressCellFocus={suppressCellFocus}
        floatingFiltersHeight={35}
        statusBar={{
          statusPanels: [
            ...statusBarPanels.map((statusPanel) => ({
              statusPanel,
              key: statusPanel.displayName || statusPanel.name,
              align: 'left',
            })),
          ],
        }}
        popupParent={popupParent}
        postProcessPopup={postProcessPopup}
        processDataFromClipboard={processDataFromClipboard}
        processHeaderForClipboard={processHeaderForClipboard}
        rowClassRules={getRowClassRules}
        onCellEditRequest={onCellEditRequest}
        onCellClicked={onCellClicked}
        onCellFocused={onCellFocused}
        onCellKeyDown={onCellKeyDown}
        onRangeSelectionChanged={onRangeSelectionChanged}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        sendToClipboard={sendToClipboard}
        processCellForClipboard={processCellForClipboard}
        onColumnMoved={onColumnMoved}
        onColumnResized={onColumnResized}
        onColumnPinned={onColumnPinned}
        onColumnVisible={handleOnColumnVisible}
        onColumnEverythingChanged={onColumnEverythingChanged}
        onRowDragEnd={onRowDragEnd}
        onRowDragMove={onRowDragMove}
        onRowDragEnter={onRowDragEnter}
        onFirstDataRendered={onFirstDataRendered} // onFirstDataRendered is triggered once in the whole grid lifetime
        onModelUpdated={onModelUpdated} // onModelUpdated is triggered whenever the data on the grid changes
        onViewportChanged={onViewportChanged} // onViewportChanged is triggered whenever the rendered rows change
        onVirtualRowRemoved={onVirtualRowRemoved} // onVirtualRowRemoved is triggered whenever a rendered row is removed from the viewport
        onDragStarted={(params: DragStartedEvent) => {
          if (!onGroupUpdateStart) return;
          const { target: { className: fillClassName = '' } = {} } = params;
          if (fillClassName.includes('ag-fill-handle')) onGroupUpdateStart();
        }}
        onDragStopped={(params: DragStoppedEvent) => {
          if (!onGroupUpdateComplete) return;
          const { target: { className: fillClassName = '' } = {} } = params;
          if (fillClassName.includes('ag-fill-handle')) onGroupUpdateComplete();
        }}
      />
    </div>
  );
};
