import { Button } from '@mui/material';
import React from 'react';
import { ColumnApi, GridApi, IFloatingFilterParams } from 'ag-grid-community';
import { DefaultColumnFieldNames } from '../constants/ClientDataColumn';
import { DefaultPricingColumnFieldNames } from './PricingBaseSheet';

export const selectAllCells = (columnApi?: ColumnApi, api?: GridApi, rootGridApi?: GridApi) => {
  const allColumns = columnApi
    ?.getAllGridColumns()
    .filter(
      (col) =>
        !(
          [
            DefaultColumnFieldNames.Index,
            DefaultColumnFieldNames.AddToTable,
            DefaultColumnFieldNames.GoToCellRange,
            DefaultPricingColumnFieldNames.Length,
            DefaultPricingColumnFieldNames.LengthSpanLabel,
          ] as string[]
        ).includes(col.getId()),
    );
  const rowCount = api?.getModel()?.getRowCount();

  if (!allColumns?.length || !rowCount) return;

  const gridApis = [api, rootGridApi].filter((a) => a);
  gridApis.forEach((a) => {
    // ? isn't necessary because of the filter. Its a linter problem
    a?.clearFocusedCell();
    a?.clearRangeSelection();
  });
  api?.setFocusedCell(0, allColumns[0]);
  api?.addCellRange({
    rowStartIndex: 0,
    rowEndIndex: rowCount - 1,
    columns: [...allColumns],
  });
};

export const SelectAllCellsHeader: React.FC<IFloatingFilterParams> = ({
  columnApi,
  api,
  context: { rootGridApi } = {},
}: IFloatingFilterParams) => (
  <Button
    style={{ minWidth: '34px', borderRadius: '0px', height: '100%' }}
    onClick={() => selectAllCells(columnApi, api, rootGridApi)}
  />
);
