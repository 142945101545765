import {
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Link,
  Popover,
  Skeleton,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import moment from 'moment';
import { Change, diffWords } from 'diff';
import { useResizeDetector } from 'react-resize-detector';
import { useGetCellHistoryQuery } from '../services/clientDataApi';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientDataBranchChip } from './ClientDataBranchChip';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataCellHistoryChange, ColumnDataType, DoltDiffType } from '../constants/ClientData';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { LocalStorage } from '../constants/LocalStorage';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';

const useStyles = makeStyles<Theme, { largeMode?: boolean; height?: number; width?: number }>((theme: Theme) => ({
  card: ({ largeMode, height, width }) => ({
    minWidth: largeMode ? 445 : 335,
    width: width || (largeMode ? 445 : 335),
    minHeight: 135,
    height,
    resize: 'both',
    display: 'flex',
    flexDirection: 'column',
  }),
  headerRoot: {
    background: 'var(--BG-Light, rgba(0, 0, 0, 0.08))',
    paddingTop: '6px',
    paddingBottom: '10px',
  },
  headerTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    background: 'var(--BG-Light, rgba(0, 0, 0, 0.08))',
    padding: '10px 16px 0px 16px',
    display: 'flex',
    alignItems: 'center',
  },
  titleTypo: {
    flexGrow: 1,
    fontWeight: theme.typography.fontWeightBold,
  },
  titleNavButtonLeft: {
    margin: '-5px',
  },
  titleNavButtonRight: {
    margin: '-5px',
    marginLeft: '3px',
  },
  titleOnlyButton: {
    color: 'rgba(0, 0, 0, 0.60)',
  },
  contentRoot: {
    padding: '10px 16px',
    overflowY: 'auto',
  },
  branchChip: {
    margin: '-3px 0px',
  },
  largeDiff: {
    display: 'block',
    marginLeft: '8px',
    marginTop: '4px',
    marginBottom: '4px',
  },
  versionButton: {
    margin: '-3px 0px',
  },
}));

interface Props {
  rowId?: string | null;
  column?: string | null;
  anchorEl?: HTMLElement | null;
  open: boolean;
  onClose: { (): void };
}

const EMPTY_LIST: ClientDataCellHistoryChange[] = [];

const extractNameInitials = (fullName: string) => {
  const names = fullName.split(' ');
  const firstLetter = names[0].charAt(0);
  const lastLetter = names[names.length - 1].charAt(0);
  return names.length > 1 ? `${firstLetter}${lastLetter}` : firstLetter;
};

const getClientPreferencesFromLocalStorage = (): any =>
  JSON.parse(localStorage.getItem(LocalStorage.ClientDataCellHistorySize) || '{}');

const setClientPreferencesFromLocalStorage = (prefs: any) => {
  localStorage.setItem(LocalStorage.ClientDataCellHistorySize, JSON.stringify(prefs));
};

const CellDiff: React.FC<{
  change: {
    version: number | ClientDataBranch;
    diffType?: DoltDiffType;
    to?: Change[];
    from?: Change[];
    toFormula?: Change[];
    fromFormula?: Change[];
    message?: string;
  };
  largeMode: boolean;
}> = ({ change, largeMode }) => {
  const theme = useTheme();
  const classes = useStyles({ largeMode });
  if (change.version === 0) {
    return <Typography variant="body2">{change.message}</Typography>;
  }
  if (
    change.diffType === DoltDiffType.Added &&
    change.to &&
    change.to.length === 0 &&
    (!change.toFormula || change.toFormula.length === 0)
  ) {
    return <Typography variant="body2">Row Added</Typography>;
  }

  const renderDiffValue = (diff: Change[]) =>
    diff.map((part, index) => (
      <span
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        style={{
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: part.added
            ? theme.palette.diffAdd.main
            : part.removed
            ? theme.palette.diffRemove.main
            : undefined,
          whiteSpace: 'pre-line',
        }}
      >
        {part.value}
      </span>
    ));

  const renderDiff = (from: Change[] = [], to: Change[] = [], formula: boolean) => {
    const isAdd = from.length === 0 && to.length > 0;
    const isDelete = to.length === 0 && from.length > 0;
    const prefix = formula ? 'Formula ' : '';
    if (isAdd) {
      return (
        <Typography variant="body2">
          {prefix}Added: &quot;{renderDiffValue(to)}&quot;
        </Typography>
      );
    }
    if (isDelete) {
      return (
        <Typography variant="body2">
          {prefix}Deleted: &quot;{renderDiffValue(from)}&quot;
        </Typography>
      );
    }
    if (!from.some((part) => part.removed || part.added) && !to.some((part) => part.removed || part.added)) {
      return largeMode ? (
        <Typography variant="body2">
          {prefix}Unchanged:
          <code className={classes.largeDiff}>{renderDiffValue(from)}</code>
        </Typography>
      ) : (
        <Typography variant="body2">
          {prefix}Unchanged: &quot;{renderDiffValue(from)}&quot;
        </Typography>
      );
    }
    return largeMode ? (
      <Typography variant="body2">
        {prefix}Changed:
        <code className={classes.largeDiff}>{renderDiffValue(from)}</code>
        to
        <code className={classes.largeDiff}>{renderDiffValue(to)}</code>
      </Typography>
    ) : (
      <Typography variant="body2">
        {prefix}Changed: &quot;{renderDiffValue(from)}&quot; to &quot;{renderDiffValue(to)}&quot;
      </Typography>
    );
  };

  return (
    <>
      <div>{renderDiff(change.from, change.to, false)}</div>
      {((change.fromFormula && change.fromFormula.length > 0) || (change.toFormula && change.toFormula.length > 0)) && (
        <div style={{ marginTop: '10px' }}>{renderDiff(change.fromFormula, change.toFormula, true)}</div>
      )}
    </>
  );
};

export const ClientDataCellHistoryPopover: React.FC<Props> = ({ rowId, column, anchorEl, open, onClose }) => {
  const dispatch = useAppDispatch();
  const { clientId, clientDataType, clientDataBranch, selectedTable } = useAppSelector((state) => state?.clientData);
  const [changeIndex, setChangeIndex] = useState(-1);

  const { currentData = EMPTY_LIST, isFetching } = useGetCellHistoryQuery(
    {
      clientId,
      dataType: clientDataType,
      table: selectedTable,
      rowId: rowId || '',
      column: column || '',
      branch: clientDataBranch,
    },
    { skip: !rowId || !column || !clientDataBranch, refetchOnMountOrArgChange: true },
  );

  const isLoading = isFetching && currentData.length === 0;
  const noData = !currentData || currentData.length === 0;

  const { tableMetadata } = useClientDataRepo({ useTableMetadata: true });
  const largeMode =
    tableMetadata && column
      ? [ColumnDataType.Expression, ColumnDataType.Html, ColumnDataType.Json].includes(
          tableMetadata.metadata[column]?.dataType || ColumnDataType.Text,
        )
      : false;

  useEffect(() => {
    setChangeIndex(0);
  }, [currentData]);

  const data = useMemo(() => {
    if (changeIndex === currentData.length) {
      return { message: 'Original Dataset', author: 'System', email: 'ops@idearoom.com', date: undefined, version: 0 };
    }

    const change = currentData[changeIndex] ? { ...currentData[changeIndex] } : undefined;

    if (change) {
      const diff = diffWords(`${change.from || ''}`, `${change.to || ''}`);
      const from = diff.filter((part) => (part.removed || !part.added) && part.value.length > 0);
      const to = diff.filter((part) => (part.added || !part.removed) && part.value.length > 0);

      const diffFormula =
        change.fromFormula !== undefined || change.toFormula !== undefined
          ? diffWords(`${change.fromFormula || ''}`, `${change.toFormula || ''}`)
          : undefined;
      const fromFormula =
        diffFormula && diffFormula.filter((part) => (part.removed || !part.added) && part.value.length > 0);
      const toFormula =
        diffFormula && diffFormula.filter((part) => (part.added || !part.removed) && part.value.length > 0);
      return {
        ...change,
        from,
        to,
        fromFormula,
        toFormula,
      };
    }
    return undefined;
  }, [changeIndex, currentData]);

  const clientPrefs = getClientPreferencesFromLocalStorage();
  const { height, width } = (largeMode ? clientPrefs.largeSize : clientPrefs.smallSize) || {};

  const [persistResize, setPersistResize] = useState(false);
  const onResizePanel = useCallback(
    (newWidth?: number, newHeight?: number) => {
      if (persistResize) {
        const newPrefs = { ...clientPrefs };
        if (largeMode) {
          newPrefs.largeSize = { width: newWidth, height: newHeight };
        } else {
          newPrefs.smallSize = { width: newWidth, height: newHeight };
        }
        setClientPreferencesFromLocalStorage(newPrefs);
      }
    },
    [persistResize, clientPrefs, largeMode],
  );
  const classes = useStyles({ largeMode, height, width });
  const { ref: cardRef } = useResizeDetector({ onResize: onResizePanel, skipOnMount: true });

  return !anchorEl ? null : (
    <Popover
      id="cell-history-popper"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card
        classes={{ root: classes.card }}
        ref={cardRef}
        onMouseDown={() => setPersistResize(true)}
        onMouseUp={() => setPersistResize(false)}
      >
        <div className={classes.title}>
          <Typography className={classes.titleTypo} variant="body2">
            Edit History
          </Typography>
          {currentData.length <= 1 && !isLoading ? (
            <Typography className={classes.titleOnlyButton} variant="body2">
              Only version
            </Typography>
          ) : (
            <>
              <IconButton
                className={classes.titleNavButtonLeft}
                size="small"
                disabled={changeIndex >= currentData.length || isLoading || noData}
                onClick={() => setChangeIndex(changeIndex + 1)}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                className={classes.titleNavButtonRight}
                size="small"
                disabled={changeIndex <= 0 || isLoading || noData}
                onClick={() => setChangeIndex(changeIndex - 1)}
              >
                <ChevronRightIcon />
              </IconButton>
            </>
          )}
        </div>
        {!data && !isLoading ? (
          <div className={classes.contentRoot}>
            <Typography variant="body2">No Data</Typography>
          </div>
        ) : (
          <>
            <CardHeader
              avatar={
                isLoading || !data ? (
                  <Skeleton variant="circular" width={40} height={40} />
                ) : (
                  <Avatar sx={{ bgcolor: 'rgba(143, 143, 143, 1)' }} aria-label="recipe">
                    {extractNameInitials(data.author)}
                  </Avatar>
                )
              }
              title={isLoading || !data ? <Skeleton variant="text" /> : <span>{data.author}</span>}
              subheader={
                isLoading || !data ? (
                  <Skeleton variant="text" />
                ) : (
                  <div>
                    {data.date
                      ? `${moment(data.date).calendar({
                          sameDay: '[Today], h:mm A',
                          lastDay: '[Yesterday], h:mm A',
                          lastWeek: 'MMM D, h:mm A',
                          sameElse: moment().isSame(data.date, 'year') ? 'MMM D, h:mm A' : 'MMM D, YYYY, h:mm A',
                        })} - `
                      : ''}
                    {Object.values(ClientDataBranch).includes(data.version as ClientDataBranch) ? (
                      <ClientDataBranchChip className={classes.branchChip} branch={data.version as ClientDataBranch} />
                    ) : (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <Link
                        className={classes.versionButton}
                        component="button"
                        variant="body2"
                        underline="always"
                        color="rgba(0, 0, 0, 0.60)"
                        onClick={() => {
                          onClose();
                          dispatch(
                            openDialog({
                              dialog: Dialogs.ClientDataRollback,
                              options: { selectVersion: data.version as number },
                            }),
                          );
                        }}
                      >
                        Version {data.version}
                      </Link>
                    )}
                  </div>
                )
              }
              classes={{ root: classes.headerRoot, title: classes.headerTitle }}
            />
            <div className={classes.contentRoot}>
              {isLoading || !data ? (
                <Skeleton variant="rectangular" />
              ) : (
                <CellDiff change={data} largeMode={largeMode} />
              )}
            </div>
          </>
        )}
      </Card>
    </Popover>
  );
};
