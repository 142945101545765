import { Checkbox, FormControl, FormControlLabel, Grid, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { I18nKeys } from '../constants/I18nKeys';
import { SiteDetailDataFields } from '../constants/VendorData';
import { fetchSceneEnvironment } from '../ducks/settings';
import { AppState } from '../types/AppState';
import { DEFAULT_SCENE_KEY, SceneEnvironment } from '../types/SceneEnvironment';
import { SiteDetail } from '../types/ClientData';
import { getConfiguratorFromClientId } from '../utils/clientIdUtils';
import { disabledSiteDetailField, ideaRoomOnlySiteDetailField } from '../utils/vendorDataUtils';
import { LoadingSelect } from './LoadingSelect';
import { SceneEnvironmentIconPreview } from './SceneEnvironmentIconPreview';
import { SiteInputLabel } from './SiteInputLabel';

const useStyles = makeStyles({
  sceneSelectorContainer: {
    padding: '8px',
    display: 'flex',
    flexWrap: 'nowrap',
    alignContent: 'center',
  },
  sceneSelectorPreview: {
    marginLeft: '8px',
  },
  sceneChangeCheckbox: {
    padding: '8px',
  },
  inputLabel: {
    marginTop: '16px',
  },
});

interface Props {
  vendorPropChange: Function;
  data?: SiteDetail;
  clientId: string;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

export const SceneEnvironmentSelector: React.FC<Props> = ({
  vendorPropChange,
  data = {},
  clientId,
  isIdeaRoomUser = false,
  selectedGroupId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const sceneEnvironments = useSelector<AppState, SceneEnvironment[]>((state) => state.settings.sceneEnvironment);
  const loading = useSelector<AppState, boolean>((state) => state.settings.loadingSceneEnvironment);

  const dispatch = useDispatch();
  useEffect(() => {
    const configurator = getConfiguratorFromClientId(clientId);
    if (configurator) {
      const { key } = configurator;
      dispatch(fetchSceneEnvironment(key));
    }
  }, [clientId]);

  const selectedScene = sceneEnvironments.find(
    (scene) => scene.key === (data.defaultSceneEnvironment || DEFAULT_SCENE_KEY),
  );

  return (
    <Grid container item xs={12} sm={6} alignContent="flex-start">
      <Grid item xs={12} className={classes.sceneSelectorContainer}>
        <FormControl fullWidth>
          <SiteInputLabel
            shrink
            className={classes.inputLabel}
            htmlFor="defaultSceneEnvironment-select"
            i18nKey={I18nKeys.SceneEnvironmentSelector}
            ideaRoomOnly={ideaRoomOnlySiteDetailField(
              SiteDetailDataFields.DefaultSceneEnvironment,
              data,
              isIdeaRoomUser,
              selectedGroupId,
            )}
          />
          <LoadingSelect
            defaultValue={DEFAULT_SCENE_KEY}
            value={data.defaultSceneEnvironment}
            inputProps={{
              displayEmpty: false,
              name: SiteDetailDataFields.DefaultSceneEnvironment,
              id: 'defaultSceneEnvironment-select',
            }}
            variant="filled"
            onChange={(e) => vendorPropChange(e.target.name, e.target.value)}
            disabled={
              loading ||
              sceneEnvironments.length === 0 ||
              disabledSiteDetailField(
                SiteDetailDataFields.DefaultSceneEnvironment,
                data,
                isIdeaRoomUser,
                selectedGroupId,
              )
            }
            alerttext={
              disabledSiteDetailField(
                SiteDetailDataFields.DefaultSceneEnvironment,
                data,
                isIdeaRoomUser,
                selectedGroupId,
              )
                ? t(I18nKeys.SitesProSubscriptionAlert)
                : undefined
            }
            loading={loading}
          >
            {sceneEnvironments.map((scene) => (
              <MenuItem key={scene.key} value={scene.key}>
                {scene.label}
              </MenuItem>
            ))}
          </LoadingSelect>
        </FormControl>
        <div className={classes.sceneSelectorPreview}>
          <SceneEnvironmentIconPreview url={selectedScene ? selectedScene.previewUrl : ''} size={1.4} />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.sceneChangeCheckbox}>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                name={SiteDetailDataFields.DisableSceneEnvironmentChange}
                checked={!data.disableSceneEnvironmentChange}
                onChange={(e) => vendorPropChange(e.target.name, !e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'allow user scene change' }}
                disabled={
                  loading ||
                  sceneEnvironments.length === 0 ||
                  disabledSiteDetailField(
                    SiteDetailDataFields.DefaultSceneEnvironment,
                    data,
                    isIdeaRoomUser,
                    selectedGroupId,
                  )
                }
              />
            }
            label={t(I18nKeys.SceneEnvironmentAllowUserChange)}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
