import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { AppState } from '../types/AppState';
import { GroupSettings } from '../types/Group';
import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { defaultGroupSettings, unknownGroup } from '../constants/Group';
import { updateGroupSettings } from '../ducks/group';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  rootGrid: {
    padding: '2%',
    flex: 1,
  },
});

interface StateProps {
  settings: GroupSettings;
  dealerNetworkEnabled: boolean;
}

interface DispatchProps {
  updateNotificationValues(values: any): AnyAction;
}

type Props = StateProps & DispatchProps;

const NotificationSettingsComponent: React.FC<Props> = ({
  settings,
  dealerNetworkEnabled,
  updateNotificationValues,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleNotificationChange = (prop: string, value: any): void => {
    const values: { [key: string]: any } = {
      ...settings,
    };
    values[prop] = value;
    updateNotificationValues(values);
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.rootGrid}
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid item>
          <Typography variant="h6" component="h6">
            {t(I18nKeys.NotificationSettingsLeadsTitle)}
          </Typography>
        </Grid>

        <Grid item>
          <Box ml={4}>
            <FormGroup>
              {dealerNetworkEnabled && (
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Checkbox
                      name="dealer-notifications"
                      color="primary"
                      checked={!!settings.dealerNotifications}
                      onChange={(e): void => handleNotificationChange('dealerNotifications', e.currentTarget.checked)}
                    />
                  }
                  label={t(I18nKeys.NotificationSettingsLeadsEmailDealer)}
                />
              )}
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Checkbox
                    name="sales-rep-notifications"
                    color="primary"
                    checked={!!settings.salesRepNotifications}
                    onChange={(e): void => handleNotificationChange('salesRepNotifications', e.currentTarget.checked)}
                  />
                }
                label={t(I18nKeys.NotificationSettingsLeadsEmailOwner)}
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ group: { group = unknownGroup } }: AppState): StateProps => {
  const { configurators } = group;
  const dealerNetworkEnabled =
    (configurators &&
      configurators.some((c) =>
        getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.DealerNetworkEnabled, true),
      )) ||
    true;
  const { settings = defaultGroupSettings } = group;
  return {
    settings,
    dealerNetworkEnabled,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateNotificationValues: (values: any): AnyAction => dispatch(updateGroupSettings(values)),
});

export const NotificationSettings = connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsComponent);
