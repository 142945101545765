// In ch8325 converting to different permission structure
// Map SALES_TOOLS to new SALES permission and SALES_VIEW and DEAL_MANAGER to MANAGER permission

import { Permissions } from '../constants/Permissions';

// Filter to unique permissions
export const convertAndFilterPermissions = (permissions: string[] | undefined): string[] =>
  permissions && permissions.length > 0
    ? permissions
        .map((permission) => {
          if (['SALES_TOOLS'].includes(permission)) {
            return Permissions.Sales;
          }
          if (['SALES_VIEW', 'DEAL_MANAGER'].includes(permission)) {
            return Permissions.Manager;
          }
          return permission;
        })
        .filter((value, index, self) => self.indexOf(value) === index)
    : [];
