import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { LocalizationProvider, MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import { DateRange } from '../types/DateRange';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexDirection: 'row',
  },
  dividerContainer: {
    padding: theme.spacing(3),
  },
  dividerItem: {
    height: '75%',
    marginLeft: theme.spacing(1),
  },
  datePickerItems: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1',
  },
  to: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
}));

interface Props {
  dateRange: DateRange;
  onApply(dateRange: DateRange): void;
  onCancel(): void;
  open: boolean;
}

export const DateRangeDialog: React.FC<Props> = ({
  dateRange: { startDate, endDate },
  onApply,
  open,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [pickerStartDate, setPickerStartDate] = React.useState(startDate);
  const [pickerEndDate, setPickerEndDate] = React.useState(endDate);
  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    if (pickerStartDate && pickerEndDate) {
      setIsValid(pickerStartDate.isValid() && pickerEndDate.isValid());
    } else {
      setIsValid(false);
    }
  }, [pickerStartDate, pickerEndDate]);

  React.useEffect(() => setPickerStartDate(startDate), [startDate]);
  React.useEffect(() => setPickerEndDate(endDate), [endDate]);

  const handleStartDateChange = (newStartDate: any): void => setPickerStartDate(newStartDate);
  const handleEndDateChange = (newEndDate: any): void => setPickerEndDate(newEndDate);

  const handleApplyClick = (): void => {
    if (pickerStartDate && pickerEndDate) {
      onApply({ startDate: pickerStartDate.startOf('day'), endDate: pickerEndDate.endOf('day') });
    }
  };

  return (
    <Dialog open={open} maxWidth="md" disableEnforceFocus>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="body1">{t(I18nKeys.DateRangeDialogTitle)}</Typography>
            </Grid>
            <Hidden smDown>
              <Grid item className={classes.datePickerItems}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={pickerStartDate}
                  disableFuture
                  onChange={handleStartDateChange}
                  renderInput={(): JSX.Element => <TextField label={t(I18nKeys.DateRangeDialogStartDateLabel)} />}
                />
                <div className={classes.dividerContainer}>
                  <Typography className={classes.to} variant="body1">
                    {t(I18nKeys.DateRangeDialogDivider)}
                  </Typography>
                  <Divider orientation="vertical" className={classes.dividerItem} />
                </div>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={pickerEndDate}
                  disableFuture
                  onChange={handleEndDateChange}
                  renderInput={(): JSX.Element => <TextField label={t(I18nKeys.DateRangeDialogEndDateLabel)} />}
                />
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item>
                <MobileDatePicker
                  value={pickerStartDate}
                  disableFuture
                  onChange={handleStartDateChange}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps): JSX.Element => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField label={t(I18nKeys.DateRangeDialogStartDateLabel)} {...params} />
                  )}
                />
              </Grid>
              <Grid item>
                <MobileDatePicker
                  value={pickerEndDate}
                  disableFuture
                  onChange={handleEndDateChange}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps): JSX.Element => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField label={t(I18nKeys.DateRangeDialogEndDateLabel)} {...params} />
                  )}
                />
              </Grid>
            </Hidden>
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t(I18nKeys.DialogCancelButton)}</Button>
        <Button disabled={!isValid} onClick={handleApplyClick}>
          {t(I18nKeys.DialogApplyButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
