import { Box, Grid, Checkbox, FormControl, FormControlLabel, Typography, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SiteDetail, SiteDetailField } from '../types/ClientData';
import { SiteDetailDataFields, leadsFormPanelFieldsMap, leadsPopupDelayOptions } from '../constants/VendorData';
import { disabledSiteDetailField, hideSiteDetailField, ideaRoomOnlySiteDetailField } from '../utils/vendorDataUtils';
import { LoadingSelect } from './LoadingSelect';
import { SiteInputLabel } from './SiteInputLabel';
import { I18nKeys } from '../constants/I18nKeys';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';

interface OwnProps {
  data?: SiteDetail;
  vendorPropChange: Function;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

type Props = OwnProps;

const useStyles = makeStyles({
  inputLabel: {
    marginTop: '16px',
  },
  fieldContainer: {
    padding: '8px',
  },
});

export const SitesLeadsFormPanel: React.FC<Props> = ({
  data = {},
  vendorPropChange,
  isIdeaRoomUser = false,
  selectedGroupId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [leadTitle, setLeadTitle] = React.useState(
    data[SiteDetailDataFields.LeadsPopupTitle] !== null
      ? data[SiteDetailDataFields.LeadsPopupTitle]
      : t(I18nKeys.LeadsPopupDefaultTitle),
  );

  const [leadMessage, setLeadMessage] = React.useState(
    data[SiteDetailDataFields.LeadsPopupMessage] !== null
      ? data[SiteDetailDataFields.LeadsPopupMessage]
      : t(I18nKeys.LeadsPopupDefaultMessage),
  );

  const getValue = (key: SiteDetailDataFields, defaultValue: string) => {
    if (key === SiteDetailDataFields.LeadsPopupTitle) {
      return leadTitle;
    }
    if (key === SiteDetailDataFields.LeadsPopupMessage) {
      return leadMessage;
    }
    return data[key as SiteDetailDataFields] ? data[key as SiteDetailDataFields] : defaultValue || '';
  };

  return (
    <Grid container>
      {Object.entries(leadsFormPanelFieldsMap)
        .filter(([key]) => !hideSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId))
        .map(([key, field]) => {
          const { props: gridProps = {}, i18nKey } = field as SiteDetailField;
          const checkboxFields = [
            SiteDetailDataFields.EnableClientManagedLeadsForm,
            SiteDetailDataFields.LeadsEnableEmailPopup,
          ];
          if (checkboxFields.includes(key as SiteDetailDataFields)) {
            return (
              <Grid item xs={gridProps.xs || 12} key={key}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={key}
                        checked={
                          data[key as SiteDetailDataFields] === undefined
                            ? (!!gridProps.default as boolean)
                            : (!!data[key as SiteDetailDataFields] as boolean)
                        }
                        onChange={(e): void => vendorPropChange(e.target.name, e.target.checked)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={disabledSiteDetailField(
                          key as SiteDetailDataFields,
                          data,
                          isIdeaRoomUser,
                          selectedGroupId,
                        )}
                      />
                    }
                    label={
                      <>
                        {ideaRoomOnlySiteDetailField(
                          key as SiteDetailDataFields,
                          data,
                          isIdeaRoomUser,
                          selectedGroupId,
                        ) && <IdeaRoomOnlyIndicator />}
                        <Typography style={{ display: 'inline' }}>{t(i18nKey)}</Typography>
                      </>
                    }
                  />
                </Box>
              </Grid>
            );
          }
          if (SiteDetailDataFields.LeadsPopupDelay === (key as SiteDetailDataFields)) {
            return (
              <Grid item xs={6} sm={gridProps.sm || 6} className={classes.fieldContainer} key={key}>
                <FormControl fullWidth style={{ marginBottom: '3px' }}>
                  <SiteInputLabel shrink className={classes.inputLabel} htmlFor={`${key}-select`} i18nKey={i18nKey} />
                  <LoadingSelect
                    disabled={disabledSiteDetailField(
                      key as SiteDetailDataFields,
                      data,
                      isIdeaRoomUser,
                      selectedGroupId,
                    )}
                    value={data[key as SiteDetailDataFields.LeadsPopupDelay] || gridProps.default}
                    onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value)}
                    inputProps={{
                      displayEmpty: false,
                      name: key,
                      id: `${key}-select`,
                    }}
                    variant="filled"
                    loading={false}
                  >
                    {leadsPopupDelayOptions.map((option: any) => (
                      <MenuItem key={option.key} value={option.key}>
                        {t(option.label)}
                      </MenuItem>
                    ))}
                  </LoadingSelect>
                </FormControl>
              </Grid>
            );
          }
          if (SiteDetailDataFields.LeadsRequireForDesign === (key as SiteDetailDataFields)) {
            return (
              <Grid item xs={6} style={{ padding: '12px' }} key={key}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabledSiteDetailField(
                          SiteDetailDataFields.LeadsRequireForDesign,
                          data,
                          isIdeaRoomUser,
                          selectedGroupId,
                        )}
                        name={SiteDetailDataFields.LeadsRequireForDesign}
                        checked={!!data.leadsRequireForDesign}
                        onChange={(e: any): void => vendorPropChange(e.target.name, e.target.checked)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={t(
                      (leadsFormPanelFieldsMap[SiteDetailDataFields.LeadsRequireForDesign] as SiteDetailField).i18nKey,
                    )}
                  />
                </FormControl>
              </Grid>
            );
          }
          return (
            <Grid container>
              <Grid item xs={12} sm={gridProps.sm || 6} className={classes.fieldContainer} key={key}>
                <FormControl fullWidth>
                  <SiteInputLabel shrink className={classes.inputLabel} i18nKey={i18nKey} />
                  <TextField
                    disabled={disabledSiteDetailField(
                      key as SiteDetailDataFields,
                      data,
                      isIdeaRoomUser,
                      selectedGroupId,
                    )}
                    hidden={hideSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId)}
                    name={key}
                    onChange={(e): void => {
                      if (key === SiteDetailDataFields.LeadsPopupTitle) {
                        setLeadTitle(e.target.value);
                      } else {
                        setLeadMessage(e.target.value);
                      }
                      vendorPropChange(e.target.name, e.target.value);
                    }}
                    value={getValue(key as SiteDetailDataFields, gridProps.default)}
                    type="text"
                    variant="filled"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...([SiteDetailDataFields.LeadsPopupMessage].includes(key as SiteDetailDataFields)
                      ? {
                          rows: '2',
                          multiline: true,
                          onBlur: (e): void => {
                            vendorPropChange(e.target.name, e.target.value);
                          },
                        }
                      : {})}
                  />
                </FormControl>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};
