import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { PricingAdjustmentCondition, SurchargeRule, SurchargeRangeCondition } from '../types/PricingAdjustment';
import { PricingSurchargeCondition } from '../constants/PricingAdjustment';
import { updateSurchargeRuleCondition as updateSurchargeRuleConditionFunc } from '../ducks/pricingAdjustment';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
  ariaLabel: {
    color: 'white',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '10px',
    marginRight: '10px',
  },
});

interface OwnProps {
  anchorEl: Element | null;
  pricingAdjustmentCondition?: PricingAdjustmentCondition;
  rule?: SurchargeRule;
  onClose(): void;
}

interface DispatchProps {
  updateSurchargeRuleCondition(
    id: number,
    type: PricingSurchargeCondition,
    range: { minimum: number; maximum: number },
  ): void;
}

type Props = OwnProps & DispatchProps;

const PricingSubtotalConditionMenuComponent: React.FC<Props> = ({
  anchorEl,

  onClose,
  pricingAdjustmentCondition,
  rule,
  updateSurchargeRuleCondition,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const saveRangeCondition = (
    minimum: number,
    maximum: number,
    type?: PricingSurchargeCondition,
    surchargeRule?: SurchargeRule,
  ): void => {
    if (type && surchargeRule) {
      updateSurchargeRuleCondition(surchargeRule.id, type, { minimum, maximum });
      onClose();
    }
  };

  const [min, setMin] = React.useState<number>(0);
  const [max, setMax] = React.useState<number>(0);

  React.useEffect(() => {
    if (rule && rule.conditions && pricingAdjustmentCondition) {
      const condition = rule.conditions.find(
        (r) => r.type === pricingAdjustmentCondition.value,
      ) as SurchargeRangeCondition;
      const newMin = (condition && condition.minimum) || 0;
      const newMax = (condition && condition.maximum) || 0;
      setMin(newMin);
      setMax(newMax);
    }
  }, [rule, pricingAdjustmentCondition]);

  return (
    <Dialog open={Boolean(anchorEl)} onClose={onClose}>
      <DialogTitle id="form-dialog-title">
        {t(I18nKeys.PricingSubtotalTitle, {
          condition: pricingAdjustmentCondition && pricingAdjustmentCondition.label,
        })}
      </DialogTitle>
      <DialogContent className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography>{t(I18nKeys.PricingSubtotalRangeStart)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              label="Low"
              variant="standard"
              value={min}
              onChange={(e: any): void => {
                setMin(parseInt(e.target.value, 10) || 0);
              }}
            />
          </Grid>
          <Grid item className={classes.textContainer}>
            <Typography>{t(I18nKeys.PricingSubtotalRangeEnd)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              label="High"
              variant="standard"
              value={max}
              onChange={(e: any): void => {
                setMax(parseInt(e.target.value, 10) || 0);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button
          color="primary"
          onClick={(): void => {
            saveRangeCondition(
              min,
              max,
              pricingAdjustmentCondition ? (pricingAdjustmentCondition.value as PricingSurchargeCondition) : undefined,
              rule,
            );
          }}
        >
          {t(I18nKeys.DialogApplyButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateSurchargeRuleCondition: (
    id: number,
    type: PricingSurchargeCondition,
    range: { minimum: number; maximum: number },
  ): void => {
    dispatch(updateSurchargeRuleConditionFunc(id, type, range));
  },
});

export const PricingSubtotalConditionMenu = connect(
  undefined,
  mapDispatchToProps,
)(PricingSubtotalConditionMenuComponent);
