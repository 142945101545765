import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import SortIcon from '@mui/icons-material/Sort';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '../constants/SortDirection';
import { SortableProperty } from '../types/SortableProperty';
import { SortProperty } from '../types/SortProperty';
import { I18nKeys } from '../constants/I18nKeys';

interface Props {
  readonly sortableProperties: SortableProperty[];
  readonly sortProperties: SortProperty[];
  onSort(sortProperties: SortProperty[]): void;
}

export const TableSortMenu: React.FC<Props> = ({ sortableProperties, sortProperties, onSort }: Props) => {
  const { t } = useTranslation();
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const anchorElRef = React.createRef<HTMLDivElement>();

  const handleSortMenuClick = (): void => setSortMenuAnchorEl(anchorElRef.current);
  const handleSortMenuClose = (): void => setSortMenuAnchorEl(null);

  const handleSortMenuItemClick = ({ sortKey, property }: SortableProperty, direction?: SortDirection): void => {
    handleSortMenuClose();
    const newSortProperties = direction ? [{ key: sortKey || property, direction }] : [];
    onSort(newSortProperties);
  };

  return (
    <>
      <Grid container>
        <IconButton aria-controls="sort-menu" aria-haspopup="true" onClick={handleSortMenuClick} size="large">
          <SortIcon />
        </IconButton>
        <Menu id="sort-menu" anchorEl={sortMenuAnchorEl} open={Boolean(sortMenuAnchorEl)} onClose={handleSortMenuClose}>
          {sortableProperties.map((sortableProperty: SortableProperty) => {
            const key = sortableProperty.sortKey || sortableProperty.property;
            const found = sortProperties.find((sortProperty: SortProperty) => sortProperty.key === key);

            if (found) {
              return [
                <MenuItem
                  key={`${key}-ASC`}
                  onClick={(): void => {
                    handleSortMenuItemClick(
                      sortableProperty,
                      // if selected send undefined to remove the SortDirection on click
                      found.direction === SortDirection.Asc ? undefined : SortDirection.Asc,
                    );
                  }}
                >
                  <ListItemIcon style={{ color: found.direction === SortDirection.Asc ? undefined : 'transparent' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <Typography>{`${t(sortableProperty.i18nKey)} ${t(I18nKeys.TableSortMenuAscendingText)}`}</Typography>
                </MenuItem>,
                <MenuItem
                  key={`${key}-DESC`}
                  onClick={(): void => {
                    handleSortMenuItemClick(
                      sortableProperty,
                      // if selected send undefined to remove the SortDirection on click
                      found.direction === SortDirection.Desc ? undefined : SortDirection.Desc,
                    );
                  }}
                >
                  <ListItemIcon style={{ color: found.direction === SortDirection.Desc ? undefined : 'transparent' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <Typography>{`${t(sortableProperty.i18nKey)} ${t(I18nKeys.TableSortMenuDescendingText)}`}</Typography>
                </MenuItem>,
              ];
            }
            return (
              <MenuItem
                style={{ paddingRight: '40px' }}
                key={key}
                onClick={(): void => handleSortMenuItemClick(sortableProperty, SortDirection.Asc)}
              >
                <ListItemIcon style={{ color: 'transparent' }}>
                  <CheckIcon />
                </ListItemIcon>
                <Typography>{t(sortableProperty.i18nKey)}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </Grid>
      <div style={{ position: 'relative', bottom: '-20px' }} ref={anchorElRef} />
    </>
  );
};
