/**
 * Map an object to a key value pair array.
 * Allows the passing of additionalProps to the mapped object with the key value. This is useful for
 * adding additional props such as override, etc. that share the same value.
 *
 * @param object any
 * @param additionalProps string[]
 * @returns { key: any; value: any }[]
 */
export const mapObjectToKeyValuePair = (obj: any, additionalProps: string[] = []): { key: any; value: any }[] => {
  // Throw an error if obj is not an object
  if (!obj || typeof obj !== 'object') {
    throw new Error('mapObjectToKeyValuePair: obj cannot be undefined and must be an object');
  }

  const keyValuePair = Object.keys(obj).map((key) => ({
    key,
    value: obj[key],
    ...additionalProps.reduce((acc, prop) => {
      acc[prop] = obj[key];
      return acc;
    }, {} as any),
  }));
  return keyValuePair;
};

/**
 * Remove unnecessary properties from an object including nested objects and arrays
 *
 * @param object any
 * @param properties string[]
 */
export const removeProperties = (object: any, properties: string[]): any => {
  // Throw an error if object is not an object
  if (typeof object !== 'object') {
    throw new Error('removeProperties: object must be an object');
  }

  // Throw an error if properties is not an array
  if (!Array.isArray(properties)) {
    throw new Error('removeProperties: properties must be an array');
  }

  // Remove properties from object
  const newObject = mapObjectToKeyValuePair(object).reduce((acc, { key, value }) => {
    if (properties.includes(key)) {
      return acc;
    }

    // Check if a properties string is a nested object
    if (properties.some((property) => property.includes(`${key}.`))) {
      const nestedProperties = properties
        .filter((property) => property.startsWith(`${key}.`))
        .map((property) => property.replace(`${key}.`, ''));
      const nestedObject = removeProperties(value, nestedProperties);
      return { ...acc, [key]: nestedObject };
    }

    // If value is an object, recursively call removeProperties
    if (value && typeof value === 'object') {
      return { ...acc, [key]: removeProperties(value, properties) };
    }

    return { ...acc, [key]: value };
  }, {});

  return newObject;
};
