import { createListenerMiddleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AppState } from '../types/AppState';
import { cimode, Language, languages as supportedLanguages } from '../constants/Languages';
import {
  clearFilters,
  clearFiltersSuccess,
  fetchGroupFilters,
  fetchGroupFiltersSuccess,
  fetchVisibleColumns,
  fetchVisibleColumnsError,
  fetchVisibleColumnsSuccess,
  getTheme,
  getVisibleLanguages,
  resetClientIds,
  setCurrency,
  setGroupFilters,
  setGroupFiltersSuccess,
  setSelectedClientId,
  setTheme,
  setToastMessage,
  setUseMetricUnits,
  setVisibleColumns,
  setVisibleColumnsError,
  setVisibleColumnsSuccess,
  setVisibleLanguages,
} from '../ducks/viewerSlice';
import { Columns, ToastMessageType, defaultTableFilters, defaultVisibleColumns } from '../constants/Viewer';
import { LocalStorage } from '../constants/LocalStorage';
import { GroupFilter } from '../types/CustomFilter';
import { getGroupFilterParts } from '../selectors/getGroupFilters';
import { Configurator } from '../types/Configurator';
import { extractThemeFromConfig } from '../utils/vendorDataUtils';
import { isIdeaRoomGroup, isIdeaRoomUser } from '../utils/userUtils';
import { Group } from '../types/Group';
import { i18n } from '../i18n';
import { unknownUser } from '../types/User';
import { getConfigWithVendor } from '../utils/configuratorUtils';
import { clientDataApi } from '../services/clientDataApi';
import { extractErrorProps } from '../utils/errorUtils';
import { defaultErrorMessage } from '../constants/Error';
import { Theme } from '../types/ViewerState';

export const viewerListener = createListenerMiddleware<AppState>();

viewerListener.startListening({
  actionCreator: setToastMessage,
  effect: async (action) => {
    const { type, message } = action.payload;
    try {
      switch (type) {
        case ToastMessageType.Success:
          toast.success(message);
          break;
        case ToastMessageType.Error:
          toast.error(message);
          break;
        case ToastMessageType.Warning:
          toast.warn(message);
          break;
        case ToastMessageType.Info:
          toast.info(message);
          break;
        default:
          toast.info(message);
          break;
      }
    } catch (error) {
      console.log('Error setting toast message: ', error);
    }
  },
});

viewerListener.startListening({
  actionCreator: fetchVisibleColumns,
  effect: async (action, { dispatch }) => {
    try {
      const storedVisibleColumns = localStorage.getItem(LocalStorage.VisibleColumnKey);
      let visibleColumns = defaultVisibleColumns;

      const validColumns = storedVisibleColumns
        ? (storedVisibleColumns.split(',') as Columns[]).filter((column) => Object.values(Columns).includes(column))
        : [];
      if (validColumns && validColumns.length > 0) {
        visibleColumns = validColumns;
      }

      dispatch(fetchVisibleColumnsSuccess(visibleColumns));
    } catch (error) {
      dispatch(fetchVisibleColumnsError());
    }
  },
});

viewerListener.startListening({
  actionCreator: setVisibleColumns,
  effect: async (action, { dispatch }) => {
    try {
      const visibleColumns = action.payload;
      localStorage.setItem(LocalStorage.VisibleColumnKey, visibleColumns.join(','));

      dispatch(setVisibleColumnsSuccess(visibleColumns));
    } catch (error) {
      dispatch(setVisibleColumnsError());
    }
  },
});

viewerListener.startListening({
  actionCreator: fetchGroupFilters,
  effect: async (action, { dispatch, getState }) => {
    try {
      const state = getState();
      const {
        currentUser: { group: { groupId } = {} },
      } = state;
      const groupFiltersFromLocalStorage = localStorage.getItem(LocalStorage.GroupFilters);

      if (groupFiltersFromLocalStorage) {
        const groupFilters = JSON.parse(groupFiltersFromLocalStorage);
        const filtersForGroup = groupFilters.find((groupFilter: GroupFilter) => groupFilter.groupId === groupId);
        if (filtersForGroup) {
          dispatch(fetchGroupFiltersSuccess(groupFilters));
        } else {
          dispatch(fetchGroupFiltersSuccess([...groupFilters, { groupId, tableFilters: defaultTableFilters }]));
        }
      } else {
        dispatch(fetchGroupFiltersSuccess([{ groupId, tableFilters: defaultTableFilters }]));
      }
    } catch (error) {
      toast.error(`Failed to load table filters`);
    }
  },
});

viewerListener.startListening({
  actionCreator: setGroupFilters,
  effect: async (action, { dispatch, getState }) => {
    try {
      const state = getState();
      const { groupId, groupFilters, tableFilterType, filterType, selectedFilterValues } = getGroupFilterParts(state);

      const selectedValues = selectedFilterValues.some((value) => value.key === 'all') ? [] : selectedFilterValues;

      if (groupId) {
        let newGroupFilters: GroupFilter[] = [];
        const existingGroupFilter = groupFilters.find((groupFilter) => groupFilter.groupId === groupId);
        if (existingGroupFilter) {
          // There are existing filters for this group
          const existingTableFilters = existingGroupFilter.tableFilters.find(
            (tableFilter) => tableFilter.tableFilterType === tableFilterType,
          );
          if (existingTableFilters) {
            // There are existing table filters for this group
            newGroupFilters = [
              ...groupFilters.filter((groupFilter) => groupFilter.groupId !== groupId),
              {
                groupId,
                tableFilters: [
                  ...existingGroupFilter.tableFilters.filter(
                    (tableFilter) => tableFilter.tableFilterType !== tableFilterType,
                  ),
                  {
                    tableFilterType,
                    filters: [
                      ...existingTableFilters.filters.filter((filter) => filter.filterType !== filterType),
                      ...(selectedValues.length > 0 ? [{ filterType, selectedFilterValues: selectedValues }] : []),
                    ],
                  },
                ],
              } as GroupFilter,
            ];
          } else {
            // There are no existing table filters for the group
            newGroupFilters = [
              ...groupFilters.filter((groupFilter) => groupFilter.groupId !== groupId),
              {
                groupId,
                tableFilters: [
                  ...existingGroupFilter.tableFilters.filter(
                    (tableFilter) => tableFilter.tableFilterType !== tableFilterType,
                  ),
                  {
                    tableFilterType,
                    filters: [
                      ...(selectedValues.length > 0 ? [{ filterType, selectedFilterValues: selectedValues }] : []),
                    ],
                  },
                ],
              } as GroupFilter,
            ];
          }
        } else {
          // There are no existing filters for this group, add one
          newGroupFilters = [
            ...groupFilters,
            {
              groupId,
              tableFilters: [
                {
                  tableFilterType,
                  filters: [
                    ...(selectedValues.length > 0 ? [{ filterType, selectedFilterValues: selectedValues }] : []),
                  ],
                },
              ],
            } as GroupFilter,
          ];
        }
        localStorage.setItem(LocalStorage.GroupFilters, JSON.stringify(newGroupFilters));

        dispatch(setGroupFiltersSuccess(newGroupFilters));
      }
    } catch (error) {
      toast.error(`Failed to save table filters`);
    }
  },
});

viewerListener.startListening({
  actionCreator: clearFilters,
  effect: async (action, { dispatch, getState }) => {
    try {
      const {
        currentUser: { group: { groupId } = {} },
        viewer: { groupFilters, tableFilterType },
      } = getState();

      if (groupId) {
        let newGroupFilters: GroupFilter[] = [];
        const existingGroupFilter = groupFilters.find((groupFilter) => groupFilter.groupId === groupId);
        if (existingGroupFilter) {
          // There are existing filters for this group
          const existingTableFilters = existingGroupFilter.tableFilters.find(
            (tableFilter) => tableFilter.tableFilterType === tableFilterType,
          );
          if (existingTableFilters) {
            // There are existing table filters for this group
            newGroupFilters = [
              ...groupFilters.filter((groupFilter) => groupFilter.groupId !== groupId),
              {
                groupId,
                tableFilters: [
                  ...existingGroupFilter.tableFilters.filter(
                    (tableFilter) => tableFilter.tableFilterType !== tableFilterType,
                  ),
                  {
                    tableFilterType,
                    filters: [],
                  },
                ],
              },
            ];
          } else {
            // There are no existing table filters for the group
            newGroupFilters = [
              ...groupFilters.filter((groupFilter) => groupFilter.groupId !== groupId),
              {
                groupId,
                tableFilters: [
                  ...existingGroupFilter.tableFilters.filter(
                    (tableFilter) => tableFilter.tableFilterType !== tableFilterType,
                  ),
                  {
                    tableFilterType,
                    filters: [],
                  },
                ],
              },
            ];
          }
        } else {
          // There are no existing filters for this group, add one
          newGroupFilters = [...groupFilters, { groupId, tableFilters: [{ tableFilterType, filters: [] }] }];
        }
        localStorage.setItem(LocalStorage.GroupFilters, JSON.stringify(newGroupFilters));

        dispatch(clearFiltersSuccess(newGroupFilters));
      }
    } catch (error) {
      toast.error(`Failed to clear table filters`);
    }
  },
});

viewerListener.startListening({
  actionCreator: getTheme,
  effect: async (action, { dispatch, getState }) => {
    try {
      const {
        currentUser: { group = {} },
        viewer: { selectedClientId = '', defaultClientId = '' },
      } = getState();
      const { configurators = [] } = group as Group;

      const [selectedConfig, defaultConfig] = [selectedClientId, defaultClientId].map((clientId) =>
        configurators.find((configurator: Configurator) => configurator.clientId === clientId),
      );

      let theme: Theme = {};
      let currency = '';
      let useMetricUnits = false;
      let languages: string[] = [];
      if (!isIdeaRoomGroup((group as Group).groupId) && (selectedConfig || defaultConfig)) {
        const clientId = selectedConfig ? selectedClientId : defaultClientId;
        const config = (selectedConfig || defaultConfig) as Configurator;

        const configVendor = await dispatch(
          clientDataApi.endpoints.getVendorData.initiate(
            { clientId },
            { subscriptionOptions: { refetchOnFocus: false } },
          ),
        ).unwrap();
        const updatedConfig = (await getConfigWithVendor(config, configVendor)) as Configurator;

        theme = extractThemeFromConfig(updatedConfig);
        const {
          vendorData: {
            vendor: {
              currency: configCurrency = '',
              useMetricUnits: configUseMetricUnits = false,
              languages: vendorLanguages = 'en',
            } = {},
          } = {},
        } = updatedConfig as {
          vendorData: { vendor: { currency: string; useMetricUnits?: boolean; languages: string } };
        };
        currency = configCurrency;
        useMetricUnits = configUseMetricUnits;
        languages = vendorLanguages.split(',');
      }

      dispatch(setTheme(theme));
      dispatch(setCurrency(currency));
      dispatch(setUseMetricUnits(useMetricUnits));
      dispatch(getVisibleLanguages(languages));
    } catch (error) {
      const { message = 'Failed to update Theme' } = error as Error;
      toast.error(message);
    }
  },
});

viewerListener.startListening({
  actionCreator: getVisibleLanguages,
  effect: async (action, { dispatch, getState }) => {
    try {
      const vendorLanguages = action.payload;
      const {
        currentUser: { user = unknownUser },
      } = getState();
      const ideaRoomUser = isIdeaRoomUser(user);

      const languages = (
        (await Promise.all([
          ...supportedLanguages.map(
            async (lng: Language): Promise<Language> =>
              ({
                ...lng,
                hidden: !ideaRoomUser && !vendorLanguages.includes(lng.key),
                order: vendorLanguages.includes(lng.key) ? vendorLanguages.indexOf(lng.key) : Infinity,
              } as Language),
          ),
        ])) as Language[]
      ).sort(({ order: a = Infinity }, { order: b = Infinity }) => a - b);

      if (ideaRoomUser) languages.push(cimode);

      // Change language if default language is different or the current language is not supported
      const defaultLanguage = languages.filter((language) => !language.hidden)[0]?.key;
      if (defaultLanguage !== i18n.language) {
        i18n.changeLanguage(defaultLanguage || 'en');
      }

      dispatch(setVisibleLanguages(languages));
    } catch (error) {
      const { message = 'Failed to get visible languages' } = error as Error;
      toast.error(message);
    }
  },
});

viewerListener.startListening({
  actionCreator: setSelectedClientId,
  effect: async (action, { dispatch }) => {
    try {
      dispatch(getTheme());
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    }
  },
});

viewerListener.startListening({
  actionCreator: resetClientIds,
  effect: async (action, { dispatch }) => {
    try {
      dispatch(getTheme());
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    }
  },
});
