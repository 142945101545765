import { Dispatch } from 'react';
import { TFunction } from 'react-i18next';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import {
  PricingAdjustmentCondition,
  PricingAdjustmentConditionOption,
  SurchargeSystemOrderCalculation,
  SurchargeRule,
  SurchargeKeyCondition,
  SurchargeCalculation,
} from '../types/PricingAdjustment';
import {
  PricingSurchargeCondition,
  PricingSurchargeConditions,
  SystemOrderCalculations,
  noSurchargeSystemOrderCalculation,
  defaultSurchargeSystemOrderCalculation,
  PricingCategoryValues,
  maxRoundTo,
  SystemOrderSupplierCalculations,
} from '../constants/PricingAdjustment';
import { i18n } from '../i18n';
import { getVendorFromClientId } from './clientIdUtils';
import { ClientDataBranchMetadata } from '../types/ClientData';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { I18nKeys } from '../constants/I18nKeys';
import { Dialogs } from '../constants/Dialogs';
import { clientDataApi } from '../services/clientDataApi';
import { ClientDataType } from '../constants/ClientDataType';
import { setPricingBaseDataBranch, setPricingComponentDataBranch } from '../ducks/pricingSlice';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { Commit } from '../types/Commit';
import { LocalStorage } from '../constants/LocalStorage';
import { openNotificationDialog } from '../ducks/notification';

/**
 * Maps a list of PricingAdjustmentConditionOption to a list of PricingAdjustmentConditionOption
 *
 * @param conditionOptions list of PricingAdjustmentConditionOption
 * @returns list of PricingAdjustmentConditions
 */
export const mapPricingAdjustmentConditionOptions = (
  conditionOptions: PricingAdjustmentConditionOption[] = [],
): PricingAdjustmentCondition[] =>
  PricingSurchargeConditions.map((condition) => ({
    ...condition,
    options: conditionOptions.filter((option) => option.optionType === condition.value),
  }));

/**
 * Validate if price string contains decimal values
 *
 * @param priceAsString price value
 * @returns if price contains decimal values
 */
export const isDecimalPrice = (priceAsString: string | undefined) => {
  const priceAsFloat = priceAsString ? parseFloat(priceAsString) : '';

  if (!priceAsFloat) {
    return false;
  }
  return !Number.isInteger(priceAsFloat);
};

/**
 * Returns the currency symbol for the current vendor locale
 *
 * @param currency vendor currency format
 * @returns currency symbol
 */
export const getCurrencySymbol = (currency = 'USD'): string =>
  new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(0)
    .replace('0', '')
    .trim();

/**
 * Takes a string or number and converts it to a number with a given number of decimal places
 *
 * @param value string or number to convert into a number
 * @param decimalPlaces number of decimal places to round to
 * @returns formatted number as a string
 */
export const formatNumber = (value: string | number | null, decimalPlaces = 2): string => {
  const valueAsNumber = typeof value === 'number' ? value : parseFloat(value || '0');
  return valueAsNumber.toFixed(decimalPlaces);
};

/**
 * Takes a string or number and converts it to a price
 *
 * @param price string or number to convert into a price
 * @returns formatted price as a string
 */
export const formatPrice = (
  price: string | number | null,
  currency = 'USD',
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
): string => {
  const formattedPrice = typeof price === 'number' ? price.toString() : price || '0';
  const priceWithoutSymbol = [getCurrencySymbol(currency), '$'].reduce(
    (acc, symbol) => acc.replace(symbol, ''),
    formattedPrice,
  );

  const formattedPriceAsNumber = Number(priceWithoutSymbol);

  if (Number.isNaN(formattedPriceAsNumber)) {
    return formattedPrice;
  }

  return new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(formattedPriceAsNumber);
};

/**
 * Count of the unique labels within a list of PricingAdjustmentConditionOptions
 *
 * @param pricingAdjustmentConditionOptions list of PricingAdjustmentConditionOption to count labels in
 * @returns count of unique labels in pricingAdjustmentConditionOptions
 */
export const countUniqueLabels = (pricingAdjustmentConditionOptions: PricingAdjustmentConditionOption[]): number => {
  const uniqueLabels: string[] = [];
  pricingAdjustmentConditionOptions.forEach(
    (option) => option.label && !uniqueLabels.includes(option.label) && uniqueLabels.push(option.label),
  );
  return uniqueLabels.length;
};

/**
 * Checks to see if the surcharge has a valid surcharge calculation
 *
 * @param calculation surcharge to validate the calculation on
 * @returns boolean value based on the validity of the surcharge calculation
 */
export const hasSurchargeCalculation = (calculation: SurchargeSystemOrderCalculation | undefined): boolean => {
  const hasCalc = (calc: SurchargeCalculation): boolean =>
    (!!calc.amountChange && calc.amountChange !== 0) || (!!calc.percentChange && calc.percentChange !== 0);

  if (!calculation || !calculation.calculation) return false;
  // A surcharge is valid if it has a default amount/percentage or at least one rule has an amount/percentage
  return (
    hasCalc(calculation.calculation) ||
    (calculation.rules && !!calculation.rules.find((rule) => hasCalc(rule.calculation)))
  );
};

/**
 * Takes a SurchargeSystemOrderCalculation and returns whether it affects the base prices
 *
 * @param calculation SurchargeSystemOrderCalculation to check categories of
 * @returns boolean whether or not the calculation affects to base prices
 */
export const affectsBasePrice = (calculation: SurchargeSystemOrderCalculation | undefined): boolean =>
  !!calculation && calculation.categories && calculation.categories.includes(PricingCategoryValues.BasePrice);

/**
 * Takes a SurchargeSystemOrderCalculation and returns whether it is a line item surcharge
 *
 * @param calculation SurchargeSystemOrderCalculation to check categories of
 * @returns boolean whether or not the calculation is a line item surcharge
 */
export const isLineItemSurcharge = (calculation: SurchargeSystemOrderCalculation | undefined): boolean =>
  !!calculation && calculation.categories && calculation.key === SystemOrderCalculations.LineItem;

/**
 * Takes a surcharge rule and condition type. Checks if all the keys in the condition are checked.
 *
 * @param rule SurchargeRule to check the keys of
 * @param type condition type of the rule
 * @param optionKeys option keys to check against the selected keys of the rule
 * @returns whether or not all of the option keys in the rule are checked
 */
export const allKeysAreSelected = (
  rule: SurchargeRule,
  type: PricingSurchargeCondition,
  optionKeys: string[],
): boolean => {
  const surchargeCondition = rule.conditions.find((condition) => condition.type === type) as SurchargeKeyCondition;
  if (surchargeCondition && surchargeCondition.keys && optionKeys.length > 0) {
    const checkedKeys = [...surchargeCondition.keys];
    return optionKeys.every((key) => checkedKeys.includes(key));
  }
  return false;
};

/**
 * Attempts to find a surcharge in a list of surcharges based on a supplierKey and pricing Selection
 * If the pricingSelection param is NONE, return a noSurchargeSystemOrderCalculation object
 * If there is a surcharge found, return it filling in any missing props with defaultSurchargeSystemOrderCalculation values
 *
 * @param clientId clientId to use as a fallback
 * @param calculations list of SurchargeSystemOrderCalculation to search
 * @param supplierKey supplierKey to find
 * @param pricingSelection pricingSelection type to find
 * @returns SurchargeSystemOrderCalculation with the given supplierKey and pricingSelection otherwise returns a default surcharge
 */
export const findMatchingSurchargeCalculation = (
  clientId: string,
  calculations: SurchargeSystemOrderCalculation[],
  supplierKey: string | undefined,
  pricingSelection: SystemOrderCalculations,
): SurchargeSystemOrderCalculation => {
  const vendor = getVendorFromClientId(clientId) || undefined;
  const matchingCalculation = calculations.find(
    (calc) =>
      (calc.supplierKey || vendor) === (supplierKey || vendor) && (!pricingSelection || calc.key === pricingSelection),
  );
  return {
    ...(pricingSelection === SystemOrderCalculations.None
      ? noSurchargeSystemOrderCalculation
      : defaultSurchargeSystemOrderCalculation),
    ...matchingCalculation,
    supplierKey,
    key: pricingSelection,
  };
};

/**
 * Updates a list of SurchargeSystemOrderCalculations with a given SurchargeSystemOrderCalculation.
 * If the surchargeCalculation exists in the surcharge for the supplierKey, replace it
 * Otherwise add it as a new surchargeCalculation
 * If surchargeCalculation is undefined, don't add it
 *
 * @param clientId clientId to use as a fallback
 * @param calculations list of SurchargeSystemOrderCalculation
 * @param surchargeCalculation SurchargeSystemOrderCalculation
 * @param deleteSurchargeCalculation defaults to false
 * @param pricingSelection defaults to false
 * @returns list of SurchargeSystemOrderCalculation with the given SurchargeSystemOrderCalculation updated in the list
 */
export const reconcileSurchargeCalculations = (
  clientId: string,
  calculations: SurchargeSystemOrderCalculation[] = [],
  surchargeCalculation: SurchargeSystemOrderCalculation,
  deleteSurchargeCalculation = false,
  pricingSelection = false,
): SurchargeSystemOrderCalculation[] => {
  const vendor = getVendorFromClientId(clientId) || undefined;
  return [
    ...calculations.filter(
      (calc) =>
        (calc.supplierKey || vendor) !== (surchargeCalculation.supplierKey || vendor) ||
        (pricingSelection && (calc.key || undefined) !== (surchargeCalculation.key || undefined)),
    ),
    ...(deleteSurchargeCalculation ? [] : [surchargeCalculation]),
  ];
};

/**
 * Gets the first id from a list of SurchargeSystemOrderCalculation
 *
 * @param {SurchargeSystemOrderCalculation[] | undefined} surcharges list of SurchargeSystemOrderCalculation's to get id from
 * @returns {number | undefined} id as a Number or undefined if not found
 */
export const getIdFromSurcharges = (surcharges: SurchargeSystemOrderCalculation[] | undefined): number | undefined =>
  surcharges
    ? surcharges.reduce((result, current) => {
        if (current.id) {
          result.push(current.id);
        }
        return result;
      }, [] as number[])[0]
    : undefined;

/**
 * Gets the pricing book options from a list of pricing conditions
 *
 * @param {PricingAdjustmentCondition[]} conditionOptions list of pricing conditions
 * @returns {PricingAdjustmentConditionOption[]} list of price book pricing condition options
 */
export const getPriceBooks = (conditionOptions: PricingAdjustmentCondition[]): PricingAdjustmentConditionOption[] => {
  const priceBookOptions = conditionOptions.find((option) => option.value === PricingSurchargeCondition.Region);
  return priceBookOptions ? priceBookOptions.options : [];
};

/**
 * Parsing the surcharge roundTo value. Values are restricted to whole numbers between 0 and 100 (inclusive).
 *
 * @param {string | undefined} input Round to value to be parsed
 * @returns {number} Parsed value
 */
export const parseRoundTo = (input: string | undefined): number => {
  let inputAsInt = parseInt(input || '', 10);
  // Do not allow values over maximum round to value or below 0
  if (!!inputAsInt && inputAsInt > maxRoundTo) inputAsInt = maxRoundTo;
  if (!!inputAsInt && inputAsInt < 0) inputAsInt = 0;
  // Can return a value of 0, but this is interpreted as $0.01 when applied in estimate
  return !!inputAsInt || inputAsInt === 0 ? inputAsInt : 0;
};

/**
 * Compares whether a draft and active surcharge have matching supplierSurcharge values and
 * returns a boolean value. The absence of a value is equivalent to having inheritance as "off".
 *
 * @param {SurchargeSystemOrderCalculation} draft draft surcharge calculation
 * @param {SurchargeSystemOrderCalculation} active active surcharge calculation
 */
export const compareSupplierSurcharge = (
  draft: SurchargeSystemOrderCalculation,
  active: SurchargeSystemOrderCalculation,
): boolean =>
  ((draft ? draft.supplierSurcharge : SystemOrderSupplierCalculations.Off) || SystemOrderSupplierCalculations.Off) ===
  ((active ? active.supplierSurcharge : SystemOrderSupplierCalculations.Off) || SystemOrderSupplierCalculations.Off);

export const getPricingPublishBarActions = ({
  clientId,
  groupId,
  branch,
  dispatch,
  activeBranches,
  t,
}: {
  clientId: string;
  groupId: string;
  branch: ClientDataBranch;
  dispatch: Dispatch<any>;
  activeBranches: ClientDataBranchMetadata[];
  t: TFunction;
}) => ({
  revert: (): void => {
    dispatch(
      openConfirmationDialog(
        [],
        [
          () => {
            if (activeBranches.find((b) => b.branchType === branch)) {
              dispatch(
                clientDataApi.endpoints.deleteBranch.initiate(
                  {
                    dataType: ClientDataType.Supplier,
                    branch,
                    clientId,
                    groupId,
                  },
                  { fixedCacheKey: 'revert' },
                ) as any,
              ) as unknown as QueryActionCreatorResult<any>;
              dispatch(
                branch === ClientDataBranch.Pricing
                  ? setPricingBaseDataBranch(ClientDataBranch.Main)
                  : setPricingComponentDataBranch(ClientDataBranch.Main),
              );
            }
          },
        ],
        t(I18nKeys.PricingBaseRevertConfirmationTitle),
        t(I18nKeys.PricingBaseRevertConfirmationMessage),
      ),
    );
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  },
  preview: (): void => {
    dispatch(
      openDialog({
        dialog: branch === ClientDataBranch.Pricing ? Dialogs.PricingBasePreview : Dialogs.PricingComponentPreview,
      }),
    );
  },
  publish: (): void => {
    dispatch(openDialog({ dialog: Dialogs.PricingPublish }));
  },
});

export const getValidatedNewValue = (newValue: string, oldValue: string): string => {
  const unformattedNewValue = newValue.replace(/[$€£¥₣,]/g, '');
  let valueAsNumber = Number(unformattedNewValue);
  if (Number.isNaN(valueAsNumber) || valueAsNumber < 0) {
    return oldValue;
  }
  // Only allow newValue to have 2 decimal places
  valueAsNumber = Math.round(valueAsNumber * 100) / 100;
  // price column is a string so return as a string type
  return valueAsNumber.toString();
};

export const arePriceValuesDifferent = (oldValue: any, newValue: any): boolean => {
  // need to check the value as a float so that 1.00 is the same as 1
  const a = newValue === null || newValue === undefined ? '' : parseFloat(newValue);
  const b = oldValue === null || oldValue === undefined ? '' : parseFloat(oldValue);
  return a !== b;
};

export const notifyOfDataChange = ({
  groupId,
  clientId,
  latestCommit,
  key,
  dispatch,
  t,
}: {
  groupId: string;
  clientId: string;
  latestCommit: Commit | undefined;
  key: LocalStorage;
  dispatch: Dispatch<any>;
  t: TFunction;
}) => {
  if (latestCommit) {
    const lastKnownCommit = JSON.parse(localStorage.getItem(key) || '{}');
    const lastKnownCommitHash = lastKnownCommit?.[groupId]?.[clientId];

    if (lastKnownCommitHash !== latestCommit.hash) {
      lastKnownCommit[groupId] = {
        ...(lastKnownCommit[groupId] || {}),
        [clientId]: latestCommit.hash,
      };

      localStorage.setItem(key, JSON.stringify(lastKnownCommit));
    }

    if (lastKnownCommitHash && lastKnownCommitHash !== latestCommit.hash) {
      dispatch(
        openNotificationDialog(
          '',
          t(I18nKeys.PricingBaseDataChangeDialogMessage, {
            author: latestCommit.committer,
          }),
        ),
      );
      dispatch(openDialog({ dialog: Dialogs.Notification }));
    }
  }
};

/**
 * Checks if the items have duplicate labels
 *
 * @param items Items with label and key properties
 * @returns boolean
 */
export const hasDuplicateLabels = (item: any, items: { key: string; label: string }[]): boolean =>
  items.some((i) => i.label === item.label && i.key !== item.key);
