import {
  CellKeyDownEvent,
  CellPosition,
  ColDef,
  ColumnApi,
  GridApi,
  SuppressKeyboardEventParams,
} from 'ag-grid-community';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { setCellNote, setPaste, setSearchOpen, setSearchOptions, toggleSettingOption } from '../ducks/clientDataSlice';

import {
  ClientDataEditor,
  ColumnDataType,
  KeyboardKeys,
  SearchOptions,
  SettingsOptions,
} from '../constants/ClientData';
import { undoManager } from '../services/undoManager';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { disco, getCellRangeInfo, getRowIdsFromCellRange, removeRows, updateValues } from './clientDataUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { clearSelections, selectRows } from './selectionUtils';
import { AppState } from '../types/AppState';
import { ToastMessageType } from '../constants/Viewer';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';
import { largeTextCellEditorParams } from '../constants/ClientDataColumn';
import { LocalStorage } from '../constants/LocalStorage';
import { CustomCellEditorInstance } from '../types/ClientData';
import { selectAllCells } from '../components/SelectAllCellsHeader';
import { PricingSheetTableAgGrid } from '../constants/PricingBase';
import { removePrices } from './pricingSheetUtils';
import { setToastMessage } from '../ducks/viewerSlice';

const onEditorKeyDown = (event: KeyboardEvent, { api }: { api?: GridApi }) => {
  const { ctrlKey, metaKey, shiftKey, altKey, key: pressedKey } = event;
  const [cellEditorInstance] = (api?.getCellEditorInstances() || []) as CustomCellEditorInstance[];
  if (!cellEditorInstance) return;

  if ((ctrlKey || metaKey) && pressedKey === 'v') {
    event.preventDefault();
    navigator.clipboard.readText().then((text) => {
      let pastedText = text;
      const [clipboardData] = JSON.parse(localStorage.getItem(LocalStorage.ClientDataClipboardData) || '[]')[0] || [];
      if (clipboardData?.value === pastedText && !event.shiftKey) pastedText = clipboardData?.formula || pastedText;
      if (cellEditorInstance.insertValue) cellEditorInstance.insertValue(pastedText);
    });
  }

  // Move selection within editor with arrow keys
  if (
    ((metaKey || ctrlKey) && ([KeyboardKeys.Left, KeyboardKeys.Right] as string[]).includes(pressedKey)) ||
    (([KeyboardKeys.Home, KeyboardKeys.End] as string[]).includes(pressedKey) &&
      ![ClientDataEditor.Large].includes(cellEditorInstance?.getEditorType?.() || ClientDataEditor.Standard))
  ) {
    event.preventDefault();
    if (!cellEditorInstance.setSelection || !cellEditorInstance.getSelection) return;
    const { start: currentStart, end: currentEnd } = cellEditorInstance.getSelection();

    let start;
    let end;
    switch (pressedKey) {
      case KeyboardKeys.Left:
      case KeyboardKeys.Home:
        start = 0;
        end = shiftKey ? currentStart : 0;
        break;
      case KeyboardKeys.Right:
      case KeyboardKeys.End:
        start = shiftKey ? currentEnd : cellEditorInstance.getValue().length;
        end = cellEditorInstance.getValue().length;
        break;
      default:
        break;
    }
    if (start !== undefined && end !== undefined) cellEditorInstance.setSelection({ start, end });
  }

  if ((metaKey || ctrlKey || altKey || shiftKey) && pressedKey === KeyboardKeys.Enter) {
    event.preventDefault();
    if (cellEditorInstance.insertValue) cellEditorInstance.insertValue('\n');
  }
};

const getSelectedNodes = (gridApi: GridApi) => {
  const cellRanges = gridApi.getCellRanges();
  const { startRowIndex, endRowIndex, columns } = getCellRangeInfo(cellRanges);

  const nodes = [];
  for (let i = startRowIndex; i <= endRowIndex; i += 1) {
    const rowNode = gridApi.getDisplayedRowAtIndex(i);
    if (!rowNode?.data.rowIsReadOnly) {
      for (let j = 0; j < columns.length; j += 1) {
        nodes.push({ priceColumn: columns[j], data: rowNode?.data[columns[j]] });
      }
    }
  }
  return nodes;
};

const onCommandArrowKey = (event?: KeyboardEvent, api?: GridApi, columnApi?: ColumnApi) => {
  if (!event || !api || !columnApi) return;

  const { key: arrowKey, shiftKey } = event as KeyboardEvent & { key: KeyboardKeys };

  const cellRanges = api.getCellRanges() || [];
  let { startRowIndex, endRowIndex, columns: selectedColumns } = getCellRangeInfo(cellRanges);
  const totalRowCount = api.getModel().getRowCount();

  const displayedColumns = (columnApi.getAllDisplayedColumns() || [])
    .map((c) => c.getColId())
    .filter((col) => !([ClientDataFixedColumns.Index] as string[]).includes(col));
  const startColIndex = displayedColumns.findIndex((c) => selectedColumns.includes(c));

  const focusedCell = (api.getFocusedCell() || {}) as CellPosition;
  let { rowIndex: focusedIndex } = focusedCell;
  let focusedColumnId = focusedCell.column?.getColId() || '';
  const focusedColIndex = displayedColumns.findIndex((c) => c === focusedColumnId);

  clearSelections(api);

  let viewIndex = focusedIndex;
  let viewColumn = focusedColumnId;
  switch (arrowKey) {
    case KeyboardKeys.Up:
      endRowIndex = focusedIndex;
      startRowIndex = 0;
      viewIndex = startRowIndex;
      if (!shiftKey) {
        endRowIndex = startRowIndex;
        selectedColumns = [displayedColumns[startColIndex]];
        [viewColumn] = selectedColumns;
      }
      break;
    case KeyboardKeys.Down:
      startRowIndex = focusedIndex;
      endRowIndex = totalRowCount - 1;
      viewIndex = endRowIndex;
      if (!shiftKey) {
        startRowIndex = endRowIndex;
        selectedColumns = [displayedColumns[startColIndex]];
        [viewColumn] = selectedColumns;
      }
      break;
    case KeyboardKeys.Left:
      selectedColumns = displayedColumns?.slice(0, focusedColIndex + 1) || selectedColumns;
      [viewColumn] = selectedColumns;
      if (!shiftKey) {
        endRowIndex = startRowIndex;
        viewIndex = startRowIndex;
        selectedColumns = [viewColumn];
        [viewColumn] = selectedColumns;
      }
      break;
    case KeyboardKeys.Right:
      selectedColumns = displayedColumns?.slice(focusedColIndex) || selectedColumns;
      viewColumn = selectedColumns[selectedColumns.length - 1];
      if (!shiftKey) {
        endRowIndex = startRowIndex;
        viewIndex = startRowIndex;
        selectedColumns = [viewColumn];
      }
      break;
    default:
      break;
  }

  api.addCellRange({
    rowStartIndex: startRowIndex,
    rowEndIndex: endRowIndex,
    columns: selectedColumns,
  });

  if ((!focusedIndex && focusedIndex !== 0) || focusedIndex < startRowIndex || focusedIndex > endRowIndex) {
    focusedIndex = startRowIndex;
  }
  if (!selectedColumns.includes(focusedColumnId)) {
    [focusedColumnId] = selectedColumns;
  }

  api.setFocusedCell(focusedIndex, focusedColumnId);
  api.ensureIndexVisible(viewIndex);
  api.ensureColumnVisible(viewColumn, 'auto');
};

const onShiftArrowKey = (event?: KeyboardEvent, api?: GridApi, columnApi?: ColumnApi, rowSelected?: boolean) => {
  if (!event || !api || !columnApi) return;

  const { key: arrowKey } = event as KeyboardEvent & { key: KeyboardKeys };

  const cellRanges = api.getCellRanges() || [];
  let { startRowIndex, endRowIndex, columns: selectedColumns } = getCellRangeInfo(cellRanges);
  const totalRowCount = api.getModel().getRowCount();

  const displayedColumns = (columnApi.getAllDisplayedColumns() || [])
    .map((c) => c.getColId())
    .filter((col) => !([ClientDataFixedColumns.Index] as string[]).includes(col));
  const startColIndex = displayedColumns.findIndex((c) => selectedColumns.includes(c));
  const endColIndex =
    displayedColumns.length - [...displayedColumns].reverse().findIndex((c) => selectedColumns.includes(c)) - 1;

  const focusedCell = (api.getFocusedCell() || {}) as CellPosition;
  let { rowIndex: focusedIndex } = focusedCell;
  let focusedColumnId = focusedCell.column?.getColId() || '';
  const focusedColIndex = displayedColumns.findIndex((c) => c === focusedColumnId);

  clearSelections(api);

  let viewIndex = focusedIndex;
  let viewColumn = focusedColumnId;
  switch (arrowKey) {
    case KeyboardKeys.Up:
      if (endRowIndex > focusedIndex) {
        endRowIndex -= 1;
        viewIndex = endRowIndex;
      } else if (startRowIndex > 0) {
        startRowIndex -= 1;
        viewIndex = startRowIndex;
      }
      break;
    case KeyboardKeys.Down:
      if (startRowIndex < focusedIndex) {
        startRowIndex += 1;
        viewIndex = startRowIndex;
      } else if (endRowIndex < totalRowCount - 1) {
        endRowIndex += 1;
        viewIndex = endRowIndex;
      }
      break;
    case KeyboardKeys.Left:
      if (endColIndex > focusedColIndex) {
        selectedColumns = displayedColumns.slice(startColIndex, endColIndex);
        viewColumn = selectedColumns[selectedColumns.length - 1];
      } else if (startColIndex > 0) {
        selectedColumns = displayedColumns.slice(startColIndex - 1, endColIndex + 1);
        [viewColumn] = selectedColumns;
      }
      break;
    case KeyboardKeys.Right:
      if (startColIndex < focusedColIndex) {
        selectedColumns = displayedColumns.slice(startColIndex + 1, endColIndex + 1);
        [viewColumn] = selectedColumns;
      } else if (endColIndex < displayedColumns.length - 1) {
        selectedColumns = displayedColumns.slice(startColIndex, endColIndex + 2);
        viewColumn = selectedColumns[selectedColumns.length - 1];
      }
      break;
    default:
      break;
  }

  if (rowSelected) {
    selectRows(startRowIndex, endRowIndex, columnApi.getAllGridColumns(), api, columnApi);
  } else {
    api.addCellRange({
      rowStartIndex: startRowIndex,
      rowEndIndex: endRowIndex,
      columns: selectedColumns,
    });
  }

  if ((!focusedIndex && focusedIndex !== 0) || focusedIndex < startRowIndex || focusedIndex > endRowIndex) {
    focusedIndex = startRowIndex;
  }
  if (!selectedColumns.includes(focusedColumnId)) {
    [focusedColumnId] = selectedColumns;
  }
  api.setFocusedCell(focusedIndex, focusedColumnId);

  api.ensureIndexVisible(viewIndex);
  api.ensureColumnVisible(viewColumn, 'auto');
};

export const onGridKeyboardShortcut = (
  event: KeyboardEvent,
  params: {
    dispatch: Dispatch<any>;
    openTableMenu: () => void;
    goToSourceData?: () => void;
    columnApi?: ColumnApi;
    gridApi?: GridApi;
    setCellFlashColor?: (color?: string) => void | undefined;
    dataColumn?: string;
  },
  clientDataTableId: string,
  isGridReadOnly: boolean,
  isCellBased: boolean,
): void => {
  const { t } = i18n;
  const { dispatch, columnApi, gridApi, openTableMenu, setCellFlashColor, dataColumn, goToSourceData } = params;
  const [cellEditorInstance] = gridApi?.getCellEditorInstances() || [];

  if (cellEditorInstance) onEditorKeyDown(event, { api: gridApi });

  // Select all cells with (ctrl || cmd) + a
  if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
    selectAllCells(columnApi, gridApi);
  }

  // Select column with ctrl + space
  // Gets the first column in the selection and selects all cells in that column
  // Starts at the first cell in the column and ends at the last cell in the column
  if (event.ctrlKey && event.key === KeyboardKeys.Space) {
    event.preventDefault();
    const { columns } = getCellRangeInfo(gridApi?.getCellRanges());
    const [colId] = columns;
    const startRowIndex = 0;
    const endRowIndex = (gridApi?.getModel().getRowCount() || 0) - 1;
    if (colId) {
      gridApi?.clearRangeSelection();
      gridApi?.setFocusedCell(startRowIndex, colId);
      gridApi?.addCellRange({
        rowStartIndex: startRowIndex,
        rowEndIndex: endRowIndex,
        columns: [colId],
      });
    }
  }

  // Copy with headers with (ctrl || cmd) + shift + c
  if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'c') {
    event.preventDefault();
    gridApi?.copySelectedRangeToClipboard({ includeHeaders: true });
  }
  // Undo with (ctrl || cmd) + z
  if ((event.ctrlKey || event.metaKey) && event.key === 'z' && !event.shiftKey) {
    event.preventDefault();
    undoManager.undo(clientDataTableId);
  }
  // Redo with (ctrl || cmd) + shift + z
  if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'z') {
    event.preventDefault();
    undoManager.redo(clientDataTableId);
  }
  // Open replace with (ctrl || cmd) + shift + h
  if (
    !clientDataTableId.includes(PricingSheetTableAgGrid) &&
    (event.ctrlKey || event.metaKey) &&
    event.shiftKey &&
    event.key === 'h'
  ) {
    event.preventDefault();
    dispatch(setSearchOptions({ [SearchOptions.ShowReplace]: true }));
    dispatch(setSearchOpen(true));
  }
  // Find with (ctrl || cmd) + f
  if (!clientDataTableId.includes(PricingSheetTableAgGrid) && (event.ctrlKey || event.metaKey) && event.key === 'f') {
    event.preventDefault();
    dispatch(setSearchOpen(true));
  }
  // Close find or replace with esc
  if (!clientDataTableId.includes(PricingSheetTableAgGrid) && event.key === 'Escape') {
    event.preventDefault();
    dispatch(setSearchOpen(false));
  }
  // Show formulas with ctrl + `
  if (!clientDataTableId.includes(PricingSheetTableAgGrid) && event.ctrlKey && event.key === '`') {
    event.preventDefault();
    dispatch(toggleSettingOption(SettingsOptions.ShowFormulas));
  }
  // Open keyboard shortcuts with cmd + /
  if (event.metaKey && event.key === '/') {
    event.preventDefault();
    window.open(t(I18nKeys.UtilityOptionsKeyboardShortcutsLink), '_blank', 'noopener noreferrer');
  }
  // Open note dialog with shift + F2
  if (event.shiftKey && event.key === 'F2') {
    const { startRowIndex, columns } = getCellRangeInfo(gridApi?.getCellRanges());
    const rowId = gridApi?.getDisplayedRowAtIndex(startRowIndex)?.id;
    const [colId] = columns;

    if (rowId && colId) {
      event.preventDefault();
      dispatch(setCellNote({ rowIds: getRowIdsFromCellRange(gridApi), colIds: columns }));
      dispatch(openDialog({ dialog: Dialogs.ClientDataNote }));
    }
  }
  // Paste values only with (ctrl || cmd) + shift + v
  // Nothing will happen if there is no focused cell, so check before setting paste options
  if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'v' && !!gridApi?.getFocusedCell()) {
    dispatch(setPaste({ formulas: false }));
    event.preventDefault();
    gridApi?.pasteFromClipboard();
  }
  // Edit the cell in large text editor with Cmd + Enter
  if (!cellEditorInstance && event.metaKey && event.key === KeyboardKeys.Enter) {
    gridApi?.stopEditing(true);
    const { startRowIndex, columns } = getCellRangeInfo(gridApi?.getCellRanges());
    const [colId] = columns;

    const columnDefs = gridApi?.getColumnDefs();
    const columnDef: ColDef<any> | undefined = columnDefs?.find((colDef: ColDef<any>) => colDef.colId === colId);

    if (columnDefs && columnDef && columnDef.cellEditor !== largeTextCellEditorParams.cellEditor) {
      columnDef.cellEditor = largeTextCellEditorParams.cellEditor;
      columnDef.cellEditorPopup = largeTextCellEditorParams.cellEditorPopup;
      columnDef.cellEditorParams = largeTextCellEditorParams.cellEditorParams;
      gridApi?.setColumnDefs(columnDefs);
    }
    // wait for 100 ms to make sure the cell editing has stopped before starting the large text editor
    setTimeout(() => {
      gridApi?.startEditingCell({
        rowIndex: startRowIndex,
        colKey: colId,
      });
    }, 100);
  }
  // Open table menu with option + shift + k
  if (event.altKey && event.shiftKey && event.code === 'KeyK') {
    event.preventDefault();
    openTableMenu();
  }
  if (
    (event.metaKey || event.ctrlKey) &&
    ([KeyboardKeys.Up, KeyboardKeys.Down, KeyboardKeys.Left, KeyboardKeys.Right] as string[]).includes(event.key)
  ) {
    event.preventDefault();
  }

  // Delete rows with cmd + '-'
  if (event.metaKey && event.key === KeyboardKeys.Minus) {
    if (isGridReadOnly) {
      dispatch(
        setToastMessage({ type: ToastMessageType.Info, message: i18n.t(I18nKeys.ClientDataPublishedBranchReadOnly) }),
      );
    } else if (gridApi) {
      if (isCellBased) {
        event.preventDefault();
        const nodes = getSelectedNodes(gridApi);
        if (clientDataTableId.includes(PricingSheetTableAgGrid)) {
          removePrices(clientDataTableId, nodes, dataColumn, dispatch);
        }
        clearSelections(gridApi);
      } else {
        const cellRanges = gridApi.getCellRanges();
        const { startRowIndex, endRowIndex } = getCellRangeInfo(cellRanges);

        const rows = [];
        for (let i = startRowIndex; i <= endRowIndex; i += 1) {
          const rowNode = gridApi.getDisplayedRowAtIndex(i);
          if (!rowNode?.data.rowIsReadOnly) {
            rows.push(rowNode?.data);
          }
        }
        if (rows.length > 0) {
          event.preventDefault();
          removeRows(clientDataTableId, rows, dispatch);
          clearSelections(gridApi);
        }
      }
    }
  }

  // Check if the event is happening inside a cell
  const isCellDeleteEvent =
    event && event.target && event.target instanceof HTMLElement && event.target.classList.contains('ag-cell');

  // Delete selected rows with Backspace or Delete key but only if the event is a cell delete event
  if ([KeyboardKeys.Backspace, KeyboardKeys.Delete].includes(event.key as KeyboardKeys) && isCellDeleteEvent) {
    if (isGridReadOnly) {
      dispatch(
        setToastMessage({ type: ToastMessageType.Info, message: i18n.t(I18nKeys.ClientDataPublishedBranchReadOnly) }),
      );
    } else if (gridApi) {
      if (isCellBased) {
        const nodes = getSelectedNodes(gridApi);
        if (clientDataTableId.includes(PricingSheetTableAgGrid)) {
          removePrices(clientDataTableId, nodes, dataColumn, dispatch);
        }
        clearSelections(gridApi);
      } else {
        const selectedRows = gridApi.getSelectedRows().filter((row) => !row.rowIsReadOnly);
        if (selectedRows.length > 0) {
          removeRows(clientDataTableId, selectedRows, dispatch);
          clearSelections(gridApi);
        }
      }
    }
  }

  // Open table menu with cmd + ctrl + g
  if (goToSourceData && !event.shiftKey && event.metaKey && event.ctrlKey && event.code === 'KeyG') {
    event.preventDefault();
    goToSourceData();
  }

  // Party Time!!
  if (event.ctrlKey && event.metaKey && ['1', '2', '3', '4', '5'].includes(event.key)) {
    event.preventDefault();
    disco(gridApi, setCellFlashColor, Number(event.key) - 1);
  }
};

export const onTableMenuKeyboardShortcut = (
  event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>,
  params: { currentIndex?: number; focusMenuItem: (i: number) => void; clickMenuItem: () => void },
): void => {
  const { currentIndex, focusMenuItem, clickMenuItem } = params;
  // Focus table menu item with tab or down arrow
  if (event.key === KeyboardKeys.Tab || event.key === KeyboardKeys.Down) {
    event.preventDefault();
    event.stopPropagation();
    focusMenuItem(currentIndex !== undefined ? currentIndex + 1 : 0);
  }
  if (currentIndex && event.key === KeyboardKeys.Up) {
    event.preventDefault();
    event.stopPropagation();
    focusMenuItem(currentIndex - 1);
  }
  if (event.key === KeyboardKeys.Enter) {
    event.preventDefault();
    event.stopPropagation();
    clickMenuItem();
  }
};

export const suppressPricingGridKeyboardEvent = ({ event: { key } }: SuppressKeyboardEventParams) => {
  if (([KeyboardKeys.Backspace, KeyboardKeys.Delete] as string[]).includes(key)) return true;

  return false;
};

export const suppressGridKeyboardEvent = ({
  event: { key, metaKey, ctrlKey, shiftKey, altKey },
  api,
}: SuppressKeyboardEventParams) => {
  if (([KeyboardKeys.Backspace, KeyboardKeys.Delete] as string[]).includes(key)) return true;

  const isEditing = api.getEditingCells()?.length > 0;
  if (
    !isEditing &&
    (metaKey || ctrlKey || shiftKey) &&
    ([KeyboardKeys.Up, KeyboardKeys.Down, KeyboardKeys.Left, KeyboardKeys.Right] as string[]).includes(key)
  ) {
    return true;
  }
  // Do not close editor when adding a new line
  if (key === KeyboardKeys.Enter && ((isEditing && (ctrlKey || altKey || shiftKey)) || metaKey)) return true;

  return false;
};

export const suppressGridKeyboardEventAutocompleteCell = (params: SuppressKeyboardEventParams) => {
  if (suppressGridKeyboardEvent(params)) {
    return true;
  }
  const {
    event: { key },
    editing,
  } = params;

  // Enter/Escape keys are going to be handled by DataGridAutocompleteCellEditor.tsx
  if ((key === KeyboardKeys.Enter || key === KeyboardKeys.Escape) && editing) return true;

  return false;
};

export const onGridCellKeyDown = (
  cellKeyDownEvent: CellKeyDownEvent,
  params: { dispatch: ThunkDispatch<AppState, unknown, AnyAction>; isGridReadOnly: boolean; clientDataTableId: string },
) => {
  const { api, event, column, columnApi } = cellKeyDownEvent;
  const { dispatch, isGridReadOnly, clientDataTableId } = params;
  const { key: pressedKey, shiftKey, metaKey, ctrlKey } = event as KeyboardEvent & { key: KeyboardKeys };
  const currentColumnId = column.getColId();
  const gridColumns = columnApi.getAllGridColumns();

  const editingCells = api.getEditingCells();
  const isEditing = editingCells && editingCells.length > 0;
  const [cellEditorInstance] = api?.getCellEditorInstances() || [];

  const cellRanges = api.getCellRanges() || [];
  const { startRowIndex, endRowIndex, columns } = getCellRangeInfo(cellRanges);
  const totalRowCount = cellKeyDownEvent.api?.getModel().getRowCount();

  if (pressedKey === KeyboardKeys.Space) {
    const checkboxColIds = gridColumns
      .filter((col) => col.getColDef().type === ColumnDataType.Boolean)
      .map((col) => col.getColId())
      .filter((col) => columns.includes(col));
    const rowsData = Array.from({ length: endRowIndex - startRowIndex + 1 }, (_el, i) => {
      const { data } = api.getDisplayedRowAtIndex(startRowIndex + i) || {};
      return data;
    }).filter((row) => row);
    // Matching the behavior of GS, if a checkbox is unchecked, check all checkboxes in the selection
    const enableAll = rowsData.some((row) => checkboxColIds.some((col) => row[col] === 0));

    const updates = checkboxColIds
      .map((colId) =>
        rowsData.map((row) => ({
          table: cellKeyDownEvent.context.selectedTable,
          data: row,
          column: colId,
          oldValue: row[colId],
          newValue: enableAll || row[colId] === 0 ? 1 : 0,
        })),
      )
      .flat();
    updateValues(clientDataTableId, updates, cellKeyDownEvent.context.cellMetadata, dispatch);
  }

  // If the meta (command) key is pressed, navigate to the farthest cell in the direction of the arrow key
  if (
    !isEditing &&
    (metaKey || ctrlKey) &&
    [KeyboardKeys.Up, KeyboardKeys.Down, KeyboardKeys.Left, KeyboardKeys.Right].includes(pressedKey)
  ) {
    onCommandArrowKey(event as KeyboardEvent, api, columnApi);
  }

  // If the shift key is pressed, add to the current selection in the direction of the arrow key
  if (
    !cellEditorInstance &&
    shiftKey &&
    [KeyboardKeys.Up, KeyboardKeys.Down, KeyboardKeys.Left, KeyboardKeys.Right].includes(pressedKey)
  ) {
    onShiftArrowKey(event as KeyboardEvent, api, columnApi, currentColumnId === ClientDataFixedColumns.Index);
  }

  // Select first column above or below with regular up/down arrow keys and index column selection
  if (
    currentColumnId === ClientDataFixedColumns.Index &&
    !metaKey &&
    !shiftKey &&
    [KeyboardKeys.Up, KeyboardKeys.Down].includes(pressedKey)
  ) {
    event?.preventDefault();
    const nextColumn = gridColumns.filter((col) => col.getColId() !== ClientDataFixedColumns.Index)[0];

    let nextRowIndex = endRowIndex;
    let previousRowIndex = startRowIndex;
    if (nextRowIndex < totalRowCount) nextRowIndex = endRowIndex + 1;
    if (previousRowIndex > 0) previousRowIndex = startRowIndex - 1;

    clearSelections(api);
    api.setFocusedCell(pressedKey === KeyboardKeys.Down ? nextRowIndex : previousRowIndex, nextColumn);
  }

  // Delete selected cell values with Backspace and Delete keys
  if (!isEditing && [KeyboardKeys.Backspace, KeyboardKeys.Delete].includes(pressedKey)) {
    if (isGridReadOnly) {
      dispatch(
        setToastMessage({ type: ToastMessageType.Info, message: i18n.t(I18nKeys.ClientDataPublishedBranchReadOnly) }),
      );
    } else {
      const selectedRows = api.getSelectedRows();
      if (selectedRows.length === 0) {
        const { selectedTable, cellMetadata } = cellKeyDownEvent.context;

        const updates: { table?: string; data: any; column: string; oldValue: any; newValue: any }[] = [];
        for (let i = startRowIndex; i <= endRowIndex; i += 1) {
          const rowNode = api.getDisplayedRowAtIndex(i);
          if (rowNode) {
            columns.forEach((col) => {
              const colDef = api.getColumnDef(col);
              const newValue = colDef?.type === ColumnDataType.Boolean ? 0 : null;
              updates.push({
                table: selectedTable,
                data: rowNode.data,
                column: col,
                oldValue: rowNode.data[col],
                newValue,
              });
            });
          }
        }
        updateValues(clientDataTableId, updates, cellMetadata, dispatch);
      }
    }
  }
};
