import { I18nKeys } from './I18nKeys';
import {
  FloorplanComponentStyle,
  FloorplanGridStyle,
  FloorplanLabelingMethod,
  FloorplanMeasurementArrowheadStyle,
} from './VendorData';

export const floorplanLabelingMethodOptions = [
  {
    key: FloorplanLabelingMethod.LabelsInLegend,
    label: I18nKeys.SitesFloorplanFloorplanLabelingMethodFieldLabelsInLegend,
  },
  {
    key: FloorplanLabelingMethod.LabelsOnDiagram,
    label: I18nKeys.SitesFloorplanFloorplanLabelingMethodFieldLabelsOnDiagram,
  },
];

export const floorplanGridStyleOptions = [
  {
    key: FloorplanGridStyle.Solid,
    label: I18nKeys.SitesFloorplanFloorplanGridStyleFieldLabelsSolid,
  },
  {
    key: FloorplanGridStyle.Dotted,
    label: I18nKeys.SitesFloorplanFloorplanGridStyleFieldLabelsDotted,
  },
  {
    key: FloorplanGridStyle.None,
    label: I18nKeys.SitesFloorplanFloorplanGridStyleFieldLabelsNone,
  },
];

export const floorplanMeasurementArrowheadStyleOptions = [
  {
    key: FloorplanMeasurementArrowheadStyle.Oblique,
    label: I18nKeys.SitesFloorplanFloorplanMeasurementArrowheadStyleFieldLabelsOblique,
  },
  {
    key: FloorplanMeasurementArrowheadStyle.OpenArrow,
    label: I18nKeys.SitesFloorplanFloorplanMeasurementArrowheadStyleFieldLabelsOpenArrow,
  },
];

export const floorplanComponentStyleOptions = [
  {
    key: FloorplanComponentStyle.Classic,
    label: I18nKeys.SitesFloorplanFloorplanComponentStyleFieldLabelsClassic,
  },
  {
    key: FloorplanComponentStyle.Version2,
    label: I18nKeys.SitesFloorplanFloorplanComponentStyleFieldlabelsVersion2,
  },
];
