import React, { useCallback, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  MenuItemDef,
  SendToClipboardParams,
  ValueGetterParams,
  ColDef,
} from 'ag-grid-community';
import { ContentCopy, InsertLink, OpenInNew } from '@mui/icons-material';
import { renderToStaticMarkup } from 'react-dom/server';
import { AppState } from '../types/AppState';
import { useAppDispatch, useAppSelector } from '../hooks';
import { DataGrid } from './DataGrid';
import {
  addToTableColumn,
  columnHeights,
  defaultColumn,
  getIndexColumnDef,
  goToCellRangeColumn,
  goToCellRangeColumnInNewTab,
} from '../constants/ClientDataColumn';
import { TableData } from '../types/DataGrid';
import { DataGridAccordion } from './DataGridAccordion';
import { ColumnDataType, SearchType } from '../constants/ClientData';
import {
  copyToClipboard,
  searchIndexValueGetter,
  copyEntireRowToClipboard,
  getCellRangeInfo,
  autosizeColumns,
  filterHiddenColumns,
} from '../utils/clientDataUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { useGetClientDataAllTableMetadataQuery } from '../services/clientDataApi';
import { getClientDataTableLink } from '../utils/contextMenuUtils';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { TableMetadata } from '../types/ClientData';
import { mapClientAndDataTypeToClientDataId } from '../utils/clientIdUtils';
import { ClientDataSearchCell } from './ClientDataSearchCell';
import { ClientDataSearchHeader } from './ClientDataSearchHeader';
import { goToCellRange } from '../ducks/clientDataSlice';
import { UserPreference } from '../constants/User';
import { ClientDataPreferences } from '../types/User';

type Props = {
  clientId: string;
  table: string;
  searchData: TableData[];
  expandedView: string;
  handleExpand: () => void;
  getMaxTableHeight: () => string;
  refs: {
    search: React.RefObject<HTMLDivElement>;
    findAll: React.RefObject<HTMLDivElement>;
    rootGrid: React.RefObject<AgGridReact>;
  };
  context: {
    groupId: string;
  };
};

interface ContextMenuParams {
  mainId: string;
  preferences?: ClientDataPreferences;
  metadata?: TableMetadata;
  searchType: SearchType;
}

export const TableSearchResults: React.FC<Props> = ({
  clientId,
  table,
  searchData,
  expandedView,
  handleExpand,
  getMaxTableHeight,
  refs,
  context,
}) => {
  const dispatch = useAppDispatch();

  const containerRef = React.useRef<HTMLDivElement>(null);
  const gridRef = React.useRef<AgGridReact>(null);
  const [orderedSearchData, setOrderedSearchData] = useState<TableData[]>([]);

  const {
    clientId: mainClientId,
    search: {
      value: searchValue = '',
      type: searchType = SearchType.AllTables,
      result: { ids: resultIds = [] } = {},
    } = {},
    selectedTable: mainSelectedTable,
    clientDataBranch,
    clientDataType,
  } = useAppSelector((state: AppState) => state?.clientData);
  const { preferences: { [UserPreference.ClientDataPreferences]: clientDataPreferences = {} } = {} } = useAppSelector(
    (state) => state?.currentUser,
  );
  const {
    clientTables,
    clientTableColumns,
    tableMetadata: mainTableMetadata,
    isInitializingTableMetadata,
  } = useClientDataRepo({
    useClientTables: true,
    useClientTablesColumns: true,
    useTableMetadata: true,
  });

  // Ignore if the results are for the main selected table
  const skip =
    ![SearchType.AllTables, SearchType.AllVendorsAllTables].includes(searchType) ||
    table === mainSelectedTable ||
    !clientDataBranch;
  const { currentData: tablesMetadata = [] } = useGetClientDataAllTableMetadataQuery(
    { dataType: clientDataType, clientId: mainClientId, branch: clientDataBranch as ClientDataBranch },
    {
      skip,
    },
  );
  const tableMetadata =
    tablesMetadata.find(({ formattedTableName }) => formattedTableName === table) || mainTableMetadata;

  const { api: rootGridApi, columnApi: rootGridColApi } = refs.rootGrid.current || {};

  const [count, setCount] = React.useState(0);
  const [columns, setColumns] = React.useState<(string | ColDef)[]>([]);

  React.useEffect(() => {
    if (searchData) {
      const visibleColumns = filterHiddenColumns(mainClientId, clientTableColumns[table], tableMetadata);

      // Only look up tables that match exactly (i.e. frameoutCorner not pricingFrameoutCorner)
      const regex = new RegExp(`${clientId}:${table}:`);
      const filteredResultIds = resultIds.filter((id) => regex.test(id));
      const resultColumns = filteredResultIds.reduce((uniqueColumns, id) => {
        const column = id.substring(id.lastIndexOf(':') + 1);
        if (!uniqueColumns.includes(column) && visibleColumns.includes(column)) return [...uniqueColumns, column];
        return uniqueColumns;
      }, [] as string[]);

      // Check column metadata for columns that should always be displayed in the results
      const hasColumn = (column: string) => searchData.some((row) => !!row[column]);
      const columnMetadata = tableMetadata?.metadata;
      if (columnMetadata) {
        Object.entries(columnMetadata).forEach(([column, { searchDefault = false }]) => {
          if (searchDefault && hasColumn(column) && !resultColumns.includes(column)) resultColumns.unshift(column);
        });
      }

      setCount(filteredResultIds.length);
      setColumns(
        visibleColumns.map((column) => {
          const { dataType } = (columnMetadata || {})[column] || {};
          return {
            field: column,
            hide: !resultColumns.includes(column),
            floatingFilter: false,
            editable: false,
            type: dataType && [ColumnDataType.Enum, ColumnDataType.Lookup].includes(dataType) ? undefined : dataType,
          };
        }),
      );
    }
  }, [clientId, searchData, searchValue, tableMetadata]);

  React.useEffect(() => {
    if (searchData) {
      const orderedData = [...searchData].sort((a, b) => {
        const [i, j] = [a, b].map((row) => {
          const id = row[ClientDataFixedColumns.RowId] as string;
          const rootGridRow = rootGridApi?.getRowNode(id);
          if (rootGridRow) return rootGridApi?.getValue(ClientDataFixedColumns.Index, rootGridRow);
          return 0;
        });
        return i - j;
      });
      setOrderedSearchData(orderedData);
    } else {
      setOrderedSearchData([]);
    }
  }, [searchData, table]);

  const sendToClipboard = useCallback((params: SendToClipboardParams) => {
    const copiedData = copyToClipboard(params);
    navigator.clipboard.writeText(copiedData);
  }, []);

  const contextMenuParams: ContextMenuParams = {
    mainId: mainClientId,
    preferences: clientDataPreferences,
    metadata: tableMetadata,
    searchType,
  };

  const getContextMenuItems = useCallback(
    (
      { api, column, node, context: gridContext }: GetContextMenuItemsParams,
      { mainId, preferences, metadata, searchType: type }: ContextMenuParams,
    ): (string | MenuItemDef)[] => {
      const {
        groupId,
        clientId: displayedClientId,
        clientDataType: displayedClientDataType,
        table: gridTable,
      } = gridContext;
      const displayedClientDataId = mapClientAndDataTypeToClientDataId(displayedClientId, displayedClientDataType);
      const cellSelected = node && column;
      const cellRanges = api.getCellRanges();
      const { startRowIndex, endRowIndex, rowCount, columns: selectedColumns } = getCellRangeInfo(cellRanges);

      const startRowId = api.getDisplayedRowAtIndex(startRowIndex)?.id;
      const endRowId = api.getDisplayedRowAtIndex(endRowIndex)?.id;
      const disableCellLink =
        !startRowId || !endRowId || !selectedColumns?.length || !groupId || !displayedClientDataId || !gridTable;
      const { data: { [ClientDataFixedColumns.RowId]: nodeRowId = '' } = {} } = node || {};

      const result: (string | MenuItemDef)[] = [
        ...(cellSelected
          ? [
              'copy',
              'copyWithHeaders',
              {
                // custom item to add rows above
                name: `Copy entire row${rowCount > 1 ? 's' : ''}`,
                action: () => {
                  const data = node?.parent?.allLeafChildren;

                  if (data) {
                    const dataToCopy = copyEntireRowToClipboard(
                      mainId,
                      data.slice(startRowIndex, endRowIndex + 1),
                      preferences,
                      metadata,
                    );
                    navigator.clipboard.writeText(dataToCopy);
                  }
                },
                icon: renderToStaticMarkup(<ContentCopy className="ag-icon" />),
              },
              {
                // custom item to add rows above
                name: `Copy entire row${rowCount > 1 ? 's' : ''} with Headers`,
                action: () => {
                  const data = node?.parent?.allLeafChildren;

                  if (data) {
                    const dataToCopy = copyEntireRowToClipboard(
                      mainId,
                      data.slice(startRowIndex, endRowIndex + 1),
                      preferences,
                      metadata,
                      true,
                    );
                    navigator.clipboard.writeText(dataToCopy);
                  }
                },
                icon: renderToStaticMarkup(<ContentCopy className="ag-icon" />),
              },
              'separator',
              {
                // custom item
                name: 'Get Link to this Cell',
                action: async () => {
                  const cellLink = getClientDataTableLink(groupId, displayedClientDataId, ClientDataBranch.Main, {
                    start: startRowId,
                    end: endRowId,
                    columns: selectedColumns,
                    table: gridTable,
                  });
                  await navigator.clipboard.writeText(cellLink);
                },
                icon: renderToStaticMarkup(<InsertLink className="ag-icon" />),
                disabled: disableCellLink,
              },
              ...([SearchType.CurrentSelection, SearchType.CurrentTable, SearchType.AllTables].includes(type)
                ? [
                    {
                      name: 'Go to this Cell',
                      action: () => {
                        dispatch(
                          goToCellRange({
                            table,
                            location: { startRowId: nodeRowId, endRowId: nodeRowId, columns: [column.getId()] },
                            rootGridApi,
                            rootGridColApi,
                          }),
                        );
                      },
                      icon: renderToStaticMarkup(<OpenInNew className="ag-icon" />),
                    },
                  ]
                : []),
              'separator',
            ]
          : []),
        'export',
      ];
      return result;
    },
    [],
  );

  const getMainMenuItems = useCallback(
    ({ column }: GetMainMenuItemsParams) => [
      'pinSubMenu',
      {
        name: 'Go to this Column',
        action: () => {
          dispatch(
            goToCellRange({
              table,
              location: { columns: [column.getId()] },
              rootGridApi,
              rootGridColApi,
            }),
          );
        },
        icon: renderToStaticMarkup(<OpenInNew className="ag-icon" />),
      },
      'separator',
      'autoSizeThis',
      'autoSizeAll',
      'separator',
      'resetColumns',
    ],
    [],
  );

  const updateGridContainerHeightAndAutosizeColumns = () => {
    const maxTableHeight = getMaxTableHeight();

    const { api } = gridRef.current || {};
    const rowCount = api?.getDisplayedRowCount() || 0;
    const columnDefinitions = api?.getColumnDefs() || [];

    const getMaxColTypeRowHeight = (height: number, type?: string | string[]): number => {
      if (!type) return height;
      if (typeof type === 'string')
        return Math.max(height, (columnHeights as { [columnType: string]: number })?.[type] || 0);
      return type.reduce((maxHeight: number, t: string) => getMaxColTypeRowHeight(maxHeight, t), height);
    };
    const singleRowHeight = columnDefinitions.reduce((height, def) => {
      const columnDef = def as { type?: string | string[]; hide?: boolean };
      return Math.max(height, !columnDef?.hide ? getMaxColTypeRowHeight(height, columnDef?.type) : 0);
    }, columnHeights[ColumnDataType.Text]);
    const rowsHeight = `${54 + rowCount * singleRowHeight}px`;
    // Set the height of the container to the height of the grid view
    if (containerRef?.current) {
      containerRef.current.style.height = `max(min(max(${maxTableHeight}, 300px), ${rowsHeight}), 50px)`;
    }
    autosizeColumns(gridRef.current?.columnApi, clientDataPreferences, tableMetadata);
  };

  const selectedTableColumns = useMemo(() => {
    const selectedColumns = [
      {
        ...getIndexColumnDef({}),
        valueGetter: (params: ValueGetterParams) => searchIndexValueGetter(params, rootGridApi),
      },
      ...columns,
    ];

    // Show the Go To Cell column if the search is for the current client
    if (
      [SearchType.CurrentSelection, SearchType.CurrentTable, SearchType.AllTables].includes(searchType) &&
      searchData.length
    )
      return [...selectedColumns, { ...goToCellRangeColumn }];
    // Show the Go To Cell column and Add to Table column if the search is for the current table and all clients
    if ([SearchType.AllVendorsCurrentTable].includes(searchType) && searchData.length)
      return [...selectedColumns, { ...goToCellRangeColumnInNewTab }, { ...addToTableColumn }];

    return selectedColumns;
  }, [rootGridApi, columns, searchType]);

  return count ? (
    <div style={searchType === SearchType.AllVendorsAllTables ? { display: 'flex', flexDirection: 'row' } : {}}>
      {searchType === SearchType.AllVendorsAllTables && (
        <div style={{ width: '34px', backgroundColor: '#F5F5F5', border: '1px solid #D0D0D0' }} />
      )}
      <DataGridAccordion
        hideExpand={![SearchType.AllTables, SearchType.AllVendorsAllTables].includes(searchType)}
        expanded={!!expandedView && expandedView.split(':')[1] === table}
        handleExpand={handleExpand}
        title={clientTables.find((t) => t.formattedTableName === table)?.label || table}
        resultCount={count}
      >
        <div ref={containerRef}>
          <DataGrid
            clientId={clientId}
            gridRef={gridRef}
            hideOverlay
            data={orderedSearchData}
            tableMetadata={tableMetadata}
            isLoadingTableMetadata={isInitializingTableMetadata}
            onModelUpdated={updateGridContainerHeightAndAutosizeColumns}
            onFirstDataRendered={updateGridContainerHeightAndAutosizeColumns}
            getContextMenuItems={(params: GetContextMenuItemsParams) => getContextMenuItems(params, contextMenuParams)}
            getMainMenuItems={getMainMenuItems}
            selectedTable={table}
            sendToClipboard={sendToClipboard}
            selectedTableColumns={selectedTableColumns}
            defaultColDefinition={{
              ...defaultColumn,
              cellRenderer: ClientDataSearchCell,
              headerComponent: ClientDataSearchHeader,
            }}
            context={{
              highlightSearchResults: true,
              table,
              rootGridApi,
              rootGridColApi,
              ...context,
            }}
            popupParent={(!!refs.search.current && refs.search.current) || undefined}
          />
        </div>
      </DataGridAccordion>
    </div>
  ) : null;
};
