import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import React from 'react';
import { SortableProperty } from '../types/SortableProperty';
import { SortProperty } from '../types/SortProperty';
import { TableSortMenu } from './TableSortMenu';

interface Props {
  filterRows?: JSX.Element;
  filterColumns?: JSX.Element;
  sortableProperties?: SortableProperty[];
  sortProperties?: SortProperty[];
  onSort(sortProperties: SortProperty[]): void;
}

export const TableControls: React.FC<Props> = ({
  filterRows,
  filterColumns,
  sortProperties = [],
  sortableProperties = [],
  onSort,
}: Props) => (
  <Grid container direction="row" alignItems="flex-start" justifyContent="space-between">
    <Grid container item alignItems="center" xs>
      {filterRows}
    </Grid>
    <Grid item>
      <Hidden smUp>
        <TableSortMenu sortProperties={sortProperties} sortableProperties={sortableProperties} onSort={onSort} />
      </Hidden>
    </Grid>
    <Grid container item alignItems="flex-start" xs={2}>
      {filterColumns}
    </Grid>
  </Grid>
);
