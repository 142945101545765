import { Configurator } from '@idearoom/sdk';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Trans, useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  CARPORTVIEW,
  mapClientAndDataTypeAndTableToUndoStackId,
  mapClientIdToConfiguratorAndVendor,
} from '../utils/clientIdUtils';
import { getConfiguratorUrlWithLocale } from '../utils/vendorUtils';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientDataType } from '../constants/ClientDataType';
import { setStyleIconsToGenerate } from '../ducks/clientDataSlice';
import { I18nKeys } from '../constants/I18nKeys';
import { updateValues } from '../utils/clientDataUtils';
import { TableData } from '../types/DataGrid';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { S3Buckets } from '../constants/S3';
import { clientDataApi } from '../services/clientDataApi';
import { IFCIconImagesSize, IFSIconImagesSize } from '../constants/ClientData';
import { config } from '../config/config';
import { s3Api } from '../services/s3Api';
import { unknownGroup } from '../constants/Group';

/**
 * Turn it on to see the configurator working
 */
const DEBUG_MODE = false;

const DEFAULT_ICON_SCALE = 4;
const DEFAULT_ICON_CARPORTS_ZOOM = 0.1;
const DEFAULT_ICON_SHEDS_ZOOM = 0.2;

const useStyles = makeStyles<Theme>((theme) => ({
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  statusIcon: {
    width: '30px',
    height: '30px',
    marginRight: '15px',
  },
  errorColor: {
    color: theme.palette.error.dark,
  },
  successColor: {
    color: theme.palette.success.dark,
  },
  dialogActions: { padding: '0px 8px 8px 8px' },
}));

export const ClientDataGenerateStyleIconDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    clientData: { clientId, clientDataBranch = ClientDataBranch.Main, clientDataType, styleIconsToGenerate },
    currentUser: { group: { groupId } = unknownGroup },
  } = useAppSelector((state) => state);
  const { cellMetadata, vendorData: { locale = '', productionURL = '' } = {} } = useClientDataRepo({
    useCellMetadata: true,
    useVendorData: true,
  });
  const dispatch = useAppDispatch();
  const [configuratorSdk, setConfiguratorSdk] = useState<Configurator>();
  const [configuratorComponentRoot, setConfiguratorComponentRoot] = useState<Root>();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<string | undefined>(undefined);
  const [generatingIndex, setGeneratingIndex] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState<string | undefined>(undefined);

  const embeddedConfiguratorContainer = 'embedded-configurator-container';
  const embeddedConfigurator = 'embedded-configurator';
  const embeddedConfiguratorDiv = <div id={embeddedConfigurator} style={{ display: 'flex', flex: '1' }} />;
  const ensureEmbeddedConfiguratorDivExists = (): void => {
    if (configuratorSdk) {
      configuratorSdk.destroy();
      setConfiguratorSdk(undefined);
    }
    if (configuratorComponentRoot) {
      configuratorComponentRoot.unmount();
    }
    const configuratorContainerElement = document.getElementById(embeddedConfiguratorContainer);
    if (configuratorContainerElement) {
      const root = createRoot(configuratorContainerElement);
      root.render(embeddedConfiguratorDiv);
      setConfiguratorComponentRoot(root);
    }
  };

  const handleClose = (): void => {
    ensureEmbeddedConfiguratorDivExists();
    dispatch(setStyleIconsToGenerate(undefined));
    setDialogOpen(false);
    setIsSuccess(false);
    setIsError(undefined);
    setGeneratingIndex(0);
  };

  useEffect(() => {
    if (styleIconsToGenerate) {
      setDialogOpen(true);

      const clientBranch = clientDataType === ClientDataType.Vendor ? clientDataBranch : ClientDataBranch.Main;
      const structureBranch = clientDataType === ClientDataType.Supplier ? clientDataBranch : ClientDataBranch.Main;
      const systemBranch = ClientDataBranch.Main;
      const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);

      setIsLoading('Loading embedded configurator...');

      const generateStyleIcons = async () => {
        let configuratorUrl = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
        if (config.environment.STAGE !== 'localhost') {
          // Force use HTTPS as our SDK doesn't allow non-secure URLs inside secure sites
          configuratorUrl = configuratorUrl.replace('http://', 'https://');
        }
        configuratorUrl =
          `${configuratorUrl}` +
          `${configuratorUrl.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2` +
          `&clientSettings=${clientBranch}` +
          `&structureSettings=${structureBranch}` +
          `&systemSettings=${systemBranch}` +
          `&fetchFromServer=true`;
        const sdk = new Configurator(configuratorUrl, embeddedConfigurator);
        setConfiguratorSdk(sdk);

        const iconScale = DEFAULT_ICON_SCALE;
        const iconZoom = configurator === CARPORTVIEW ? DEFAULT_ICON_CARPORTS_ZOOM : DEFAULT_ICON_SHEDS_ZOOM;

        let imgWidth: number = IFSIconImagesSize.Width;
        let imgHeight: number = IFSIconImagesSize.Height;
        if (configurator === CARPORTVIEW) {
          imgWidth = IFCIconImagesSize.Width;
          imgHeight = IFCIconImagesSize.Height;
        }

        try {
          await new Promise<void>((resolve, reject) => {
            const errorListener = (error: any) => {
              reject(error);
              sdk.removeOnErrorListener(errorListener);
            };

            sdk.onConnected = async () => {
              resolve();
              sdk.removeOnErrorListener(errorListener);
            };
            sdk.addOnErrorListener(errorListener);
          });

          sdk.closeLocationDialog();

          const uploadList: Promise<{
            row: TableData;
            fileUrl: string;
          }>[] = [];

          let tableData: TableData[] = [];
          if (styleIconsToGenerate.column === 'value1') {
            tableData = await dispatch(
              clientDataApi.endpoints.getClientDataTableData.initiate(
                {
                  dataType: clientDataType,
                  branch: clientDataBranch,
                  clientId,
                  groupId,
                  table: styleIconsToGenerate.table,
                },
                { subscribe: false },
              ),
            ).unwrap();
          }

          let currentlyLoadedSupplier: string | undefined;
          for (let i = 0; i < styleIconsToGenerate.rows.length; i += 1) {
            const row = styleIconsToGenerate.rows[i];

            let styleKey = row.key as string;
            // Workaround for Sheds Style table
            if (styleIconsToGenerate.column === 'value1') {
              const keyRow = tableData.find(
                (rowData) => rowData.style === row.style && rowData.property === 'style key',
              );
              if (keyRow) {
                styleKey = keyRow.value1 as string;
              } else {
                throw new Error(
                  `Couldn't find 'style key' for [${row.style}] style. Make sure to add a key for that style first.`,
                );
              }
            }

            if (!styleKey) {
              throw new Error(`Couldn't find the style key value for style ${i + 1}.`);
            }

            setIsLoading((row.label as string) || styleKey);
            setGeneratingIndex(i + 1);

            const { supplierKey } = row;
            if (
              clientDataType === ClientDataType.Vendor &&
              supplierKey &&
              typeof supplierKey === 'string' &&
              supplierKey !== currentlyLoadedSupplier
            ) {
              // eslint-disable-next-line no-await-in-loop
              await sdk.changeStructureSupplier(supplierKey);
              currentlyLoadedSupplier = supplierKey;
            }

            if (!styleKey) {
              throw new Error('Missing style key information.');
            }
            // eslint-disable-next-line no-await-in-loop
            await sdk.changeStyle(styleKey);

            // eslint-disable-next-line no-await-in-loop
            const image = await sdk.saveScreenshot(imgWidth * iconScale, imgHeight * iconScale, true, iconZoom, true);

            if (!image.src) {
              throw new Error('Failed to generate style image.');
            }

            const name = `icon-${configurator}-${clientDataType}-${vendor}-${styleKey}-${Date.now()}.png`;
            uploadList.push(
              dispatch(
                s3Api.endpoints.uploadFile.initiate({
                  bucket: S3Buckets.Assets,
                  path: `images`,
                  file: {
                    name,
                    content: image.src.replace(/data:image\/[a-z]+;base64/i, ''),
                    contentType: 'image/png',
                  },
                }),
              )
                .unwrap()
                .then(({ fileUrl }) => ({
                  fileUrl,
                  row,
                })),
            );
          }

          setIsLoading('Uploading images to S3...');
          const uploaded = await Promise.all(uploadList);
          const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(
            clientId,
            clientDataType,
            styleIconsToGenerate.table,
          );

          updateValues(
            clientDataTableId,
            uploaded.map((upload) => ({
              table: styleIconsToGenerate.table,
              data: upload.row,
              column: styleIconsToGenerate.column,
              oldValue: upload.row[styleIconsToGenerate.column],
              newValue: upload.fileUrl,
            })),
            cellMetadata,
            dispatch,
          );

          setIsLoading(undefined);
          setIsSuccess(true);
        } catch (error) {
          setIsLoading(undefined);
          setIsError(`${error}`);
        }
      };

      generateStyleIcons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [styleIconsToGenerate]);

  return (
    <>
      <Dialog open={isDialogOpen} maxWidth="lg">
        <DialogContent className={classes.dialogContent}>
          {isLoading && <CircularProgress className={classes.statusIcon} size={30} style={{ padding: '2px' }} />}
          {isSuccess && <CheckCircleIcon className={`${classes.statusIcon} ${classes.successColor}`} />}
          {isError && <CancelIcon className={`${classes.statusIcon} ${classes.errorColor}`} />}
          <div>
            {isLoading && styleIconsToGenerate && (
              <>
                <Typography gutterBottom>
                  <Trans
                    i18nKey={I18nKeys.ClientDataGenerateIconDialogGenerating as string}
                    values={{ actual: generatingIndex, total: styleIconsToGenerate.rows.length }}
                  />
                </Typography>
                <Typography>{isLoading}</Typography>
              </>
            )}
            {isSuccess && styleIconsToGenerate && (
              <Typography>
                <Trans
                  i18nKey={I18nKeys.ClientDataGenerateIconDialogSuccess as string}
                  values={{ total: styleIconsToGenerate.rows.length }}
                />
              </Typography>
            )}
            {isError && (
              <>
                <Typography>
                  <Trans i18nKey={I18nKeys.ClientDataGenerateIconDialogError as string} />
                </Typography>
                <Alert severity="error" icon={false}>
                  <code>{isError}</code>
                </Alert>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} color="primary">
            {t(I18nKeys.DialogCloseButton)}
          </Button>
        </DialogActions>
      </Dialog>
      <div
        id={embeddedConfiguratorContainer}
        style={{
          ...((isLoading || isSuccess || isError) && !DEBUG_MODE ? { opacity: '0' } : {}),
          ...(DEBUG_MODE ? { zIndex: 1000 } : {}),
          display: isDialogOpen ? 'flex' : 'none',
          position: 'absolute',
          flex: '1',
          minHeight: '1000px',
          maxHeight: '1000px',
          minWidth: '1000px',
          maxWidth: '1000px',
        }}
      >
        {embeddedConfiguratorDiv}
      </div>
    </>
  );
};
